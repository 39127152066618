import geoViewport from "@mapbox/geo-viewport";
import {Util} from "./util.js";
/**
 * obecna pomocna knihovna pro mapy nad Mapboxem
 * zavisi na Mapbox GL api
*/
const MBUtil = {
  /**
   * default map options
  */
  mapOptions : {
      container: "map",
      style: 'https://www.xcontest.org/test/mapbox/test-xc.json',
      center: [15.5, 50.7],
      zoom: 9
  },

  /**
   * options: object
   * - objekt options pro vytvoreni Mapbox GL mapy
   * - prepisuje defaultni options v MBUtil, takze muze byt null
   * bounds: [minX, minY, maxX, maxY] optional
   * - pokud jsou predany, pocita se automaticky inicialni center a zoom pres geo-viewport plugin
   * @returns object map, nebo null, pokud Mapbox neni supported
  */
  loadMap (options = null, bounds) {
    if (!mapboxgl.supported()) return null;

    options = Object.assign({}, MBUtil.mapOptions, options);

    if (bounds) {
      let node = typeof options.container == "string" ? document.querySelector("#"+options.container) : options.container;
      let vp = MBUtil.getViewport(node, bounds);
      options.center = vp.center;
      options.zoom = vp.zoom;
    };

    //init
    return new mapboxgl.Map(options);
  },

  /**
   * aplikuje na vytvorenou mapu default controls a chovani
  */
  prepareMapBehavior (map, pgStore) {
    // disabling behavior
    //map.dragRotate.disable();
    //map.touchZoomRotate.disableRotation();

    const NaviControl = new mapboxgl.NavigationControl();
    pgStore.set({NaviControl});

    //add user controls
    map.addControl(new mapboxgl.AttributionControl({compact: true}), 'bottom-right');
    map.addControl(new mapboxgl.ScaleControl(), 'bottom-left');
    map.addControl(NaviControl, 'bottom-right');

    return map;
  },

  mapDataPromise (map, fDataLoaded) {
    return new Promise((resolve, reject) => {
     const onData = evt => {
       if (!fDataLoaded(map, evt)) return;
       resolve(map);
       map.off('data', onData);
     };
     map.on('data', onData);
   })
  },

  /**
   * spocita bounding box z pole pozic
   * @param coords - pole koordinat z GeoJSON, tj. [[x,y],...]
   * @returns mapbox LngLatBounds
  */
  boundsFromCoords (coords) {
    let lnglat0 = [coords[0][0], coords[0][1]];
    return coords.reduce(function(bounds, coord) {
        return bounds.extend([coord[0], coord[1]]);
    }, new mapboxgl.LngLatBounds(lnglat0, lnglat0));
  },

  /**
   * stejne jako boundsFromCoords, ale jako vstup bere currentCoords array
   * @param coords - store.get().currentCoords,tj. [[id,[x,y]], ...] kde misto [x,y] muze byt null|-1|1
   * @returns mapbox LngLatBounds | null (pokud neni ani jedina coords aktivni)
  */
  boundsFromCurrentCoords (coords) {
    coords = coords
      .filter(([id, coord]) => Array.isArray(coord))
      .map(([id, coord]) => coord);

    return coords.length>0 ? this.boundsFromCoords(coords) : null;
  },

  /**
   * konvertuje bounds z API dat {minX:.., minY:...,maxX:..., maxY} do mapbox LngLatBounds
   * b mohou muze byt objekt {minX:.., minY:...,maxX:..., maxY} nebo pole techto objektu
  */
  boundsFromAPI (b) {
    if (Array.isArray(b)) {
      b = b.reduce((acc, curr) => {
        acc.minX = curr.minX < acc.minX ? curr.minX : acc.minX;
        acc.minY = curr.minY < acc.minY ? curr.minY : acc.minY;
        acc.maxX = curr.maxX > acc.maxX ? curr.maxX : acc.maxX;
        acc.maxY = curr.maxY > acc.maxY ? curr.maxY : acc.maxY;
        return acc;
      }, {minX: 180, minY: 90,maxX: -180, maxY: -90});
    };
    return new mapboxgl.LngLatBounds([b.minX, b.minY], [b.maxX, b.maxY]);
  },

  /**
   * @param bounds - mapbox LngLatBounds
  */
  cloneBounds (bounds) {
    const [x,y] = bounds.toArray();
    return new mapboxgl.LngLatBounds(x, y);
  },

  /**
   * rozsiruje dane mapbox lngLatBounds o prislusny pocet px na kazde strane
   * @param map - mapbox map Object
   * @param bounds - mapbox LngLatBounds
   * @param px - int, pocet pixelu o ktere se zvetsuji bounds
   * @returns mapbox LngLatBounds
  */
  extendBounds (map, bounds, px) {
    return mapboxgl.LngLatBounds.convert(
      bounds.toArray()
      .map((lngLat, i) => {
        const dif = px * (i==0 ? -1 : 1);
        const p = map.project(lngLat);
        p.x += dif;
        p.y -= dif;
        return map.unproject(p);
      })
    );
  },

  /**
   * @param bounds0 - mapbox LngLatBounds
   * @param bounds1 - mapbox LngLatBounds
  */
  isBoundsEqual (bounds0, bounds1) {
    return bounds0.toString() == bounds1.toString();
  },

  /**
   * @param masterBounds - mapbox LngLatBounds
   * @param bounds - mapbox LngLatBounds
  */
  isBoundsInside (masterBounds, bounds) {
    const extBounds = this.cloneBounds(masterBounds).extend(bounds);
    return this.isBoundsEqual(masterBounds, extBounds);
  },

  /**
   * vraci Array Mapbox LngLatLike
   * Point Array - extended GeoJSON point [x,y,z, {...}]
  */
  pointToLngLatLike (Point = []) {
    const [x,y] = Point;
    return [x,y];
  },

  /**
   * vytvari Mapbox Marker
   * @param options Marker options
   * @param coords - lnglat coordinates
   * @returns Mapbox Marker
  */
  createMarker (options, coords) {
    return MBUtil.updateMarker(
      new mapboxgl.Marker(options),
      coords
    );
  },

  updateMarker (marker, coords) {
    return marker.setLngLat(coords);
  },

  /**
   * prida marker do mapy
   * @param marker mapbox marker
   * @param map mapbox map instance
  */
  showMarker (marker, map) {
    return marker.addTo(map);
  },

  /**
   * odebere marker z mapy
   * @param marker mapbox marker
  */
  hideMarker (marker) {
    return marker.remove();
  },

  /**
   * vyzaduje geo-viewport
   * bounds: [minX, minY, maxX, maxY]
  */
  getViewport (mapNode, bounds) {
    var mapRect = mapNode.getBoundingClientRect();
    //console.log(bounds, mapRect);
    return geoViewport.viewport(bounds, [mapRect.width, mapRect.height], 0, 20, 512, false, false);
  },

  /**
   * merge 2 json stylu
   * @param style0 - JSON, mapbox GL style
   * @param style1 - JSON, mapbox GL style
   * @param layers0first - jestli maji mit prednost vrstvy ve style0
   * spoji tak, ze pridava vrstvy ze style1 za vrstvy ze style 0
   * v pripade konfliktu (stejny ID) ma prednost vrstva ze style1 (duplikat ze style0 bude vyrazen)
   * @returns style - JSON, mapbox GL style
  */
  mergeStyles (style0, style1, layers0First = true) {
    //metadata and sources
    ['metadata', 'sources'].forEach(key => {
      Object.assign(style0[key], Util.fresh(style1[key]));
    });
    //sprite, glyphs
    ['sprite', 'glyphs'].forEach(key => {
      if (style1[key]) style0[key] = style1[key];
    });
    //layers
    const s1layersById = style1.layers.reduce((acc, layer) => {
      acc[layer.id] = layer;
      return acc;
    }, {});

    const layers0 = style0.layers
      .filter(layer => !s1layersById[layer.id]);
    const layers1 = style1.layers;

    style0.layers =  layers0First ? [...layers0, ...layers1] : [...layers1, ...layers0];
    return style0;
  },

  getStyleLayer (style, id) {
     return style.layers.find(layer => layer.id == id);
  },

  layerDefModel : {
    line : {
      "id": null,
      "type": "line",
      "source": {
        "type": "geojson",
        "data": null
      },
      "layout": {
        "line-join": "round",
        "line-cap": "round",
        "visibility": "none"
      },
      "paint": {}
    },
    fill : {
      "id": null,
      "type": "fill",
      "source": {
        "type": "geojson",
        "data": null
      },
      "layout": {
        "visibility": "visible"
      },
      "paint": {}
    },
    symbol : {
      "id": null,
      "type": "symbol",
      "source": {
        "type": "geojson",
        "data": null
      },
      "layout": {
        "visibility": "visible"
      },
      "paint": {}
    }
  },

  /**
   * @param id - id layeru,
   * @param type -
    - flight-line|flight-outline|route-line|walk-line|walk-outline [line]
    - airspace [fill]
    - positions [symbol]
    - user-position [symbol]
   * @param source - geojson data (object) | id source (string)
   * @param color - color layeru (ne u airspace)
   * @param assign0 - object, jehoz properties se pridavaji do definice layeru
  */
  getLayerDef (id, type, source, color = null, assignO = {}) {
    let modelKey = null;
    let def = {};


    switch (type) {
      case "flight-line":
        modelKey = 'line';
        def = {
          "id": id,
          "paint": {
            "line-color": color,
            "line-width": 2
          }
        };
        break;

      case "flight-outline":
        modelKey = 'line';
        def = {
          id,
          "paint": {
            "line-color": color,
            "line-width": 0.5,
            "line-gap-width": 2,
            "line-opacity": 0.5
          }
        };
        break;

      case "route-line":
        modelKey = 'line';
        def = {
          id,
          "paint": {
            "line-color": color,
            "line-width": 1
          },
          "layout": {
            "visibility": "visible"
          },
        };
        break;

      case "walk-line":
        modelKey = 'line';
        def = {
          id,
          "paint": {
            "line-color": color,
            "line-width": 3,
            "line-dasharray": [0.0, 1.6]
          }
        };
        break;

      case "walk-outline":
        modelKey = 'line';
        def = {
          id,
          "paint": {
            "line-color": color,
            "line-width": 3
          }
        };
        break;

      case "airspace":
        modelKey = 'fill';
        def = {
          id,
          "paint": {
            "fill-outline-color": ['get', 'strokeColor'],
            "fill-color": ['get', 'fillColor'],
            "fill-opacity": ['get', 'fillOpacity']
          }/*,
          "filter": ["!=", 'zIndex', 0] //jen airspace ktere nemaji zIndex == 0 (vynecha treba CZ IFR)
          */
        };
        break;

        case "task-fill":
          modelKey = 'fill';
          def = {
            id,
            "paint": {
              "fill-outline-color": ['get', 'outlineColor'],
              "fill-color": ['get', 'color'],
              "fill-opacity": ['get', 'opacity']
            }
          };
          break;

          case "task-line":
            modelKey = 'line';
            def = {
              id,
              "paint": {
                "line-color": ['get', 'color'],
                "line-width": ['get', 'width'],
                //"line-gap-width": 1,

                "line-opacity": ['get', 'opacity'],
              }
            };
            break;

      case "positions":
      case "user-position":
      case "task-symbol":
        modelKey = 'symbol';
        def = {id};
        break;
    };

    let model = Util.fresh(MBUtil.layerDefModel[modelKey]);

    const LD = Object.assign(
      model, def, assignO
    );

    if (typeof source=='string') {
      LD.source = source;
    } else {
      LD.source.data = source;
    };

    return LD;
  },

  /**
   * vraci cistou kopii result objektu
   * @param type - GeoJSON type (LineString|Point atd)
  */
  getFreshResultObject (type) {
    return {
      geojson : Util.getFreshGeoJSON(type)
    };
  },

  changeVisibility (map, hide = [], show = []) {
    hide.forEach(id => map.getLayer(id) ? map.setLayoutProperty(id, "visibility", "none") : map);
    show.forEach(id => map.getLayer(id) ? map.setLayoutProperty(id, "visibility", "visible") : map);
  },

  changeLineColor (map, id, color, setGradient = false) {
    map.setPaintProperty(id, 'line-color', color);
    if (setGradient) {
      this.changeLineGradient(map, id, [0, color, 1, color]);
    };
  },

  changeLineGradient (map, id, gradientPoints = [], validate = false) {
    const gradient = ['interpolate', ['linear'], ['line-progress']].concat(gradientPoints);

    map.setPaintProperty(
      id,
      'line-gradient',
      gradient,
      {validate}
    );
  },

  SOURCE_DATA : new Map(),
  SOURCE_DATA_LISTEN : false,

  /**
   * chytre provadi source.setData()
   * bud provede ihned, pokud je source loaded
   * nebo ulozi do SOURCE_DATA Map objektu pro pozdejsi setData() v _processSetData
  */
  setSourceData (map, sourceId, data, force = false) {
    const src = map.getSource(sourceId);

    if (force) {
      if (src) src.setData(data);
      return;
    };

    if (src && map.isSourceLoaded(sourceId)) {
      const staleUrl = src._data;
      src.setData(data);
      window.URL.revokeObjectURL(staleUrl);
    } else {
      this._attachProcessSetData(map);
      const staleUrl = this.SOURCE_DATA.get(sourceId);
      this.SOURCE_DATA.set(sourceId, {map, data});
      if (staleUrl) {
        window.URL.revokeObjectURL(staleUrl);
      };
    };
  },

  _attachProcessSetData (map) {
    if (this.SOURCE_DATA_LISTEN) return;
    map.on('sourcedata', evt => {
      this._processSetData(evt.sourceId, evt);
    });
    this.SOURCE_DATA_LISTEN = true;
  },

  /**
   * vola se z map.on(sourcedata)
   * zpracovava odlozena source data v pripade, ze source nebyl jeste loaded a proto nebylo zavolano setData() ihned
  */

  _processSetData (sourceId, e) {
//console.log('processSourceData.event', sourceId, e.source.data)
//console.dir(e)
    const item = this.SOURCE_DATA.get(sourceId);
    if (!item) return;
    const {map, data} = item;

    //const map = store.get().map;
    const src = map.getSource(sourceId);
    if (!src || ! map.isSourceLoaded(sourceId)) return;
    const staleUrl = src._data;
    src.setData(data);
    window.URL.revokeObjectURL(staleUrl);
//console.log('processSourceData.revoke', sourceId, staleUrl, data, e.source.data)
//console.dir(e)
    this.SOURCE_DATA.delete(sourceId);
  },

  /**
   * normalize souradnice po pricteni ci odecteni nejake hodnoty na -90;90 (lat) nebo -180;180 (lng)
   * @param value - lat nebo lng
   * @param dim - X|Y (tj. jestli je to lng nebo lat)

  */
  normalize (value, dim = 'X') {
    if (dim == 'X') {
      if (value > 180) {
        value -= 360;
      } else if (value < -180) {
        value += 360;
      }
    } else if (dim == 'Y') {
      if (value > 90) {
        value = 180 - value;
      } else if (value < -90) {
        value = -180 - value;
      }
    };
    return value;
  }
};
export {MBUtil};
