import { Util } from "./util.js";
import { writable as w, readable as r } from 'svelte/store';
import { EventStore, watchable, watchableDerived } from "./store-ext.js";

import {
  hires, playRunMode, playRun, playMode
} from './store-shared.js';

import {
  fromState
} from './helpers.js';


class ViewStore extends EventStore {
  constructor () {
    super();

    this.setStores(this._getBasic());

    this.setStores(this._getDerived());

    this._setEvents();
  }

  _getBasic () {

    //nactem si defaulty z localStorage
    let lS_baseSize = null;
    let lS_posAgl = false;
    let lS_posSpeed = false;
    let lS_posVario = false;
    try {
      const lS = window.localStorage;

      lS_baseSize = parseInt(lS.getItem('baseSize'));
      lS_posAgl = lS.getItem('posAgl') === 'true';
      lS_posSpeed = lS.getItem('posSpeed') === 'true';
      lS_posVario = lS.getItem('posVario') === 'true';
    } catch (err) {
      console.log("localStorage read failed", err);
    };

    return {
      fullscreen : watchable(false), //je mapa ve fullscreenu?
      sat : watchable(fromState('sat', false)), //je zobrazena sat mapa?
      airspace : watchable(false), //jsou zobrazeny prostory?
      radar : watchable(fromState('radar', false)),//je zobrazen radar

      radarTimestamp : w(0),
      radarTimeoutId : w(null),

      hires, //SHARED; w(true) //aktualni stav hires prepinatka true/false

      vario : w(fromState('vario', false)), //je v grafu zobrazeno vario?
      speed : w(fromState('speed', false)), //je v grafu zobrazena rychlost?

      /** play/stop **/
      playRunMode, //SHARED:
      /*
      playRunMode : watchable({
        playRun: false,
        playMode: false
      }),
      */
      //SHARED DERIVED: playMode : false, //je zapnuty play mode?
      //SHARED DERIVED: playRun : false, //je zapnute prehravani?
      playSpeed : w(10), //aktualni rychlost prehravani, default je 10
      playTS : w(null), //posledni timestamp z metody requestAnimationFrame() v ms
      playTX : w(null), //posledni tX na ose X (v sekundach)
      animId : w(null), //requestId z metody requestAnimationFrame()
      mapMove : w(false),  //je prave mapa ve stavu move? tj. mezi movestart a moveend eventy
      mapUserControl : w(false),  //jestli user prebral kontrolu nad posuvem mapy; je to v pripade, ze svou akci posunul mapu tak, ze neobsahne boundsPilots

      //position options
      posAgl: w(lS_posAgl), //zobrazovat AGL?
      posSpeed: w(lS_posSpeed), //zobrazovat rychlost?
      posVario: w(lS_posVario), //zobrazovat vario?
      //DERIVED: posTextField : {}

      baseSize: w(lS_baseSize || 11), //pro text-size positions (replay & live)

      //pro LIVE
      playOpened: w(false),

      //set objekt pro showFlight()
      showFlightSet : r({
          startend: true,
          turnpoints: true,
          route: true,
          graph: true,
          pilot: true
      }),

      //pro forwarding touchstart eventu z ControlPanelu (protoze touchstart tam je zastaven pres stopPropagation)
      //potrebujeme ho pro Live, aby kazdy touchstart mohl "ozivit" websocket, pokud nezafungovala visibilitychange udalost
      //vklada se sem proste objekt touchstart eventu
      touchstart: w(null),

      //online/offline store
      online: r(
        window.navigator.onLine,
        (set) => {
          const onlineListener =  ev => {
            set(navigator.onLine);
          };
          window.addEventListener('online', onlineListener);
          window.addEventListener('offline', onlineListener);
        }
      ),
    };
  }

  _getDerived () {

    const {
      fullscreen, sat, airspace,
      posAgl, posSpeed, posVario
    } = this;

    /*
    //playRun, playMode - SHARED DERIVED
    */

    const posTextField = watchableDerived(
      [posAgl, posSpeed, posVario],
      ([$posAgl, $posSpeed, $posVario]) => {
        let value = ["format",
          ["get", "user"], {},
          "\n", {},
          ["get", "a"], {},
          " m", {}
        ];

        if ($posAgl) {
          value = value.concat([
            " [", {},
            ["get", "agl"], {},
            " AGL]", {}
          ]);
        };

        if ($posSpeed || $posVario) {
          const sv = ["\n", {}];
          if ($posSpeed) sv.push(["get", "s"], {}, " km/h", {});
          if ($posSpeed && $posVario) sv.push(", ", {});
          if ($posVario) sv.push(["get", "v"], {}, " m/s", {});
          value = value.concat(sv);
        };

        return value;
      },
      //INITIAL hodnota
      ["format",
        ["get", "user"], {},
        "\n", {},
        ["get", "a"], {},
        " m", {}
      ]
    );

    return {
      playRun,
      playMode,
      posTextField
    };
  }

  _setEvents () {
    const {
      fullscreen,
      sat,
      airspace,
      hires,
      playMode,
      playRunMode,
      posTextField
    } = this;

    this.subscribeEvent(
      fullscreen,
      'fullscreen-change',
      ($c, $p) => $c !== $p
    );

    this.subscribeEvent(
      sat,
      'sat-change',
      ($c, $p) => $c !== $p
    );

    this.subscribeEvent(
      airspace,
      'airspace-change',
      ($c, $p) => $c !== $p
    );

    this.subscribeEvent(
      hires,
      'hires-change',
      ($c, $p) => $c !== $p
    );

    this.subscribeEvent(
      playMode,
      'playmode-change',
      ($c, $p) => $c !== $p
    );

    this.subscribeEvent(
      playRunMode,
      'playrun-change',
      ($c, $p) => $p && $c.playRun !== $p.playRun,
      (evt, $c, $p) => {
        this._fireChange(
          evt, $c.playRun, $p.playRun,
          {playMode : $c.playMode !== $p.playMode ? $c.playMode : null}
        );
      }
    );

    this.subscribeEvent(
      posTextField,
      'posopts-change',
      ($c, $p) => $c !== $p
    );
  }
};

export {ViewStore};
