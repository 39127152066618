import { writable as w, get } from 'svelte/store';
import { EventStore } from "./store-ext.js";

class PmsStore extends EventStore {

  constructor () {
    super();

    this.PMS = {
      DOMContentLoaded: null, //promisifikovana udalost DOMContentLoaded
      MapboxLoaded : null, //promise nacteni mapbox gl js JS a CSS
      MapLoaded : null, //promise nacteni mapy po inicializaci
      StyleLoaded : null,//promise nacteni full stylu mapy
      MovePrepared : null,//promise pro pripravu osetreni plynuleho pohybu mapy
      SnifferPrepared : null, //promise pro pripravu sniffingu pohybu mysi po mape
      MarkerMovePrepared : null, //promise pro sledovani zmeny tX a posunu markeru po mape
      FullscreenPrepared : null,
      BackgroundPrepared : null,
      AirspacePrepared : null,
      GeolocationPrepared : null,
      GraphPrepared : null, //promise ze svg graf je pripraven

      //kolekce vazane na id letu (key je id letu)
      FlightDataLoaded : {
        'basic' : new Map(),
        'hires' : new Map(),
      },
      TrackDataReady : {
        'basic' : new Map(),
        'hires' : new Map(),
      },
      TrackDrawn : {
        'basic' : new Map(),
        'exact' : new Map(),
      },
      RouteDrawn : {
        'DEFAULT' : new Map(),
        'TRIANGLE' : new Map(),
        'FREE_FLIGHT' : new Map(),
        'FREE_DISTANCE' : new Map(),
      },
      MarkersCreated : {
        'startend' : new Map(),
        'turnpoints' : {
          'DEFAULT' : new Map(),
          'FREE_FLIGHT' : new Map(),
          'FREE_DISTANCE' : new Map(),
        }
      },
      SourceSet : {
        //track sources
        'basic' : new Map(),
        'hires' : new Map(),
        //route sources
        'DEFAULT' : new Map(),
        'TRIANGLE' : new Map(),
        'FREE_FLIGHT' : new Map(),
        'FREE_DISTANCE' : new Map(),
      },

      GraphFlightDrawn : new Map(),

      TaskLoaded : new Map(), //kolekce vazana na ctyrpismenny code tasku
      TaskDrawn : new Map(), //kolekce vazana na ctyrpismenny code tasku

      //kolekce vazane na iso2 zeme
      AirspaceLoaded : new Map(), //pozor, key je iso2 zeme!
      AirspaceDrawn : new Map(), //pozor, key je iso2 zeme!

      PlayPosLayer : null, ////promise pro pridani positions layer pro PLAY
    };

    Object.entries(this.PMS).forEach(([key, value]) => {
      if (value !== null) return;
      this.PMS[key] = w();
    });
  }


  /**
   * vraci Promise ze store.PMS pomoci Promise:)
   * pokud jeste neni vytvorena, ceka az bude pridana a pak teprve ji vraci
   * @param keys je array [keypath, key] - key nemusi byt definovano, pokud koncovy PMS neni typu Map
   * @returns Promise; resolvuje s danou hledanou promise ze store.PMS
  */
  getPMS (keys) {
    return new Promise((resolve, reject) => {
      const PMS = this.gPMS(keys);
      if (PMS) {
        resolve(PMS);
      } else {
        this.sPMS(keys);
        this.gPMS(keys, s => {
//console.log(keys,s)
//console.trace();
          const unsub = s.subscribe(PMS => {
            if (PMS === undefined) return;
            resolve(PMS);
            unsub();
          });
        });
      };
    });
  }

  /**
    * @param keys je array [keypath, key] - key nemusi byt definovano, pokud koncovy PMS neni typu Map
    * @param getFunc optional - viz getDeep() getFunc parameter
  */
  gPMS (keys, getFunc) {
    if (typeof keys === "string") keys = [keys];
    const [keypath, key] = keys;
    return this._getDeep(['PMS.'+keypath, key], getFunc);
  }

  /**
   * vraci array of promises ulozeny v Map objektu
   * @returns array [[key, PMS1], [key, PMS2], ...]
  */
  gPMSEntries (keys) {
    return this.gPMS(keys, obj => {
      return [...obj].map(([key, pms]) => [key, get(pms)]);
    });
  }

  /**
   * pridava globalni Promise do store.PMS
   * @param keys je array [keypath, key] - key nemusi byt definovano, pokud koncovy PMS neni typu Map
   * @returns Promise
  */
  sPMS (keys, PMS, setFunc) {
    if (typeof keys === "string") keys = [keys];
    const [keypath, key] = keys;

    this._setDeep(
      ['PMS.'+keypath, key],
      PMS,
      setFunc
    );
    return PMS;
  }

  /**
  * promisifikovany PMSDOMContentLoaded event
  */
  DOMContentLoaded () {
    let PMS = this.gPMS('DOMContentLoaded');
    if (PMS instanceof Promise) return PMS;

    PMS = new Promise((resolve, reject) => {
      if (document.readyState != 'loading') resolve();
      document.addEventListener("DOMContentLoaded", function(event) {
        resolve();
      });
    });
    this.sPMS('DOMContentLoaded', PMS);
    return PMS;
  }
};

/**
  * @param keys je array [keypath, key] - key nemusi byt definovano, pokud koncovy PMS neni typu Set
*/
const PMS = (keys, func, a1, a2, a3) => {
  if (!PMS.store) throw new Error("PMS.store not set");
  const P = PMS.store.gPMS(keys);
  if (P != null) return P;
  return PMS.store.sPMS(keys, func(a1, a2, a3));
};
PMS.store = null;//je nutne predem nastavit pmsStore!

export { PmsStore, PMS };
