<script>
export let contest = null;
const routeTag = contest && contest.route ? contest.route.type.tag : "FreeFlight";
</script>

{#each [
  ["FreeFlight", "&#8722;"],
  ["FlatTriangle", "&#9659;"],
  ["FaiTriangle", "&#9651;"]
].filter(([tag, ent]) => tag === routeTag) as [tag, ent]}
<span class="xcdisc" title="{tag}">{@html ent}</span>
{/each}
