<script>
import {formDur} from './utils/helpers.js';
export let info = null;
</script>

<style>
.status {
	font-weight: bold;
}
.status.live {
	color: hsla(100, 100%, 50%, 1);
	-webkit-text-stroke: 0.5px hsla(0, 0%, 50%, 0.7);
}
.status.landed {
	color: hsla(190, 100%, 75%, 1);
	-webkit-text-stroke: 0.5px hsla(0, 0%, 50%, 0.7);
	
}
.status.lost {
	color: hsla(0, 100%, 80%, 1);
	-webkit-text-stroke: 0.5px hsla(0, 0%, 50%, 0.7);
}
.followers {
	margin: 0 5px 0 5px;
}
</style>
{#if info.status == 'live' && info.actual.followers > 0}
	<div class="followers" title="number of followers">👁 {info.actual.followers}</div>
{/if}
<div class="status {info.status}">
  {#if info.status == 'live'}
  LIVE
  {:else if info.status == 'landed'}
  &#10004;-{formDur(info.delay)}
  {:else if info.status == 'lost'}
  ?-{formDur(info.delay)}
  {/if}
</div>
