<script>
import {formDur} from './utils/helpers.js';
export let info = null;
</script>

<style>
.airtime {
	display: flex;
	align-items: flex-end;
	font-weight: bold;
	padding-right: 5px;
}
svg {
	margin-right: 2px;
}
</style>
<div class="airtime"><svg class='icon w12 h12'><use xlink:href='#icon-clock' /></svg> {formDur(info.duration)}</div>
