<script>
import {isoToEmojiFlag} from './utils/helpers.js';

export let info = null;
export let mode = 'list';
export let isPortrait = false;
</script>

<style>
:global(.popup .pilot) {
  top: 15px;
  margin-top: -10px;
}
.pilot {
  position: relative;
  top: 8px;
  margin-right: 35px;
}
:global(.detail .pilot) {
  background: linear-gradient(180deg, hsla(40, 100%, 95%, 1) 0% 50%, hsla(0, 0%, 100%, 1) 100%);
  top: 0;
  margin-right: 0;
  border-radius: 3px;
  font-size: 120%;
  padding: 5px;
  text-align: center;
  line-height: 1.5em;
}
.fullname {
	font-weight: bold;
}
.username {
	font-weight: normal;
}
.altagl {
  border: 0.5px solid hsla(207, 29%, 94%, 1);
  border-radius: 2px;
  white-space: nowrap;
}
.altitude {
  color: hsla(28, 100%, 50%, 1);
  padding: 0 2px;
}
.agl {
  color: hsla(0, 0%, 66%, 1);
  padding: 0 2px;
}
.act {
  margin-top:-2px;
}
</style>

{#each [info.static.user] as user}
<div class="pilot">
  <span class="fullname">{user.fullname}</span> <span class="username">[{user.username}]</span> <span class="nationality" title="{user.nationality.iso}">{isoToEmojiFlag(user.nationality.iso)}</span>
  {#if info.static.simulation}<b>[SIMULATED]</b>
  {:else if ['live', 'lost'].includes(info.status)}
    {#if isPortrait && mode == 'popup'}<br>{/if}
    <span class="altagl"><span class="altitude">{info.lastFix[2]} m</span><span class="agl">{ (info.lastFix[2] - info.lastFix[3].g).toFixed() } AGL</span></span>
    
    {#if info.status == 'live' && info.actual.addInfo.xcActivity[0] == 'ON_FOOT'}
      <svg class='icon inline w12 h12 act'><use xlink:href="#icon-walk" /></svg>
    {/if}

  {/if}
</div>
{/each}
