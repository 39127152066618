<svelte:options immutable/>

<script>
import {PATHS} from './utils/const.js';
import {Style} from "./utils/mbstyle.js";

import {PGUtil} from './utils/pgutil.js';


let mbstyleOriginUrls;
$: {
	mbstyleOriginUrls = Object.entries(Style.sources)
		//filtruje ty sources, ktere nejsou v defaultnim stylu invisible
		//tj. existuje aspon 1 visible layer pro dany source
		.filter(([id, source]) => {
			return Style.layers.filter(l => {
				return l.source == id && (!l.layout || l.layout.visibility != 'none');
			}).length > 0;
		})
		.map(([id, source]) => source)
		.reduce((acc, {url, tiles}) => {
			return acc.concat(url ? [url] : (Array.isArray(tiles) ? tiles : []));
		}, [])
		.map(url => new URL(url).origin)
}
</script>

<svelte:window on:resize="{() => PGUtil.checkMapResize()}" />

<svelte:head>
	<meta name="viewport" content="width=device-width, initial-scale=1">

	<!-- kvuli mapbox-gl.js a mapbox-gl.css -->
	<!--<link href="https://api.tiles.mapbox.com" rel="preconnect">-->

	<!-- origin url ze mapbox style.sources -->
	{#each mbstyleOriginUrls as url}
		<link href="{ url }" rel="preconnect">
	{/each}

	<link href="https://maps.googleapis.com" rel="preconnect">
	<link href="https://fonts.googleapis.com" rel="preconnect">
	<link href="https://fonts.gstatic.com" rel="preconnect">

	<link href="https://fonts.googleapis.com/css?family=Roboto" rel="preload" as="style">
	<link href="{ PATHS.MAPBOX_JS }" rel="preload" as="script">
	<link href="{ PATHS.MAPBOX_CSS }" rel="preload" as="style">
	<link href="{ PATHS.ASSEMBLY_JS }" rel="preload" as="script">
	<link href="{ PATHS.ASSEMBLY_CSS }" rel="preload" as="style">

	<link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet">
</svelte:head>

<!-- nacitame obsah do slotu z parent Map komponenty -->
<div class="container">
	<slot></slot>
</div>

<style>
	/** fullscreen **/
	div.container:fullscreen, div.container:-webkit-full-screen, div.container:-moz-full-screen {
		width: 100%;
		height: 100%;
	}


	/** prepis mapboxich stylu **/
	div.container :global(.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl) {
		margin: auto auto 19px 0;
	}
	div.container :global(.mapboxgl-ctrl-scale) {
		background-color: hsla(0, 0%, 100%, 0);
		border-style: none solid solid solid;
		border-width: 0 1px 1px 1px;
		border-color: hsla(0, 0%, 100%, 0.6);
		line-height: 15px;
		text-align: right;
		text-shadow:-1px -1px 1px white, 1px -1px 1px white, -1px 1px 1px white, 1px 1px 1px white;
	}
	div.container :global(.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl.mapboxgl-ctrl-attrib) {
		margin: auto 10px 30px auto;
		min-height: 60px;
		opacity: 0.75;
		padding: 0;
	}

	div.container :global(.mapboxgl-ctrl-attrib.mapboxgl-compact:hover) {
		padding: 0 24px 0 4px;
	}

	div.container :global(.mapboxgl-ctrl-attrib.mapboxgl-compact::after) {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 197 197' xmlns='http://www.w3.org/2000/svg'%3E %3Ccircle cx='98' cy='98' r='60' fill='%23333333'/%3E %3Ccircle cx='98' cy='98' r='35' fill='white'/%3E %3Ccircle cx='98' cy='98' r='22' fill='%23333333'/%3E %3Crect x='115' y='90' width='23' height='16' fill='%23333333'/%3E %3C/svg%3E");
	}

	div.container :global(.mapboxgl-ctrl-group) {
	 background-color: hsla(0, 0%, 100%, 1);
	 border-radius: 2px;
	 opacity: 0.6;
	}

	div.container :global(*) {
		user-select: none;
		-webkit-user-select: none;
	}

	/** xc icons **/
	div.container :global(.xcicon) {
		width: 15px;
		height: 15px;
	}
	div.container :global(.xcicon.start) {
		z-index: 3;
	}
	div.container :global(.xcicon.end) {
		z-index: 2;
	}
	div.container :global(.xcicon.start svg) {
		fill: hsla(120, 100%, 50%, 0.75);
		stroke: hsla(120, 100%, 50%, 0.75);
	}
	div.container :global(.xcicon.end svg) {
		fill: hsla(360, 100%, 50%, 0.75);
		stroke: hsla(360, 100%, 50%, 0.75);
	}

	:global(.airspace-popup) {
		opacity: 0.9;
	}
</style>
