<svelte:options immutable/>

<script>
import {xToDate} from './utils/helpers.js';
import {ExtDate} from './utils/extdate.js';
import {Play} from './utils/play.js';

import fade from 'svelte-transitions-fade';
import { getContext } from 'svelte';

import ControlPanel from './ControlPanel.html';

const pgStore = getContext('pgStore');
const viewStore = getContext('viewStore');
const pmsStore = getContext('pmsStore');

Play.setStores({pgStore, viewStore, pmsStore});

//export let PP;
//export let PlayPause;
//export let playSpeedDisplay;
//export let time;

export let active = false;
//export let opened = false;
export let standalone = false;

const {
  playOpened,
  playRun, playSpeed, mapUserControl,
  posAgl, posSpeed, posVario
} = viewStore.getStores([
  'playOpened',
  'playRun', 'playSpeed', 'mapUserControl',
  'posAgl', 'posSpeed', 'posVario'
]);

const {tX, utcOffset, utcOffsetTS, showGraph} = pgStore.getStores(['tX', 'utcOffset', 'utcOffsetTS', 'showGraph']);


pgStore.on('flight-add', ({id}) => {
//console.log('PP::flight-add', id)
  if (viewStore.get('playMode')) Play._showPlayTrack(id);
});
pgStore.on('flight-rem', ({id}) => {
//console.log('PP::flight-rem', id)
  if (viewStore.get('playMode')) Play._hidePlayTrack(id);
});

viewStore.on('playmode-change', ({current}) => {
  Play.changeMode(current);
});
viewStore.on('playrun-change', ({current, playMode}) => {
  Play.changeRun(current, playMode);
});
viewStore.on('posopts-change', ({current}) => {
  Play.changePosLayout();
});
viewStore.getStores('baseSize').subscribe($bS => {
  Play.changePositionsBaseSize($bS);
});

pgStore.on('graph-click', ({gX, tX}) => {
  if (viewStore.get('playMode')) Play.move(tX);
});
pgStore.on('graph-touchmove', ({gX, tX}) => {
  if (viewStore.get('playMode')) Play.move(tX);
});
pgStore.on('graph-mousedrag', ({gX, tX}) => {
  if (viewStore.get('playMode')) Play.move(tX);
});


export let cActive;
$: cActive = active ? 'active' : '';

export let cOpened;
$: cOpened = $playOpened ? 'opened' : ''
//$: cOpened = opened ? 'opened' : '';

export let controls;
$: controls = [
    {key: 'posAgl', label: 'AGL', checked: $posAgl},
    {key: 'posSpeed', label: 'speed', checked: $posSpeed},
    {key: 'posVario', label: 'vario', checked: $posVario}
  ];

// [svelte-upgrade suggestion]
// review these functions and remove unnecessary 'export' keywords
export function playPause() {
  //opened = true,
//console.log('playPause', playOpened, $playOpened)
  $playOpened = true;
  active = false;
  Play.playPause();
}

export function changeSpeed(increase) {
  Play.changeSpeed(increase);
}

export function close() {
  //opened = false,
  $playOpened = false;
  active = false;
  Play.close();
}

export function autoControl() {
  active = false;
  Play.autoControl();
}

export function sat() {
  active = true;
}

export function saf() {
  active = false;
}
</script>

<!--<div bind:this={PP}-->
<div
  class="PP mapboxgl-ctrl {cActive} {cOpened}"
  style="bottom: {$showGraph ? '128px' : '28px'}"
  on:mouseenter="{sat}"
  on:mouseleave="{saf}"
  on:touchstart="{sat}"
  on:touchend="{saf}">

  <!--<div bind:this={PlayPause}-->
  <div
    class="PlayPause"
    on:click="{playPause}"
    on:touchend|preventDefault="{playPause}"
    title="{$playRun ? 'Pause' : 'Play'}"
  ><svg class='icon w30 h30'><use xlink:href="#icon-{ $playRun ? 'pause' : 'play' }" /></svg></div>

  <!--{#if opened}-->
  {#if $playOpened}
    <div
      on:click="{() => changeSpeed(-1)}"
      on:touchend|preventDefault="{() => changeSpeed(-1)}"
      title="Slower"
    ><svg class='icon w30 h30'><use xlink:href="#icon-minus" /></svg></div>
    <!--<div bind:this={playSpeedDisplay}-->
    <div
      class="playSpeedDisplay" title="Play speed"><div>{$playSpeed} &#215;</div></div>
    <div
      on:click="{() => changeSpeed(1)}"
      on:touchend|preventDefault="{() => changeSpeed(1)}"
      title="Faster"
    ><svg class='icon w30 h30'><use xlink:href="#icon-plus" /></svg></div>
    <div
      on:click="{close}"
      on:touchend|preventDefault="{close}"
      title="Stop/cancel Play mode"
    ><svg class='icon w30 h30'><use xlink:href="#icon-close" /></svg></div>
    <!---<div bind:this={time}-->
    <div
      class="time"><b>{ xToDate($tX, $utcOffsetTS).strftime('%T') }</b><br>=UTC{ ExtDate.timezoneToUTCoffset($utcOffset) }</div>
    <!--<ControlPanel {controls} {standalone} />-->
    {#if $mapUserControl}
    <div
      on:click="{autoControl}"
      on:touchend|preventDefault="{autoControl}"
      title="Auto move/zoom out to pilots"
    ><svg class='icon w30 h30'><use xlink:href="#icon-crosshair" /></svg></div>
    {/if}
  {/if}
</div>

<style>
div.PP {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 3;
  /*bottom: 128px;*/
  left: 10px;
  display: flex;
  border-radius: 2px;
  color: hsla(204, 47%, 38%, 0.8);
  background-color: hsla(0, 0%, 100%, 0.6);
  font-size: 10px;
  line-height: 12px;
}
div.PP.opened .icon {
  padding: 0 5px;
}
.icon {
  cursor: pointer;
}

div.PP.active {
  background-color: hsla(0, 0%, 100%, 0.9);
}

div.playSpeedDisplay div {
  padding: 2px 5px;
  font-size: 14px;
  line-height: 26px;
  background-color: hsla(20, 100%, 85%, 0.6);
}
div.time {
  padding: 2px 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 7px;
  background-color: hsla(207, 29%, 94%, 0.6);
  text-align: center;
}
div.time b {
  font-size: 9px;
}
</style>
