<svelte:options immutable/>

<script>
//let displayer;
import { getContext } from 'svelte';

import {ExtDate} from './utils/extdate.js';
import {xToDate, round} from './utils/helpers.js';

const pgStore = getContext('pgStore');

const {multimode, masterCoord, tX, utcOffset, utcOffsetTS, showGraph} = pgStore.getStores(['multimode', 'masterCoord', 'tX', 'utcOffset', 'utcOffsetTS', 'showGraph']);

let UTCoffset;
$: UTCoffset = ExtDate.timezoneToUTCoffset($utcOffset);
</script>

<div class="displayer">
	{#if !$multimode && $masterCoord}
		<div class="display-alt-gps">{ round($masterCoord[2]) } m</div>
		{#if $masterCoord[3].b} <div class="display-alt-baro">{ round($masterCoord[3].b) } m</div>{/if}
		<div class="display-alt-agl">{ round($masterCoord[2] - $masterCoord[3].g) } m AGL</div>
		<div class="display-alt-gnd">{ round($masterCoord[3].g) } m GND</div>
		<div class="display-vario">{ round($masterCoord[3].v, 1) } m/s</div>
		<div class="display-speed">{ round($masterCoord[3].s) } km/h</div>
	{/if}
	{#if $showGraph}<div class="display-time"><b>{ xToDate($tX, $utcOffsetTS).strftime('%T') }</b>{ UTCoffset }</div>{/if}
</div>

<style>

	div.displayer {
		display: flex;
		justify-content: left;
		align-items: left;
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
        height: 18px;
		color: hsla(204, 47%, 38%, 1);
		background-color: hsla(207, 29%, 94%, 1);
		font-family: 'Roboto', sans-serif;
		font-size: 9.5px;
		will-change: contents;
	}
	div.displayer div {
		display: flex;
		align-items: center;
		justify-content: safe flex-end;
		border: 0.5px solid lightgrey;
		border-width: 0 0.5px;
		padding: 0 1px;
		width: 4em;
		height: 18px;
		overflow: hidden;
        white-space: nowrap;
	}
	.display-alt-gps {
		color: hsla(28, 100%, 50%, 1);
	}
	.display-alt-baro {
		color: hsla(198, 100%, 40%, 1);
	}
	div.displayer div.display-alt-gnd {
		width: 6em;
		color: white;
		background-color: hsla(0, 0%, 66%, 1);
	}
  div.displayer div.display-alt-agl {
		width: 6em;
		color: black;
		background-color: hsla(0, 0%, 86%, 1);
	}
	div.displayer div.display-vario {
		width: 4.5em;
		color: hsla(0, 100%, 25%, 1);
		background-color: hsla(60, 100%, 70%, 1);
	}
	div.displayer div.display-speed {
		width: 4.5em;
		color: black;
		background-color: hsla(20, 100%, 85%, 1);
	}
	div.displayer div.display-time {
		width: 8em;
		color: black;
	}
</style>
