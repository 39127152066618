import {Util} from "./util.js";

const SvgUtil = {
  /** vytvari SVG element **/
  createNode (name, attrs, text = null, innerHTML = null) {
    const svgNS = 'http://www.w3.org/2000/svg';
    const node = document.createElementNS(svgNS, name);
    if (text) node.appendChild(document.createTextNode(text));
    if (innerHTML) node.innerHTML = innerHTML;
    return Object.entries(attrs || {}).reduce((node, [key, val]) => {
      node.setAttribute(key, val);
      return node;
    }, node);
  },

  /**
   * tranformuje GeoJSON coords do svg path atributu d;
   * POZOR - paths nejsou kompletni, je treba je protahnout metodami toSvgPathLine() nebo toSvgPathFill() pro pouziti
   * @param coords - geojson coords [x,y,z,o] kde o = {g: gnd vyska, b: baro vyska, t: time (sec od zacatku)}
   * @param index - indexove pole, tj. res.index
   * @returns object {
   *  paths: { a, b, g, v, s }
   *  bounds: {minX, maxX, minY, maxY}
   * }
  */
  coordsToPaths (coords, index) {
    let res = {
      paths: {
        a: '', //gps alti
        b: '', //baro alti
        g: '', //ground
        v: '', //vario
        s: ''  //speed
      },
      bounds: Util.getFreshBounds({
        minX: coords[0][3].t, //minX je time prvniho bodu
        maxX: coords.slice(-1).pop()[3].t, //max X je time posledniho bodu
      })
    };
//console.log('coords', JSON.parse(JSON.stringify(coords)));
    return coords.reduce((r, coord, i) => {
      if (!coord[3]) return r;

      const x = (i==0) ? '' : ' L'+coord[3].t;
      const hgts = [coord[2], coord[3].g];

      r.paths.a += x+' '+coord[2]; //gps alti
      r.paths.g += x+' '+coord[3].g; //ground alti

      //baro alti, if present
      if (coord[3].b) {
        r.paths.b += x+' '+coord[3].b;
        hgts.push(coord[3].b);
      };
      
      const {v, s} = Util.averagedVarioSpeed(coord, coords, index);

      r.paths.v += x+' '+v; //vario
      r.paths.s += x+' '+s; //speed

      //extend y bounds
      const [min, max] = Util.extendRange(hgts, [r.bounds.minY, r.bounds.maxY]);
      r.bounds.minY = min;
      r.bounds.maxY = max;
      return r;
    }, res);
  },

  toPathLine (path) {
    if (path.substr(0,2)==' L') return 'M' + path.substr(2);//workaround kdyz napr. baro nezacina na x0
    return 'M0 '+path;
  },

  toPathFill (path) {
    return 'M0 0 V '+path+' V 0';
  }
};
export {SvgUtil};
