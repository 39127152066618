import {
  ID_ROOT,
  TRACK_DATA_TYPES,
  TRACK_TYPES,
  ROUTE_TYPES,
  BASIC_ROUTE_TYPES,
  MARKER_TYPES,
  COLORS,
  PATHS,
  MAP_OPTIONS
} from './const.js';
import {Style} from "./mbstyle.js";
import {ExtDate} from "./extdate.js";
import {EncPoly} from "./encpoly.js";
import {Util} from "./util.js";
import {MBUtil} from "./mbutil.js";
import {XCUtil} from "./xcutil.js";
import {SvgUtil} from "./svgutil.js";
import {GraphMod} from "./graphmod.js";

import rbush from "rbush";
import knn from "rbush-knn";

import { get } from 'svelte/store';
import { PMS } from './pms-store.js';

import {d0} from './c.js';

/**
 * Utils funkce pro stranku
 * async funkce
*/
const PGUtil = {

  pgStore : null,
  viewStore : null,
  pmsStore : null,

  /** volat uplne na zacatku **/
  setStores ({pgStore, viewStore, pmsStore}) {
    this.pgStore = pgStore;
    this.viewStore = viewStore;
    this.pmsStore = pmsStore;

    XCUtil.setStores({pgStore});
    GraphMod.setStores({pgStore, viewStore, pmsStore});
    PMS.store = pmsStore;
  },

  /**
   * nacita mapbox javascript a css
   * @returns Promise, resolvuje s undefined pote, co se nactou js i css
  */
  loadMapbox () {
    return PMS('MapboxLoaded', PGUtil._loadMapbox);
  },
  _loadMapbox () {
    
    //assembly css - musi do head PRED ostatni styly
    const acss = document.createElement('link');
    acss.href = PATHS.ASSEMBLY_CSS;
    acss.rel = 'stylesheet';
    document.head.insertBefore(acss, document.head.firstChild);

    //assembly js
    const ajs = document.createElement('script');
    ajs.src = PATHS.ASSEMBLY_JS;
    document.body.insertBefore(ajs, document.body.firstChild);
    

    //mapbox css
    const css = document.createElement('link');
    css.href = PATHS.MAPBOX_CSS;
    css.rel = 'stylesheet';
    document.body.insertBefore(css, document.body.firstChild);

    //mapbox js
    return new Promise ((resolve, reject) => {
      const js = document.createElement('script');
      js.src = PATHS.MAPBOX_JS;
      js.onload = () => resolve();
      js.onerror = () => reject('mapbox gl js load error');
      document.body.insertBefore(js, document.body.firstChild);
    });
  },


  /**
   * nacita mapu
   * u flight map pracuje az pote, co byla nactena data letu (loadFlightData()) - kvuli vykreseni dle bounds letu
   * uklada Mapbox map do Store
   * @param bounds - bounds (u letu data.enc.bounds)
   * @returns Promise; resolvuje s Mapbox map objektem
  */
  loadMap (bounds) {
    return PMS('MapLoaded', bounds => PGUtil._loadMap(bounds), bounds);
  },
  _loadMap (bounds) {
    return PGUtil.loadMapbox()
      .then(() => [
        Object.assign(
          {},
          MAP_OPTIONS,
          {container : this.pgStore.get('mapNode')}
        ),
        bounds,
        this.pgStore
      ],

    ) //konverze
      .then(XCUtil.prepareMap) //priprava mapy
      .then(map => {
        this.pgStore.set({map}); //ulozeni do Store
        return map;
      });
  },

  /**
   * nacteni full stylu mapy
   * vola se zpravidla po necteni trackogu, aby se zobrazil co nejrychleji
   * @param waitForData boolean - jestli se ceka na nacteni source letu
   * - default true - pro flightMap
   * - pokud se ma nacist bezprostredne, false (pro search map, kde se zadny let na zacatku nenacita)
   * @returns Promise, ktera resolvuje s map
  */
  loadFullStyle (waitForData = true) {
    return PMS('StyleLoaded', waitForData => PGUtil._loadFullStyle(waitForData), waitForData);
  },
  _loadFullStyle (waitForData) {
    const PMSmap = this.pmsStore.getPMS('MapLoaded');
    const PMSmapData = PMSmap.then(map => {
      return waitForData
      ?
      MBUtil.mapDataPromise(
        map,
        (map, evt) => evt.isSourceLoaded && evt.sourceId.indexOf(ID_ROOT.tracksrc)==0
      )
      : Promise.resolve(map);
    });

    return Promise.all([PMSmapData, Promise.resolve(Style)])
    .then(([map, style]) => {
      const PMS = MBUtil.mapDataPromise(
        map,
        (map, evt) => map.isStyleLoaded()
      );
      map.setStyle(MBUtil.mergeStyles(map.getStyle(), style, false));
      return PMS;
    });
/* //testovaci odlozeni nacteni
    .then(([map, style]) => {
      return new Promise((res, rej) => window.setTimeout(() => res([map, style]), 2000));
    })
*/
/*
    .then(([map, style]) => {
//console.log('%cloadFullStyle', "color: blue; background-color: gold; padding: 10px")
//console.log('old style', Util.fresh(map.getStyle()))
      const newStyle = MBUtil.mergeStyles(map.getStyle(), style, false);
//console.log('new style', Util.fresh(newStyle))
      map.setStyle(newStyle);
      return map;
    });
*/
  },

  /**
   * registruje osetreni plynuleho pohybu mapy a zamereni na piloty
   * @returns Promise, ktera resolvuje s void
  */
  prepareMove (map) {
    return PMS('MovePrepared', map => PGUtil._prepareMove(map), map);
  },

  _prepareMove (map) {
    return new Promise((res, rej) => {
      map.on('movestart', evt => this.viewStore.set({mapMove: true}));
      map.on('moveend', evt => {
        this.viewStore.set({mapMove: false});
        if (evt.isAuto) return;
        PGUtil.checkBounds(true);
      });
      res(map);
    });
  },

  _mapHeight: 9999,//vychozi hodnota

  /**
   * provede map.resize() po kazde pripadne zmene velikost map nodu
   * typicky pri window.onresize (coz se vola i pri fullscreenu) ci objeveni/zmizeni grafu
  */
  checkMapResize () {
  	this.pmsStore.getPMS('MapLoaded').then(map => {
  		window.setTimeout(() => {
        const mapHeight = this.pgStore.get('mapNode').clientHeight;
        const prevMapHeight = this._mapHeight;

        const tresholdHeight = 270;

        const NaviControl = this.pgStore.get('NaviControl');
        const GeoControl = this.pgStore.get('GeoControl');

        //pripadne odebrani Navigacnich +- tlacitek pokud vyska mapy neni dostatecna
        if (NaviControl) {
          if (mapHeight < tresholdHeight && prevMapHeight >= tresholdHeight) {
            map.removeControl(NaviControl);
          } else if (mapHeight >= tresholdHeight && prevMapHeight < tresholdHeight) {
            if (GeoControl) map.removeControl(GeoControl);
            map.addControl(NaviControl, 'bottom-right');
            if (GeoControl) map.addControl(GeoControl, 'bottom-right');
          };
        };
        this._mapHeight = mapHeight;

        map.resize();
      }, 10);

  	});
  },

  checkBounds (UI_MOVE = false) {
    const {map, currentCoords} = this.pgStore.get(['map', 'currentCoords']);
    const {mapMove, mapUserControl} = this.viewStore.get(['mapMove', 'mapUserControl']);

    if (mapMove || mapUserControl) return;

    const boundsPilots = MBUtil.boundsFromCurrentCoords(currentCoords);
    if (boundsPilots == null) return;

    if (!MBUtil.isBoundsInside(map.getBounds(), MBUtil.extendBounds(map, boundsPilots, 20))) {
      if (!UI_MOVE) {
        PGUtil.fitPilots(boundsPilots);
      } else {
        this.viewStore.set({mapUserControl: true});
      };
    };
  },

  /**
   * presune mapu na aktualni bounds ziskanych z pozic pilotu
   * @param bounds - lngLatBounds | null; currentCoords bounds, pokud uz je mame jinde spocitane, usetrime tim jednu iteraci
  */
  fitPilots (bounds = null) {
    const map = this.pgStore.get('map');
    if (!map) return;

    bounds = bounds==null ? MBUtil.boundsFromCurrentCoords(this.pgStore.get('currentCoords')) : bounds;
    if (bounds == null) return;

    map.fitBounds(bounds, {
      padding: 50,
      maxZoom: map.getZoom(),
      linear: true
    }, {
      isAuto: true //indikace, ze tato akce byla automaticka, tj. nevychazi z akce uzivatele v UI
    });
  },

  /**
   * registruje sniffing na mousemove nad mapou
   * @returns Promise, ktera resolvuje s void
  */
  prepareSniffer () {
    return PMS(['SnifferPrepared'], () => PGUtil._prepareSniffer());
  },

  _prepareSniffer () {
    return this.pmsStore.getPMS('MapLoaded').then(map => {
      map.on('mousemove', evt => {
        PGUtil.sniffMove(evt);
      });
    });
  },

  /**
   * implementace sniffingu
   * @param evt - Mapbox MapMouseEvent
  */
  sniffMove (evt) {
    //v playMode neni sniffing
    if (this.viewStore.get('playMode')) return;

    const tree = this.pgStore.get('masterRtree');
    if (tree == null) return;

    const [x,y] = [evt.lngLat.lng, evt.lngLat.lat];
    const nearest = knn(tree, x, y, 1)[0];
    const id = nearest[3].id;
//console.log('sniffMove()', id,  this.pgStore.get('ZTbounds'));
    const bds = this.pgStore.get('translatedActiveZTbounds').find(([i, bounds]) => i == id);
    if (!bds) return;

    const bounds = bds[1];
    this.pgStore.tXset(nearest[3].t + bounds.minX);
  },

  /**
   * pripravuje pohyb markeru pilota dle zmeny pozice na ose X
  */
  prepareMarkerMove (map) {
    return PMS(['MarkerMovePrepared'], map => PGUtil._prepareMarkerMove(map), map);
  },
  _prepareMarkerMove (map) {
    //napred cekame na DOMContentLoaded, abychom mohli zavolat getBoundingClientRect()
    return this.pmsStore.DOMContentLoaded()
    .then(() => {
      //marker pozice pilota
      this.pgStore.on('current-x-change', (obj) => {
        if (this.viewStore.get('playMode')) return;

        const markers = this.pgStore.get('markers_pilot');

        this.pgStore.get('currentCoords').forEach(([id, coords]) => {
          const marker = markers[id];
          if (!marker) return;
          if (Array.isArray(coords)) {//je to pole - tj. mame pozici
            if (!marker._map) marker.addTo(map);
            const [x, y] = coords;
            const [x0, y0] = marker.getLngLat().toArray();
            if (x!=x0 || y!=y0) {//pozice se od te soucasne zmenila - menime ji
              marker.setLngLat([x, y]);
              //bounds1.extend([x, y]);
            };
          } else {//neni to pole (null, -1, +1) nemame pozici, resp. jsme v case pred startem/po pristani
            if (marker._map) marker.remove();
          };
        });
      });

      return map;
    });
  },

  /**
   * registruje zmenu na fullscreen
  */
  prepareFullscreen () {
    return PMS(['FullscreenPrepared'], () => PGUtil._prepareFullscreen());
  },
  _prepareFullscreen () {
    this.viewStore.on('fullscreen-change', ({current}) => {
      PGUtil.changeFullscreen(current);
    });
    const fsChangeListener = event => {
      const fsEl = Util.fullscreen(document, 'FullscreenElement');

      //pokud mame fullscreen element, ale neni to "nas", tj. mapa, nedelame nic
      if (fsEl && fsEl != document.querySelector('#map')) return;

      const fullscreen = fsEl ? true : false;
      this.viewStore.set({fullscreen});
    };
    document.addEventListener("fullscreenchange", fsChangeListener);
    document.addEventListener("webkitfullscreenchange", fsChangeListener);
    document.addEventListener("mozfullscreenchange", fsChangeListener);

    return Promise.resolve();
  },

  changeFullscreen (act) {
    this.pmsStore.getPMS('MapLoaded').then(map => {
      const node = document.querySelector('#map');
      if (act) {
        Util.fullscreen(node, 'RequestFullscreen', true);
      } else {
        const fsEl = Util.fullscreen(document, 'FullscreenElement');
        if (!fsEl || fsEl != document.querySelector('#map')) return;
        Util.fullscreen(document, 'ExitFullscreen', true);
      };
    });
  },


  /**
   * registruje zmenu pozadi mapy na SAT a zpet
  */
  prepareBackground () {
    return PMS('BackgroundPrepared', () => PGUtil._prepareBackground());
  },
  _prepareBackground () {
    this.viewStore.on('sat-change', ({current}) => {
      PGUtil.changeBackgroundLayer(current);
    });
    //teoreticky muze byt sat default ON, po reloadu
    PGUtil.changeBackgroundLayer(this.viewStore.get('sat'));
    return Promise.resolve();
  },

  /**
   * prepina na satelitni background a zpet na topo XC
   * @param sat -true|false
  */
  changeBackgroundLayer (sat) {
    const satHide = [
      "background_topo",
      "building",
      "water",
      "waterway",
      "waterway-tunnel",
      "waterway-bridge-case",
      "waterway-bridge"
    ];
    this.pmsStore.getPMS('StyleLoaded').then(map => {
      map.setLayoutProperty('background_sat', 'visibility', sat ? 'visible' : 'none');
      satHide.forEach(ident => map.setLayoutProperty(ident, 'visibility', sat ? 'none' : 'visible'));
    });
  },

  prepareGeolocation () {
    return PMS(['GeolocationPrepared'], () => PGUtil._prepareGeolocation(), this);
  },
  _prepareGeolocation () {
    this.pmsStore.getPMS('MapLoaded').then(map => {
      const GeoControl = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        fitBoundsOptions: {
          linear: true,
          maxZoom: 15
        },
        trackUserLocation: true
      });
      this.pgStore.set({GeoControl});

      map.addControl(GeoControl, 'bottom-right');
    });

    return Promise.resolve();
  },

  /**
   * registruje zobrazeni airspacu
  */
  prepareAirspace () {
    return PMS(['AirspacePrepared'], () => PGUtil._prepareAirspace(), this);
  },
  _prepareAirspace () {
    //airspace on/off
    this.viewStore.on('airspace-change', ({current}) => {
      if (current) {//zobrazeni airspace
        this.pgStore.get('countries').forEach(([iso, date]) => {
          PGUtil.drawAirspace(iso, date).then(map => {
            MBUtil.changeVisibility(map, [], [XCUtil.getAirspaceId(iso)]);
          });
        });
      } else {//skryti airspace
        this.pmsStore.gPMSEntries('AirspaceDrawn').forEach(([iso, PMS]) => {
          PMS.then(map => {
            MBUtil.changeVisibility(map, [XCUtil.getAirspaceId(iso)], []);
          });
        });
        map.getCanvas().style.cursor = ''; //vynulovani cursoru, aby tam nestrasil
      }
    });

    //zmena zemi
    this.pgStore.on('countries-change', ({current}) => {
      if (!this.viewStore.get('airspace')) return;
      current.forEach(([iso, date]) => {
        PGUtil.drawAirspace(iso, date).then(map => {
          MBUtil.changeVisibility(map, [], [XCUtil.getAirspaceId(iso)]);
        });
      });
    });

    const map = this.pgStore.get('map');

    //cursor nad airspace layers
    map.on('mousemove', evt => {
      if (!this.viewStore.get('airspace')) return;
      const layers = PGUtil.getRenderedAirspaces(evt.point);
      map.getCanvas().style.cursor = layers.length == 0 ? '' : 'help';
    });

    //otevreni info popup s prostory
    map.on('click', evt => {
      if (!this.viewStore.get('airspace')) return;
      const layers = PGUtil.getRenderedAirspaces(evt.point);
      if (layers.length == 0) return;

      evt.preventDefault();

      //html content popupu
      const content = layers.map(l => l.properties).reduce((c, props) => {
         c += '<b>'+props.name+'</b> ['+props.lowerLimit+' - '+props.upperLimit+'] class '+props.airspaceClass+'<br>';
         const act = props.activations ? JSON.parse(props.activations) : [];
         c += act.reduce((ca, a) => {
           const A0 = ExtDate.toTimezone(new ExtDate(a[0]));
           const A1 = ExtDate.toTimezone(new ExtDate(a[1]));
           ca += 'active: '+A0.strftime('%F')+' '+A0.strftime('%R')+' UTC - '+A1.strftime('%R')+' UTC<br>';
           return ca;
         }, '');
         return c;
      }, '<div style="font-size:10px"><b>AIRSPACE INFO</b><hr>');

      PGUtil.getAirspacePopup().setHTML(content).setLngLat(evt.lngLat).addTo(map);
    });

    return Promise.resolve();
  },

  /**
   * vytvari objekt pro popup s Airspace info
   * pokud je vytvoren, vraci ho (je jen jeden jediny, ulozeny ve store.airspacePopup
   * @returns mapbox GL JS Popup objekt
  */
  getAirspacePopup () {
    let p = this.pgStore.get('airspacePopup');
    if (p != null) return p;

    p = new mapboxgl.Popup({closeButton: true, closeOnClick: false, className: 'airspace-popup'});
    this.pgStore.set({airspacePopup : p});
    return p;
  },

  /**
   * najde rendered features pro airspacy
   * @param point - objekt Mapbox Point {x, y}
   * @returns array of layers
  */
  getRenderedAirspaces (point) {
    const map = this.pgStore.get('map');
    const layers = this.pgStore.get('airspaceDrawn').map(iso => XCUtil.getAirspaceId(iso));
    return map.queryRenderedFeatures(point, {layers});
  },

  /**
   * fetchne data prislusneho airspacu
   * @param iso - iso2 country code, napr. CZ
   * @returns Promise, ktera resolvuje s geojsonem nacteneho airspacu
  */
  loadAirspace (iso, date = '') {
    return PMS(['AirspaceLoaded', iso], (iso, date) => PGUtil._loadAirspace(iso, date), iso, date);
  },
  _loadAirspace (iso, date) {
    const roundDate = ExtDate.toTimezone(new ExtDate(date)).strftime('%FT%H:00:00Z');
    return XCUtil.fetchJSON(XCUtil.resolveAirspaceApiUrl(iso, roundDate))
      .then(geojson => {
        this.pgStore.setProp('airspaceData', iso, geojson);//ulozeni do store
        return geojson;
      });
  },

  /**
   * vykresli airspace
   * osetri geojson (problem se plne pruhlednymi vrstvami  fillOpacity : 0)
   * prida iso code do array store.airspaceDrawn
   * @param iso - iso2 country code, napr. CZ
   * @returns Promise, ktera resolvuje objektem map
  */
  drawAirspace (iso, date = '') {
    return PMS(['AirspaceDrawn', iso], (iso, date) => PGUtil._drawAirspace(iso, date), iso, date);
  },
  _drawAirspace (iso, date) {
    const MapLoaded = this.pmsStore.getPMS('MapLoaded');
    const AirspaceLoaded = PGUtil.loadAirspace(iso, date);

    return Promise.all([MapLoaded, AirspaceLoaded]).then(([map, geojson]) => {
      //konverze geojsonu - vynechava CZ IFR prostor
      geojson.features = geojson.features.filter(f => !["CZ - IFR prostor"].includes(f.properties.name));
      // osetreni opacity vs. barvy u plne plne pruhlednych prostoru, napr. TMA V Praha
      geojson.features.forEach(f => {
        if (f.properties.fillOpacity == 0) {
          Object.assign(f.properties, {
            fillColor : "hsla(0, 0%, 100%, 0.2)",//"hsla(0, 0%, 0%, 0)",
            fillOpacity : 0.8
          });
        }
      });

      //kresleni
      XCUtil.drawAirspace(map, XCUtil.getAirspaceId(iso), geojson/*, before*/);
      //vlozeni do store
      const airspaceDrawn = [...this.pgStore.get('airspaceDrawn'), iso].sort();
      this.pgStore.set({airspaceDrawn});
      return map;
    });
  },

  loadTask (code) {
    return PMS(['TaskLoaded', code], (code) => PGUtil._loadTask(code), code);
  },
  _loadTask (code) {
    return XCUtil.fetchJSON(XCUtil.resolveTaskApiUrl(code))
      .then(geojson => {
        this.pgStore.setProp('taskData', code, geojson);//ulozeni do store
        return geojson;
      });
  },

  /**
   * vykresli task
   * prida code do array store.taskDrawn
   * @param code - 4 pismenny code tasku
   * @returns Promise, ktera resolvuje objektem map
  */
  drawTask (code = '', before) {
    return PMS(['TaskDrawn', code, before], (code, before) => PGUtil._drawTask(code, before), code, before);
  },
  _drawTask (code, before) {
    const MapLoaded = this.pmsStore.getPMS('MapLoaded');
    const TaskLoaded = PGUtil.loadTask(code);

    return Promise.all([MapLoaded, TaskLoaded]).then(([map, geojson]) => {
      const gs = {};
      gs.fill = Util.getFreshGeoJSONCollection();
      gs.line = Util.getFreshGeoJSONCollection();
      //gs.symbol = Util.getFreshGeoJSONCollection();

      const pf = window.performance;
      if (!pf || !pf.navigation || !pf.navigation.type == pf.navigation.TYPE_RELOAD) {
        if (geojson.bbox) gs.bounds = MBUtil.boundsFromCoords(geojson.bbox);
      }

      gs.fill.features = geojson.features
        .filter(f => f.properties.type == 'cylinder' && f.properties.radius > 0)
        .map(f => {
          //typy cylindru: WNoType | WTakeoff | WSSS | WESS | WGoal
          let outlineColor, color, opacity;
          switch (f.properties.ctype) {
            case 'WSSS':
            case 'WESS':
              outlineColor = 'blue';
              color = 'blue';
              opacity = 0.3;
              break;

            case 'WGoal':
              outlineColor = 'hsl(16,100%,50%)';
              color = 'hsl(16,100%,50%)';
              opacity = 0.3;
              break;
            //WNoType | WTakeoff
            default:
              outlineColor = 'black';
              color = 'white';
              opacity = 0.3;
          }
          Object.assign(f.properties, {outlineColor, color, opacity});
          return f;
        });

      gs.line.features = geojson.features
        .filter(f => ['centerline', 'optiline'].includes(f.properties.type))
        .map(f => {
          let color, width, opacity;
          switch (f.properties.type) {
            case 'optiline':
              color = 'blue';
              width = 1;
              opacity = 0.8;
              break;

            //centerline
            default:
              color = 'white';
              width = 1;
              opacity = 0.5;
          }
          Object.assign(f.properties, {color, width, opacity});
          return f;
        }).sort((a, b) => {
          let o = 0;
          if (a.properties.type == 'centerline') o = -1;
          if (b.properties.type == 'centerline') o = 1;
          return o;
        });

      //gs.symbol.features = geojson.features.filter(f => f.geometry.type == 'Point');

      //kresleni
      XCUtil.drawTask(map, XCUtil.getTaskId(code), gs, before);
      //vlozeni do store
      const taskDrawn = [...this.pgStore.get('taskDrawn'), code].sort();
      this.pgStore.set({taskDrawn});
      return map;
    });
  },

  /**
   * nacita basic/hires data letu
   * uklada data do Store
   * uklada promise do PMS
   * @param type = basic|hires
   * @returns Promise; resolvuje s nactenymi daty basic/hires letu z API
  */
  loadFlightData (o) {
    return PMS(['FlightDataLoaded.'+o.type, o.id], o => PGUtil._loadFlightData(o), o);
  },
  _loadFlightData (o = {id: "", type: ""}) {
    XCUtil.checkType(o.type, TRACK_DATA_TYPES);
    return XCUtil.fetchJSON(XCUtil.resolveTicketUrl())
      .then(json => d0(json.ticket).then(ticketResponse => [json.ticket, ticketResponse]))
      .then(([ticket, ticketResponse]) => XCUtil.fetchJSON(
        XCUtil.resolveFlightApiUrl(o.id, o.type), {
          headers: {
            'X-Ticket': ticket,
            'X-Ticket-Response': ticketResponse
          }
        }
      ))
      .then(data => {
        //ulozeni do Store
        this.pgStore.setFlightData(o.type, XCUtil.getFlightId(data), data);
        return data;
      });
  },

  /**
   * pripravuje GeoJSON basic/hires tracku
   * pracuje az pote, co byla nactena data basic/hires letu
   * @param data - nactena data letu z API
   * @returns Promise; resolvuje s resultObjekt, ktery ma property geojson
  */
  prepareTrackData (o, data) {
    return PMS(['TrackDataReady.'+o.type, o.id], (o, data) => PGUtil._prepareTrackData(o, data), o, data);
  },
  _prepareTrackData (o = {id: "", type: ""}, data) {
    XCUtil.checkType(o.type, TRACK_DATA_TYPES);

    const encKEY = o.type == 'hires' ? 'encHiRes' : 'enc';
    const bData = this.pgStore.getFlightData(o.id);
    const startT = new ExtDate().setIsoTime(bData.pointStart.time);
    const endT = new ExtDate().setIsoTime(bData.pointEnd.time);
    const indexLength = ((endT.getTime() - startT.getTime())/1000)+1;

    //priprava GeoJSON track source
    return XCUtil.prepareTrackData({
      enc: data[encKEY],
      res: EncPoly.getFreshResultObject(indexLength, data[encKEY].prec, o.id),
      add: o.type=='basic' ? bData.pointEnd : null,
      id: XCUtil.getFlightId(data)
    }).then(([res, id]) => {
      //ulozeni resultu do Store
      const keypath = o.type+'Track';
      const track = Object.assign({}, this.pgStore.get(keypath), {[id]: res});
//console.log('track', track)
      this.pgStore.set({[keypath]: track});
      return res;
    });
  },

  /**
   * kresli track do mapy
   * pracuje az pote, co:
    - byla nactena data letu (loadFlightData())
    - byla pripravena GeoJSON data tracku (prepareTrackData())
    - byla nactena mapa (loadMap())
   * @param [map, res]
    - map je Mapbox map objekt
    - res je resultObjekt
   * pridava do mapy 2 layery (line a outline) - neviditelne
   * @param o {id : id flight|'all', type :TRACK_TYPES, lineColor? : hsla, outlineColor? : hsla, before? : mapbox id layer}
   * @returns Promise; resolvuje s resultObjekt, ktery ma property geojson
  */
  drawTrack (o, mr) {
    return PMS(['TrackDrawn.'+o.type, o.id], (o, mr) => PGUtil._drawTrack(o, mr), o, mr);
  },
  _drawTrack (o = {id: "", type: "", lineColor: null, outlineColor: null, idSource: null, before: undefined}, [map, res]) {
    XCUtil.checkType(o.type, TRACK_TYPES);
//console.log('PGUtil.drawTrack()::', o)
    //kresleni tracku
    return new Promise((resolve, reject) => {

      const {id, type, lineColor, before} = o;
      let {idSource, outlineColor} = o;

      //pripadne pridani source, pokud nebyl predan idSource
      if (!idSource) {
        idSource = XCUtil.getTrackSourceId(type, id);
        XCUtil.setSource(map, idSource, res.geojson2D, res.sourceDef || {});
      };


      const args = [map];
      const fData = this.pgStore.getFlightData(id);
      const flight_type = fData ? fData.type : 1;

      //pridame do resultu
      //2. prvek - objekt s id pro layery,
      //3. prvek - objekt s barvami pro layery
      args.push({
        line: XCUtil.getTrackLayerId(type, 'line', id),
        outline: XCUtil.getTrackLayerId(type, 'outline', id),
        source: idSource,
        before
      }, {
        line: lineColor || COLORS.FLIGHT_LINE,
        outline: outlineColor || {1: COLORS.FLIGHT_OUTLINE, 2: COLORS.WALK_OUTLINE}[flight_type]
      });
//console.log('drawTrack::', args)
      resolve(XCUtil.drawTrack(args, flight_type, res.layerDef || {}).then(() => res));
    });
  },



  /**
   * nastaveni source pro basic track, basic i hires data
   * @param o.force true|false (default false) - je-li true, dojde VZDY k celemu cistemu behu, tj.
   * - probehne i kdyz uz PMS existuje
   * - vzdy se vola nastaveni source (XCUtil.setSource)
   * @param o.id: id letu
   * @param o.type: string TRACK_DATA_TYPES basic|hires
   * @param geojson: object res.geojson2D
   * @param assignO: object props se pridavaji do definice source
   * @returns id source (z mapy)
  */
  setTrackSource  ({id, type, force}, geojson, assignO) {
    XCUtil.checkType(type, TRACK_DATA_TYPES);

    const idSource = XCUtil.getTrackSourceId('basic', id);

    //skip funkce
    //pokud source uz existuje a soucasny data type je basic, znamena to, ze uz tam JE hires - ten nechcem menit!
    const skipF = source => !force && type == 'basic' && source;

    return this.setSource({id, idSource, type, force, skipF}, geojson, assignO);
  },

  /** nastaveni source pro route **/
  setRouteSource ({id, type, force}, geojson) {
    XCUtil.checkType(type, ROUTE_TYPES);
//console.log('setRouteSource', id, type, geojson)
    const idSource = XCUtil.getRouteSourceId(type, id);

    return this.setSource({id, idSource, type, force}, geojson);
  },

  /**
   * obecne nastaveni source pro track, route....
   * @param o.force true|false (default false) - je-li true, dojde VZDY k celemu cistemu behu, tj.
   * - probehne i kdyz uz PMS existuje
   * - vzdy se vola nastaveni source (XCUtil.setSource)
   * @param o.skipF - skip funkce
   * - dostava jako parametr source objekt z mapy
   * - pokud vrati true, promise se rovnou resolvuje s idSource aniz by se doslo ke zmene source
   * @param o.id: id letu
   * @param o.idSource id source
   * @param o.type: string TRACK_DATA_TYPES [basic|hires];ROUTE_TYPES [DEFAULT|TRIANGLE|FREE_FLIGHT|FREE_DISTANCE]
   * @param geojson: object
   * @param assignO: object props se pridavaji do definice source
   * @returns id source (z mapy)
  */

  setSource (o, geojson, assignO) {
    const keypath = ['SourceSet.'+o.type, o.id];
    const func = (o, geojson, assignO) => PGUtil._setSource(o, geojson, assignO);
    if (o.force) this.pmsStore.sPMS(keypath, null);
    return PMS(keypath, func, o, geojson, assignO);
  },
  _setSource ({id, idSource, type, force, skipF}, geojson, assignO = {}) {
//console.log('_setSource::', o)
    //nastaveni source
    return new Promise((resolve, reject) => {
      const map = this.pgStore.get('map');
      const source = map.getSource(idSource);

      if (typeof skipF === "function" && skipF(source)) {
        resolve(idSource);
        return;
      };

      XCUtil.setSource(map, idSource, geojson, assignO);
      resolve(idSource);
    });
  },

  /**
   * @param data - basic data letu
   * @param type :ROUTE_TYPES
   * @returns resultObject s geojson (LineString trati)
  */
  prepareRouteData (data, type) {
    const prepareFunc = type=='TRIANGLE' ? XCUtil.prepareTriangleData : XCUtil.prepareRouteData;
    const route = XCUtil.getRouteData(data, type);
    const res = MBUtil.getFreshResultObject('LineString');

    return prepareFunc({route, res});
  },

  /**
   * kresli route do mapy
   * pracuje az pote, co:
    - byla nactena data letu (loadFlightData())
    - byla pripravena GeoJSON data route (viz showRoute())
    - byla nactena mapa (loadMap())
   * @param [map, res]
    - map je Mapbox map objekt
    - res je resultObjekt
   * pridava do mapy layer
   * @returns Promise; resolvuje s resultObjekt, ktery ma property geojson
  */
  drawRoute (o, mr) {
    return PMS(['RouteDrawn.'+o.type, o.id], (o, mr) => PGUtil._drawRoute(o, mr), o, mr);
  },
  _drawRoute ({id, type, source, lineColor}, [map, res]) {
    XCUtil.checkType(type, ROUTE_TYPES);

    //cekame na to az se vykresli basic track
    return this.pmsStore.getPMS(['TrackDrawn.basic', id])
    //kresleni route
    .then(() => {
      //pridame do resultu
      //2. prvek - id pro layer
      //3. prvek - source, tj. geojson nebo id source (string)
      //4. prvek - barva pro layer
      //5. prvek - ID outlinelayeru tracku, pokud je (aby se route mohla dat POD nej)
      const trackLayerID = TRACK_TYPES
        .map(type => XCUtil.getTrackLayerId(type, 'outline', id))
        .find(layerID => map.getLayer(layerID));

      const args = [
        map,
        XCUtil.getRouteLayerId(type, id),
        source,
        lineColor,
        trackLayerID
      ];
      return XCUtil.drawRoute(args);
    });
  },

  /**
   * @param data - basic data letu
   * @param type :MARKER_TYPES
   * @param route_type :ROUTE_TYPES
   * @returns resultObject s geojson (MutliPoint trati)
  */
  prepareMarkersData (data, type, route_type) {
    const points = XCUtil.getTurnpointsData(data, type, route_type);
    return XCUtil.preparePointsData({points, res: MBUtil.getFreshResultObject('MultiPoint')});
  },

  /**
   * vytvari set markeru, pokud jeste nejsou vytvorene
   * uklada je do store.markers.x.y.z
   * type :MARKER_TYPES
   * route_type :ROUTE_TYPES nebo null
   * element - DOM node nebo Array [...DOMnode]
   * - pokud je to DOMnode, klonuje se pro kazdy Marker
   * - pokud je to Array, bere se kazdy prvek jako DOMnode pro Marker
   * color - array(pro kazdy bod barva) nebo string (jeden color pro vsechny)
   * @returns Promise; resolvuje s markers - Array mapbox markeru
  */
  createMarkers (o, res) {
    const keypath = ['MarkersCreated.'+o.type+(o.route_type ? '.'+o.route_type : ''), o.id];
    return PMS(keypath, (o, res) => PGUtil._createMarkers(o, res), o, res);
  },
  _createMarkers (o = {id: "", type: "", route_type: "", color: null, element: null}, res) {
    XCUtil.checkType(o.type, MARKER_TYPES);

    //vytvoreni markeru, pokud nejsou
    return new Promise((resolve, reject) => {
      const markers = this.pgStore.getMarkers(o) || [];
      if (markers.length > 0) {
        resolve(markers);
        return;
      };

      res.geojson.geometry.coordinates.forEach((coords, i) => {
        const color = Array.isArray(o.color) ? o.color[i] : o.color;
        const element = o.element ? (Array.isArray(o.element) ? o.element[i] : o.element.cloneNode(true)) : null;
        if (typeof color == 'undefined') console.log('PGUtil.createMarkers('+[...arguments].toString()+') warning: color is undefined for index '+i);
        if (typeof element == 'undefined') console.log('PGUtil.createMarkers('+[...arguments].toString()+') warning: element is undefined for index '+i);
        markers.push(MBUtil.createMarker({color, element}, coords));
      });
      this.pgStore.setMarkers(o, markers);
      resolve(markers);
    });
  },

  showMarkerGroup (id, type, route_type = null) {
    const map = this.pgStore.get('map');
    const to_show = this.pgStore.getMarkers({id, type, route_type}) || [];
    to_show.forEach(marker => MBUtil.showMarker(marker, map));
  },

  hideMarkerGroup (id, type, route_type = null) {
    const to_hide = this.pgStore.getMarkers({id, type, route_type}) || [];
    to_hide.forEach(marker => MBUtil.hideMarker(marker));
  },

  /**
   * @param type = all|startend|turnpoints
   * @return Array [[marker_type, route_type], ...]
   * marker_type 'turnpoints'
   * route_type: :BASIC_ROUTE_TYPES
   * - pouze pro turnpoints typy
  */
  getMarkerGroups (type = 'all') {
    const g1 = ['startend','all'].includes(type) ? MARKER_TYPES.filter(mt => mt!='turnpoints').map(mt => [mt, null]) : [];
    const g2 = ['turnpoints','all'].includes(type) ? BASIC_ROUTE_TYPES.map(rt => ['turnpoints', rt]) : [];
    return [...g1, ...g2];
  },

  /**
   * adjustuje mapu podle zobrazenych tracklogu
   * pracuje az po:
   * - nacteni mapy
   * - nacteni dat vsech letu pridanych do mapy v dobe volani funkce
  */
  adjustToFlightBounds () {
    const PMSf = this.pmsStore.gPMSEntries('FlightDataLoaded.basic').map(([key, pms]) => pms);
    const PMSm = this.pmsStore.getPMS('MapLoaded');
//console.log('PMSf', PMSf);
    Promise.all([...PMSf, PMSm])
    //ziskani pole bounds
    .then(vals => {
      const map = vals.pop();
      const bounds = MBUtil.boundsFromAPI(
        vals
        .filter(data => this.pgStore.hasFlight(data.id))//pouze aktualne zobrazene lety
        .map(data => data.enc.bounds)
      );
//console.log('adjustToFlightBounds::bounds', bounds)
      map.fitBounds(bounds, {padding: 50});
    });
  },

  /*
  **********************************************
  kompozitni page funkce
  **********************************************
  */
  /**
   * nacteni mapy bez letu
  */
  initMap (bounds, options = {
    fullscreen: true,
    background: true,
    airspace: true,
    geolocation: false,
    sniffer: true,
    fullstyle: false,
    graph: false
  }) {
    return PGUtil.loadMap(bounds)
    .then(map => {
      if (options.fullscreen) PGUtil.prepareFullscreen();//pripravi prepinani na fullscreen
      if (options.background) PGUtil.prepareBackground();//pripravi prepinani satelitni mapy
      if (options.airspace) PGUtil.prepareAirspace();//pripravi sledovani airspace
      if (options.geolocation) PGUtil.prepareGeolocation();//pripravi sledovani user pozice
      if (options.sniffer) PGUtil.prepareSniffer();//init map sniffingu
      if (options.fullstyle) PGUtil.loadFullStyle(false);//nacteni full stylu
      if (options.graph) GraphMod.prepareGraph();
      return map;
    });
  },

  /**
   * nacteni dat letu a mapy
   * @returns [Promise MapLoaded, Promise basicFlightLoaded]
  */
  initFlightMap (idFlight) {
    const PMSbasicFlightLoaded = PGUtil.loadFlightData({
      id : idFlight,
      type: "basic"
    });

    const PMSmapLoaded = PMSbasicFlightLoaded
    .then(data => data.enc.bounds)
    .then(PGUtil.initMap)
    .then(map => PGUtil.prepareMove(map))
    .then(map => PGUtil.prepareMarkerMove(map));

    return [PMSmapLoaded, PMSbasicFlightLoaded];
  },

  /**
   * nacteni letu, oba typy, basic i hires
   * @param id: id letu
   * @param type: string TRACK_DATA_TYPES basic|hires
   * @param force: boolean [default false] - jestl se ma vynutit setSource() i kdyz uz je PMS
   * @returns void
  */
  showTrack (id, type, force = false, before = undefined) {
    const [PMSmapLoaded, PMSbasicFlightDataLoaded] = PGUtil.initFlightMap(id);

    //u hires musime nacist hires data; u basic uz je mame
    const PMSflightDataLoaded = (type=='hires') ? Promise.all([
      PGUtil.loadFlightData({id, type}),
      PMSbasicFlightDataLoaded
    ]).then(([data, d]) => data) : PMSbasicFlightDataLoaded;

    //priprava track dat
    const PMStrackDataReady = PMSflightDataLoaded
      .then(data => PGUtil.prepareTrackData({id, type}, data));

    Promise.all([PMSmapLoaded, PMStrackDataReady])
    //nastaveni source
    .then(([map, res]) => {

      const sourceDef = {
        lineMetrics : true,
        tolerance : 0.125 //nastavuje 0 kvuli animaci pomoci lineGradientu - u mensich zoomu pak vykreslena cast nesedela s pozici pilota a presahovala ji
      };

      return PGUtil.setTrackSource(
        {id, type, force}, res.geojson2D, sourceDef
      )
      .then(idSource => {
        return [map, res, idSource];
      });
    })
    //kresleni
    .then(([map, res, idSource]) => {
      const type = 'basic';

      return PGUtil.drawTrack({
        id,
        type,
        idSource,
        before
      }, [map, res]);
    })
    //zviditelneni
    .then(res => {

      const map = this.pgStore.get('map');
//console.log('%cchange line color', "color: blue; background-color: green; padding: 10px")
      //nastaveni barvy line
      MBUtil.changeLineColor(map,
        XCUtil.getTrackLayerId('basic', 'line', id),
        XCUtil.getColor(id),
        !this.viewStore.get('playMode') //pokud neni zapnuty playMode, resetuje se rovnou i line-gradient
      );
//console.log('%cchange visibility', "color: blue; background-color: green; padding: 10px")
      //visibility
      MBUtil.changeVisibility(map, [], //hide
        XCUtil.getTrackLayersIds(id, tp => tp=='basic') , //show
      );

      return res;
    })
    .then(res => {
      PGUtil.loadFullStyle();
      return res;
    })
    //zachyceni rejectu
    //.catch(err => console.log('PGUtil.showFlight('+[...arguments].toString()+') rejection:' + err));
  },

  /**
   * skryje tracky (basic, exact) pri odebrani letu
   * @param id - id flight
  */
  hideTracks (id) {
    const PMSmapLoaded = this.pmsStore.getPMS('MapLoaded');
    const PMStrackDrawn = this.pmsStore.getPMS(['TrackDrawn.basic', id]);

    Promise.all([PMSmapLoaded, PMStrackDrawn]).then(([map, res]) => {
      //release color
      this.pgStore.releaseColor(id);
      //skryti tracklogu; line/outline
      MBUtil.changeVisibility(map,
        XCUtil.getTrackLayersIds(id), //hide - vsechno!
      );
    });
  },

  prepareExactTrackSource (id = 'all') {
    const idSource = XCUtil.getTrackSourceId('exact', id);
    const map = this.pgStore.get('map');
    if (map.getSource(idSource)) return idSource;
    XCUtil.setSource(map, idSource, Util.getFreshGeoJSON(), {
      lineMetrics: true
    });
    return idSource;
  },

  showExactTrack (id) {
    //kresleni

    Promise.all([this.pmsStore.getPMS('MapLoaded'), this.pmsStore.getPMS(['FlightDataLoaded.basic', id])])
    .then(([map]) => {
      //vytvorime genericky res - geojson se pak bude menit dynamicky
      //pridame akorat sourdeDef a layerDef
      const res = {
        geojson2D : Util.getFreshGeoJSON(),
        layerDef: {
          //filter: ["==", ["number", ["id"]], id]
          //filter: ["==", ["string", ["id"]], String(id)]
        }
      };

      const idSource = this.prepareExactTrackSource(id);

      //exact track
      return PGUtil.drawTrack({
        id,
        type : 'exact',
        idSource
      }, [map, res]);
    })
    .then(res => {
      //pokud nema byt tracklog zviditelnen, tj.
      //1) je vypnuty playMode
      if (!this.viewStore.get('playMode')) return res;

      const map = this.pgStore.get('map');
      const idLine = XCUtil.getTrackLayerId('exact', 'line', id);
      const idOutline = XCUtil.getTrackLayerId('exact', 'outline', id);
      const idPositions = ID_ROOT.pos;

      //posun pod positions
      map.moveLayer(idOutline, idPositions);
      map.moveLayer(idLine, idPositions);

      MBUtil.changeLineGradient(map,
        idOutline,
        [0, 'hsla(0, 0%, 0%, 0.1)',
        1, 'hsla(0, 0%, 0%, 0.6)']
      );

      MBUtil.changeLineGradient(map,
        idLine,
        [0, XCUtil.getColor(id, 0.1),
        1, XCUtil.getColor(id, 1)]
      );

      //visibility
      MBUtil.changeVisibility(map, [],
        XCUtil.getTrackLayersIds(id, tp => tp=='exact')
      );
//console.log('showExactTrack', map.getStyle().layers);
    });
  },

  prepareExactTrack (id) {
    return this.pmsStore.getPMS(['TrackDataReady.basic', id])
    .then(data => {
      const res = {
        geojson2D : Util.getFreshGeoJSON("LineString", {
          id,
          properties : {
            color : XCUtil.getColor(id)
          }
        })
      };
      //ulozeni do store
      const exactTrack = Object.assign({}, this.pgStore.get('exactTrack'), {[id]: Util.fresh(res)});
      this.pgStore.set({exactTrack});
      return res;
    });
  },

  /** vraci Promise resolvujici s idLayer positions **/
  showPositionsLayer (idLayer = '', idSource = '', customLayerDef = {}, customSourceDef = {}, fLoadIcons = null) {

    const filter = customLayerDef && customLayerDef.filter ? {"filter": customLayerDef.filter} : {};
    const {baseSize, posTextField} = this.viewStore.get(['baseSize', 'posTextField']);

    return this.pmsStore.getPMS('MapLoaded')

    .then(map => {
      if (!map.getSource(idSource)) {
        XCUtil.setSource(map, idSource, Util.getFreshGeoJSONCollection(), customSourceDef);
      };
      return map;
    })

    .then(map => {

      const PosLayer = map.getLayer(idLayer) || Object.assign(
        MBUtil.getLayerDef(idLayer, 'positions', idSource),
        Object.assign({
          "paint": Object.assign({
            "text-color": ["get", "cText"],
            "text-halo-color": ["get", "cHalo"],
            "text-halo-width": baseSize/4,
            "text-halo-blur": baseSize/6,
          }, customLayerDef && customLayerDef.paint ? customLayerDef.paint : {}),
          "layout": Object.assign({
            "symbol-placement": "point",
            "symbol-z-order": "auto",
            "symbol-sort-key": ["get", "a"],
            "icon-image": ["concat", "icon-dot-", ["get", "cIcon"]],
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "text-allow-overlap": true,
            //"text-ignore-placement": true,
            //"text-optional": true,
            "icon-padding": 0,
            "text-padding": 0,
            "text-field": posTextField,
            "text-size": baseSize,
            "text-max-width": 16,
            "text-justify": "left",
            "text-font": ["Noto Sans Regular"],
            "text-anchor": "bottom-left",
            "text-offset": [0.5,-0.5]
          }, customLayerDef && customLayerDef.layout ? customLayerDef.layout : {})
        }, filter)
      );

//console.log('showPositionsLayer',idLayer, PosLayer, this.viewStore.get('posTextField'))

      if (!map.getLayer(idLayer)) map.addLayer(PosLayer);

      MBUtil.changeVisibility(map, [], [idLayer]);

      return [map, PosLayer];
    })
    .then(out => {
      return new Promise((res, rej) => {
        if (typeof fLoadIcons !== 'function') res(out);
        fLoadIcons(out).then(() => {res(out)});
      });
    });
  },

  loadIconsPromise (IconsSet = []) {
//console.log('loadIconsPromise:::', IconsSet);
    const StyleLoaded = this.pmsStore.getPMS('StyleLoaded');
    return StyleLoaded.then(map => {
      const ImgPromises = IconsSet.map(([ident, fill, stroke, strokewidth, text, textAttrs, attrs, donut]) => {
        return XCUtil
          .loadIcon(fill, stroke, strokewidth, text, textAttrs, attrs, donut)
          .then(icon => {
            const imgid = 'icon-dot-'+ident;
            if (!map.hasImage(imgid)) map.addImage(imgid, icon);
          });
      });
      return Promise.all(ImgPromises);
    });
  },

  /**
   * @param type :ROUTE_TYPES
   * @returns res {
   *  route - resultObjekt s route
   *  triangle - result Objekt s triangle (optional)
   * }
  */
  showRoute (id, type = "DEFAULT", force = false) {
    XCUtil.checkType(type, ROUTE_TYPES);

    const [PMSmapLoaded, PMSbasicFlightDataLoaded] = PGUtil.initFlightMap(id);

    const PMSrouteDataReady = PMSbasicFlightDataLoaded
    //pripadny reject
    .then(data => {
//console.log('showRoute::data', id, type, data)
      return new Promise ((resolve, reject) => {
        if (data.type!=1) {
          reject('route allowed only data.type==1 (flight); current type: '+data.type);
        };
        if (type=="TRIANGLE" && data.league.route.type=='FREE_FLIGHT') {
          reject('TRIANGLE not allowed for FREE_DISTANCE default route');
        };
        resolve(data);
      });
    })
    //priprava route dat
    .then(data => PGUtil.prepareRouteData(data, type));

    Promise.all([this.pmsStore.getPMS('MapLoaded'), PMSrouteDataReady])
    //nastaveni source
    .then(([map, res]) => {
      return PGUtil.setRouteSource(
        {id, type, force}, res.geojson
      ).then(idSource => [map, res, idSource]);
    })
    //kresleni
    .then(([map, res, source]) => {
      return PGUtil.drawRoute({
        id, type, source,
        lineColor: type=='TRIANGLE' ? COLORS.TRIANGLE: COLORS.ROUTE
      }, [map, res]);
    })
    .then(source => {
      //skryti a zobrazeni
      //v multimode nezobrqzujeme zadnou route
      //v playMode nezobrazujeme route
      const noshow = this.pgStore.get('colors').length>1 || this.viewStore.get('playMode');
      const group = noshow ? [] : XCUtil.getRouteGroup(type);

      const to_hide = ROUTE_TYPES
        .filter(tp => !group.includes(tp))
        .map(tp => XCUtil.getRouteLayerId(tp, id));

      const to_show = ROUTE_TYPES
        .filter(tp => group.includes(tp))
        .map(tp => XCUtil.getRouteLayerId(tp, id));
      MBUtil.changeVisibility(
        this.pgStore.get('map'), to_hide, to_show
      );
    })
    //zachyceni rejectu
    .catch(err => {
      //console.log('PGUtil.showRoute('+[...arguments].toString()+') rejection:' + err)
    });
  },

  /**
   * skreje vsechny routes pro 1 let
   * pouze ty ktere jsou v tu chvili (nejdrive ale po nacteni mapy) vykreslene
   * @param id idFlight
  */
  hideRoutes (id) {
    const PMSmapLoaded = this.pmsStore.getPMS('MapLoaded');
    ROUTE_TYPES.forEach(type => {
      const PMS = this.pmsStore.gPMS(['RouteDrawn.'+type, id]);
      if (!PMS) return;
      Promise.all([PMSmapLoaded, PMS]).then(([map, res]) => MBUtil.changeVisibility(
        map, [XCUtil.getRouteLayerId(type, id)], []
      ));
    });
  },

  /**
   * @param type :MARKER_TYPES
   * @param route_type :ROUTE_TYPES, pouze pokud type je turnpoints; jinak se nepouzije
   * @returns res {
   *  route - resultObjekt s route
   *  triangle - result Objekt s triangle (optional)
   * }
  */
  showMarkers (id, type, route_type = undefined, force = false) {
    XCUtil.checkType(type, MARKER_TYPES);

    const [PMSmapLoaded, PMSbasicFlightDataLoaded] = PGUtil.initFlightMap(id);

    const PMSmarkerDataReady = PMSbasicFlightDataLoaded
    //pripadny reject
    .then(data => {
      return new Promise ((resolve, reject) => {
        //turnpoints se vykresluji jen u data.type == 1
        if (['turnpoints'].includes(type) && data.type!=1) {
          reject('Turnpoints allowed only for data.type==1 (flight); current type: '+data.type);
        };
        resolve(data);
      });
    })
    //priprava markers dat
    .then(data => PGUtil.prepareMarkersData(data, type, route_type));

    Promise.all([PMSmapLoaded, PMSmarkerDataReady])
    .then(([map, res]) => {
      const data = this.pgStore.getFlightData(id);

      return PGUtil.createMarkers({
        id: id,
        type: type,
        route_type: route_type,
        color: type=='startend' ? COLORS.startend : COLORS.turnpoints,
        element: type=='startend' ? (data.type==2 ? XCUtil.getStartendWalkElements() : XCUtil.getStartendFlyElements()) : XCUtil.getTurnpointElement()
      }, res)
      //pridame res
      .then(markers => ({markers, res}));
    })

    //force update
    .then(({markers, res}) => {
      //pokud je force, prenastavujeme pozici
      if (force) {
        res.geojson.geometry.coordinates.forEach((coords, i) => {
          MBUtil.updateMarker(markers[i], coords)
        });
      };
      return markers;
    })

    //skryvani a odkryvani
    .then(markers => {
      const noshow = this.pgStore.get('colors').length>1 || this.viewStore.get('playMode');
      if (type=='turnpoints') {
        //skryti ostatnich TP group; v noshow skryvame vsechny TP groups
        PGUtil.getMarkerGroups(type)
          .filter(([mt, rt]) => rt!=route_type || noshow)
          .forEach(([mt, rt]) => PGUtil.hideMarkerGroup(id, mt, rt));
      };
      if (!noshow) PGUtil.showMarkerGroup(id, type, route_type);
    })
    .catch(err => console.log('PGUtil.showMarkers('+[...arguments].toString()+') rejection:' + err));
  },

  hideMarkers (id, type) {
    PGUtil.getMarkerGroups(type).forEach(([mt, rt]) => {
      PGUtil.hideMarkerGroup(id, mt, rt);
    });
  },

  /**
   *  vytvori marker pro aktualni polohu pilota
  */
  createPilot (id) {
    return Promise.all(PGUtil.initFlightMap(id))
    .then(([map, data]) => {
      const type = 'pilot';

      let marker = this.pgStore.getMarkers({id, type});
      if (marker) {
        MBUtil.hideMarker(marker);
      };

      const color = XCUtil.getColor(id);
      const element = XCUtil.getPilotElement(color);
      marker = MBUtil.createMarker({color, element}, [0,0]);
      this.pgStore.setMarkers({id, type}, marker);

      return marker;
    });
  },

  hidePilot (id) {
    const marker = this.pgStore.getMarkers({id, type : 'pilot'});
    if (marker) MBUtil.hideMarker(marker);
  },

  /**
   * @param force - prekresli se pri kazdem volani; hodi se pro Live
  */
  showGraph (id, force = false) {
    const type = 'basic';
    const PMSgraphPrepared = GraphMod.prepareGraph();
    const PMStrackDataReady = PGUtil.loadFlightData({id, type})
    .then(data => PGUtil.prepareTrackData({id, type}, data));

    Promise.all([PMSgraphPrepared, PMStrackDataReady])
    .then(([svg, res]) => {
//console.log('PGUtil.showGraph()', res)
      return {id, svg, res};
    })
    .then(({id, svg, res}) => GraphMod.createFlightGroup({id, svg, res, force}))
    //.catch(err => console.log('PGUtil.showGraph('+[...arguments].toString()+') rejection:' + err));
  },

  /**
   * uzpusobuje zobrazeni na mape tomu, jestli je zobrazen 1 nebo vice barev (colorOwneru)
   * pokud vice, skryje routes a route Markers
  */
  checkMultiMode (multiple) {
    const {routeType, FLIGHTS} = this.pgStore.get(['routeType', 'FLIGHTS']);
//console.log('checkMultiMode', multiple, routeType, FLIGHTS)
    if (multiple) {
      FLIGHTS.forEach(id => PGUtil.hideRoutes(id));
      FLIGHTS.forEach(id => PGUtil.hideMarkers(id, 'turnpoints'));
    } else {
      const showFlightSet = this.viewStore.get('showFlightSet');
      if (showFlightSet.route) FLIGHTS.forEach(id => PGUtil.showRoute(id, routeType));
      if (showFlightSet.turnpoints) FLIGHTS.forEach(id => PGUtil.showMarkers(id, 'turnpoints', routeType));
    }
  },

  /**
   * @returns Promise, ktera resolvuje v momente kdy dojde k nacteni dat 1. letu
   * resolvuje s data prvniho nacteneho letu
  */
  firstFlightLoaded () {
    return new Promise((resolve, reject) => {
      if (this.pgStore.get('FLIGHTS').length>0) {
        const currentFlights = this.pgStore.get('FLIGHTS').map(id => this.pmsStore.getPMS(['FlightDataLoaded.basic', id]));
        Promise.race(currentFlights).then(data => resolve(data));
      } else {
        this.pgStore.on('flight-add', ({id}) => {
          this.pmsStore.getPMS(['FlightDataLoaded.basic', id]).then(data => resolve(data));
        });
      }
    });
  }
};

/**
 * kompletni nacteni letu (dle id)
 * @param force - boolean; true -> prekresli se pri kazdem volani; hodi se pro Live
 * @param before - mapbox id layer vrsty, pod kterou se ma kreslit track (typicky position layer u live)
*/
function showFlight (id, o = {
  startend: true,
  turnpoints: true,
  route: true,
  graph: true,
  pilot: true
}, force = false, before = undefined) {
//console.log('PGutil.showFlight', id, o)
  PGUtil.showTrack(id, PGUtil.viewStore.get('hires') ? 'hires' : 'basic', force, before);

  const routeType = PGUtil.pgStore.get('routeType');

  if (o.turnpoints) PGUtil.showMarkers(id, 'turnpoints', routeType, force);
  if (o.route) {
    const routeGroup = XCUtil.getRouteGroup(routeType);
    routeGroup.forEach(type => PGUtil.showRoute(id, type, force));
  };
  if (o.startend) PGUtil.showMarkers(id, 'startend', undefined, force);
  if (o.graph) PGUtil.showGraph(id, force);
  if (o.pilot) PGUtil.createPilot(id);
//console.log(PGUtil.pgStore.get('map').getStyle().layers)
};

/**
 * skryje tracklog (dle id) a prislusne dalsi prvky (route, markery)
 * layer zustava pridany, jen se zmeni visibility
*/
function hideFlight (id) {
  //skryti tracku
  PGUtil.hideTracks(id);

  //skryti routes
  PGUtil.hideRoutes(id);

  //skryti markeruu
  PGUtil.hideMarkers(id);

  //skryti pilot markeru
  PGUtil.hidePilot(id);
};

export {
  PGUtil,
  showFlight,
  hideFlight
};
