import App from './App.live-map.html';

const app = new App({
	target: document.querySelector('#map'),
	props : {
		//showPlay : true,
		//showSettings : true
	}
});

export default app;
