import { writable as w } from 'svelte/store';
import { watchable, watchableDerived } from "./store-ext.js";

//aktualni stav hires prepinatka true/false
const hires = w(true);

/** play/stop **/
const playRunMode = watchable({
  playRun: false,
  playMode: false
});

//je zapnute prehravani?
const playRun = watchableDerived(
  playRunMode,
  $playRunMode => $playRunMode.playRun
);

//je zapnuty play mode?
const playMode = watchableDerived(
  playRunMode,
  $playRunMode => $playRunMode.playMode
);

export { hires, playRunMode, playRun, playMode };
