import {Util} from "./util.js";

/**
 * enc - encoded polyline string nebo array (u hi res)
 * pointCallback - callback fce pro zpracovani bodu
 *  - dostava (x nebo [x,y]),i (index bodu, od 0) a resultObject
 *  - vraci resultObject (s pridanymi hodnotami)
 * resultObject - objekt, ktery se predava pointCallback fci
 *  - mel by mit .geojson: {}
 *  - muze obsahovat nejake nenulove vychozi hodnoty: .x, .y,.time, .dist (default 0)
*/
const EncPoly = {
  decode (enc, resultObject, pointCallback, numItems = 1) {
    if (!enc) return resultObject;//napr. enc.b nemusi byt definovan - ignorujeme

    if (!Array.isArray(enc)) enc = [enc];
    resultObject = enc.reduce(
      (rO, seg, idx) => EncPoly.decodeSegment(seg, rO, pointCallback, numItems),
      resultObject
    );
    return resultObject;
  },

  decodeSegment (str, resultObject, pointCallback, numItems = 1) {
    //pomocne prommene
    let base = null,
        index = 0,
        i = resultObject.i ? resultObject.i : 0,
        j = 0;

    //pomocna funkce pro zpracovani jedne hodnoty
    const move = (str, index, base) => {
      let byte = null,
          shift = 0,
          result = 0;
      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      base += ((result & 1) ? ~(result >> 1) : (result >> 1));
      return {base, index};
    };

    let row = Array(numItems).fill(0);

    while (index < str.length) {
      j = 0;
      while (j < numItems) {
        ({base, index} = move(str, index, row[j]));
        row[j] = base;
        j++;
      };

      resultObject = pointCallback(row, i, resultObject);
      i++;
    };

    return resultObject;
  },

  /**
   * jednotne zpracovani noveho formatu komprese
   * @param row - Array
   * @param i - index bodu (od 0)
   * @param r - result object - pocita s {geojson}
  */
  callbackPoint (row,i,r) {
    let [y/*lat*/, x/*lng*/, a/*gps alt*/, dt/*delta time*/, dd/*delta distance*/, g/*gnd elev*/, b/*baro al*/] = row;

    const m = r.multiplier;
    const id = r.id;

    const t = r.time += dt;

    dd = dd/10;//prevod na metry
    let l = 0;//default pro bod 0

    x = x/m;
    y = y/m;

    if (i > 0) {
      const prev = r.geojson.geometry.coordinates[i-1];

      //spocitani vzdalenosti mezi body
      const [x0, y0] = prev;

      const ll = Util.distance([x0,y0], [x,y]);
      l = r.length += ll;//delka tracklogu jen pres ty body, ktere prijdou (v pripade zjednoduseni tracklogu tedy d > l)

      //pokud delta distance je nulova a neni to prvni bod, zmerime radeji cistou vzdalenost od prechoziho bodu
      //hodi se zejmena pro navazovani chunku u livetrackingu
      if (dd == 0) dd = ll;

      //naplneni indexu
      r.index.fill(
        i-1,
        prev[3].t,
        r.time
      );
    };

    const d = r.dist += dd; //delka tracklogu pres VSECHNY body (i ty, ktere kvuli zjednoduseni tracklogu nedostaneme)

    r.index[r.time] = i;

    r.geojson.geometry.coordinates[i] = [x, y, a, {t, d, l, g, b, id}];
    r.geojson2D.geometry.coordinates[i] = [x, y];

    return r;
  },

  /**
   * vraci cistou kopii result objektu
   * @param indexLength - pocet bodu (pro vytvoreni indexu)
   * @param prec precision pro lat/lon
   * @param id - idFlight
  */
  getFreshResultObject (indexLength = 1000, prec = 5, id = null) {
    let res = Object.assign({}, this.resultObject);
    res.id = id;
    res.index = new Uint16Array(new ArrayBuffer(indexLength*2));
    res.geojson = Util.getFreshGeoJSON();
    res.geojson2D = Util.getFreshGeoJSON();
    res.precision = prec;
    res.multiplier = Math.pow(10, prec);
    return res;
  },

  /**
   * prazdny resultObject pro callback* funkce
  */
  resultObject : {
    geojson: null, //pro full track
    geojson2D: null, //pro 2D track, tj. [[y,x], ...]
    index: null, //index pole, ArrayBuffer
    id: null, //id letu
    x: 0, //vychozi x
    y: 0, //vychozi y
    i: 0, //vychozi i - pri pridavani musime nastavit index na konec!
    time: 0, //vychozi time
    dist: 0, //vychozi distance
    length: 0,//vychozi prima delka (primo mezi body, ne pres pripadne vyfitrovane body co nejsou v trakclogu)
    precision: 5, //pocet desetinnych mist lng/lat - 5|6
    multiplier: 1e5,//nasobitel pro lat/lon, dle precision 1e5|1e6
  },

  /**
   * empty GeoJSON LineString
  */
  GeoJSON : {
    linestring : {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "LineString",
        "coordinates": []
      }
    }
  }
};
export {EncPoly};
