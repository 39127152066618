import {
  BASIC_ROUTE_TYPES,
  TS_LABEL_STEPS
} from './const.js';

import { Util } from "./util.js";

import {
  readable,
  writable as w,
  derived,
  get
} from 'svelte/store';

import {
  EventStore,
  watchable,
  watchableFlights,
  watchableDerived,
  getPrev,
  getRem,
  getExt
} from './store-ext.js';

import {
  hires
} from './store-shared.js';


class PgStore extends EventStore {

  /***** constructor() begin *****/
  constructor () {

    super();

    /*** basic stores ***/
    this.setStores(this._getBasic());

    /*** derived stores ***/
    this.setStores(this._getDerived());

    /*** events ***/
    this._setEvents();
  }

  _getBasic () {
    return {
      map : w(null), //objekt Mapbox mapy

      NaviControl: w(null),//objekt Mapbox NavigationControl
      GeoControl: w(null),//objekt Mapbox GeolocationControl
      mapNode : w(null), //DOM node mapy; pouzije se pak v mapOptions
      graphNode : w(null), //DOM node grafu

      apiType : w('api'),//api|api.dev - xcontest api type
      apiSource : w({league : "world"}),//xcontest api source
      apiLang : w("en"), //api language

      FLIGHTS : watchableFlights([]),//prave zobrazene flights; array; ids; id musi byt string

      //ma byt zobrazen graf? K prepisu u live
      showGraph : readable(true, (set) => {
        set(true);
        return () => {};
      }),

      //timeMode: synchronizace casove osy v grafu
      //ABSOLUTE - casova osa se vykresli dle absolutniho casu, tj. alokuje se interval dle skutecnych timestampu letu; zobrazeny cas = localtime prvniho letu
      //UTCTIME - bere se cas letu, tj. lety v ruzne dny jsou syncovane dle UTC casu od pulnoci; zobrazeny cas = UTC
      //LOCALTIME - viz vyse, ale bere se lokalni cas; zobrazeny cas = local time
      //STARTTIME - vsechny lety startuji na X:0 - jsou tedy sychronizovany tak, aby startovali "stejne"; zobrazeny cas = trvani od startu
      graphTimeMode : w('LOCALTIME'),

      //multiMode:
      //SERIAL - lety 1 pilota jsou spojeny do logickeho celku
      //PARALEL - lety 1 pilota jsou povazovany za oddelene
      multiMode : w('SERIAL'),
      routeType : w('DEFAULT'), //vykreslena route type, viz const BASIC_ROUTE_TYPE
      colorOwner : w('PILOT'), //FLIGHT|PILOT - barva se alokuje podle id letu nebo id pilota

      //zaokrouhlovani grafu x - horizontalni (sec), y - vertikalni (m)
      graphCeiling : w({x:0, y:500}),

      //nactena basic data z API; index: flightId, value: json data
      basicFlightData : w({}),
      hiresFlightData : w({}),

      basicTrack : watchable({}),//basic track resObj; index: flightId; value: resObj = {geojson, geojson2D, index, atd...}
      hiresTrack : watchable({}),//hires track resObj; index: flightId; value: resObj = {geojson, geojson2D, index, atd...}
      exactTrack : w({}),//exactTrack resObj; index: flightId; value: resObj = {geojson2D, index, atd...}

      //rtree - rtree pro vsechny aktivni ownerIds a FLIGHTS (viz manageRtree())
      //index: ownerId (!!!), value: rtree object
      basicRtree : w({}),
      hiresRtree : w({}),

      //svg paths ke kazdemu letu
      paths : w({}),

      //prirazene barevne indexy k letum; objekt; index: idOwner (tj. zpravidla flightId), value: colorIndex (viz colors) 0-x
      flightColors : watchable({}),

      //rozmery svg canvasu
      canvas : watchable({w: null, h: null}),

      gX : w(0), //aktualni x osa na grafu (v px)
      tX : watchable(null), //aktualni casova pozice na X ose (v sekundach)

      //bounds pro Z(vysky) a T(cas) - zejmena pro graf
      //index: flightId,  value: objekt ZTbounds {minX, maxX, minY, maxY}
      ZTbounds : watchable({}),

      //specialni ZTbounds zejmena pro live mode; objekt ZTbounds {minX, maxX, minY, maxY} | null
      //pokud jsme v live, je tento store rewritten - vklada se tam minimalni live usek, ktery ma byt videt, i kdyz nejsou zadne live lety zobrazeny
      //defaultne (mimo live) je null
      defaultZTbounds: readable(null, (set) => {
        set(null);
        return () => {};
      }),

      minPixelStep : readable(0, (set) => {
        set(5/window.devicePixelRatio);
        return () => {};
      }),

      taskData : w({}),//task geojsony; klic jsou ctyrpismenne code tasku
      taskDrawn : w([]), //pole vykreslenych routes; values jsou ctyrpismenne code tasku

      airspaceData : w({}),//airspace geojsony; klic je iso2 zeme, napr. CZ
      airspaceDrawn : w([]), //pole vykreslenych airspace layes; values pole jsou iso2 zeme, napr CZ
      airspacePopup : w(null), //mapbox GL JS popup pro airspace info



      //mapbox markery pro start, end, turnpoints
      markers_startend : w({}),
      markers_turnpoints_DEFAULT : w({}),
      markers_turnpoints_FREE_FLIGHT : w({}),
      markers_turnpoints_FREE_DISTANCE : w({}),
      markers_pilot : w({}),

      //pole flight fills (pro multiMode="SERIAL") pro kazdeho pilota
      //idPilot : [svgGroup, ...]
      flightFills : w({}),

      nowTime: w(null),

      defaultPlayTX : w(null)
    };
  }

  _getDerived () {
    /*** vytazeni besic stores, ktere potrebujeme pro derived, do promennych ***/
    const {
      flightColors,
      FLIGHTS,
      basicFlightData,
      colorOwner,
      graphTimeMode,
      graphCeiling,
      routeType,

      ZTbounds,
      defaultZTbounds,
      defaultPlayTX,

      canvas,
      paths,
      tX,
      basicTrack, hiresTrack,
      basicRtree, hiresRtree
    } = this;

    /*** DERIVED ***/
    //alokovane indexy barevne palety; array; cisla 0-x
    const colors = derived(
      flightColors,
      $flightColors => Object.values($flightColors).sort((a, b) => a - b)
    );

    /**
     * @return boolean, jestli jsme v multiple mode (vice barev, tj. colorOwners)
    */
    const multiple = watchableDerived(
      colors,
      $colors => $colors.length>1
    );

    /**
     * master flight id|null, tedy vzdy ten nejdrive pridany prave aktivni
    */
    const masterFlight = derived(
      FLIGHTS,
      $FLIGHTS => $FLIGHTS ? $FLIGHTS[0] : null
    );

    /**
     * vraci lety sgrupovane dle id pilota
     * @returns objekt {idPilot : [idFlight1, idFlights2, ...]}
    */
    const flightsByPilots = derived(
      basicFlightData,
      $basicFlightData => Object.entries($basicFlightData)
        .reduce((fbp, [id, data]) => {
          const idPilot = data.pilot.id;
          fbp[idPilot] = [...(fbp[idPilot] || []), String(id)];
          return fbp;
        }, {})
    );

    /**
     * vraci object {idFlight : idOwner, ...}
    */
    const ownersByFlights = derived(
      [basicFlightData, colorOwner],
      ([$basicFlightData, $colorOwner]) => Object.entries($basicFlightData)
        .map(([id, data]) => {
//console.log('ownersByFlights', id, data, $basicFlightData)
          return [id, $colorOwner=='PILOT' ? data.pilot.id : data.id];
        })
        .reduce((acc, [id, idOwner]) => {
          acc[id] = idOwner;
          return acc;
        }, {}),
      {} //default
    );

    /**
     * master owner id, tedy vzdy owner master flight
    */
    const masterOwner = derived(
      [masterFlight, ownersByFlights],
      ([$masterFlight, $ownersByFlights]) =>  $ownersByFlights && $masterFlight ? $ownersByFlights[$masterFlight] : null
    );

    /**
     * vraci globalni utc offset v hodinach
     * bere to z dat PRVNIHO nacteneho letu
     * @returns UTC offset (hod) nebo 0
    */
    const utcOffset = derived(
      basicFlightData,
      $basicFlightData => {
        const firstData = Object.values($basicFlightData || {}).shift();
        const res = firstData ? firstData.utcOffsetStart/3600 : 0;
        return res;
      }
    );

    /**
     * UTC ofset pro timescale grafu
     * pro ostatni mode nez ABSOLUTE je 0
    */
    const utcOffsetTS = derived(
      [utcOffset, graphTimeMode],
      ([$utcOffset, $graphTimeMode]) => $graphTimeMode=='ABSOLUTE' ? $utcOffset : 0
    );



    /**
     * vraci Array se vsemi ZTbounds (tj. od vsech nactenych letu, i nezobrazenych)
     * @returns Array [[idFlight, bounds], ...]
    */
    const allZTbounds = derived(
      ZTbounds,
      $ZTbounds => {
        return Object.entries($ZTbounds);
      }
    );

    /**
     * vraci Array s aktivnimi ZTbounds (tj. jen od aktualne zobraznych letu)
     * @returns Array [[idFlight, bounds], ...]
    */
    const activeZTbounds = derived(
    	[allZTbounds, FLIGHTS],
    	([$allZTbounds, $FLIGHTS]) => $allZTbounds.filter(([id, bounds]) => $FLIGHTS.includes(id))
    );

    /**
     * totez, ale translated
     * @returns Array [[idFlight, bounds], ...]
    */
    const translatedZTbounds = derived(
      [allZTbounds, graphTimeMode, basicFlightData],
      ([$allZTbounds, $graphTimeMode, $basicFlightData]) => {
        return $allZTbounds.map(([id, bounds]) => {
//console.log('translatedZTbounds::derived', $allZTbounds, $basicFlightData, id);
          const offset = $basicFlightData[id].utcOffsetStart;
          return [id, Util.translateBoundsByTimeMode(bounds, $graphTimeMode, offset)];
        });
      }
    );

    /**
     * vraci Array s translated aktivnimi ZTbounds (tj. jen od aktualne zobraznych letu)
     * @returns Array [[idFlight, bounds], ...]
    */
    const translatedActiveZTbounds = derived(
    	[translatedZTbounds, FLIGHTS],
    	([$translatedZTbounds, $FLIGHTS]) => {
        return $translatedZTbounds.filter(([id, bounds]) => $FLIGHTS.includes(id));
      }
    );

    /**
     *
     * translated default (zpravidla live) bounds, pokud tedy jsou nastavene; jinak vraci null
    */
    const translatedDefaultZTbounds = derived(
      [defaultZTbounds, graphTimeMode],
      ([$defaultZTbounds, $graphTimeMode]) => $defaultZTbounds ? Util.translateBoundsByTimeMode(
        $defaultZTbounds,
        $graphTimeMode
      ) : null
    );

    /**
     * vytvari bounds ktere urcuji aktivni casovy rozsah, tj. v ramci ktere se jde posunout po grafu
     * prepsan v LIVE
     * @returns ZTbounds (translated)
    */
    const accessibleGlobalZTbounds = derived(
      [translatedActiveZTbounds],
      ([$translatedActiveZTbounds]) =>  {
        const bounds = $translatedActiveZTbounds.map(([id, bounds]) => bounds);
        let {minX, maxX, minY, maxY} = Util.reduceBounds(bounds);
        return {minX, maxX, minY, maxY};
      }
    );



    /**
     * spocita globalni ZTbounds z aktivnich letu
     * - vzdy s ohledem na graphTimeMode! (=translated)
     *  - pridava default (live) bound, pokud jsme v live a je definovan
     * - zaokrouhluje dle graphCeiling!
     * @returns global ZTbounds objekt
    */
    const globalZTbounds = watchableDerived(
    	[translatedActiveZTbounds, graphCeiling, translatedDefaultZTbounds],
    	([$translatedActiveZTbounds, $graphCeiling, $translatedDefaultZTbounds]) =>  {
        const ceil = $graphCeiling;

        const bounds = $translatedActiveZTbounds.map(([id, bounds]) => bounds);
        if ($translatedDefaultZTbounds) {
          bounds.push($translatedDefaultZTbounds);
        };
        //reduce na global bounds
        let {minX, maxX, minY, maxY} = Util.reduceBounds(bounds);

        //zaokrouhleni bounds
        if (ceil.x>0) {
          [minX, maxX] = Util.roundRange([minX, maxX], ceil.x);
        };
        if (ceil.y>0) {
          [minY, maxY] = Util.roundRange([minY, maxY], ceil.y);
        };

        return {minX, maxX, minY, maxY};
      }
    );

    /**
     * @returns array[b0, b1,...] kde bx je objekt bounds {minX, maxX} pro spojite celky prekryvajicich se letu
    */
    const discreteTbounds = derived(
      [canvas, globalZTbounds, translatedActiveZTbounds, graphTimeMode],
      ([$canvas, $globalZTbounds, $translatedActiveZTbounds, $graphTimeMode]) => {
        if ($graphTimeMode != 'ABSOLUTE' || !$canvas.w) return [];

        let bounds;

        let sum;
        let transform;
        let xVisibleSteps;

        const STEPS = TS_LABEL_STEPS;

        //musime iterativne najit nejmensi step ktery se zobrazi
        //minimalne vsak 3600
        for (const st of STEPS.filter(s => s >= 3600)) {
          bounds = Util.discreteBounds($translatedActiveZTbounds, st);

          sum =  Util.getPrevGap(Infinity, Util.gapBounds(bounds));
          transform = Util.getTransformParams($globalZTbounds, $canvas, sum);
          xVisibleSteps = Util.getFilterStepsVisible(
      			transform.scaleX,
      			st => st >= 3600 ? 30 : 50
      		);

          if (STEPS.filter(xVisibleSteps).includes(st)) return bounds;
        };

        return [];
      }
    );

    const gaps = derived(
      discreteTbounds,
      $discreteTbounds => {
        const bounds = Util.gapBounds($discreteTbounds);
        const sum = Util.getPrevGap(Infinity, bounds);
        return {bounds, sum};
      }
    );

    /**
     * @returns {scaleX, scaleY, translateX, translateY}
    */
    const transform = derived(
      [canvas, globalZTbounds, gaps],
      ([$canvas, $globalZTbounds, $gaps]) => {
        return ($canvas.w==null || $globalZTbounds.minX==Infinity)
        ? {scaleX:1, scaleY:1, translateX:0, translateY:0}
        : Util.getTransformParams($globalZTbounds, $canvas, $gaps.sum);
      }
    );

    const translateXday = derived(
      transform,
      $transform => {
        return $transform.translateX % 86400;
      }
    );

    /**
     * vraci Array s matrix pro kazdy let
     * @returns Array [[idFlight, matrix], ...]
    */
    const matrixes = derived(
      [canvas, globalZTbounds, translatedZTbounds, gaps],
      ([$canvas, $globalZTbounds, $translatedZTbounds, $gaps]) => {
        return $translatedZTbounds.map(([id, fBounds]) => [
          id,
          Util.getTransformMatrix($canvas, fBounds, $globalZTbounds, $gaps)
        ]);
      }
    );

    /**
     * @returns array [{id, path, matrix, visible, multiple}, ....]
    */
    const graphPaths = derived(
      [paths, FLIGHTS, ownersByFlights, flightColors, matrixes, multiple],
      ([$paths, $FLIGHTS, $ownersByFlights, $flightColors, $matrixes, $multiple]) => {
//console.log('pg-store.graphPaths',$paths)

        //const fP = Util.idEntries($paths)
        const fP = Object.entries($paths)
        .map(([id, path]) => {
          const color = Util.getHslaColor($flightColors[$ownersByFlights[id]]);
          const mtrx = $matrixes.find(([i]) => i == id);
          if (!mtrx) return null;//pokud jsme nenalezli matrix, nema smysl vracet paths

          const matrix = mtrx[1];
          const visible = $FLIGHTS.includes(id);
          return {id, path, color, matrix, visible};
        })
        .filter(obj => obj !== null);//odfiltrujem null hodnoty
        return fP;
      }
    );

    /**
     * @returns Array [[idFlight, coord] nebo [idFlight, null] nebo [idFlight, -1] nebo [idFlight, +1] ]
     * vraci pole interpolovanych coords pro kazdy aktivni let
     * pokud pro danou pozici neni let aktivni, vraci misto coord null (neni tX,track nebo matrix), ci -1, +1 (pred/po tracklogu)
    */
    const currentCoords = derived(
      [tX, basicTrack, hiresTrack, translatedActiveZTbounds, globalZTbounds],
      ([$tX, $basicTrack, $hiresTrack, $translatedActiveZTbounds, $globalZTbounds]) => {
        return $translatedActiveZTbounds.map(([id, bounds]) => {
          const empty = [id, null];

          if ($tX===null) return empty;
          if ($tX < bounds.minX) return [id, -1]; //jsme PRED zacatkem tracklogu
          if ($tX > bounds.maxX) return [id, +1]; //jsme ZA koncem tracklogu

          const res = $hiresTrack[id] ? $hiresTrack[id] : $basicTrack[id];
          if (!res) return empty;

          const relativeX = $tX - bounds.minX;
          const coords = res.geojson.geometry.coordinates;
          const matrix = Util.getInterpolateMatrix(relativeX, res.index, coords);
          if (matrix===null) return empty;

          const coord = Util.interpolateCoords(coords, res.index, matrix);
          return [id, coord];
        });
      }
    );

    /**
     * vraci aktualni coords aktivniho letu master ownera
     * jedna se o prvni nalezeny aktivni flight ownera prvniho letu; neni-li, pak vraci null
     * @returns [x,y,z,o] nebo null
    */
    const masterCoord = derived(
      [currentCoords, ownersByFlights, masterOwner],
      ([$currentCoords, $ownersByFlights, $masterOwner]) => {
        const coord = $currentCoords.find(([id, coord]) => {
          return $ownersByFlights[id] == $masterOwner && Array.isArray(coord);
        });
        return Array.isArray(coord) ? coord[1] : null;
      }
    );

    /**
     * vraci rtrees
     * pokud hires==false, vraci basicRtree
     * pokud hires==true, dokud neni hotovy hiresRtree pro vsechny lety daneho ownera, vraci basicRtree; pak vraci hiresRtree
     * @returns {idOwner: rtree, ...}
    */
    const rtrees = derived(
      [hires, FLIGHTS, basicTrack, hiresTrack, basicRtree, hiresRtree],
      ([$hires, $FLIGHTS, $basicTrack, $hiresTrack, $basicRtree, $hiresRtree]) => {
        const activeOwners = this.flightOwners($FLIGHTS);
        if ($hires) {
          const affectedFlights = Util.idsDiff($basicTrack, $hiresTrack);
          const affectedOwners = this.flightOwners(affectedFlights);

          //return Util.idEntries($basicRtree).reduce((acc, [idOwner, rtree]) => {
          return Object.entries($basicRtree).reduce((acc, [idOwner, rtree]) => {
            acc[idOwner] = affectedOwners.includes(idOwner) ? rtree : $hiresRtree[idOwner];
            return acc;
          }, {});

        } else {
          return $basicRtree;
        };
      }
    );

    /**
     * vraci master rtree objekt, nebo null
    */
    const masterRtree = derived(
      [rtrees, masterOwner],
      ([$rtrees, $masterOwner]) => {
        //const tree = Util.idEntries($rtrees).find(([idOwner, rtree]) => {
        const tree = Object.entries($rtrees).find(([idOwner, rtree]) => {
          return idOwner == $masterOwner && rtree;
        });
        return Array.isArray(tree) ? tree[1] : null;
      }
    );

    /**
     * countries kterymi proleteli aktivni lety
     * pro ucely airspace
     * @returns Array [['CZ', 'iso-date'], ['DE', 'iso-date'] ...] prvek je [iso2 country, iso8601 timestamp]; serazeno dle statu
    */
    const countries = derived(
      [FLIGHTS, basicFlightData],
      ([$FLIGHTS, $basicFlightData]) => {
//console.log('pgStore:countries', $FLIGHTS, $basicFlightData);
        return Object.values($basicFlightData || {})
          .filter(data => $FLIGHTS.includes(String(data.id)))
          .reduce((acc, data) => {
            data.countries.forEach(iso => {
              const item = acc.find(([i]) =>  i == iso);
              if (item) {
                item[1] = new Date(data.pointStart.time) < new Date(item[1]) ? data.pointStart.time : item[1];
              } else {
                acc.push([iso, data.pointStart.time]);
              };
            });
            return acc;
          }, [])
          .sort();
      }
    );

    return {
      colors,
      multiple,
      masterFlight,
      flightsByPilots,
      ownersByFlights,
      masterOwner,
      utcOffset,
      utcOffsetTS,

      allZTbounds,
      activeZTbounds,
      translatedZTbounds,
      translatedActiveZTbounds,
      translatedDefaultZTbounds,
      accessibleGlobalZTbounds,
      globalZTbounds,
      discreteTbounds,
      gaps,
      transform,
      translateXday,
      matrixes,
      graphPaths,
      currentCoords,
      masterCoord,
      rtrees,
      masterRtree,
      countries
    };
  }

  _setEvents () {
    /*** vytazeni stores pro eventy ***/
    const {
      multiple,
      ownersByFlights,
      flightColors,
      FLIGHTS,
      basicTrack,
      hiresTrack,
      tX,
      canvas,
      ZTbounds,
      globalZTbounds,
      countries,
      routeType
    } = this;

    //rtree
    const manageRtree = (chng = 'F') => {
      const owners = get(ownersByFlights);

      let affectedFlights = [];
      let indexedFlights = [];
      let rebuild = false;
      let basic = true;
      let hires = true;

      //resime jen odebrani letu
      if (chng == 'F') {
        affectedFlights = getRem(FLIGHTS);
        indexedFlights = get(FLIGHTS);
        rebuild = true;
      };

      //pridani basic
      if (chng == 'B') {
        affectedFlights = indexedFlights = Util.idsDiff(get(basicTrack), getPrev(basicTrack));
        hires = false;
      };

      //pridani hires
      if (chng == 'H') {
        affectedFlights = indexedFlights = Util.idsDiff(get(hiresTrack), getPrev(hiresTrack));
        basic = false;
      };

      //ownerIds ktere byly zasazeny; array
      const affectedOwners = this.flightOwners(affectedFlights);

      Util.trueKeys({basic, hires})
      .forEach(key => {
        const keypath = key + 'Rtree';
        const result = affectedOwners.reduce((rtree, idOwner) => {
          rtree[idOwner] = indexedFlights.filter(id => owners[id] == idOwner) //jen lety daneho ownera
          .reduce((tree, id) => {
            if (!tree) tree = Util.createRbush();
            const track = this.get(key+'Track')[id];
            if (!track) return tree;
//console.log('tree.load', track.geojson.geometry.coordinates);
            return tree.load(track.geojson.geometry.coordinates);
          }, rebuild ? null : rtree[idOwner]);
          return rtree;
        }, Object.assign({}, this.get(keypath)));

        this.set({[keypath]: result});
      });
    };

    FLIGHTS.subscribe(() => manageRtree('F'));
    basicTrack.subscribe(() => manageRtree('B'));
    hiresTrack.subscribe(() => manageRtree('H'));


    /***** EVENTY **********/
    /** dalsi events:
     - graph-click a dalsi graph-... (Graph.html)
    */
    FLIGHTS.subscribe(() => {
      const $curr = get(FLIGHTS);
      const $prev = getPrev(FLIGHTS);
      const $ext = getExt(FLIGHTS);
/*
console.log("PG store::FLIGHTS subscribe",$prev, $curr, $ext);
console.log("PG store::flight-add", Util.arrDiff($curr, $prev));
console.log("PG store::flight-rem", Util.arrDiff($prev, $curr));
*/
      Util.arrDiff($curr, $prev).forEach(id => this.fire('flight-add', Object.assign({id}, $ext)));
      Util.arrDiff($prev, $curr).forEach(id => this.fire('flight-rem',{id}));
    });
    /*
    this.subscribeEvent(
      FLIGHTS,
      'flight-add',
      () => true,
      (evt, $curr, $prev, $ext) => {
  console.log("PG store::flight-add", Util.arrDiff($curr, $prev), $prev, $curr)
        Util.arrDiff($curr, $prev).forEach(id => this.fire(evt, Object.assign({id}, $ext)));
      }
    );

    this.subscribeEvent(
      FLIGHTS,
      'flight-rem',
      () => true,
      (evt, $curr, $prev) => {
  console.log("PG store::flight-rem", Util.arrDiff($prev, $curr), $prev, $curr)
        Util.arrDiff($prev, $curr).forEach(id => this.fire(evt, {id}));
      }
    );
    */

    this.subscribeEvent(
      ZTbounds,
      'bounds-add',
      () => true,
      (evt, $curr, $prev) => {
        Util.arrDiff(
          Util.ids($curr),
          Util.ids($prev)
        ).forEach(id => this.fire(evt, {id}));
      }
    );

    this.subscribeEvent(
      multiple,
      'multiple-change',
      ($curr, $prev) => $curr !== $prev
    );

    this.subscribeEvent(
      flightColors,
      'colors-change',
      () => true,
      (evt, $curr, $prev) => {
        const idsCurr = Util.ids($curr);
        const idsPrev = Util.ids($prev);
        Util.arrDiff(idsCurr, idsPrev).forEach(idOwner => this.fire(evt, {idOwner, type: 'add', flightColors: $curr}));
        Util.arrDiff(idsPrev, idsCurr).forEach(idOwner => this.fire(evt, {idOwner, type: 'rem', flightColors: $curr}));
      }
    );

    this.subscribeEvent(
      tX,
      'current-x-change',
      ($curr, $prev) => $curr !== $prev
    );

    this.subscribeEvent(
      canvas,
      'canvas-change',
      ($curr, $prev) => $prev && ($curr.w != $prev.w || $curr.h != $prev.h)
    );

    this.subscribeEvent(
      globalZTbounds,
      'global-bounds-change',
      ($curr, $prev) => $curr.minX !== NaN && JSON.stringify($prev) !== JSON.stringify($curr)
    );

    this.subscribeEvent(
      countries,
      'countries-change'
    );

    this.subscribeEvent(
      routeType,
      'route-type-change',
      ($curr, $prev) => $curr !== $prev
    );
  }

  /***** constructor() end *****/

  addFlight (id, ext) {
    this.FLIGHTS.addFlight(id, ext);
  }

  removeFlight (id) {
    this.FLIGHTS.removeFlight(id);
  }

  hasFlight (id) {
    return get(this.FLIGHTS).includes(String(id));
  }

  setMapNode (mapNode) {
    this.set({mapNode});
  }

  /**
   * pridava data letu do basicFlightData nebo hiresFlightData
   * dela novou kopii objektu
   * @param type = basic|hires
   * @param id = id pod kterym budou data ulozena v objektu
   * @param data = data z API
  */
  setFlightData (type, id, data) {
//console.log('setFlightData', type,id,data)
//console.trace();
    this._setDeep(
      type+'FlightData',
      data,
      s => this._setProp(s, id, data)
    );
  }

  getFlightData (id, type = 'basic') {
//console.log('getFlightData', id, type, JSON.parse(JSON.stringify(this.get(type+'FlightData'))));
    return this.get(type+'FlightData')[id];
  }

  /**
   * @param ids - array [flightId1, flightId2, ...]
   * @returns array [ownerId1, ownerId2, ...] - kazdy ownerId je tam jen jednou
  */
  flightOwners (ids = []) {
    const owners = this.get('ownersByFlights');
    return ids.reduce(
      (acc, id) => acc.includes(owners[id]) ? acc : acc.concat(owners[id]),
      []
    );
  }

  /**
   * alokuje barvu z barevne palety, nebo vraci jiz alokovanou pro dane idFlight
   * alokovany index barvy prida do store.colors, store.flightColors
   * vytvari se pomoci hsla()
   * @param idOwner id letu nebo id pilota, kteremu se alokuje barva; string
   * @returns index barvy
  */
  allocateColor (idOwner) {
//console.log('allocC', idOwner)
    const numCol = 100;

    //musime vytvorit novy objekt, aby se nam to nemichalo s prev
    const flightColors = {...this.get('flightColors')};

    //napred zkusime vratit alokovanou barvu
    let colorI = flightColors[idOwner];
    if (colorI>=0) return colorI;

    //jinak alokujem novou
    const colors = this.get('colors');

    //najdeme prvni cislo ktere chybi v rade
    colorI = (new Array(numCol))
      .findIndex((nic, num) => !colors.includes(num));

    //pridani
    flightColors[idOwner] = colorI;
    this.set({flightColors});

    return colorI;
  }

  /**
   * uvolni barvu zpatky
   * kontroluje, jestli nahodou neni nejaky jiny let se stejnym idOwner - pokud jo, tak barvu nereleasuje
   * @returns void
  */
  releaseColor (idFlight) {
    const flightColors = {...this.get('flightColors')};
    const {FLIGHTS, ownersByFlights} = this.get(['FLIGHTS', 'ownersByFlights']);
//console.log('releaseColor::1', idFlight, FLIGHTS, ownersByFlights);
    const flightsByOwners = FLIGHTS.reduce((acc, idFlg) => {
      const idOwn = ownersByFlights[idFlg];
      if (!idOwn) return acc;
      if (!acc[idOwn]) acc[idOwn] = [];
      acc[idOwn].push(idFlg);
      return acc;
    }, {});

    const idOwner = ownersByFlights[idFlight];
    const restFlights = (flightsByOwners[idOwner] || []).filter(idFlg => idFlight != idFlg);
    if (restFlights.length>0) return;

    const colorI = flightColors[idOwner];
    if (typeof colorI == "undefined") {
//console.log('releaseColor::2a', colorI)
      return;
    };

    delete flightColors[idOwner];
//console.log('releaseColor::2b', flightColors)
    this.set({flightColors});
  }

  /**
   * pridava bounds daneho letu do ZTbounds pod id klicem
  */
  addBounds (id, bounds) {
//console.log('pg-store.addBounds()', id, bounds)
    this._setDeep(
      'ZTbounds',
      bounds,
      s => this._setProp(s, id, bounds)
    );
  }

  getMarkers ({id, type, route_type}) {
    if (type=='turnpoints' && !BASIC_ROUTE_TYPES.includes(route_type))  {
      throw new Error("incorrect route_type of turnpoints, must be" + BASIC_ROUTE_TYPES.toString());
    };
    const key = 'markers_'+type+(type=='turnpoints' ? '_'+route_type : '');
    return this.get(key)[id];
  }

  setMarkers ({id, type, route_type}, markers) {
    if (type=='turnpoints' && !BASIC_ROUTE_TYPES.includes(route_type))  {
      throw new Error("incorrect route_type of turnpoints, must be" + BASIC_ROUTE_TYPES.toString());
    };
    const key = 'markers_'+type+(type=='turnpoints' ? '_'+route_type : '');
    this._setDeep(
      key,
      markers,
      s => this._setProp(s, id, markers)
    );
  }

  gX2tX (gX) {
    //bounding na 0<->canvas width
    gX = Util.bound(gX, 0, this.get('canvas').w);

    const {scaleX, translateX} = this.get('transform');

    let tX = Math.round(this.get('gaps').bounds.reduce((acc, B) => {
      if (B.minX < acc) acc += B.maxX - B.minX;
      return acc;
    }, gX*scaleX + translateX));

    return {gX, tX};
  }

  tX2gX (tX) {
    //bounding na minX<->maxX
    const bounds = this.get('globalZTbounds');
    tX = Util.bound(tX, bounds.minX, bounds.maxX);
//console.log('pgStore:tX2gX', bounds, tX)
    const {scaleX, translateX} = this.get('transform');
    const prevGap = Util.getPrevGap(tX, this.get('gaps').bounds);
    const gX = (tX - translateX - prevGap)/scaleX;
    return {gX, tX};
  }

  gXset (gX) {
    const gXtX = this.gX2tX(gX);
//console.log('gXset', gX, gXtX);
    this.set(gXtX);
  }

  tXset (tX) {
    const gXtX = this.tX2gX(tX);
//console.log('tXset', tX, gXtX);
//console.trace();
    this.set(gXtX);
  }
};

export { PgStore };
