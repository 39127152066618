import {
  SPEEDS,
  ID_ROOT,
  COLORS
} from "./const.js";
import {Util} from "./util.js";
//import {pgStore, viewStore, pmsStore} from "./stores.js";
import {XCUtil} from "./xcutil.js";
import {MBUtil} from "./mbutil.js";
import {
	showFlight,
  PGUtil
} from './pgutil.js';

const Play = {
  OBJECT_URLS : new Map(),
  SOURCE_DATA : new Map(),

  EXACT_SECONDS : 300,
  FACTOR : 0.004,//minimalni krok gradientu pro line-gradient

	POSITIONS : {},

  ACT_FLIGHTS : [],//pro ulozeni stavu aktivnich letu, tj. ty co jsou v danem tX a viditelne; array [id1, id2, ..]

  EXACT_IDX : -1,

  FULL_REPAINT : false, //nastavuje se true pouze v pripade, ze se maj tracky jednorazove prekreslit v dalsim ticku

  HIGHLIGHT : {
    id : null,
    time : null
  },

  pgStore : null,
  viewStore : null,
  pmsStore : null,

  /** volat uplne na zacatku **/
  setStores ({pgStore, viewStore, pmsStore}) {
    this.pgStore = pgStore;
    this.viewStore = viewStore;
    this.pmsStore = pmsStore;

    XCUtil.setStores({pgStore});
  },

  /** ------------- ACTION METHODS -------------------- **/
  playPause () {
    let {playMode, playRun, playTS, playTX, mapUserControl} = this.viewStore.get(['playMode', 'playRun', 'playTS', 'playTX', 'mapUserControl']);

    //pokud se prave zapina play mode, tak se resetuje TS/TX a user control
    if (!playMode) {
      this.pgStore.set({nowTime: Date.now()/1000});//kvuli pripadnemu prepocitani defaultPlayTX, proto musi byt pred!

      playTX = this.pgStore.get('defaultPlayTX');
      playTS = playTX === null ? null : performance.now();

      mapUserControl = false;
    };

    playMode = true;
    playRun = !playRun;

    const playRunMode = {playRun, playMode};

    const mapMove = false;
console.log("set playTS Play.playPause()", {playRunMode, playTS, playTX, mapMove, mapUserControl});
    this.viewStore.set({playRunMode, playTS, playTX, mapMove, mapUserControl});
  },

  /**
   * zavreni celeho Play
  */
  close () {
    this._rewind(false);
  },

  /**
   * meni rychlost prehravani, tj. $speed. Levely rychlosti viz consts.js/SPEEDS
   * @param increase - o kolik se zmeni index rychlosti prehravani - zpravidla +1 (o 1 level nahoru) nebo -1 (o 1 level dolu)
  */
  changeSpeed (increase) {
    let speedI = SPEEDS.findIndex((speed) => speed == this.viewStore.get('playSpeed')) + increase;
    speedI = Util.bound(speedI, 0, SPEEDS.length-1);
    const TX = this.viewStore.get('playRun') ? null : this.viewStore.get('playTX');
    this._reset(TX, SPEEDS[speedI]);
  },

  /**
   * vraci zpatky kontrolu nad mapou automatickemu posunu/zoomoutu
  */
  autoControl () {
    this.viewStore.set({mapUserControl : false});
    //tick na prekresleni
    if (!this.viewStore.get('playRun')) PGUtil.checkBounds(false);
  },

  /** -------------------------- EVENT METHODS ------------------------ **/
  /**
   * akce pri zmene menu na playMode a zpet
   * @param play true (zapnuty playMode), false (vypnuty playMode)
  */
  changeMode (play) {
    const map = this.pgStore.get('map');

    //event listerner pro data event na map
    //odchytava object url a revokuje je pote, co je dany zdroj nacten

    const onData = evt => {
      MBUtil._processSetData(evt.sourceId, evt);
    };
    const onError = evt => {
      //if (evt.dataType == 'source') console.log('%csource err', "background-color:grey", evt.sourceId +'|'+evt.source.data)
    };

    if (play) {//zacatek playMode
/*
const idents0 = ['createElement', 'createElementNS', 'createAttribute', 'createTextNode', 'createDocumentFragment',
'createComment', 'createCDATASection','createAttributeNS', 'importNode', 'adoptNode', 'write', 'writeln'];
idents0.forEach(ident => {
  const original = document[ident];
  document[ident] = function () {
    console.log(ident, arguments);
    return original.apply(document, arguments);
  };
});


const idents1 = ['cloneNode','insertAdjacentElement','insertAdjacentHTML', 'insertAdjacentText','setAttribute','setAttributeNS','toggleAttribute'];
idents1.forEach(ident => {
  const proto = Element.prototype;
  const original = proto[ident];
  proto[ident] = function () {
    console.log(ident, arguments);
    return original.apply(this, arguments);
  };
});
*/
      const PMSplayPosLayer = PGUtil.showPositionsLayer(ID_ROOT.pos, ID_ROOT.possrc, undefined, undefined, this.loadIcons);
      PMSplayPosLayer.then(() => this.pgStore.get('FLIGHTS').forEach(id => this._showPlayTrack(id)));
      this.pmsStore.sPMS('PlayPosLayer', PMSplayPosLayer);

      //map.on('sourcedata', onData);
      //map.on('error', onError);
    } else {
      //map.off('sourcedata', onData);
      //map.off('error', onError);
      this.pgStore.get('FLIGHTS').forEach(id => this._hidePlayTrack(id));
			this._hidePositionsLayer();
    };
  },

  /**
   * spousti/pausuje prehravani trakclogu
   * @param play true (play)| false (pause)
   * @param playMode
   * - true (playMode se menil a nyni je true)
   * - false (playMode se menil a nyni je false nebo se nemenil)
  */
  changeRun (play, playMode = false) {
    if (play) { //play
      this.viewStore.set({playTS: performance.now()});
console.log("set playTS Play.changeRun()", {playTS: performance.now()});
      this._tickF(this._getStepF(), playMode ? () => PGUtil.fitPilots() : null);
    } else { //pause
      window.cancelAnimationFrame(this.viewStore.get('animId'));
      this._reset();
    };
  },

  /**
   * posun na zadane tX
  */
  move (TX) {
    this.autoControl();
    this._reset(TX);
    //pokud je to pausnute, udelame jeden tick, aby se tracklogy prekreslili
    if (!this.viewStore.get('playRun')) {
      this.FULL_REPAINT = true;
      this._tickF(this._getStepF());
    };
  },

  /**
   * nacita vsechny potrebne barevne odlisene ikony pro positions layer
   * musi vracet Promise
  */
  loadIcons () {
    const IconsSet = Util.getColorCodes()
      .map((code, index) => [code, Util.getHslaColor(index)]);
    return PGUtil.loadIconsPromise(IconsSet);
  },

  changePosLayout () {
    const map = this.pgStore.get('map');
    if (!map.getLayer(ID_ROOT.pos)) return;
    map.setLayoutProperty(ID_ROOT.pos, 'text-field', this.viewStore.get('posTextField'));
  },

  changePositionsBaseSize (baseSize) {
    this.pmsStore.getPMS('PlayPosLayer').then(([map]) => {
      map.setLayoutProperty(ID_ROOT.pos, 'text-size', baseSize);
      map.setPaintProperty(ID_ROOT.pos, 'text-halo-width', baseSize/10);
    });
  },

  /** ------------------ PRIVATE METHODS ---------------------- **/
  _afterF : null,
  _stepF : null,

  _tickF (func, afterF = null) {
    this._afterF = afterF;
    this.viewStore.set({animId : window.requestAnimationFrame(func)})
  },


  _getStepF () {
    if (this._stepF) {
      return this._stepF;
    };

    this._stepF = (TS) => {
      if ( this.viewStore.get('playTS') === null) this.viewStore.set({playTS: TS});
      if ( this.viewStore.get('playTX') === null) this.viewStore.set({playTX: this.pgStore.get('globalZTbounds').minX});
//console.log("set playTS Play._getStepF()", {playTS: TS, playTX: this.pgStore.get('globalZTbounds').minX})

      const TX = this._getTX(TS);
//console.log('stepF:tX', "TX:"+TX, "TS:"+TS);
//console.trace();
      this.pgStore.tXset(TX);

      this.pgStore.set({nowTime: Date.now()/1000});

      const defaultPlayTX = this.pgStore.get('defaultPlayTX');
      if (defaultPlayTX !== null) {
        const aBounds = this.pgStore.get('accessibleGlobalZTbounds');

        //osetreni dorazu pred zacatek active bounds
        if (TX < aBounds.minX) {
          this._reset(aBounds.minX);
        };

        //osetreni dorazu za konec active bounds
        if (TX > aBounds.maxX) {
          this._reset(aBounds.maxX, 1);
        };
      };



      //osetreni "dorazu" na konec
      if (TX >  this.pgStore.get('globalZTbounds').maxX) {
        this._rewind();
        return;
      };

      const playRun = this.viewStore.get('playRun');

      //osetreni natazeni pred zacatek
      if (TX < this.pgStore.get('globalZTbounds').minX) {
        this._rewind(true, playRun);
        if (playRun) this._tickF(this._stepF, PGUtil.checkBounds);
        return;
      };

      const {
        map,
        basicTrack,
        hiresTrack,
        exactTrack,
        currentCoords
      } = this.pgStore.get([
        'map',
        'basicTrack',
        'hiresTrack',
        'exactTrack',
        'currentCoords'
      ]);

      //aktivni lety, tj. u kterych startT < tX < endT
      const ACT_FLIGHTS = currentCoords
        .filter(([id, Point]) => Array.isArray(Point))
        .map(([id, Point]) => id);
//console.log("stepF::ACT_FLIGHTS", this.ACT_FLIGHTS, ACT_FLIGHTS);
      //prave zacate a prava skoncene lety; potrebujeme je prekreslit
      const BGN_FLIGHTS = Util.arrDiff(ACT_FLIGHTS, this.ACT_FLIGHTS);
      const END_FLIGHTS = Util.arrDiff(this.ACT_FLIGHTS, ACT_FLIGHTS);

      //ziskavame "slot" pro prekreslovane id flight
      this.EXACT_IDX = this.EXACT_IDX < 0 ||  this.EXACT_IDX > ACT_FLIGHTS.length - 1 ? 0 : this.EXACT_IDX + 1;
      const currentId = ACT_FLIGHTS[this.EXACT_IDX];

      this.ACT_FLIGHTS = ACT_FLIGHTS;

      currentCoords.forEach(([id, Point]) => {
//console.log("stepF::loop", id, Point, basicTrack, hiresTrack, exactTrack);
        if (Point === null) return;

        const srcRes = hiresTrack[id] ? hiresTrack[id] : basicTrack[id];
        const extRes = exactTrack[id];
        if (!srcRes || !extRes) return;

        //if (!srcRes) return;

        const cursorPoint = this._cursorPoint(Point, srcRes);
//console.log("needRepaint", id, this.FULL_REPAINT, BGN_FLIGHTS.includes(id), END_FLIGHTS.includes(id), BGN_FLIGHTS, END_FLIGHTS)
        const needRepaint = this.FULL_REPAINT || BGN_FLIGHTS.includes(id) || END_FLIGHTS.includes(id);
        const mayRepaint = needRepaint || id==currentId;
        const masterRepaint = this._setTrackGradient(id, srcRes, cursorPoint, mayRepaint);

        //if (!extRes) return;

        if (this._checkRepaintExact(extRes, cursorPoint, masterRepaint, needRepaint, mayRepaint)) {
          const tEnd = Math.floor(cursorPoint[3].t);
          const lastEndIndex = extRes.lastEndIndex || 0;
          const coords2D = srcRes.geojson2D.geometry.coordinates;

          const tBegin = tEnd <= this.EXACT_SECONDS ? 0 : tEnd - this.EXACT_SECONDS;
          const bgnExact = masterRepaint ? srcRes.index[tBegin] : lastEndIndex;
          const endExact = srcRes.index[tEnd]+1;
          const coords = Array.isArray(Point) ? coords2D.slice(bgnExact, endExact) : [];
          this._setDataExact(id, extRes, coords, !masterRepaint, cursorPoint);
          this._updateDataExact(id);
//console.log('stepF', id, map.getSource(XCUtil.getTrackSourceId('exact', id)));
          extRes.lastEndIndex = endExact;
        };

				this._setPosition(id, Point);
      });

      this.FULL_REPAINT = false;

      this._updatePositions();

      //jednorazova after funkce
      if (typeof this._afterF == 'function') {
        this._afterF();
        this._afterF = null;
      };

      if (this.viewStore.get('playRun')) this._tickF(this._stepF, PGUtil.checkBounds);
    };
    return this._stepF;
  },

  /**
   * vraci point [x,y,z, {...}] - bud aktualni pozice, nebo prvni bod, nebo posledni bod tracklogu
   * @param Point - aktualni Point - Array| -1 (pred zacatkem tracku) | 1 (po skonceni tracku)
   * @param res - res (src, tj. z normalniho tracku) ktery ma vlastnost gejson, ve ktere jsou 4D coordinates
  */
  _cursorPoint (Point, res) {
    const coords4D = res.geojson.geometry.coordinates;
    return Array.isArray(Point) ? Point : (Point==-1 ? coords4D[0] : coords4D[coords4D.length-1]);
  },

  _setTrackGradient (id, res, cursorPoint, mayRepaint = true) {
//console.log('_setTrackGradient::1', id, cursorPoint, mayRepaint);
    const map = this.pgStore.get('map');
    const playSpeed = this.viewStore.get('playSpeed');

    const idLine = XCUtil.getTrackLayerId('basic', 'line', id);

    //pokud layer jeste neexistuje, tak nedelame nic
    if (!map.getLayer(idLine)) return true;

    const coords = res.geojson.geometry.coordinates;
    const prevPoint = res.prevPoint || coords[0];
    const lastPoint = coords[coords.length-1];

    const isLast = cursorPoint == lastPoint;
    const tDelta = cursorPoint[3].t - prevPoint[3].t;

    const ratioP = cursorPoint[3].l/lastPoint[3].l;
    const repair = (1-ratioP)*this.FACTOR;

    const ratio3 = ratioP - repair;

    const time1 = Math.max(Math.floor(cursorPoint[3].t) - this.EXACT_SECONDS, 0);
    const point1 = coords[res.index[time1]];
    //osetreni aby ratio1 a ratio3 nebylo stejne
    const length1 = point1[3].l==cursorPoint[3].l ? point1[3].l-1 : point1[3].l;
    const ratio1 = length1/lastPoint[3].l - repair;

    //const ratio0 = ratio1 - this.FACTOR;

    //repaint si vynutime kdyz je slot (mayRepaint == true) a zaroven:
    //1) dokud je ratio1 mensi nez 0
    //2) po kazdych 3 sekundach
    //3) pri dosazeni posledniho bodu
    //4)  pri posunu zpatky v case - bez ohledu na mayRepaint
    //5) pri FULL_REPAINT - bez ohledu na mayRepaint
    const repaint = this.FULL_REPAINT || tDelta < 0 || (
      mayRepaint && (
        (ratio1 < 0 && ratio3 > 0) ||
        tDelta > 3 * playSpeed ||
        (isLast && prevPoint != cursorPoint)
      )
    );
//console.log('_setTrackGradient::2',"id:"+id, "repaint:", repaint, "ratio1:"+ratio1, "ratio3:"+ratio3, "isLast:", isLast, prevPoint, cursorPoint);
    if (!repaint) return repaint;

    const cTrackHalf = XCUtil.getColor(id,0.5);
    const cTrackFull = XCUtil.getColor(id,1);
    const cOpaque = Util.getHslaColor(0,0);

    res.prevPoint = cursorPoint;

    /** -----sestavovani gradientu-----
      * prvni sektor od zacatku do ratio1 je polopruhledny (jiz uleteny)
      * druhy sektor mezi ratio1 a ratio3 se meni z polopruhlednosti do pruhledna (je to 300 sec usek, posunuty pomoci faktoru repair dozadu)
      * treti sektor mezi ratio3 do konce je pruhledny (jeste neuleteny)
      * na zacatku prehravani je cely pruhledny (dokud ratio3 < 0)
      * pak se zacatek meni postupne do polopruhlednosti (dokud ratio1 < 0)
      * na konci se stane cely polopruhledny
    **/

    //startovni barva
    const cStart = ratio3 <= 0
      ? cOpaque //na zacatku ma byt track od zacatku pruhledny
      : (ratio1 <= 0
        ? XCUtil.getColor(id, this._opacity(ratio3, ratio1)) //ratio3 uz je nad 0, ale ratio1 pod 0 -> musime najit nejakou barvu mezi opaque a half
        : cTrackHalf //od teto chvile jiz zaciname s half
      );

    const grad = [0, cStart];//uvodni par
    if (ratio1 > 0) grad.push(ratio1, cTrackHalf);//polopruhledny par jen pokud je ratio1 > 0
    if (ratio3 > 0 && ratio3 < 1) {
      grad.push(ratio3, cOpaque, 1, cOpaque);
    } else if (ratio3 == 1) {
      grad.push(1, cTrackHalf);
    };
//console.log('repaint gradient', idLine, grad);
//if (this.url_exact) return repaint;
    MBUtil.changeLineGradient(map, idLine, grad);

    return repaint;
  },

  _opacity (ratio3, ratio1) {
    if (ratio1>0) return 0.5;
    return (ratio3 / (ratio3 - ratio1)) * 0.5;
  },

  /**
   * @param res (exactTrack)
   * @return Array [x,y] | null
  */
  _lastPointExact (res) {
    const coords = res.geojson2D.geometry.coordinates;
    return (coords.length == 0) ? null : coords[coords.length-1];
  },


  _checkRepaintExact (res, Point, masterRepaint = true, needRepaint = true, mayRepaint = true) {
    if (masterRepaint || needRepaint) return true;
    if (!mayRepaint) return false;
    //tolerance zmeny pozice v px pro repaint
    const tolerance = 5;

    const PE = this._lastPointExact(res);
    if (PE != null && Array.isArray(Point)) {
      const map = this.pgStore.get('map');
      const p0 = map.project(MBUtil.pointToLngLatLike(PE));
      const p1 = map.project(MBUtil.pointToLngLatLike(Point));
      if (
        Math.abs(p0.x - p1.x) < tolerance &&
        Math.abs(p0.y - p0.y) < tolerance
      ) {
        return false;
      };
    };

    return true;
  },

	_setPosition (id, Point) {
		const positions = this.POSITIONS;

		if (!Array.isArray(Point)) {
			this._setPos(id, null);
			return;
		};

    const user = this.pgStore.get('basicFlightData')[id].pilot.username;

    //colors
    const cHalo = XCUtil.getColor(id, 0.8);//color pro halo - barva tracklogu
    const cIcon = XCUtil.getColorCode(id);  //hex color code - pro id ikonky
    const cText = XCUtil.getInvertedColor(id, 0.9);//inverted color (BW) pro text


	 	const pos = positions[id] || Util.getFreshGeoJSON('Point', {
			id, properties: {
				user,
				cHalo,
        cText,
				cIcon
			}
		});

		const a = Util.round(Point[2]);
    const agl = Util.round(a - Point[3].g);
    const s = Util.round(Point[3].s);
    const v = Util.round(Point[3].v, 1);

		const coordinates = [Point[0], Point[1]];

		Object.assign(pos.properties, {a, agl, s, v});
    if (Point[3].b)	Object.assign(
      pos.properties,
      {b: Util.round(Point[3].b)}
    );

		Object.assign(pos.geometry, {coordinates});
//console.log(pos, Point)
		this._setPos(id, pos);
	},

  _setPos (id, pos) {
		this.POSITIONS[id] = pos;
	},

	_updatePositions () {
		const map = this.pgStore.get('map');

    if (!map.getLayer(ID_ROOT.pos)) return;

		const positions = this.POSITIONS;

		const GColl = Object.entries(positions)
			.reduce((acc, [id, pos]) => {
        if (!pos) return acc;
				acc.features.push(pos);
				return acc;
			}, Util.getFreshGeoJSONCollection());

    MBUtil.setSourceData(map, ID_ROOT.possrc,
      Util.createJsonUrl(JSON.stringify(GColl), true),
      true
    );
	},

	_setDataExact (id, res, coords = [], push = false, point = null) {
//console.log('_setDataExact', 'length:'+coords.length, 'push:', push)
    res = this._updatePieces(res, coords, push, point);
  },

  _updateDataExact (id) {
    const sourceId = XCUtil.getTrackSourceId('exact', id);

    const {map, exactTrack} = this.pgStore.get(['map', 'exactTrack']);
    const str = this._pastePieces(exactTrack[id]);

//console.log('_updateDataExact', sourceId, JSON.parse(str))
    Util.createJsonUrl(str)
      .then(url => {
        MBUtil.setSourceData(map, sourceId, url, false);
      });
  },

  _updatePieces (res, coords = [], push = false, point = null) {
    res = this._preparePieces(res);
    const cl = coords.length;
    const curLength = res.curLength ? res.curLength : 0;

    res.pieces[1] = this._cleanAndJoin([
      (push ? res.pieces[1] : ''),
      Util.stringify(coords).slice(1,-1)
    ]);
    res.pieces[2] = point ? Util.stringify(point) : '';
    res.curLength = push ? curLength + cl : cl;

    return res;
  },

  _preparePieces (res) {
    if (res.pieces && typeof res.pieces[0] == 'string') return res;
    const str = JSON.stringify(res.geojson2D);
    const c0 = str.indexOf('\"coordinates\":[')+15;
    res.pieces = [
      str.slice(0, c0),//vse pred coordinates vcetne [
      str.slice(c0, -3),//samotne coordinates
      '',//vyhrazenou pro samostatny last point (nemusi byt pouzito)
      str.slice(-3)//konec. tj. ]]}
    ];
    return res;
  },

  _pastePieces (res) {
    res = this._preparePieces(res);
    return res.pieces[0] + this._cleanAndJoin([res.pieces[1], res.pieces[2]]) + res.pieces[3];
  },

  _cleanAndJoin (array = []) {
    return array.filter(str => str!='').join(',');
  },

  /**
   * zkontroluje, jestli dane id flight ma byt zvyrazneno
   * kvuli prekryvani popisku pilota, aby na chvilku problikly i ty skryte popisky
  */
  _checkHighlight (id) {
    const time = performance.now();

    if (!this.HIGHLIGHT.id) {
      this.HIGHLIGHT = {id, time};
      return true;
    };

    if (time - this.HIGHLIGHT.time < 500) {
      return this.HIGHLIGHT.id == id;
    };

    const f = this.pgStore.get('FLIGHTS');
    const i = f.indexOf(id);
    const next = i == f.length ? 0 : i + 1;

    id = f[i];
    this.HIGHLIGHT = {id, time};

    return this.HIGHLIGHT.id == id;
  },

  /**
   * resetuje play na urcite misto tracklogu, tj meni TS a TX, pripadne speed
   * @param TX - tX v sekundach, neni-li predano, spocita se aktualni dle rozdilu casu
   * @param playSpeed - s jakou rychlosti prehrani se resetuje; neni-li predana, zustava stejna)
  */
  _reset (TX = null, speed = null) {
    const playTS = performance.now();
    const playTX = TX || this._getTX(playTS);
    const playSpeed = speed || this.viewStore.get('playSpeed');
console.log("set playTS Play._reset()", {TX, speed, playTS, playTX, playSpeed});
    this.viewStore.set({playTS, playTX, playSpeed});
//console.log('test viewStore:', this.viewStore.get(['playTS', 'playTX', 'playSpeed']));
  },

  _rewind (playMode = true, playRun = false) {
    //oddelene, protoze je potreba pockat az se udela ´pripadny _reset() po nastaveni playRun
    let playRunMode = {playRun, playMode : this.viewStore.get('playMode')};
    this.viewStore.set({playRunMode});//pause
//console.log("Play._rewind()", null)
    playRunMode = {playRun, playMode};
console.log("set playTS Play._rewind()", {playRunMode, playTS: null, playTX: null});
    this.viewStore.set({playRunMode, playTS: null, playTX: null});//vynullovani
  },

  /**
   * @param TS - timestamp z requestAnimationFrame() nebo performance.now()
   * vraci tX (cas na X ose v sekundach) pro store.tXset()
  */
  _getTX (TS) {
    const deltaTX = (TS - this.viewStore.get('playTS')) * this.viewStore.get('playSpeed') / 1000;
//console.log("_getTS()", "playTS:", this.viewStore.get('playTS'), "playSpeed:", this.viewStore.get('playSpeed'), "playTX:", this.viewStore.get('playTX'));
    return this.viewStore.get('playTX') + (deltaTX<0 ? 0 : deltaTX);
  },

  _showPlayTrack (id) {
    PGUtil.hideRoutes(id);
    PGUtil.hideMarkers(id);
		PGUtil.hidePilot(id);

    PGUtil.prepareExactTrack(id);
    PGUtil.showExactTrack(id);
//console.log('_showPlayTrack::1', id);
    //skryti obou lines pres gradient
    this.pmsStore.getPMS(['TrackDrawn.basic', id]).then(res => {
      const map = this.pgStore.get('map');
//console.log('_showPlayTrack', map.getStyle().layers);

      const cOpaque = Util.getHslaColor(0,0);
      const gOpaque = [0, cOpaque, 1, cOpaque];

      const idLine = XCUtil.getTrackLayerId('basic', 'line', id);
      const idOutline = XCUtil.getTrackLayerId('basic', 'outline', id);
      const idPositions = ID_ROOT.pos;

//console.log('_showPlayTrack::changeLineGradient', idLine);
      MBUtil.changeLineGradient(map, idOutline, gOpaque);
      MBUtil.changeLineGradient(map, idLine, gOpaque);
    })
    .then(() => {
      //triggeruje vykresleni ended tracklogu
      this._checkEnded();
    });
  },

  _checkEnded () {
    const currentCoords = this.pgStore.get('currentCoords');

    //lety jiz ukoncene
    const END_FLIGHTS = currentCoords
      .filter(([id, coord]) => coord === 1)
      .map(([id]) => id);

    if (END_FLIGHTS.length == 0) return;

    //spoji ACT_FLIGHTS s END_FLIGHTS, aby ty skoncene prosly iteraci a vykreslenim
    this.ACT_FLIGHTS = Util.arrUniq([...this.ACT_FLIGHTS, ...END_FLIGHTS]);
    this.FULL_REPAINT = true;
  },

  _hidePlayTrack (id) {
    //skryti pozice
    Play._setPos(id, null);
    //odstraneni exact tracku
    Play._removeExactTrack(id);
    Play._removeExactSource(id);

    //obe lines dostavaji zpet default barvu
    this.pmsStore.getPMS(['TrackDrawn.basic', id]).then(res => {
      const map = this.pgStore.get('map');
      const color = XCUtil.getColor(id);
      const outColor = COLORS.FLIGHT_OUTLINE;

      //resetujem zaroven line-color i line-gradient
      MBUtil.changeLineColor(map,
        XCUtil.getTrackLayerId('basic', 'line', id),
        color,
        true
      );
      MBUtil.changeLineColor(map,
        XCUtil.getTrackLayerId('basic', 'outline', id),
        outColor,
        true
      );
    });
    //flight znovu zobrazujem, jen pokud nejsme ve flight mode
    //jinak se jedna o odebrani letu v prubehu play
    if (!this.viewStore.get('playMode')) showFlight(id, this.viewStore.get('showFlightSet'));
  },

  _removeExactTrack (id) {
    const map = this.pgStore.get('map');
    const idLine = XCUtil.getTrackLayerId('exact', 'line', id);
    const idOutline = XCUtil.getTrackLayerId('exact', 'outline', id);

    if (map.getLayer(idLine)) map.removeLayer(idLine);
    if (map.getLayer(idOutline)) map.removeLayer(idOutline);

    this.pmsStore.sPMS(['TrackDrawn.exact', id], null);
  },

  /**
    * odebira source pro exact track
    * musi se volat az pote, co je odebrany layer (_removeExactTrack())
  **/
  _removeExactSource (id) {
    const map = this.pgStore.get('map');
    const idSource = XCUtil.getTrackSourceId('exact', id);
    if (map.getSource(idSource)) map.removeSource(idSource);
  },

	_hidePositionsLayer () {
		MBUtil.changeVisibility(
      this.pgStore.get('map'), [ID_ROOT.pos], []
    );
	}
};

export {Play};
