import {
  readable as r,
  writable as w,
  derived
} from 'svelte/store';

import {
  watchable,
  watchableDerived,
  getPrev
} from './store-ext.js';

import {
  playMode
} from './store-shared.js';

import {Util} from "./util.js";
import {XCUtil} from "./xcutil.js";

import {
  isoToTime,
  addCntMap,
  siteProps,
  sortPropMap,
  fromState,
  fromHash
} from './helpers.js';

import {ExtDate} from "./extdate.js";

import {PgStore} from "./pg-store.js";
import {ViewStore} from "./view-store.js";
import {PmsStore} from "./pms-store.js";

class LivePgStore extends PgStore {

  _getBasic () {
    const yBounds = {minY: 200, maxY: 200};
    const ownersLivelink = fromHash('u');

    return Object.assign(super._getBasic(), {
      //NEW

      //vsechny 3 to jsou Map podle id (uuid) daneho live letu
      liveInfoActual : watchable(new Map()), //aktualni data z liveFlightInfos; obsahuje vzdy posledni liveFlightInfo; pridava (klic je uuid letu), nikdy nemaze - tj u ended a old letu tam vzdy zustava posledni liveInfo
			liveInfoStatic : w(new Map()),

      liveFlightChunk : w(new Map()), //uklada se tu vzdy POSLEDNI chunk pro prislusny let

      //DERIVED
			//liveInfo : w({}), //synteza static a actual

      uuidsActive : w([]), //array uuids letu, ktere jsou aktivni, tj. chodi jejich data do liveInfoActual; zpravidla to znamena, ze bud bezi, nebo jsou max. 3 hod stare
      uuidsFollowed: w([]), //array uuids letu ktere se followuji

      //ownersForceFollow + ownersLivelinkFollow: array owner ids (zpravidla pilotu), ktere se maji followovat "zvnejsku"
      //bez ohledu na to jestli zrovna dany pilot neco leti, tj. napriklad z url; pak se checkuji a pripadne autofollowuji - pri tom se odebiraji z tohoto pole

      //owners state follow se berou z history.state, tj. pokud byly nejaci followed pred reloaded, objevi se zde
      ownersForceFollow: w(Util.arrUniq([
        ...fromState('ownersFollow', []),
        ...ownersLivelink
      ])),

      //owners livelink follow se berou z URL hashe (viz livelinkRead()) - na rozdil od force follow je potreba na ne vzdy zazoomovat
      ownersLivelinkFollow: w(ownersLivelink),

      filteredStatus: w(fromState('filteredStatus', 'all')),
      filteredNations: w(fromState('filteredNations', [])),
      filteredSites: w(fromState('filteredSites', [])),

      liveDelay: w(120), //zpozdeni aktualni play pozice v sekundach

      showUniq: w(true), //zobrazujeme vsechno, nebo jen unikatni posledni tracklog pilota?
      pilotOrderBy: w('XCDISTANCE'),//razeni vypisu pilotu: XCDISTANCE| ?
      //DERIVED
      //contest: w('alpha9999'),//zobrazena league
      positionsPopup: w(null), //mapbox GL JS popup pro positions info

      //REWRITE
      nowTime : w(Date.now()/1000),
      graphTimeMode: w('LOCALTIME'),

      //prepis - kazdych 60 sekund se prepocita
      defaultZTbounds: r(
        {...Util.getCurrentTimespan(), ...yBounds},
        (set) => {
          const i = setInterval(() => {
        		set({...Util.getCurrentTimespan(), ...yBounds});
        	}, 60 * 1000);
        	return () => clearInterval(i);
        }
      ),

    });
  }

  _getDerived () {

    //basic, ktere potrebujeme
    const {
      apiSource,

      showUniq,
      colorOwner,
      liveInfoActual,
			liveInfoStatic,
      hiresTrack,
      liveDelay,
      uuidsActive,
      uuidsFollowed,
      filteredSites,
      filteredNations,
      filteredStatus,

      //contest,
      pilotOrderBy,
      graphTimeMode,
      nowTime,

      FLIGHTS,
      flightColors,
      tX
    } = this;

    //derived ze superu
    const superDerived = super._getDerived();

    const {
      translatedActiveZTbounds,
      ownersByFlights
    } = superDerived;

    const contest = derived(
      apiSource,
      $apiSource => $apiSource.league + $apiSource.volume
    );

    /**
		 * REWRITE
		 * nastavuje vychozi playTX na aktualni cas - 2 min (resp liveDelay)
		*/
		const defaultPlayTX = derived(
			[nowTime, graphTimeMode, liveDelay],
			([$nowTime, $graphTimeMode, $liveDelay]) => {
				return Util.translateBoundsByTimeMode(
					{minX: $nowTime, maxX: $nowTime},
					$graphTimeMode
				).minX - $liveDelay;
			}
		);

    /**
     * REWRITE
     * vytvari bounds ktere urcuji aktivni casovy rozsah, tj. v ramci ktere se jde posunout po grafu
     * pridava ohled na defaultPlayTX
    */
    const accessibleGlobalZTbounds = derived(
      [translatedActiveZTbounds, defaultPlayTX],
      ([$translatedActiveZTbounds, $defaultPlayTX]) => {
        const bounds = $translatedActiveZTbounds.map(([id, bounds]) => bounds);

        if ($defaultPlayTX !== null) bounds.push({minX: $defaultPlayTX, maxX: $defaultPlayTX, minY: 200, maxY: 200});
        let {minX, maxX, minY, maxY} = Util.reduceBounds(bounds);
        if ($defaultPlayTX !== null) maxX = $defaultPlayTX; //maxX nikdy nesmi byt vetsi nez defaultPlayTX
        return {minX, maxX, minY, maxY};
      }
    );

    /**
     * sestavuje synteticke liveInfo
     * a pocitame lastFix a prevFix, delay a status
    */
    const liveInfo = derived(
      [liveInfoActual, liveInfoStatic, hiresTrack, flightColors, ownersByFlights],
      ([$liveInfoActual, $liveInfoStatic, $hiresTrack, $flightColors, $ownersByFlights]) => {

        const lastChunkTime = (t, staticInfo) => isoToTime(staticInfo.takeoff.point[3].t) + (t * 1000);
        const lastChunkIso = (t, staticInfo) => new ExtDate(lastChunkTime(t, staticInfo)).getIsoTime();
        const isChunkLatest = (t, staticInfo, lastFixIso) => lastChunkTime(t, staticInfo) >= isoToTime(lastFixIso);

        const prevLiveInfoActual = getPrev(liveInfoActual);
        const liveInfo = {};

        $liveInfoStatic.forEach((info, uuid) => {
          const actualInfo = $liveInfoActual.get(uuid);
          const prevInfo = prevLiveInfoActual.get(uuid);
          const res = $hiresTrack[uuid];

          let lastFix = Util.fresh(actualInfo.lastFix);
          let prevFix = Util.fresh(prevInfo ? prevInfo.lastFix : lastFix);

          //pokud je nacteny track, zkusime vzit posledni pozici z coordinates - u followed byva aktualnejsi
          if (res) {
            const coords = res.geojson.geometry.coordinates;
            const lastFixChunk = coords[coords.length-1];

            //pokud byl let follow a nyni je zase unfollow, je track nacteny, ale neaktualizuje se
            //musime tedy rozhodnout, jestli je opravdu aktualnejsi
            if (isChunkLatest(lastFixChunk[3].t, info, lastFix[3].t)) {
              lastFix = Util.fresh(lastFixChunk);
              const prevTm = Math.max(lastFix[3].t-60, 0);
              const prevFixCoords = coords[res.index[prevTm]];
              //osetreni kvuli tomu, ze dana coords tam byt nemusi (stalo se)
              if (prevFixCoords) {
                prevFix = Util.fresh(prevFixCoords);
              };

              lastFix[3].t = lastChunkIso(lastFix[3].t, info);
              prevFix[3].t = lastChunkIso(prevFix[3].t, info);
            };
          };

          //pocitani delay od posledniho fixu - v milisekundach
          const lastFixMs = isoToTime(lastFix[3].t);
          const takeoffMs = isoToTime(info.takeoff.point[3].t);

          const delay = Date.now() - lastFixMs;
          const duration = lastFixMs - takeoffMs;

          //status - live|lost|landed
          //po 2 min od lastFix se status stava lost
          const status = actualInfo.landed ? 'landed' : delay < 120 * 1000 ? 'live' : 'lost';

          //pocitani barev
          const idOwner = $ownersByFlights[uuid];
          const colorIndex = idOwner && $flightColors[idOwner] >= 0 ? $flightColors[idOwner] : null;

          liveInfo[uuid] = {
            static: info,
            actual: $liveInfoActual.get(uuid),
            prevFix,
            lastFix,
            duration,
            delay,
            status,
            colorIndex
          };
        });
        return liveInfo;
      },
      {}
    );

    /**
     * vyfiltruje uuids pouze poslednich pouzitelnych letu pro kazdeho pilota
     * @returns array uuids
    */
    const uuidsLast = derived(
      [liveInfo, uuidsActive, showUniq],
      ([$liveInfo, $uuidsActive, $showUniq]) => {

        const liveInfoActiveArr = Object.entries($liveInfo)
          .filter(([uuid, info]) => $uuidsActive.includes(uuid));

        //pokud nemaji byt zobrazeny jen posledni lety pilota, vraci rovnou
        if (!$showUniq) {
          return liveInfoActiveArr.map(([uuid, info]) => uuid);
        };

        //prevedeme na byPilots Map; klic je id pilota, hodnota je Map, kde klic je uuid letu a hodnota je Info
        const byPilots = liveInfoActiveArr
          .reduce((acc, [uuid, info]) => {
            const pilotId = info.static.user.uid + '';
            if (!acc.has(pilotId)) acc.set(pilotId, new Map());
            acc.get(pilotId).set(uuid, info);
            return acc;
          }, new Map());

        //vytvarime pole uuids vsech poslednich letu od kazdeho pilota
        return [...byPilots].map(([pilotId, pilotFlights]) => {
          return [...pilotFlights].sort((
            [uuid0, info0],
            [uuid1, info1]
          ) => {
            //napred se radi non-overriden lety
            if (info0.actual.overriden !== info1.actual.overriden) return info0.actual.overriden ? 1 : -1;
            //mezi lety se stejnym overriden statusem pak napred radi pozdejsi lety
            return isoToTime(info1.lastFix[3].t) - isoToTime(info0.lastFix[3].t);
          }).shift()[0];//vezme uuid z 1. polozky pole (tj. vzdy nejpozdejsi let, prednostne non-overriden; pokud jsou vsechny overriden, bere nejpozdejsi overriden)
        });
      }
    );

    /**
     * vyfiltruje uuids pro zobrazeni (napr. v seznamu letu) -
     * jsou to 1) last lety, 2) followed lety
     * @returns array [[uuid, followed], [...]]
    */
    const uuidsDisplayable = watchableDerived(
      [uuidsActive, uuidsLast, uuidsFollowed],
      ([$uuidsActive, $uuidsLast, $uuidsFollowed]) => {
        return $uuidsActive
          .filter(uuid => $uuidsLast.includes(uuid) || $uuidsFollowed.includes(uuid))
          .map(uuid => [uuid, $uuidsFollowed.includes(uuid)]);
      }
    );

    /**
     * akumulace filtracnich options
    */
    const filterProps = derived(
      [uuidsDisplayable, liveInfo],
      ([$uuidsDisplayable, $liveInfo]) => {
        //vytahneme si z uuidsDisplayable pouze array uuids
        const uuidsAble = $uuidsDisplayable.map(([uuid]) => uuid);

        //mapa na ukladani kombinace pilota a nationality, resp. site
        //zabranuje aby se jeden pilot s vice lety zapocital do poctu souctu vicekrat pro danou nationality/takeoff
        const pilots = new Map();

        const props = Object.entries($liveInfo)
        //filtrujeme jen na ty co jsou displayable
        .filter(([uuid, info]) => uuidsAble.includes(uuid))
        //reduce na objekt s klici nations a sites
        .reduce((acc, [uuid, info]) => {
          const pilotId = info.static.user.uid;
          const nat = info.static.user.nationality;
          const {siteKey, siteLabel} = siteProps(info);
          const status = info.status === 'landed' ? 'landed' : 'live';

          addCntMap(acc.nations, nat.iso, nat.name, pilots, pilotId);
          addCntMap(acc.sites, siteKey, siteLabel, pilots, pilotId);
          addCntMap(acc.status, status, null, pilots, pilotId);

          return acc;
        }, {
          nations : new Map(),
          sites : new Map(),
          status : new Map([
            ['live', {num: 0, label: 'Live'}],
            ['landed', {num: 0, label: 'Landed'}]
          ])
        });

        //prerazeni
        props.sites = sortPropMap(props.sites);
        props.nations = sortPropMap(props.nations);

        return props;
      }
    );

    const uuidsDisplay = watchableDerived(
      [liveInfo, uuidsDisplayable, filteredSites, filteredNations, filteredStatus],
      ([$liveInfo, $uuidsDisplayable, $filteredSites, $filteredNations, $filteredStatus]) => {
        return $uuidsDisplayable.filter(([uuid, followed]) => {
          if (followed) return true; //followed uuid se zobrazuje vzdy
          const info = $liveInfo[uuid];
          const {siteKey} = siteProps(info);
          const status = info.status === 'landed' ? 'landed' : 'live';

          if ($filteredNations.length > 0 && !$filteredNations.includes(info.static.user.nationality.iso)) return false;
          if ($filteredSites.length > 0 && !$filteredSites.includes(siteKey)) return false;
          if ($filteredStatus !== 'all' && $filteredStatus !== status) return false;

          return true;
        });
      }
    );

    /**
     * vraci liveInfo filtrovane na uuidsDisplay a serazene
     * @returns Map, klic je uuid, hodnota je objekt {static, actual, lastFix, prevFix}
    */
    const liveInfoDisplay = derived(
      [liveInfo, uuidsDisplay, pilotOrderBy, contest],
      ([$liveInfo, $uuidsDisplay, $pilotOrderBy, $contest]) => new Map(
        $uuidsDisplay
          .map(([uuid, followed]) => [uuid, $liveInfo[uuid], followed])
          .sort((
            [uuid0, info0, followed0],
            [uuid1, info1, followed1]
          ) => {
            //pokud je id pilota stejne, radime podle casu startu, pozdejsi napred
            if (XCUtil.getLiveUserId(info0.static) === XCUtil.getLiveUserId(info1.static)) {
              return isoToTime(info1.lastFix[3].t) - isoToTime(info0.lastFix[3].t);
            };

            //ruzne id pilota:
            //misto followed radime podle prideleneho color indexu - kvuli animaci, aby zacala az po prideleni barvy
            const has0c = info0.colorIndex !== null;
            const has1c = info1.colorIndex !== null;
            if (has0c !== has1c) {
              return has0c ? -1 : 1;
            };

            //ruzne id pilota - pokud je colorIndex pridelen, radime podle nej
            if (has0c && has1c) {
              return info0.colorIndex - info1.colorIndex;
            };


            //ruzne id pilota, bez prideleneho color indexu: maji prednost not-landed
            if (info0.actual.landed !== info1.actual.landed) {
              return info0.actual.landed ? 1 : -1;
            };

            //ruzne id pilota, stejny followed status, stejny landed status: bereme primarni radici klic
            const xcDistSort = XCUtil.getLiveXcDist(info1.actual, $contest) - XCUtil.getLiveXcDist(info0.actual, $contest);

            switch ($pilotOrderBy) {
              case 'XCDISTANCE':
                return xcDistSort;
              default:
                xcDistSort;
            };
          })
      )
    );

    const liveInfoDisplayByPilot = watchableDerived(
      liveInfoDisplay,
      $liveInfoDisplay => [...$liveInfoDisplay].reduce((acc, [uuid, info]) => {
        const userId = XCUtil.getLiveUserId(info.static);
        if (!acc.has(userId)) acc.set(userId, new Map());
        acc.get(userId).set(uuid, info);
        return acc;
      }, new Map())
    );


    /**
     * mapuje uuid last letu pro kazdeho ownera (zpravidla pilot)
     * @returns Map, klic je uid ownera, hodnota je uuid letu
    */
    const uuidsLastByOwner = watchableDerived(
      [uuidsLast, liveInfoStatic, colorOwner],
      ([$uuidsLast, $liveInfoStatic, $colorOwner]) => $uuidsLast.reduce((acc, uuid) => {
        const info = $liveInfoStatic.get(uuid);
        if (!info) return acc;
        const owner = $colorOwner=='PILOT' ? info.user.uid : uuid;
        acc.set(owner, uuid);
        return acc;
      }, new Map())
    );

    /**
     * autofollow owners (zpravidla pilots) - owners u kterych mame zaskrtnuty let
     * @returns array owners
    */
    const ownersFollow = derived(
      [ownersByFlights, uuidsFollowed],
      ([$ownersByFlights, $uuidsFollowed]) => {
        const owners = Object.entries($ownersByFlights)
          .filter(([uuid, owner]) => $uuidsFollowed.includes(uuid))
          .map(([uuid, owner]) => owner);

        return Util.arrUniq(owners);
      }
    );

    const numActive = derived(
      uuidsLastByOwner,
      $uuidsLastByOwner => $uuidsLastByOwner.size
    );

    const numDisplay = derived(
      liveInfoDisplayByPilot,
      $liveInfoDisplayByPilot => $liveInfoDisplayByPilot.size
    );

    const numLive = derived(
      liveInfoDisplay,
      $liveInfoDisplay => [...$liveInfoDisplay].reduce((num, [uuid, info]) => {
        return ['live', 'lost'].includes(info.status) ? ++num : num;
      }, 0)
    );

    const numFollow = derived(
      ownersFollow,
      $ownersFollow => $ownersFollow.length
    );

    /** REWRITE **/
    //graf se zobrazi, pokud existuje alespon 1 aktivni followed
    const showGraph = derived(
      uuidsDisplay,
      $uuidsDisplay => $uuidsDisplay.some(([uuid, followed]) => followed)
    );

    //updatuje se pouze pokud je zapnuty playMode
    //jinak zustava v klidu
    const tXplay = derived(
      [tX, playMode],
      ([$tX, $playMode], set) => {
        if (!$playMode) return;
        set($tX);
      },
      null
    );

    //spocita positions GeoJSON
    //$ownersByFlights <= !!!basicFlightData, colorOwner
    //$flightColors <= !!!allocateColor(), releaseColor()
    //$liveInfoDisplay <= $liveInfo, $uuidsDisplay
    //$liveInfo <= $liveInfoActual, $liveInfoStatic, !!!$hiresTrack
    //$uuidsDisplay <= $uuidsActive, $uuidsLast, $uuidsFollowed
    //$uuidsLast  <= $liveInfo, $uuidsActive, $showUniq
    const positions = derived(
      [tXplay, liveInfoDisplay, uuidsFollowed, flightColors, ownersByFlights, playMode],
      ([$tXplay, $liveInfoDisplay, $uuidsFollowed, $flightColors, $ownersByFlights, $playMode]) => {
//console.log('positions', $tXplay, $liveInfoDisplay, $ownersByFlights, $playMode)
//console.log('_createPositions::begin', $ownersByFlights);
        //"aktualni" cas -> v playMode podle tX
        const cursorTstamp = $tXplay * 1000;

        const GColl = [...$liveInfoDisplay]
        .reduce((Coll, [uuid, info]) => {
          const Pt = info.lastFix;

          const id = uuid;
          const idOwner = $ownersByFlights[id];
          const user = info.static.user.username + (info.static.simulation ? ' [SIMULATED]' : '');
          const coordinates = [Pt[0], Pt[1]];
          const a = Util.round(Pt[2]);
          const agl = Util.round(a -Pt[3].g);

          const lastFixTstamp = isoToTime(Pt[3].t);

          //pokud jsme v playMode, pocitame delay dle playMode cursorTstamp; jinak uz ho mame spocitany
          const delayMs = $playMode ? cursorTstamp - lastFixTstamp : info.delay;
  //console.log('TST',cursorTstamp, lastFixTstamp, delayMs)

          //status - live|lost|landed
          //po 2 min od lastFix se status stava lost
          const status = info.actual.landed ? 'landed' : delayMs < 120 * 1000 ? 'live' : 'lost';

          //show - jestli ma byt pozice zobrazena
          //pokud je delayMs je zaporna (tj. cursorTstamp je PRED lastFixTstamp), tak se pozice nema zobrazovat
          const show = $playMode ? delayMs >= 0 : true;

          //pokud se nema zobrazovat, rovnou ho vyhodime z kolekce
          if (!show) return Coll;

          //delay - formatovany cas od lastFix
          const dD = ExtDate.toTimezone(new ExtDate(delayMs),0);
          const format = delayMs < 60 * 60 * 1000 ? "%i min" : "%k:%M h";
          const delay = dD.strftime(format);

          //pokud je barva jiz alokovana, pridavame barvicky - pozice se bude vykreslovat v barvickach daneho letu
          let colors = {};
    //console.log('_createPositions::iter', "id:"+id, "idOwner:"+idOwner, $flightColors);
          if (idOwner && $flightColors[idOwner] >= 0 && $uuidsFollowed.includes(id)) {
            //colors
            const cHalo = XCUtil.getColor(id, 0.8);//color pro halo - barva tracklogu
            const cIcon = XCUtil.getColorCode(id);  //hex color code - pro id ikonky
            const cText = XCUtil.getInvertedColor(id, 0.9);//inverted color (BW) pro text
            colors = {cHalo, cIcon, cText};
    //console.log('_createPositions::addColors', colors)
          };

          const Point = Util.getFreshGeoJSON('Point', {
            id,
            properties: {uuid, show, user, a, agl, status, delay, ...colors}
          });

          //pridani coordinates
          Object.assign(
            Point.geometry,
            {coordinates}
          );

          //pridani baro vysky, jen pokud je k dispozici
          if (Pt[3].b)	Object.assign(
            Point.properties,
            {b: Util.round(Pt[3].b)}
          );

          //pridani delta promennych (zmeny od posledniho fixu)
          const pPt = info.prevFix;
          //rozdil vysky od posledniho fixu prevedeny na minuty
          //cas od posledniho fixu v minutach
          const deltaMinutes = (lastFixTstamp - isoToTime(pPt[3].t))/60000;
          //pokud je baro vyska, bere se z ni, jinak z gps vysky; delime minutama a zaokrouhlujem
          let delta = Math.round((Pt[3].b && pPt[3].b ? Pt[3].b - pPt[3].b : Pt[2] - pPt[2])/deltaMinutes);

          Object.assign(
            Point.properties,
            {delta}
          );

    //console.log('_createPositions:2', deltaMinutes, delta)
          Coll.features.push(Point);
          return Coll;
        }, Util.getFreshGeoJSONCollection());
        return GColl;
      }
    );

    return Object.assign(superDerived, {
      contest,

      showGraph, //REWRITE
      defaultPlayTX, //REWRITE
      accessibleGlobalZTbounds, //REWRITE
      liveInfo,
      filterProps,

      uuidsLast,
      uuidsDisplay,
      liveInfoDisplay,
      liveInfoDisplayByPilot,
      uuidsLastByOwner,
      ownersFollow,

      numActive,
      numDisplay,
      numLive,
      numFollow,

      tXplay,
      positions
    });
  }

  _setEvents () {
    super._setEvents();

    const {
      uuidsDisplay,
      uuidsLastByOwner,
      liveInfoDisplayByPilot
    } = this;

    //LIVE events
    this.subscribeEvent(
      uuidsDisplay,
      'display-change',
      ($curr, $prev) => {
//console.log('display-change-test', $curr, $prev)
        return JSON.stringify($curr) != JSON.stringify($prev);
      }
    );

    this.subscribeEvent(
      uuidsLastByOwner,
      'lastflights-change',
      ($curr, $prev) => {
        return $prev && [...$prev].find(([pilotid, uuid]) => $curr.get(pilotid) != uuid)
      }
    );

    //udalost kdy jsou data poslednich letu poprve nacteny - je mozno napr. zjistit posledni pozice pilotu a zamerit je
    this.subscribeEvent(
      liveInfoDisplayByPilot,
      'liveinfo-loaded',
      ($curr, $prev) => {
        return $curr.size > 0 && (!$prev || $prev.size == 0)
      },
      undefined,
      true
    );
  }

  addLiveFlight (id) {
    this.addFlight(id, {live : true});
  }
};

class LiveViewStore extends ViewStore {
  _getBasic () {
    return Object.assign(super._getBasic(), {
      //REWRITE
      playSpeed: w(1),

      //REWRITE - u live tracklogu nezobrazujeme start-end turnpoints
      showFlightSet : r({
          startend: false,
          turnpoints: false,
          route: true,
          graph: true,
          pilot: true
      }),

      //NEW - jedna se o portrait mode (sirka obrazovky < vyska)?
      isPortrait: r(
        window.innerWidth < window.innerHeight,
        (set) => {
          const resizeListener = () => {
            set(window.innerWidth < window.innerHeight);
          };

          window.addEventListener('resize', resizeListener);

        	return () => {
            window.removeEventListener('resize', resizeListener)
          };
        }
      )
    });
  }

  _getDerived () {
    const { posAgl } = this;

    //actual positions pro LIVE
    //zohlednuje pouze AGL
    const actTextField = derived(
      posAgl,
      ($posAgl) => {
        let value = ["format",
          ["get", "user"], {},
          ["case",
            ['==', ["get", "status"], 'live'], '',
            ["concat", " (-", ["get", "delay"], ")"]
          ], {},

          "\n", {},
          ["get", "a"], {},
          " m", {}
        ];

        //if ($posAgl) {
        value = value.concat([
          " [", {},
          ["get", "agl"], {},
          " AGL]", {}
        ]);
        //};

        //pridani delta zmeny vysky
        value = value.concat([
          ["case",
            ['!=', ["get", "status"], 'live'], "",//pro lost a landed se nezobrazuje
            ['==', ["typeof", ["get", "delta"]], "null"], "",//pokud neni parametr delta (pri prvnim nacteni), nezobrazuje se
            ['>', ["get", "delta"], 0], ["concat", " ↗ ", ["get", "delta"], " m"],
            ['<', ["get", "delta"], 0], ["concat", " ↘ ", ["get", "delta"], " m"],
            ['==', ["get", "delta"], 0], ["concat", " → ", ["get", "delta"], " m"],
            ""
          ], {}
        ]);

//console.log(value)


        return value;
      },
      //INITIAL hodnota
      ["format",
        ["get", "user"], {},
        ["case",
          ['==', ["get", "status"], 'live'], '',
          ["concat", " (-", ["get", "delay"], ")"]
        ], {},
        "\n", {},
        ["get", "a"], {},
        " m", {}
      ]
    );

    return Object.assign(super._getDerived(), {
      actTextField
    });
  }
}

const pgStore = new LivePgStore();
const viewStore = new LiveViewStore();
const pmsStore = new PmsStore();

//promise pro pridani actual positions layer
pmsStore.sPMS('ActPosLayer', null);

//promise pro prvni nacteni z WS
pmsStore.sPMS('LiveFlightsLoaded', null);


export { pgStore, viewStore, pmsStore };
