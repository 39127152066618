import { writable, derived, get } from 'svelte/store';

import { Util } from "./util.js";

/**
 * @param root - objekt, ze ktereho hledame dle keypath
 * @param keys je array [keypath, key?] nebo string (pokud je jen keypath) - key nemusi byt definovano, pokud koncovy objekt neni typu Map
 * @param getFunc optional - pripadna vraceci funce. Ma jediny parametr (value). Vysledek funkce je vracen.
 * @returns hodnotu store ktery je pod zadanou keypath cestou.
 * Pokud store na dane keypath neni definovan, vraci undefined
 * Pokud je predana getFunc(value), tato funkce dostava jako parametr primo dany store (tj. nikoliv hodnotu) a to co vraci getFunc() je vraceno
*/
function _getDeep (root, keys, getFunc) {
  if (typeof keys === "string") keys = [keys];

  const [keypath, key] = keys;

	if (keypath === undefined) {
		return undefined;
	};

	const keypcs = keypath.replace(/\[(\d+)\]/g, '.$1').split('.');
	let value = root[keypcs[0]];
	for (let i = 1; i < keypcs.length; i++) {
		value = value[keypcs[i]];
	};

	if (key && value instanceof Map) value = value.get(key);
	return getFunc instanceof Function ? getFunc(value) : value === undefined ? value : get(value);
};

/**
 * @param root - objekt, ze ktereho hledame dle keypath
 * @param keys je array [keypath, key?] nebo string (pokud je jen keypath) - key nemusi byt definovano, pokud koncovy objekt neni typu Map
 * @param value - hodnota ktera ma byt nastavena storu na dane keypath. Alternativne nastaveni zajisti setFunc(), je-li predana
 * @param setFunc - optional - pripadna nastavovaci funkce. Parametry obj.
 * Napr. (obj) => obj.set(value) //pokud koncovy objekt je store.
 * Pokud setFunc neni predana, pocita s tim, ze koncovy objekt je writable store (pripadne i vytvari novy)
 * @returns void
*/
function _setDeep(root, keys, value, setFunc) {
  if (typeof keys === "string") keys = [keys];
  const [keypath, key] = keys;

	if (keypath === undefined) {
		return;
	};

	const keypcs = keypath
		.replace(/\[(\d+)\]/g, '.$1')
		.split('.');

	const lastKey = keypcs.pop();

	let obj = keypcs
		.reduce((obj, key) => obj[key], root);

	if (key) {
		obj = obj[lastKey];

		if (!obj instanceof Map) {
			console.log('setDeep() - Map object expected. Key: '+key);
			return;
		};

		let s = obj.get(key);
		if (setFunc instanceof Function) {
			obj.set(key, setFunc(s));
		} else {
			s = s || writable();
      s.set(value);
			obj.set(key, s);
		};
	} else {
//onsole.log('_setDeep', keys, value, setFunc);
//console.trace();
		let s = obj[lastKey];

		if (setFunc instanceof Function) {
			obj[lastKey] = setFunc(s);
		} else {
      s = s || writable();
      s.set(value);
			obj[lastKey] = s;
		};
	};
};


function watchable (initial, subscribeFunc, watchFunc = () => {}) {
	const { subscribe, update } = writable(initial, subscribeFunc);

	//store pro ulozeni previous stavu
	const prev = writable();

  //store pro ulozeni ext (objekt s rozsirujicimi daty, ktery se muze predavat watch funkci)
  const ext = writable();

	const watch = (previous, current, external) => {
		prev.set(previous);
    ext.set(external);
		watchFunc(previous, current, external);
		return current;
	};

	return {
		prev, //store!
    ext, //store!

		subscribe,
    update,
		watch,
    set: (current) => {
      update(previous => {
				return watch(previous, current);
			});
    }
	};
};

function watchableFlights (initial, subscribeFunc, watchFunc) {
	const { prev, ext, subscribe, update, watch, set } = watchable(initial, subscribeFunc, watchFunc);
	return {
		prev,
    ext,
		subscribe,
		update,
		watch,
		set,
		addFlight : (id, external) => {
//console.log("watchableFlights::addFlight", id, external)
      id = String(id);
      update(previous => {
				if (previous.includes(id)) return previous;
				const current = [...previous, id];
				return watch(previous, current, external);
			});
		},
		removeFlight : id => {
//console.log("watchableFlights::removeFlight", id)
      id = String(id);
			update(previous => {
				if (!previous.includes(id)) return previous;
				const current = previous.filter(i => i !== id);
				return watch(previous, current);
			});
		}
	};
};

function watchableDerived (stores, fn, initial) {
	//store pro ulozeni previous a current stavu
	const prev = writable(initial);
	const curr = writable(initial);

	const { subscribe } = derived(
		stores,
		(args, set) => {

			const wSet = (val) => {
				prev.set(get(curr));
				curr.set(val);
				set(val);
			};

			const result = fn(args, wSet);

			if (!["function", "undefined"].includes(typeof result)) {
				wSet(result);
			};

			return result;
		},
		initial
	);

	return {
		prev,
		curr,
		subscribe
	};
};

class EventStore {
	constructor () {
		this._on = new Map();
	}

  _getDeep (keys, getFunc) {
		return _getDeep(this, keys, getFunc);
	}

  _setDeep (keys, value, setFunc) {
		return _setDeep(this, keys, value, setFunc);
	}

  /**
   * nacita properties objektu do this a dela z nich stores
   * @param obj - objekt, kde value dane prop se stane hodnotou noveho storu
   * @param setFn - [optional] set funkce, kterou lze zmenit
  */
  _setValues (
    obj,
    setFn = (val, store) => {
      if (store === undefined) {
        store = writable(store);
      } else {
        store.set(val);
      };
      return store;
    }
  ) {
    Object.entries(obj).forEach(([key, value]) => {
      this[key] = setFn(value, this[key]);
    });
  }

  /**
   * pomocna funkce ktera nastavuje hodnotu property objektu (pokud ve store je objekt)
   * @param store - svelte store
   * @param key - klic
   * @param value hodnota, ktera se ma danemu nastavit pro dany klic objektu ve store
   * @returns svelte store
  */
  _setProp (store, key, value) {
    const obj = get(store);
    obj[key] = value;
    store.set(obj);
    return store;
  }

  /**
   * @param evt string; nazev udalosti
   * @param c - current state
   * @param p - previous state
   * @param ext - optional object, jeho props se pridavaji
  */
  _fireChange (evt, c, p, ext = null) {
    Util.fireChange(this, evt, c, p, ext);
    /*
    this.fire(evt, Object.assign({
      current: c,
      previous: p
    }, ext));
    */
  }

  setProp (keys, key, value) {
    this._setProp(
      this._getDeep(keys, store => store),
      key,
      value
    );
  }

  set (obj) {
    this._setValues(obj);
  }

  /**
   * nastavuje properties obj do this, ale pocita s tim, ze hodnoty props jsou jiz stores
  */
  setStores (obj) {
    this._setValues(obj, store => store);
  }

  /**
   * vraci hodnoty stores dle keys
   * @param keys - array|string
   * - pokud array, vraci objekt, ve kterem pod danym key je hodnota prislusneho store
   * - pokud string, vraci primo hodnotu daneho storu pod danym key (chova se tedy stejne jako getDeep(string), pokud string je primarni key, ne keypath)
   * @param getFn - funkce pro vraceni; defaultne vraci hodnotu store
  **/
  get (keys = [], getFn = store => get(store)) {
    return typeof keys === "string"
    ? getFn(this[keys.trim()])
    : keys.reduce(
      (res, key) => {
        key = key.trim();
        res[key] = getFn(this[key]);
        return res;
      },
      {}
    );
  }

  getStores (keys = []) {
    return this.get(keys, store => store);
  }

  /**
    * @returns unsubscribe function - anonymni fukce, ktera po zavolani odstrani dany listener
  */
	on (evt, func) {
    const callbacks = this._on.has(evt) ? this._on.get(evt) : [];
    callbacks.push(func);
    this._on.set(evt, callbacks);

    //vraci funkci ktera odebere dany listener
    return () => {
      this._on.set(evt, this._on.get(evt).filter(fn => fn !== func));
    };
  }

  fire (evt, payload) {
    const callbacks = this._on.get(evt) || [];
    callbacks.forEach(func => func(payload));
  }

  /**
   * registrace eventu na store
   * @param store - svelte store
   * @param evt - event identifier (string)
   * @param fireCondFn - [optional] function ($curr, $prev) => {...vraci true, pokud ma byt udalost spustena}
   * - pokud fireCondFn neni definovana, udalost se spousti vzdy pri zmene storu
   * @param fireFn -[optional] function (evt, $curr, $prev) => {...provadi spusteni udalosti}
   * - pokud fireFn neni definovana, pouzije se standardni fireChange funkce ktera predava objekt {current: ..., previous: ...[, ...]}
   * @param once - [optional] pokud TRUE, udalost se spusti pouze jednou a pak se sama odregistruje
  */
  subscribeEvent (store, evt, fireCondFn = () => true, fireFn, once = false) {

    fireFn = fireFn || ((evt, c, p, ext = null) => {
      this.fire(evt, Object.assign({
        current: c,
        previous: p
      }, ext));
    });

    store.subscribe($curr => {
      const $prev = getPrev(store);
      const $ext = getExt(store);
//console.log(evt, $curr, $prev)
      if (fireCondFn($curr, $prev)) {
        fireFn(evt, $curr, $prev, $ext);
        if (once) this._on.delete(evt);
      };
    });
  }
};

/** vraci previous z watchable store **/
const getPrev = (store) => get(store.prev);

/** vraci external z watchable store **/
const getExt = (store) => get(store.ext);

/** vraci odebrane polozky (pokud hodnota store je Array) **/
const getRem = store => Util.arrDiff(getPrev(store), get(store));

/** vraci pridane polozky (pokud hodnota store je Array) **/
const getAdd = store => Util.arrDiff(get(store), getPrev(store));

/**
 * vytvari specialni derived store, ktery porovnava predchozi a soucasny stav watchable store
 * POUZE pokud je hodnotou pole!
**/
const diff = (store) => {
	return derived(store, $store => {
		return {
			add: Util.arrDiff($store, p(store)),
			rem: Util.arrDiff(p(store), $store)
		};
	});
};

export {
	EventStore,
	watchable,
	watchableFlights,
	watchableDerived,
	diff,
	getPrev,
	getRem,
	getAdd,
  getExt
};
