<script>
import { getContext } from 'svelte';
import { slide } from 'svelte/transition';

const pgStore = getContext('pgStore');

//je filtr otevren?
let opened = false;

//je filtr aktivni (neco zvoleno/filtrovano)?
let active = false;

//Live lib knihovna
export let Live = null;

const {
  filterProps,
  filteredNations,
  filteredSites,
  filteredStatus,
  uuidsFollowed
} = pgStore.getStores([
  'filterProps',
  'filteredNations',
  'filteredSites',
  'filteredStatus',
  'uuidsFollowed'
]);
/*
$: console.log("$filterProps", $filterProps);
$: console.log("$filteredNations", $filteredNations);
$: console.log("$filteredSites", $filteredSites);
$: console.log("$filteredStatus", $filteredStatus);
*/
$: active = $filteredStatus !== 'all' || $filteredSites.length > 0 || $filteredNations.length > 0;

const open = evt => {
  opened = !opened;
};

const reset = evt => {
  $filteredStatus = 'all';
  $filteredSites = [];
  $filteredNations = [];
};
</script>

<style>
.filter {
  margin: 5px 10px;
  border: 0.7px dotted grey;
  border-radius: 2px;
}

h2 {
  font-weight: bold;
  font-size: 120%;
  cursor: pointer;
  margin: 0;
  padding: 10px 10px 10px 5px;
  display: flex;
  justify-content: space-between;
}
.active h2 {
  background-color: hsla(190, 100%, 75%, 1);
  padding: 10px 5px 0 5px;
}

.active h2 label {
  position: relative;
  top: -5px;
}

h2 span {
  color: white;
}

div.cols {
  display: flex;
  justify-content: space-between;
}

div.cols > div {
  flex: 1;
}

h3 {
  font-style: italic;
  margin: 10px 0 0 0;
  padding-left: 5px;
}

li {
  list-style-type: none;
  margin: 5px 0;
}

label {
  display: block;
  border-radius: 2px;
  padding: 5px;
  margin: 0;
  min-width: 50px;
  border: 0.7px dotted grey;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

label * {
  vertical-align: middle;
}

ul {
  margin: 5px;
  padding: 0;
}

.status {
  display: flex;
  justify-content: space-between;
}
.status li {
  display: block;
  padding-left: 0;
  margin-left: 0;
}

input[type=radio],
input[type=checkbox] {
  display: none;
}

input[type=checkbox] {
  margin: 0;
}

label.chosen {
  font-weight: bold;
  background-color: hsla(190, 100%, 75%, 1);
  border: 0.7px solid grey;
}
.note {
  margin: 5px 10px 5px 5px;
  text-align: right;
  color: hsla(190, 100%, 75%, 1);
}

</style>

<div class="filter" class:active>
  <h2 on:click={open}>Pilots filter 🜄
    {#if active}<span>ACTIVE!</span> <label on:click|stopPropagation={reset}>RESET</label>{/if}
  </h2>

  {#if opened}
  <div transition:slide="{{delay: 100, duration: 200}}">
    <h3>Status:</h3>
    <ul class="status">
      <li><label class:chosen={$filteredStatus === 'all'}><input type="radio" bind:group={$filteredStatus} value="all" /> All</label></li>
      {#each [...$filterProps.status] as [status, {num, label}]}
      <li><label class:chosen={$filteredStatus === status}><input type="radio" bind:group={$filteredStatus} value={status} /> {label} ({num})</label></li>
      {/each}
    </ul>
    <div class="cols">
      <div>
        <h3>Site:</h3>
        <ul class="sites">
          {#each [...$filterProps.sites] as [name, {num, label}]}
          <li><label class:chosen={$filteredSites.includes(name)}><input type="checkbox" bind:group={$filteredSites} value={name} /> {label} ({num})</label></li>
          {/each}
        </ul>
      </div>
      <div>
        <h3>Nationality:</h3>
        <ul class="nations">
          {#each [...$filterProps.nations] as [iso, {num, label}]}
          <li><label class:chosen={$filteredNations.includes(iso)}><input type="checkbox" bind:group={$filteredNations} value={iso} /> {label} ({num})</label></li>
          {/each}
        </ul>
      </div>
    </div>
  </div>
  {/if}
</div>
{#if active && $uuidsFollowed.length > 0} <div class="note">Followed pilots are always visible.</div>{/if}
