<svelte:options immutable/>

<script>
import { onMount, getContext } from 'svelte';

import {
	showFlight,
	hideFlight,
  PGUtil
} from './utils/pgutil.js';

import PlayPanel from './PlayPanel.html';
import MapDefaults from './MapDefaults.html';
import ControlPanel from './ControlPanel.html';
import Graph from './Graph.html';
import Displayer from './Displayer.html';

const pgStore = getContext('pgStore');
const viewStore = getContext('viewStore');
const pmsStore = getContext('pmsStore');

export let showPlay = false;
export let showSettings = false;

let map;

const {fullscreen, sat, hires, airspace, baseSize, posAgl, posSpeed, posVario} = viewStore.getStores(['fullscreen', 'sat', 'hires', 'airspace', 'baseSize', 'posAgl', 'posSpeed', 'posVario']);
const {showGraph} = pgStore.getStores(['showGraph']);

onMount(() => {
	//nastaveni map elementu
	pgStore.setMapNode(map);
});

pgStore.set({
  graphTimeMode: 'ABSOLUTE',
	//graphTimeMode: 'UTCTIME',
	//graphTimeMode: 'LOCALTIME',
	//graphTimeMode: 'STARTTIME',
  //colorOwner: 'FLIGHT'
});

//pri zmene zobrazovani grafu je potreba zavolat map.resize()
showGraph.subscribe(() => PGUtil.checkMapResize());

//pridani letu, NE live
pgStore.on('flight-add', ({id, live}) => {
//console.log('Map.flight-map :: flight-add', id, live)
//console.trace();
	if (live) return;
//console.log('Map.flight-map :: flight-add show', id)
	showFlight(id);
});

//odebrani letu
pgStore.on('flight-rem', ({id}) => hideFlight(id));

//na zmenu multiple menime multiMode
pgStore.on('multiple-change', ({current}) => PGUtil.checkMultiMode(current));

//na kliknuti do grafu posun mapy
pgStore.on('graph-click', () => PGUtil.fitPilots());

//na touchmove checkovani bounds a pripadny posun
pgStore.on('graph-touchmove', () => {
	//napred musime vratit kontrolu nad mapou automatice
	viewStore.set({mapUserControl: false});
  PGUtil.checkBounds(false);
});

viewStore.on('hires-change', () => {
	pgStore.get('FLIGHTS').forEach(id => showFlight(id));
});

pgStore.on('route-type-change', ({current}) => {
//console.log('route-type-change', current);
	pgStore.get('FLIGHTS').forEach(id => {
    PGUtil.showRoute(id, current);
    PGUtil.showMarkers(id, 'turnpoints', current);
  });
});

const letOpened = true;
const localStorage = true;

export let controls = [
	  {key: 'fullscreen', label: 'fullscreen', checked: $fullscreen},
	  {key: 'sat', label: 'sat map', checked: $sat},
	  {key: 'hires', label: 'hi-res tracklog', checked: $hires},
	  {key: 'airspace', label: 'airspace', checked: $airspace}
];

export let controlsSettings = [
		{key: 'baseSize', label: 'positions font size:', range: [10,20], letOpened, localStorage},
		{key: 'posAgl', label: 'play: show AGL', checked: $posAgl, letOpened, localStorage},
    {key: 'posSpeed', label: 'play: show speed', checked: $posSpeed, letOpened, localStorage},
    {key: 'posVario', label: 'play: show vario', checked: $posVario, letOpened, localStorage}
];
</script>

<MapDefaults>
	<!--
	<div>
		{#await flightLoadedDelayed}
			<div class="container" style="background-color: lightgrey;"></div>
		{:then data}
			<div class="container" style="background: hsla(108, 45%, 43%, 1)"></div>
		{:catch error}
			<div class="container" style="background-color: black;opacity: 0.5">Error</div>
		{/await}
	</div>
	-->
	<div class="map" bind:this={map} style="height:calc(100% {$showGraph ? '- 98px' : ''})"></div>
	<!--
	<div id="control-route-type" class="control">
	  <select id="switch-route-type" name="route-type" on:change="switchRouteType(this.value)">
	    <option value="DEFAULT" selected>DEFAULT</option>
	    <option value="FREE_FLIGHT">FREE_FLIGHT</option>
	    <option value="FREE_DISTANCE">FREE_DISTANCE</option>
	</div>
	-->
	<ControlPanel uniqid="layers" {controls} />

	<svelte:component
		this={showSettings ? ControlPanel : null}
		uniqid="globalSettings"
		controls={controlsSettings}
		controlType="sprocket"
		cssStyle="right: 50px" />

	<svelte:component this={showPlay ? PlayPanel : null} />

	<Graph />


	<Displayer />

</MapDefaults>

<style>
	div.map {
		position: absolute;
		top:0px;
		width:100%;
	}

	* {
		color: #333;
		box-sizing: border-box;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	}
/*
	div.map :global(a) {
		color: rgb(0,100,200);
		text-decoration: none;
	}

	div.map :global(a:hover) {
		text-decoration: underline;
	}

	div.map :global(a:visited) {
		color: rgb(0,80,160);
	}

	div.map :global(label) {
		display: block;
	}

	div.map :global(input),
	div.map :global(button),
	div.map :global(select),
	div.map :global(textarea) {
		font-family: inherit;
		font-size: inherit;
		padding: 0.4em;
		margin: 0;
		box-sizing: border-box;
		border: 1px solid #ccc;
		border-radius: 2px;
	}

	div.map :global(input:disabled) {
		color: #ccc;
	}

	div.map :global(input[type="range"]) {
		height: 0;
	}

	div.map :global(button) {
		background-color: #f4f4f4;
		outline: none;
	}

	div.map :global(button:active) {
		background-color: #ddd;
	}

	div.map :global(button:focus) {
		border-color: #666;
	}
*/
</style>
