<svelte:options immutable/>

<script>
import { onMount, getContext } from 'svelte';

import {
	TS_NOTCH_STEPS,
	TS_LABEL_STEPS,
	COLORS
} from './utils/const.js';
import {xToDate} from './utils/helpers.js';
import {Util} from './utils/util.js';
import {SvgUtil} from './utils/svgutil.js';
import {GraphMod} from "./utils/graphmod.js";
//import {pgStore, viewStore} from "./utils/stores.js";

import ControlPanel from './ControlPanel.html';

const pgStore = getContext('pgStore');
const viewStore = getContext('viewStore');
const pmsStore = getContext('pmsStore');

GraphMod.setStores({pgStore, viewStore, pmsStore});

let graphNode;
let overlayer;
export let mousedown = false;

//stores pg
const {
	showGraph,
	transform,
  canvas,
  gaps,
  activeZTbounds,
  globalZTbounds,
  basicFlightData,
  multiple,
  routeType,
  matrixes,
  graphPaths,
  translateXday,
	utcOffsetTS,
  minPixelStep,
  gX,
	defaultPlayTX
} = pgStore.getStores([
	'showGraph',
  'transform',
  'canvas',
  'gaps',
  'activeZTbounds',
  'globalZTbounds',
  'basicFlightData',
  'multiple',
  'routeType',
  'matrixes',
  'graphPaths',
  'translateXday',
	'utcOffsetTS',
  'minPixelStep',
  'gX',
	'defaultPlayTX'
]);

//const flightColors = pgStore.getStores('flightColors');
//flightColors.subscribe ((curr) => console.log('Graph:flightColors:subs', curr));
//$: console.log('Graph:flightColors', $flightColors);
//$: console.log('Graph:gx:canvas:...', $gX, $canvas, ($canvas.w - $gX) / $canvas.w);

//stores view
const {
  playMode,
  vario,
  speed
} = viewStore.getStores([
  'playMode',
  'vario',
  'speed'
]);


onMount(() => {
	//nastaveni graphNode elementu
	pgStore.set({graphNode});
});


let xVisibleSteps;
$: xVisibleSteps = Util.getFilterStepsVisible(
		$transform.scaleX,
		step => step >= 3600 ? 30 : 50
);

let yVisibleSteps;
$: yVisibleSteps = Util.getFilterStepsVisible(
		$transform.scaleY,
		10
);

let xLabelIterator;
$: xLabelIterator = GraphMod.getLabelIterator(
		$canvas.w,
		$transform.scaleX,
		$transform.translateX,
		$gaps.bounds
);

let yLabelIterator;
$: yLabelIterator = GraphMod.getLabelIterator(
		$canvas.h,
		$transform.scaleY,
		$transform.translateY
);

let flightsBounds;
$: flightsBounds = $activeZTbounds.map(
		([id, bounds]) => [id, $basicFlightData[id], bounds]
);

let flightsTurnpoints;
$: flightsTurnpoints = GraphMod.getFilterTPs($multiple);

let turnpointsIterator;
$: turnpointsIterator = GraphMod.getTurnpointsIterator(
		$routeType,
		$globalZTbounds,
		$matrixes
	);

let controls;
$: controls = [
    {key: 'vario', label: 'vario', checked: $vario},
    {key: 'speed', label: 'speed', checked: $speed}
  ];

const toPathFill = path => SvgUtil.toPathFill(path);
const toPathLine = path => SvgUtil.toPathLine(path);
const tX2gX = tX => {
	return pgStore.tX2gX(tX);
};

function checkResize() {
	GraphMod.checkResize('windowResize');
}

function graphClick(e) {
//console.log('graphClick', e)
  fireGraphEvent(e,
    gXtX => pgStore.set(gXtX)
  );
}

function graphMouseMove(e) {
  //optimalizace
  // protoze fireGraphEvent vola eventXY(), potazmo getBoundingClientRect() -> narocne na reflow [https://gist.github.com/paulirish/5d52fb081b3570c81e3a]
  // proto volame v playMode pouze pokud se taha mysi (mousedrag)
  if (viewStore.get('playMode')) {
    if (!mousedown) return;

    fireGraphEvent(e,
      gXtX => fireGraphEvent(e, null, 'mousedrag')
    );
    return;
  };

  fireGraphEvent(e,
    gXtX => pgStore.set(gXtX)
  );
}

function graphMouseDown(e) {
  fireGraphEvent(e,
    () => mousedown = true
  );
}

function graphMouseUp(e) {
  fireGraphEvent(e,
    () => mousedown = false
  );
}

function graphMouseLeave(e) {
  fireGraphEvent(e,
    () => mousedown = false
  );
}

function graphMouseWheel(e) {
  GraphMod.graphMouseWheel(e);
}

function graphTouchMove(e) {
  fireGraphEvent(
    Util.getLeftmostTouch(e),
    gXtX => pgStore.set(gXtX),
    'touchmove'
  );
}

function nic(e = null) {
  //if (e) console.log('void event', e.type, e);
}

function fireGraphEvent(e, func = null, type = null) {
//console.log('fire', type || e.type, e); return;
  const gXtX = GraphMod.getX(e);
  if (!gXtX) return;
  if (func) func(gXtX);
  pgStore.fire('graph-'+ (type || e.type), gXtX);
}
</script>

<svelte:window on:resize="{checkResize}" />

<div class="graph" bind:this={graphNode} on:touchmove|passive="{nic}" style="visibility: {$showGraph ? 'visible' : 'hidden'}"
  ><svg version="1.2" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
  <style>
  path.hide, line.hide {
    visibility: hidden;
  }

  /** paths **/
  .path-gps,
  .path-baro {
    stroke-width: 1;
    vector-effect: non-scaling-stroke;
    fill: none;
    visibility: inherit;
  }
  .path-gps {
    stroke: hsla(28, 100%, 50%, 1);/*COLORS.GRAPH.gps*/
  }
  .path-baro {
    stroke: hsla(200, 100%, 50%, 0.7); /*COLORS.GRAPH.baro*/
  }
  .path-fill {
    fill: url(#gps-fill-bgd);
    visibility: inherit;
  }
  .path-ground {
    fill: hsla(0, 0%, 66%, 1);/*COLORS.GRAPH.ground*/
    visibility: inherit;
  }

  .path-vario,
  .path-speed {
    stroke-width: 0.5;
    vector-effect: non-scaling-stroke;
  }
  .path-vario {
    stroke: hsla(0, 100%, 25%, 0.75);
    fill: hsla(60, 100%, 70%, 0.5);
  }
  .path-speed {
    stroke: hsla(20, 100%, 50%, 0.75);
    fill: hsla(20, 100%, 85%, 0.5);
  }

  g.walk .path-gps {
    stroke-dasharray: 2 2;
  }
  g.rest .path-gps {
    stroke-dasharray: 0 2 4 2 4 0;
  }
  g.rest .path-ground {
    fill: url(#bgd-rest);
  }

  .tp-DEFAULT,
  .tp-FREE_FLIGHT,
  .tp-FREE_DISTANCE {
    stroke: black;
    stroke-width: 0.5;
    vector-effect: non-scaling-stroke;
    visibility: inherit;
  }

  .ts-tm-86400,
  .ts-tm-43200,
  .ts-tm-21600,
  .ts-tm-7200,
  .ts-tm-3600,
  .ts-tm-1800,
  .ts-tm-900,
  .ts-tm-600,
  .ts-tm-300,
  .ts-tm-60 {
    fill: hsla(204, 47%, 38%, 1);
    font-family: 'Roboto', sans-serif;
  }
  .ts-tm-86400,
  .ts-tm-43200,
  .ts-tm-21600 {
    font-size: 10px;
    font-weight: bold;
  }
  .ts-tm-7200,
  .ts-tm-3600 {
    font-size: 9px;
    font-weight: bold;
  }
  .ts-tm-1800 {
    font-size: 8px;
  }
  .ts-tm-900,
  .ts-tm-600,
  .ts-tm-300,
  .ts-tm-60 {
    font-size: 8px;
  }

  .lvl-label-1000,
  .lvl-label-500,
  .lvl-label-200,
  .lvl-label-100 {
    font-family: 'Roboto', sans-serif;
    fill: hsla(0, 0%, 0%, 0.5);
    font-size: 8px;
  }
  .lvl-label-1000 {
    fill: hsla(0, 0%, 0%, 0.9);
  }

  .lvl-line-1000,
  .lvl-line-500,
  .lvl-line-200,
  .lvl-line-100 {
    stroke: hsla(0, 0%, 0%, 0.3);
    stroke-width: 0.5;
  }
  .lvl-line-1000 {
    stroke: hsla(0, 0%, 0%, 0.7);
    stroke-width: 0.5;
  }
  </style>
  <defs>
    <linearGradient id="bgd" x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stop-color="lightsteelblue" stop-opacity="0.2"/>
      <stop offset="20%" stop-color="white" stop-opacity="1.0"/>
      <stop offset="100%" stop-color="white" stop-opacity="1.0"/>
    </linearGradient>

    <linearGradient id="gps-fill-bgd" x1="0" x2="0" y1="1" y2="0">
      <stop offset="0%" stop-color="lightsteelblue" stop-opacity="0.2"/>
      <stop offset="20%" stop-color="white" stop-opacity="1.0"/>
      <stop offset="50%" stop-color="{COLORS.GRAPH.fill}" stop-opacity="1.0"/>
      <stop offset="100%" stop-color="{COLORS.GRAPH.fill}" stop-opacity="1.0"/>
    </linearGradient>

    <linearGradient id="gap">
      <stop offset="0%" stop-color="hsla(0, 0%, 60%, 0.1)" />
      <stop offset="40%" stop-color="hsla(0, 0%, 40%, 0.8)" />
      <stop offset="50%" stop-color="hsla(0, 0%, 20%, 1)" />
      <stop offset="60%" stop-color="hsla(0, 0%, 40%, 0.8)" />
      <stop offset="100%" stop-color="hsla(0, 0%, 60%, 0.1)" />
    </linearGradient>

    <filter id="gps-shadow">
      <feGaussianBlur in="SourceAlpha" stdDeviation="{$transform.scaleX} {$transform.scaleY}" />
      <feMerge>
        <feMergeNode/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>

    <line id="ts-bar" x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="1" transform="scale({$transform.scaleX}, 1)" />

    {#each TS_NOTCH_STEPS as [step, height, strokeWidth]}
      <pattern id="ts-{step}" class="ts" width="{step}" height="{height}" patternUnits="userSpaceOnUse"
        patternTransform="scale({1/$transform.scaleX},1) translate(-{$translateXday},0)">
        <use href="#ts-bar" stroke-width="{strokeWidth ? strokeWidth : 1}" />
      </pattern>
    {/each}

    <pattern id="bgd-rest" width="12" height="100" patternUnits="userSpaceOnUse" patternTransform="scale({$transform.scaleX}, 1)">
      <rect width="10" height="100%" fill="hsla(0, 0%, 66%, 1)" x="2" y="0" />
    </pattern>
  </defs>

  <rect width="100%" height="100%" fill="url(#bgd)" />

  <!-- paths for flights -->
  <g class="paths">
    {#each $graphPaths as {id, path, color, matrix, visible}}
    <g class="{ {1: 'flight', 2: 'walk', 3: 'rest'}[path.type] }"
      visibility="{ visible ? 'visible' : 'hidden' }"
      transform="matrix({ matrix.join(',') })">

      <path class="path-fill { $multiple ? 'hide' : '' }" d="{ toPathFill(path.a) }" />
      <path class="path-ground { $multiple ? 'hide' : '' }" d="{ toPathFill(path.g) }" />
      <path class="path-gps" d="{ toPathLine(path.a) }" filter="{ $multiple ? 'url(#gps-shadow)' : '' }"
       style="stroke: { color }" />
      {#if path.b}<path class="path-baro { $multiple ? 'hide' : '' }" d="{ toPathLine(path.b) }" />{/if}
    </g>

    <g visibility="{ visible && $vario ? 'visible' : 'hidden' }"
      transform="matrix({ matrix[0] + ',' + '0' + ',' + matrix[2] + ',' + '-6.25' + ',' + matrix[4] + ',' + '50'})">
      <path class="path-vario { $multiple ? 'hide' : '' }" d="{ toPathFill(path.v) }" />
    </g>
    <g visibility="{ visible && $speed ? 'visible' : 'hidden' }"
      transform="matrix({ matrix[0] + ',' + '0' + ',' + matrix[2] + ',' + '-1' + ',' + matrix[4] + ',' + '100'})">
      <path class="path-speed { $multiple ? 'hide' : '' }" d="{ toPathFill(path.s) }" />
    </g>
    {/each}
  </g>

  <!-- turnpoints -->
  <g>
    {#each flightsBounds.filter(flightsTurnpoints).map(turnpointsIterator) as {id, items, minY, maxY, matrix, routeType}}
      <g transform="matrix({ matrix.join(',') })">
        {#each items as pointX}
          <line class="tp-{routeType}" x1="{pointX}" y1="{minY}" x2="{pointX}" y2="{maxY}" />
        {/each}
      </g>
    {/each}
  <g>

  <!-- timescale -->
  <g>
    <!-- notches -->
    <g width="100%">
      {#each TS_NOTCH_STEPS as [step, height]}
        <rect id="notch-{step}" x="0" y="{$canvas.h-height}" width="100%" height="{height}" fill="url(#ts-{step})"
          visibility="{step/$transform.scaleX < $minPixelStep ? 'hidden' : 'visible'}" />
      {/each}
    </g>
    <!-- labels -->
    <g width="100%">
      {#each TS_LABEL_STEPS.filter(xVisibleSteps).map(xLabelIterator) as step}
        {#each [...step.items()] as i}
          {#if Array.isArray(i.dim)}
            <path d="M {i.px} {$canvas.h - 15} l-10 {15 - $canvas.h} h20 z" fill="url(#gap)" />
            <path d="M {i.px - 10} {$canvas.h} h20 l-10 -10 z" fill="url(#gap)" />
            <text class="ts-tm-{step.step}" x="{i.px - 29}" y="{$canvas.h - 10}">
              {xToDate(i.dim[0], $utcOffsetTS).strftime('%R')} &#8904; {xToDate(i.dim[1], $utcOffsetTS).strftime('%R')}
            </text>
          {:else}
            <text class="ts-tm-{step.step}" x="{i.px + 2}" y="{$canvas.h - 7}">{xToDate(i.dim, $utcOffsetTS).strftime('%R')}</text>
          {/if}
        {/each}
      {/each}
    </g>
  </g>
  <!-- levels -->
  <g width="100%" height="100%">
    {#each [100, 200, 500, 1000].filter(yVisibleSteps).map(yLabelIterator) as step}
      {#each [...step.items()] as i}
        <line class="lvl-line-{step.step}" x1="0" y1="{$canvas.h - i.px}" x2="100%" y2="{$canvas.h - i.px}" />
        {#if i.px > 20}
          <text class="lvl-label-{step.step}" x="2" y="{$canvas.h - i.px}" dy="8">{i.dim} m</text>
        {/if}
      {/each}
    {/each}
  </g>
  <!-- frame -->
  <rect width="100%" height="100%" stroke="black" stroke-width="1" fill="transparent" />
</svg>

<!-- specialni prekryvna vrstva - musi byt samostatne kvuli rychlosti (will-change) -->
<svg class="overlayer" bind:this={overlayer} version="1.2" width="100%" height="100%" style="width: {$canvas.w}px" xmlns="http://www.w3.org/2000/svg">
  <!-- currentX line -->
  <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="0.6" stroke-dasharray="5 3" transform="translate({$gX}, 1)" style="will-change: transform"  />
  <!-- overshadow pro play mode -->
  {#if $playMode}
    <rect width="100%" height="100%" fill="hsla(0, 0%, 0%, 0.5)" transform="translate({$gX}, 1) scale({($canvas.w - $gX) / $canvas.w}, 1)" style="will-change: transform" />
  {/if}
	<!-- current play time line -->
  {#if $playMode && $defaultPlayTX}
      <line x1="0" y1="0" x2="0" y2="100%" stroke="lightgreen" stroke-width="0.6" stroke-dasharray="5 3" transform="translate({tX2gX($defaultPlayTX).gX}, 1)" style="will-change: transform"  />
  {/if}
  <!-- pruhledna jednoducha vrstva pro chytani udalosti-->
  <rect width="100%" height="100%" fill="transparent"
    on:click|preventDefault="{graphClick}"

    on:mousemove="{graphMouseMove}"
    on:touchmove|passive="{graphTouchMove}"

    on:wheel|passive="{graphMouseWheel}"
    on:contextmenu|preventDefault="{nic}"

    on:mousedown="{graphMouseDown}"
    on:mouseup="{graphMouseUp}"
    on:mouseleave="{graphMouseLeave}"
  />
</svg>

<ControlPanel uniqid="graphSettings" {controls} openOnclickOnly="true" />
</div>


<style>
:global(body) {
	overscroll-behavior-x: none;
}
div.graph {
  position: absolute;
  bottom: 18px;
  left: 0px;
  width: 100%;
  height: 100px;
  border: 0 none;
  background-color: transparent;
  font-size: 10px;
  line-height: 18px;
  overflow-y: hidden;
  /*z-index: 4;*/
}
div.graph :global(*) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
svg.overlayer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
