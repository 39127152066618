<script>
	import { beforeUpdate, createEventDispatcher, setContext } from 'svelte';

	const dispatch = createEventDispatcher();

	import {ID_ROOT} from "./utils/const.js";

	import {Util} from './utils/util.js';
	import {XCUtil} from './utils/xcutil.js';
	import {PGUtil} from './utils/pgutil.js';
	import {MBUtil} from './utils/mbutil.js';

	import Map from './Map.flight-map.html';
	import Live, {live_getBoundsByGeolocation} from './Live.html';

	import {pgStore, viewStore, pmsStore} from "./utils/live-stores.js";

	setContext('pgStore', pgStore);
	setContext('viewStore', viewStore);
	setContext('pmsStore', pmsStore);

	XCUtil.setStores({pgStore});
	PGUtil.setStores({pgStore, viewStore, pmsStore});

	const {fullscreen, sat, hires, airspace, radar} = viewStore.getStores(['fullscreen', 'sat', 'hires', 'airspace', 'radar']);

	export let showPlay = true;
	export let showSettings = true;

	let controls = [];
	$: controls = [
		  {key: 'fullscreen', label: 'fullscreen', checked: $fullscreen},
		  {key: 'sat', label: 'sat map', checked: $sat},
			{key: 'radar', label: 'rain radar', checked: $radar}
	];

	pgStore.on('colors-change', ({flightColors}) => {
		dispatch('colors-change', flightColors);
	});

	export function setApi(apiType = 'api', apiSource = {league : "world"}, apiLang = null) {
		if (apiLang == null) {
			const dLang = document.documentElement.lang;
			apiLang = dLang ? dLang : pgStore.get('apiLang');
		};
		pgStore.set({apiType, apiSource, apiLang});
	}

	export function getBoundsByGeolocation () {
		return live_getBoundsByGeolocation();
	}


	export function initMap(bounds = {maxX: 18.8531441586, minX: 12.2401111182, maxY: 51.1172677679, minY: 48.5553052842}/*CZ*/) {
		return PGUtil.initMap(bounds, {
			fullscreen: true,
			background: true,
			airspace: true,
			geolocation: true,
			sniffer: true,
			fullstyle: true,
			graph: true,
		});
	}

	export function addFlight(id) {
		pgStore.addFlight(id);
	}

	export function removeFlight(id) {
		pgStore.removeFlight(id);
	}

	export function hasFlight(id) {
		return pgStore.hasFlight(id);
	}

	export function getColor(id, alpha = 1) {
		const ownerId = pgStore.get('ownersByFlights')[id];
		const colorId = pgStore.get('flightColors')[ownerId];
		return colorId ? Util.getHslaColor(colorId, alpha) : null;
	}

	export function adjustToFlightBounds() {
		PGUtil.adjustToFlightBounds();
	}

	export function drawTask (code) {
		PGUtil.drawTask(code, ID_ROOT.pos+':actual').then(map => {
			const base_id = XCUtil.getTaskId(code);
			MBUtil.changeVisibility(map, [], [base_id+'-fill', base_id+'-line', base_id+'-symbol']);
		});
	}
</script>

<Map {showPlay} {showSettings} {controls} />
<Live />
