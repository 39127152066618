<script>
import { getContext } from 'svelte';
import { flip } from 'svelte/animate';
import { fade } from 'svelte/transition';
import {isoToTime, formDur, preRoute, hasPreRoute} from './utils/helpers.js';

import {Util} from "./utils/util.js";

import LiveListPilot from './LiveListPilot.html';
import LiveListTakeoff from './LiveListTakeoff.html';
import LiveListTaskType from './LiveListTaskType.html';
import LiveListXcDist from './LiveListXcDist.html';
import LiveListAirtime from './LiveListAirtime.html';
import LiveListWind from './LiveListWind.html';
import LiveListActivity from './LiveListActivity.html';
import LiveListStatus from './LiveListStatus.html';

import {GOOGLE_DIR_URL} from './utils/const.js';

//pgStore importujeme z live-stores jako fallback
//pokud je komponenta iniciovana samostatne, pro positions-popup do mapy (viz live.js), getContext() nic nevraci
import {pgStore as pgStoreLive, viewStore as viewStoreLive} from "./utils/live-stores.js";
const pgStore = getContext('pgStore') || pgStoreLive;
const viewStore = getContext('viewStore') || viewStoreLive;

const {
  map,
	contest,
	liveInfoDisplayByPilot,
	uuidsFollowed
} = pgStore.getStores([
  'map',
	'contest',
	'liveInfoDisplayByPilot',
	'uuidsFollowed',
]);

const isPortrait = viewStore.getStores('isPortrait');

//$: console.log("isPortraitChanged", $isPortrait);

//component prop
//if true, vypisuje pouze jmeno pilota (bez detailu)
export let mode = 'list';//list|popup|detail

//Live lib knihovna
export let Live = null;

export let CPopened = false;

//component prop
//pouziva se pro PopupList a PopupDetail
//null | [uuid0, uuid1,...]
//pokud je null, nic se nefiltruje
//pokud je to pole, lety se filtruji jen na dane uuids
export let filterUuids = null;

export const toFlightsByPilot = (infoByPilot, uuids) => {
  return new Map(
    [...infoByPilot].map(([pilotId, flights]) => {
      const filterFlights = new Map([...flights]
        .filter(([uuid, flight]) => uuids ? uuids.includes(uuid) : true)
        //.map(([uuid, flight]) => [uuid, Util.fresh(flight)])
      );
      const info = Util.fresh([...filterFlights.values()][0]);
      return [pilotId, [filterFlights, info]];
    }).filter(([pilotId, [flights]]) => flights.size > 0)
  );
}

let flightsByPilot = toFlightsByPilot($liveInfoDisplayByPilot, filterUuids);
$ : flightsByPilot = toFlightsByPilot($liveInfoDisplayByPilot, filterUuids);

//bind:group bohuzel nefungovalo spravne, tak musime takto
function changeUuids (evt) {
  const uuid = evt.target.value;
  const uuids = evt.target.checked ? Util.arrUniq([...$uuidsFollowed, uuid]) : Live.autoUnfollow($uuidsFollowed.filter(uuidItem => uuidItem !== uuid));
  pgStore.set({uuidsFollowed: uuids});
};

function centerOnPilot (evt, pilotId, uuid = null) {
//console.log('centerOnPilot', pilotId, uuid)

  const flights = $liveInfoDisplayByPilot.get(pilotId);
  const info = uuid ? flights.get(uuid) : flights.values().next().value;
  const lastFix = info.lastFix;
  const center = [lastFix[0], lastFix[1]];

  //pri centrovani na pilota zavirame pilots list, ale jen v portrait rezimu obrazovky
  if (mode == 'list' && CPopened && $isPortrait) {
    $map.once('moveend', (mapevt) => {
      CPopened = false;
      Util.dialogClosed('list');
    });
  };

  $map.easeTo({center});
};

function openPopupDetail (evt, pilotId, uuid) {
  if (mode == 'list') {
    CPopened = false;
    Util.dialogClosed('list');
  };

  $map.once('moveend', (mapevt) => {
    Live.openPopupDetail(uuid);
  });
  if (!$map.isMoving()) {
    centerOnPilot(evt, pilotId, uuid);
  };
};

function pilotClick (evt, pilotId, uuid) {
  if (mode == 'list') { //hlavni flights list
    if (evt.detail == 1) {//pri prvnim kliku vycentrovani na let
      centerOnPilot(evt, pilotId);
    } else if (evt.detail == 2) {//pri dblclicku detail letu
      openPopupDetail(evt, pilotId, uuid);
    };
  } else if (mode == 'popup' && evt.detail == 1) {//v popup menu detail letu rovnou na klik
    openPopupDetail(evt, pilotId, uuid);
  };
};

const getClientLabel = (clientId) => {
  if (clientId === '') clientId = 'flyskyhy';//FSH neposila clientID, takze predpokladame, ze prazdny string je FSH
  const c = {
    xctrack : 'XCTrack', 
    flyskyhy : 'FlySkyHy',
  };
  return c[clientId] ? c[clientId] : '? (' + clientId + ')';
};
</script>

<div
  class="CParea checkbox-pill live-list {mode}"
  out:fade={{delay: 0, duration: 100}}
  on:contextmenu|preventDefault>
  {#if flightsByPilot}
    <ul>
      {#each [...flightsByPilot] as [pilotId, [flights, info]] (pilotId)}
        <li class="pilot-item {info && info.colorIndex !== null  ? 'c'+ info.colorIndex  : ''}"
          on:click={evt => pilotClick(evt, pilotId, flights.keys().next().value)}
          on:contextmenu|preventDefault={evt => openPopupDetail(evt, pilotId, flights.keys().next().value)}
          >
          <!-- animate:flip={{duration: 100}} -->
          <LiveListPilot info={info} mode={mode} isPortrait={$isPortrait} />

          <ul>
          {#each [...flights] as [uuid, info] (uuid)}
            <li>
              <div class="primary">
                {#if ['list', 'detail'].includes(mode) }
                  <LiveListTakeoff info={info} />
                  {#each [info.actual.contest[$contest]] as contest}
                    <LiveListTaskType contest={contest} />
                    <LiveListXcDist contest={contest} />
                  {/each}
                  <LiveListAirtime info={info} />
                {/if}

                {#if mode == 'popup'}
                  {#if info.status != 'landed' && info.actual.addInfo.windFromMs[1] > 0}
                    <LiveListWind info={info} />
                  {/if}
                  {#each [info.actual.contest[$contest]] as contest}
                    <LiveListTaskType contest={contest} />
                    <LiveListXcDist contest={contest} />
                  {/each}
                {/if}

                <LiveListStatus info={info} />

                <label class='checkbox-container' class:checked={$uuidsFollowed.includes(uuid)} on:click|stopPropagation>
                  <input type='checkbox' on:change="{changeUuids}" checked={$uuidsFollowed.includes(uuid)} value="{uuid}" />
                  <div class='checkbox mr6 w24 h24'>
                    <svg class='icon w24 h24'><use xlink:href='#icon-check' /></svg>
                  </div>
                </label>

              </div>

              {#if mode == 'detail'}
              {#each [info.actual.contest[$contest]] as contest}
                <div class="secondary">
                  <div class="additional">
                    {#if hasPreRoute(contest)}<div class="unfinished">unfinished: <b>{preRoute(contest)}</b> km</div>{/if}
                    {#if contest.avgSpeed}<div class="avg">avg. speed: <b>{contest.avgSpeed.toFixed(1)}</b> km/h</div>{/if}
                    <div class="from-to"><svg class='icon w18 h18 inline-flex'><use xlink:href='#icon-arrive' /></svg>from TO: <b>{Util.distFromTo(info.lastFix, info.static.takeoff.point)}</b> km</div>
                    <div class="track-length">track length: <b>{info.actual.absDistance.toFixed(1)}</b> km</div>
                  </div>
                  <div class="actions flex flex--column flex--end-main">
                    <div class="glider">{info.static.glider}</div>
                    <div class="client">client: <b>{ getClientLabel(info.static.clientId) }</b></div>
                    <div class="button">
                      <svg class='icon w24 h24'><use xlink:href="#icon-marker" /></svg>
                      <a href="{GOOGLE_DIR_URL + 'destination=' + info.lastFix[1] + ',' + info.lastFix[0] }" target="_blank">
                        <b>Google Maps direction</b>
                      </a>
                    </div>
                  </div>
                </div>
              {/each}
              {/if}
            </li>
          {/each}
        </ul></li>
      {/each}
    </ul>
<!--<ul>{#each Array(50) as n}<li>TEST balast</li>{/each}</ul>-->
  {/if}
</div>

<style>
.live-list {
	color: #346B8F;
}

.live-list.popup {
  margin: -5px -5px -10px -5px;
  overflow-y: auto;
  max-height: 225px;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	display: block;
	padding: 0;
	margin: 0;
	text-align: right;
}

li.pilot-item {
	border: 0.5px dotted hsla(0, 0%, 100%, 1);
	border-radius: 2px;
  padding-bottom: 5px;

}

.live-list.popup li.pilot-item {
  padding-top: 5px;
}

li.pilot-item ul li {
  white-space: nowrap;
}

.live-list.list li.pilot-item:hover {
  background-color: hsla(0, 0%, 90%, 0.9);
}
.live-list.list li.pilot-item {
  cursor: pointer;
}

:global(.live-list li:not(.pilot-item) > span) {
  display: inline-block;
	position: relative;
	top: 5px;
}

div.CParea {
  padding: 5px;
}
div.CParea label {
  cursor: pointer;
  display: inline-block;
  padding: 0 0 0 5px;
}
/*
div.CParea label:before {
  margin-top: 5px;
}
label.checked:before {
  background-color: hsla(0, 0%, 40%, 1);
  color: lightgreen;
}

label:hover {
  background-color: hsla(0, 0%, 90%, 0.9);
}
*/

.primary {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.secondary {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.additional div {
  padding: 5px;
}

.additional div.from-to {
  margin-top: -8px;
}


.from-to svg.icon {
  top: 5px;
  position: relative;
  transform:rotate(270deg);
}

.actions {
  padding: 5px 5px 0 5px;
}

.glider {
  padding: 0 5px 10px 0;
  font-weight: bold;
}

.client {
  padding: 0 5px 10px 0;
}


.button {
  border-radius: 3px;
  border: 1px solid lightgrey;
  padding: 5px;
  background-color: hsla(150, 50%, 95%, 1);
  display: flex;
  align-items: center;
}

.button a {
  color: inherit;
}

.button a:hover {
  text-decoration: underline;
}

label div.checkbox {
  background-color: hsla(0, 0%, 0%, 0.25);
  margin-bottom: 3px;
}

label.checked div.checkbox {
  background-color: hsla(0, 0%, 40%, 1);
  color: lightgreen;
}

.c0 label.checked div {color:var(--ci0);background-color:var(--c0)}
.c1 label.checked div {color:var(--ci1);background-color:var(--c1)}
.c2 label.checked div {color:var(--ci2);background-color:var(--c2)}
.c3 label.checked div {color:var(--ci3);background-color:var(--c3)}
.c4 label.checked div {color:var(--ci4);background-color:var(--c4)}
.c5 label.checked div {color:var(--ci5);background-color:var(--c5)}
.c6 label.checked div {color:var(--ci6);background-color:var(--c6)}
.c7 label.checked div {color:var(--ci7);background-color:var(--c7)}
.c8 label.checked div {color:var(--ci8);background-color:var(--c8)}
.c9 label.checked div {color:var(--ci9);background-color:var(--c9)}
.c10 label.checked div {color:var(--ci10);background-color:var(--c10)}
.c11 label.checked div {color:var(--ci11);background-color:var(--c11)}
.c12 label.checked div {color:var(--ci12);background-color:var(--c12)}
.c13 label.checked div {color:var(--ci13);background-color:var(--c13)}
.c14 label.checked div {color:var(--ci14);background-color:var(--c14)}
.c15 label.checked div {color:var(--ci15);background-color:var(--c15)}
.c16 label.checked div {color:var(--ci16);background-color:var(--c16)}
.c17 label.checked div {color:var(--ci17);background-color:var(--c17)}
.c18 label.checked div {color:var(--ci18);background-color:var(--c18)}
.c19 label.checked div {color:var(--ci19);background-color:var(--c19)}

</style>
