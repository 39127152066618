const ID_ROOT = {
  'track' : 'xcontest:lay:tracklog-',
  'route' : 'xcontest:lay:route-',
  'marker': 'xcontest:lay:marker-',
  'pos'   : 'xcontest:lay:positions',
  'tracksrc' : 'xcontest:src:track-',
  'routesrc': 'xcontest:src:route-',
  'markersrc': 'xcontest:src:marker-',
  'possrc': 'xcontest:src:positions'
};
const FLIGHT_TYPES = {1: 'flight', 2: 'walk'};
const TRACK_DATA_TYPES = ['basic','hires'];//typy track dat
const TRACK_TYPES = ['basic','exact'];//typy tracku (layeru v mape)
const ROUTE_TYPES = ["DEFAULT","TRIANGLE","FREE_FLIGHT","FREE_DISTANCE"];
const BASIC_ROUTE_TYPES = ["DEFAULT","FREE_FLIGHT","FREE_DISTANCE"];//zakladny typy routes, mezi kterymi lze prepinat; podle nich se kresli i turpoints
const ROUTE_GROUPS = [["DEFAULT","TRIANGLE"]];//ktere typy jsou zobrazene pospolu
const MARKER_TYPES = ['startend','turnpoints'];
const PATH_TYPES = ['gps','baro','fill','ground'];//svg path ve vyskovem grafu
const TS_LABEL_STEPS =  [60, 300, 600, 900, 1800, 3600, 7200, 21600, 43200, 86400];//dostupne popisky casu (step v sec)
const TS_NOTCH_STEPS = [[60,1], [300,3], [1800,5], [3600,8], [43200,8,2], [86400,10,2]];//dostupne kroky dilku casu (step v sec)
const COLORS = {
  //default pro tracklog
  FLIGHT_LINE: 'hsla(30, 100%, 50%, 1)',//orange
  //default pro tracklog outline
  FLIGHT_OUTLINE: 'hsla(0, 0%, 0%, 0.6)',//black
  //walk outline
  WALK_OUTLINE: 'hsla(0, 0%, 0%, 0.1)',//black
  //bezna route
  ROUTE: 'hsla(0, 0%, 100%, 0.8)', //white
  //triangle route, co se zobrazuje, pokud default je free|fai triangle
  TRIANGLE: 'hsla(0, 100%, 50%, 1)', //red
  //markers start-end
  startend:  ['hsla(120, 100%, 50%, 0.75)', 'hsla(360, 100%, 50%, 0.75)'],
  //turnpoints markers
  turnpoints: 'hsla(0, 0%, 100%, 0.6)', //white
  //graph colors
  GRAPH : {
    gps:  'hsla(28, 100%, 50%, 1)', //orange
    fill: 'hsla(0, 0%, 86%, 1)', //light grey
    baro: 'hsla(200, 100%, 50%, 0.7)', //light blue
    ground: 'hsla(0, 0%, 66%, 1)', //grey
  }
};
/** zmenit pak take v settings.php v sekci prefetch!!! **/
const PATHS = {
  MAPBOX_JS: /*'https://unpkg.com/maplibre-gl@3.6.2/dist/maplibre-gl.js',*/ 'https://api.mapbox.com/mapbox-gl-js/v1.13.0/mapbox-gl.js',//'https://d393ilck4xazzy.cloudfront.net/mapbox/1.7b/mapbox-gl.js',
  MAPBOX_CSS: /*'https://unpkg.com/maplibre-gl@3.6.2/dist/maplibre-gl.css', */ 'https://api.mapbox.com/mapbox-gl-js/v1.13.0/mapbox-gl.css',//https://d393ilck4xazzy.cloudfront.net/mapbox/1.7b/mapbox-gl.css',
  ASSEMBLY_CSS: 'https://d393ilck4xazzy.cloudfront.net/mapbox/assembly/v1.5.1/assembly.min.css', //'https://api.mapbox.com/mapbox-assembly/v1.5.1/assembly.min.css',
  ASSEMBLY_JS: 'https://d393ilck4xazzy.cloudfront.net/mapbox/assembly/v1.5.1/assembly.js', //'https://api.mapbox.com/mapbox-assembly/v1.5.1/assembly.js',
  //WSS: "wss://live2.xcontest.org/websock/webclient",
  WSS: "wss://live2.test.xcontest.org/websock/webclient",
};
const SPEEDS = [1,2,5,10,20,50,100];
const GOOGLE_DIR_URL = 'https://www.google.com/maps/dir/?api=1&';

const MAP_OPTIONS = {
  container: null, //nastavuje se v Map.oncreate()
  attributionControl: false, //resime custom
  style: {
    "version": 8,
    "id": "xcontest:style",
    "name": "XContest style",
    "metadata": {},
    //glyphs tam musi byt kvuli tomu, aby se pri dalsim setStyle mapa neprekreslila cela
    "glyphs": "https://d393ilck4xazzy.cloudfront.net/fonts/{fontstack}/{range}.pbf",
    "sources": {
    },
    "layers": [
      {
        "id": "background_green",
        "type": "background",
        "paint" : {
          "background-color":  "hsla(108, 45%, 43%, 1)"
        }
      }
    ]
  },
  center: [15.5, 50.7],
  zoom: 9,
  fadeDuration: 0,
  crossSourceCollisions: false
};

export {
  ID_ROOT,
  FLIGHT_TYPES,
  TRACK_DATA_TYPES,
  TRACK_TYPES,
  ROUTE_TYPES,
  BASIC_ROUTE_TYPES,
  ROUTE_GROUPS,
  MARKER_TYPES,
  PATH_TYPES,
  TS_LABEL_STEPS,
  TS_NOTCH_STEPS,
  COLORS,
  PATHS,
  SPEEDS,
  GOOGLE_DIR_URL,
  MAP_OPTIONS
};
