<script>
export let info = null;
</script>

<style>
.wind {
  margin-right: auto;
  margin-left: 10px;
}
.wdir {
  display:inline-block;
  font-weight:bold;
  margin-right:3px;
}
</style>

<span class="wind" title="wind (last measurement)"><span class="wdir" style="transform:rotate({info.actual.addInfo.windFromMs[0].toFixed(0)}deg)">↓</span> {(info.actual.addInfo.windFromMs[1]*3.6).toFixed()} km/h</span>
