<script>
import {preRoute} from './utils/helpers.js';
export let contest = null;
const dist = contest.route ? contest.route.distance : (contest.score ? contest.score.distanceKm : 0);
</script>

<style>
.xcdist {
	padding-left: 2px;
	padding-right: 5px;
	font-weight: bold;
}
</style>

<div class="xcdist"
title="{preRoute(contest)}"
><b>{dist.toFixed(2)}</b> km</div>
