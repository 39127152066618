<svelte:body on:click={eventuallyClose} on:touchstart={eventuallyReconnect}/>

<script context="module">
	import {MBUtil} from './utils/mbutil.js';
	import {PGUtil} from './utils/pgutil.js';

	export function live_getBoundsByGeolocation () {

		//napred zkousime, jestli nemame v history.state bounds - jednalo by se o reload a tedy tyto bounds maji prednost
		const state = history.state;

		if (state && Array.isArray(state.bounds)) {
			const bounds = state.bounds.reduce((acc, coord, i) => {
				const mm = i==0 ? 'min' : 'max';
				acc[mm+'X'] = coord[0];
				acc[mm+'Y'] = coord[1];
				return acc;
			}, {});

			return Promise.resolve(bounds);
		};

		//pak hledame position z geolokace
		const PmsPosition = new Promise ((res, rej) => {
			if (!('geolocation' in navigator)) {
				res(null);
				return;
			};

			navigator.geolocation.getCurrentPosition(
				position => res(position),
				err => res(null),
				{
					maximumAge: Infinity,
					timeout: 10000,
					enableHighAccuracy : false
				}
			);
		});

		return PmsPosition
			.then(position => {
				const X = position.coords.longitude;
				const Y = position.coords.latitude;
				const XY = {X, Y};

				const bounds = ["X", "Y"].reduce((acc, dim) => {
					["max", "min"].forEach(edge => {
						const key = edge+dim;
						const value = MBUtil.normalize(XY[dim] + (edge=="max" ? 0.5 : -0.5), dim);
						acc[key] = value;
					});
					return acc;
				}, {});
				return bounds;
			})
			.catch(position => {
				return undefined;
			});
	};
</script>

<script>
import { onMount, onDestroy, getContext, setContext } from 'svelte';
import { flip } from 'svelte/animate';

import { getPrev } from './utils/store-ext.js';
import { get } from 'svelte/store';

import {PATHS} from "./utils/const.js";
import {Util} from "./utils/util.js";
import {XCUtil} from "./utils/xcutil.js";
import {Play} from './utils/play.js';
import {Live} from "./utils/live.js";
import {ExtDate} from './utils/extdate.js';
import {isoToTime, formDur, fromState, fromHash} from './utils/helpers.js';

const pgStore = getContext('pgStore');
const viewStore = getContext('viewStore');
const pmsStore = getContext('pmsStore');
//console.log(pmsStore);

//kontext kvuli child komponente LiveList
setContext('pgStore', pgStore);
setContext('viewStore', viewStore);

import LiveList from './LiveList.html';
import LiveFilter from './LiveFilter.html';
import OnlineStatus from './OnlineStatus.html';

const l = [];//listeners
const sids = [];//setInterval/setTimeout ids

const {
	contest,
	showUniq,
	liveInfoDisplay,
	liveInfoDisplayByPilot,
	uuidsFollowed,
	numActive,
	numDisplay,
	numLive,
	numFollow,
	ownersForceFollow,
	ownersLivelinkFollow,
	uuidsLastByOwner
} = pgStore.getStores([
	'contest',
	'showUniq',
	'liveInfoDisplay',
	'liveInfoDisplayByPilot',
	'uuidsFollowed',
	'numActive',
	'numDisplay',
	'numLive',
	'numFollow',
	'ownersForceFollow',
	'ownersLivelinkFollow',
	'uuidsLastByOwner'
]);

const baseSize = viewStore.getStores('baseSize');

const LiveFlightsLoaded = pmsStore.getPMS('LiveFlightsLoaded');

let CPnode = null;//container node celeho prvku
export let CPopened = false;


/**
 * pri popstate otvira/zavira FlightsList, podle toho, co je ve state
*/
const popstateListener = (ev) => {
//console.log('popstate Live', ev.state);
	const opened = fromState('dialogOpened', []);
	if (!opened['list']) {
		CPopened = false;
	};
	if (!opened['popup']) {
		Live.getPositionsPopup().remove();
	};
};

/**
 * pokud nalezne v hashi nove uuids, pridava je
*/
const livelinkRead = () => {
	const newUuids = Util.arrDiff(fromHash('u'), $ownersLivelinkFollow);
	if (newUuids.length == 0) return;

	ownersLivelinkFollow.update(uuids => Util.arrUniq([...uuids, ...newUuids]));
	ownersForceFollow.update(uuids => Util.arrUniq([...uuids, ...newUuids]));

	Live.checkOwnersFollow($uuidsLastByOwner);
};


/**
 * otvirani/zavirani FlightsListu
 * pri otevreni prida polozku do history pres pushState()
 * pri zavreni jde zpet v historii (aby se jednotlive otevreni nepridavaly do historie, ale vzdy se odebraly) a tim triggeruje popstate event
*/
function openClose () {
	 CPopened = !CPopened;
	 if (CPopened) {
		 Util.dialogOpened('list');
	 } else {
		 Util.dialogClosed('list');
	 };
};

/**
 * odchytava udalost click na body (viz <svelte:body>) a pokud klik neni uvnitr listu, zavira ho
*/

function eventuallyClose (evt) {
	const isClickInside = CPnode.contains(evt.target);
	if (!isClickInside && CPopened) {
		CPopened = false;
		Util.dialogClosed('list');
	};
};

/**
 * po kazdem touchstart se pokousi navazat mrtvy websocket, pokud nahodou umrel
*/
function eventuallyReconnect (evt) {
	//console.log('body.ontouchstart', evt);
	if (Live.Ws) Live.Ws.show();
};

//vola reconnect pro forwardovane touchstart eventy z ControlPanel komponent
viewStore.getStores('touchstart').subscribe(evt => eventuallyReconnect(evt));


//ladeni
//$: console.log("$uuidsFollowed",  $uuidsFollowed);
//$: console.log("$liveInfoDisplayByPilot", $liveInfoDisplayByPilot);

onMount(() => {

	Play.setStores({pgStore, viewStore, pmsStore});
	Live.setStores({pgStore, viewStore, pmsStore});

	//pridani letu - pouze LIVE
	l.push(pgStore.on('flight-add', ({id, live}) => {
		if (!live) return;
	}));

	l.push(viewStore.on('posopts-change', () => {
    Live.changePosTextField();
  }));

	l.push(pgStore.getStores('positions').subscribe($positions => {
		Live.updatePositions($positions);
	}));

/** temp/BGN **/
/*
const _tmpStores = ['tXplay', 'liveInfoDisplay', 'uuidsFollowed', 'flightColors', 'ownersByFlights', 'playMode'].forEach(ident => {
	pgStore.getStores(ident).subscribe(store => {
		console.log(ident, store);
	});
})
*/
//$tXplay, $liveInfoDisplay, $uuidsFollowed, $flightColors, $ownersByFlights, $playMode
/** temp/END **/

	l.push(viewStore.getStores('baseSize').subscribe($bS => {
		Live.changePositionsBaseSize($bS);
	}));

	l.push(pgStore.on('lastflights-change', ({current}) => {
		Live.autoFollow(current);
	}));

	l.push(pgStore.getStores('uuidsActive').subscribe($uuidsActive => {
		Live.orphanUnfollow($uuidsActive);
	}));

	l.push(pgStore.getStores('uuidsFollowed').subscribe($uuidsFollowed => {
		Live.autoUnfollow($uuidsFollowed);
	}));

	l.push(pgStore.getStores('ownersFollow').subscribe(ownersFollow => {
		Util.setHistoryState({ownersFollow});
	}));

	l.push(pgStore.getStores('filteredStatus').subscribe(filteredStatus => {
		Util.setHistoryState({filteredStatus});
	}));

	l.push(pgStore.getStores('filteredNations').subscribe(filteredNations => {
		Util.setHistoryState({filteredNations});
	}));

	l.push(pgStore.getStores('filteredSites').subscribe(filteredSites => {
		Util.setHistoryState({filteredSites});
	}));

	l.push(viewStore.getStores('radar').subscribe(radar => {
		Util.setHistoryState({radar});
		pmsStore.getPMS('StyleLoaded').then(map => {
			Live.updateRadar(map, radar);
    });
	}));

	l.push(viewStore.getStores('sat').subscribe(sat => {
		Util.setHistoryState({sat});
	}));

	l.push(viewStore.getStores('vario').subscribe(vario => {
		Util.setHistoryState({vario});
	}));

	l.push(viewStore.getStores('speed').subscribe(speed => {
		Util.setHistoryState({speed});
	}));

	l.push(viewStore.getStores('online').subscribe(online => {
		console.log('online status changed. Online = ' + online);
	}));

	//konecne establish
	Live.establish(Play);

	l.push(pgStore.getStores('uuidsLastByOwner').subscribe($uuidsLastByOwner => {
		Live.checkOwnersFollow($uuidsLastByOwner);
	}));

	//je tady pod establish(), aby bylo zaruceno, ze websocket je jiz established
	l.push(pgStore.on('display-change', ({current, previous}) => {
		//const stateUuids = current;
		Live.followChange({current, previous});
	}));

	//taky musi byt pod establish, aby byly pripraveny Popupy
	l.push(pgStore.on('liveinfo-loaded', ({current}) => {
		Live.livelinkCenter(current);
	}));

	window.addEventListener('popstate', popstateListener);

	//pridani usera  livelink URL
	window.addEventListener('hashchange', livelinkRead);

});

onDestroy(() => {
	//odstraneni listeneru
	l.forEach(unsubscribe => unsubscribe());
	sids.forEach(sid => clearInterval(sid));

	window.removeEventListener('popstate', popstateListener);
	window.removeEventListener('hashchange', livelinkRead);
});

</script>

<div
class="CP mapboxgl-ctrl"
class:open={CPopened}
bind:this="{CPnode}"
on:click|stopPropagation
on:mousemove|stopPropagation
on:touchstart|passive|stopPropagation={eventuallyReconnect}>
<div>
	{#await LiveFlightsLoaded}
		<div class="nobody flex flex--space-between-main flex--center-cross"><OnlineStatus />Connecting...</div>
	{:then}
		{#if $numActive == 0}
			<div class="nobody flex flex--space-between-main flex--center-cross"><OnlineStatus />Nobody LIVE</div>
		{:else}
			<div
				class="CPiconOOO flex flex--space-between-main flex--center-cross"
				on:click="{openClose}"
			><div class="flex flex--center-cross"><OnlineStatus />FOLLOW: {$numFollow} | LIVE: {$numLive}{#if $numLive < $numDisplay }/{$numDisplay}{/if} {#if $numDisplay < $numActive }({$numActive}){/if}</div>
			<svg class="icon w30 h30 color-gray { CPopened ? 'mr6' : 'mr3' } inline-flex"><use xlink:href="#icon-{ CPopened ? 'close' : 'caret-down' }" /></svg>
		</div>
		{/if}

		{#if CPopened}
			<LiveFilter Live={Live} />
			<LiveList Live={Live} bind:CPopened />
		{/if}
	{/await}
</div>
</div>

<style>
:global(:root) {
	--c0: hsla(36, 100%, 50%, 1); --ci0: hsla(0, 0%, 100%, 1);
	--c1: hsla(4, 90%, 58%, 1); --ci1: hsla(0, 0%, 100%, 1);
	--c2: hsla(291, 64%, 42%, 1); --ci2: hsla(0, 0%, 100%, 1);
	--c3: hsla(231, 48%, 48%, 1); --ci3: hsla(0, 0%, 100%, 1);
	--c4: hsla(199, 98%, 48%, 1); --ci4: hsla(0, 0%, 100%, 1);
	--c5: hsla(174, 100%, 29%, 1); --ci5: hsla(0, 0%, 100%, 1);
	--c6: hsla(88, 50%, 53%, 1); --ci6: hsla(0, 0%, 100%, 1);
	--c7: hsla(54, 100%, 62%, 1); --ci7: hsla(0, 0%, 0%, 1);
	--c8: hsla(14, 100%, 57%, 1); --ci8: hsla(0, 0%, 100%, 1);
	--c9: hsla(0, 0%, 62%, 1); --ci9: hsla(0, 0%, 100%, 1);
	--c10: hsla(340, 82%, 52%, 1); --ci10: hsla(0, 0%, 100%, 1);
	--c11: hsla(262, 52%, 47%, 1); --ci11: hsla(0, 0%, 100%, 1);
	--c12: hsla(207, 90%, 54%, 1); --ci12: hsla(0, 0%, 100%, 1);
	--c13: hsla(187, 100%, 42%, 1); --ci13: hsla(0, 0%, 100%, 1);
	--c14: hsla(122, 39%, 49%, 1); --ci14: hsla(0, 0%, 100%, 1);
	--c15: hsla(66, 70%, 54%, 1); --ci15: hsla(0, 0%, 0%, 1);
	--c16: hsla(45, 100%, 51%, 1); --ci16: hsla(0, 0%, 0%, 1);
	--c17: hsla(16, 25%, 38%, 1); --ci17: hsla(0, 0%, 100%, 1);
	--c18: hsla(200, 18%, 46%, 1); --ci18: hsla(0, 0%, 100%, 1);
	--c19: hsla(0, 0%, 100%, 1); --ci19: hsla(0, 0%, 0%, 1);
}

* {
	/*color: #333;*/
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

:global(.mapboxgl-ctrl button) {
	margin-bottom: 0;
}

.nobody {
	font-weight: bold;
	min-height: 30px;
	padding: 0 5px;
	user-select: none;
}

div.CP {
  position: absolute;
  border-radius: 2px;
  z-index: 3;
  top: 10px;
  left: 10px;
  background-color: hsla(0, 0%, 100%, 0.6);
  font-size: 11px;
  line-height: 11px;
	user-select: none;
	max-height: calc(100% - 40px);
	overflow: hidden;
}
div.CP.open {
  background-color: hsla(0, 0%, 100%, 1);
	min-width: 240px;

	display: flex;
	flex-direction: column;
}
div.CP.open > div {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}
div.CPiconOOO {
	cursor: pointer;
    text-align: right;
	min-height: 30px;
	font-weight: bold;
	padding-left: 5px;
}

div.CP.open div.CPiconOOO {
  position: sticky;
	top: 0;
	background: linear-gradient(180deg, hsla(0, 0%, 100%, 1) 0% 80%, hsla(0, 0%, 100%, 0) 100%);
	z-index: 1;
	margin-right: 2px;
}

div.CP.open div.CPiconOOO {
	color: hsla(0, 0%, 50%, 1);
}

:global(.positions-popup) {
	opacity: 0.95;
	font-size: 10px;
  line-height: 12px;
	user-select: none;
}
:global(.positions-popup:hover) {
	opacity: 1;
	z-index: 4;
}
</style>
