import {ExtDate} from './extdate.js';
/**
 * prevadi x dimenzi grafu (sec) na ExtTime object posunuty do prislusne timezony
 * @param x - x dimenze grafu (cas v sekundach dle timeMode)
 * @param utcOffset - UTC offset v hodinach (nejlepe $utcOffsetTS)
 * @returns ExtDate objekt, v pripade timeMode ABSOLUTE prevedeny do timezony 1. letu
*/
export const xToDate = (x, utcOffset) => ExtDate.toTimezone(new ExtDate(x*1000), utcOffset);
/**
 * prevadi cas v ISO formatu na timestamp (pocet ms)
*/
export const isoToTime = (isoTime) => new ExtDate().setIsoTime(isoTime).getTime();

export const round = (val, digits = 0) => val.toFixed(digits);

export const formDur = ms => {
  const format = ms < 3600 * 1000 ? "%i min" : "%k:%M h";
  return ExtDate.toTimezone(new ExtDate(ms), 0).strftime(format).trim();
};

export const hasPreRoute = contest => contest.preRoute && contest.preRoute.distance > 0;

export const preRoute = contest => {
  const pre = contest.preRoute;
  return pre && pre.distance > 0 ? (pre.type.tag == 'FaiIncomplete' ? '△ ' : '▻ ') + pre.distance.toFixed(1) + ' km' : '';
};

/**
 * pomucka pro scitani vyskytu
 * zjisti jestli v mape je prvek s danym key - pokud ne, zalozi ho
 * pricita 1 k danemu key
 * pokud uz dana kombinace pilotId a key byla pouzita (viz mapUsed), ignoruje se
*/
export const addCntMap = (mapO, key, label, mapUsed, pilotId) => {
  if (mapUsed.get(pilotId + key)) return;

  mapUsed.set(pilotId + key, true);
  const obj = mapO.get(key) || {num: 0, label};
  obj.num++;
  mapO.set(key, obj);
};

/** pomucka pro filtrovani pilotu **/
export const siteProps = info => {
  const cntr = info.static.country;
  const toff = info.static.takeoff;
  const siteKey = cntr.iso + '.' + toff.name;//toff.name == '?' ? '?.'+ cntr.iso : toff.name;
  const siteLabel = toff.name + '/' + cntr.iso + '';
  return {siteKey, siteLabel};
};

export const sortPropMap = mapO => {
  return new Map(Array.from(mapO).sort(([key0], [key1]) => key0.localeCompare(key1)));
};

export const fromState = (key, def) => {
  const hS = history.state;
  return hS && hS[key] ? hS[key] : def;
};

//vytahne livelinky z URL hashe
export const fromHash = (key) => {
  const hash = window.location.hash;
	if (!hash) return [];

	const pcs = hash.substr(1).split(':');
  if (key == pcs[0].toLowerCase()) {
    return (pcs[1]+'').split('|');
  };

  return [];
};

export const isoToEmojiFlag = (iso) => {
  //spanelsky provincie neprevadime
  if (['CT','PV'].includes(iso)) return iso;

  const symbolmap = new Map([
    ['A', '🇦'],
    ['B', '🇧'],
    ['C', '🇨'],
    ['D', '🇩'],
    ['E', '🇪'],
    ['F', '🇫'],
    ['G', '🇬'],
    ['H', '🇭'],
    ['I', '🇮'],
    ['J', '🇯'],
    ['K', '🇰'],
    ['L', '🇱'],
    ['M', '🇲'],
    ['N', '🇳'],
    ['O', '🇴'],
    ['P', '🇵'],
    ['Q', '🇶'],
    ['R', '🇷'],
    ['S', '🇸'],
    ['T', '🇹'],
    ['U', '🇺'],
    ['V', '🇻'],
    ['W', '🇼'],
    ['X', '🇽'],
    ['Y', '🇾'],
    ['Z', '🇿'],
  ]);
  return Array.from(iso).map(a => symbolmap.get(a)).join('');
};
