import { getPrev } from './store-ext.js';
import { get } from 'svelte/store';

import {
  PATHS,
  ID_ROOT
} from "./const.js";
import {Util} from "./util.js";
import {EncPoly} from "./encpoly.js";
import {XCUtil} from "./xcutil.js";
import {MBUtil} from "./mbutil.js";
import { PGUtil, showFlight } from "./pgutil.js";
import {ExtDate} from "./extdate.js";
import {isoToTime} from './helpers.js';

import {d} from './c.js';

//svelte komponenta LiveList
import LiveList from '../LiveList.html';


const warn = (o1,o2, trace = false) => {
  console.log(o1,o2);
  if (trace) console.trace();
};

const WS = class WS  {

  constructor (url) {
    this.url = url;
    this.Ws = null;
    this.hideTimeout = 60 * 1000; //jak dlouho po skryti stranky do pozadi ceka, nez odpoji websocket
    this.waitTimeout = 2 * 60 * 1000; //jak dlouho ceka nez prijde nova dalsi zprava od posledni zpravy, nez se pokusi restartovat websocket
    this.retryTimeout =  5 * 1000;
    this._hideTimeoutId = null;
    this._waitTimeoutId = null;
    this._retryTimeoutId = null;
    this._sendQueue = [];
    this._opn = [];
    this._cls = [];
    this._subscribe = {};
    this._listeners = {
      'opn' : e => this._onOpen(e),
      'msg' : e => this._onMsg(e),
      'err' : e => this._onErr(e),
      'cls' : e => this._onClose(e)
    };
    this._initMsg = true; //indikator, ze cekame na 1. zpravu (challenge) - pak se zmeni na false; pri otevreni ws se zas resetuje na true
  }

  /**
   * otevira WebSocket
   * @returns Promise
  */
  open () {
//warn('open() call')
    return new Promise((res, rej) => {
      try {
        this._initMsg = true;
        this.Ws = new WebSocket(this.url);
      } catch (e) {
        rej(e);
      };

      const l = this._listeners;

      //eventy k websocketu
      this.Ws.addEventListener('open', l.opn);
      this.Ws.addEventListener('message', l.msg);
      this.Ws.addEventListener('error', l.err);
      this.Ws.addEventListener('close', l.cls);

      res();
    });
  }

  /**
   * zavira WebSocket a vyprazdni message queue
  */
  close () {
    this._sendQueue = [];
    if (!this.Ws) return;
    this.Ws.close();
    this.Ws = null;
  }

  /**
   * vola se pote co se stranka dostane do popredi
   * pokud bezi hide timeout, ten se zrusi
   * pokud bezi retry timeout, nedela se nic
   * pokud websocket neexistuje nebo je zavreny, vola se open()
  */
  show () {
    this._clear('_waitTimeoutId');
    this._clear('_hideTimeoutId');

    if (this._retryTimeoutId) return;

    if (!this.Ws || this.Ws.readyState === WebSocket.CLOSED) {
      this.open();
    };
  }

  /**
   * vola se pote co se stranka dostane do pozadi
   * zrusi vsechny timeouty
   * spusti 60s hide timeout
  */
  hide () {
    this._clear('_retryTimeoutId');
    this._clear('_waitTimeoutId');
    this._clear('_hideTimeoutId');
    this._hideTimeoutId = setTimeout(() => {
      this._clear('_hideTimeoutId');
      this.close();
    }, this.hideTimeout);
  }

  /**
   * ukonci ws na hodinu
  */
  terminate () {
    //nastavi retry timeout na hodinu
    this.retryTimeout =  60 * 60 * 1000;
    this._clear('_retryTimeoutId');
    this._clear('_waitTimeoutId');
    this._clear('_hideTimeoutId');
    this.close();
  }

  /**
   * registruje funkci, ktera se zavola na udalost onopen, jeste pred tim, nez jsou poslany message z fronty
  */
  onopn (func) {
    this._opn.push(func);
  }

  /**
   * registruje funkci, ktera se zavola na udalost onclose
  */
  oncls (func) {
    this._cls.push(func);
  }

  /**
   * registruje funkci, ktera se spusti pri prijeti zpravy s danym tagem
  */
  onmsg (tag, func) {
    if (!this._subscribe[tag]) this._subscribe[tag] = [];
    this._subscribe[tag].push(func);
  }

  /**
   * odeslani zpravy
   * pokud se zavola a websocket neni OPEN, ulozi se do fronty
   * @param message string - zprava k odeslani, v pripade JSON musi byt stringify()-ovana
  */
  send (message = '') {
    if (this.Ws === null || this.Ws.readyState !== 1) {
      this._sendQueue.push(message);
    } else {
      this.Ws.send(message);
    }
  }

  /** === private metody === **/
  /**
   * odesle vsechny zpravy z fronty
  */
  _flush () {
    while (this._sendQueue.length > 0)
      this.send(this._sendQueue.shift());
  }

  /**
   * zrusi timeout a vymaze jeho id z promenne tohoto objektu
   * @param propname '_hideTimeoutId'|'_retryTimeoutId'
  */
  _clear (propname = '') {
    clearTimeout(this[propname]);
    this[propname] = null;
  }

  /**
   * testuje jestli ma byt WebSocket otevren, tj. dokument neni v pozadi a nebezi retry timeout
  */
  _shouldOpen () {
    const shouldOpen = !document.hidden && !this._showTimeoutId;
    warn('WS: shouldOpen', shouldOpen, document.hidden, this._showTimeoutId);
    return shouldOpen;
  }

  _openDelayed () {
    warn('WS: openDelayed');
    this._retryTimeoutId = setTimeout(() => {
      this._clear('_retryTimeoutId');
      this.open();
    }, this.retryTimeout);
  }

  _onOpen (e) {
    warn("WS: open", e);

    this._opn.forEach(func => func());

    this._flush();
  }

  _onMsg (e) {
    let tag, data;

    this._clear('_waitTimeoutId');
    this._waitTimeoutId = setTimeout(() => {
      this._clear('_waitTimeoutId');
      warn("WS: waiting for new message too long, restarting websocket...");
      this.close();
      this.open();
    }, this.waitTimeout);

    try {
      if (this._initMsg) {
        data = e.data;
        tag = 'challenge';
        this._initMsg = false;
      } else {
        data = JSON.parse(e.data);
        tag = data.tag;
      };

      if (!this._subscribe[tag]) {
        warn("WS: no subscriber, message thrown; tag '"+tag+"'", e);
        return;
      };

    } catch (err) {
      //pokud narazime na chybu (nespis parsing - muzou byt zmrsena data), tak restartujeme websocket
      warn("WS: unable to parse incoming message, restarting websocket...", err);

      this.close();
      this.open();
      return;
    };

    //running subscribe funkci
    this._subscribe[tag].forEach(func => func(data));
  }

  _onClose (e) {
    warn("WS: close", e);
    if (this._shouldOpen()) this._openDelayed();
  }

  _onErr (e) {
    warn('WS: error', e);
  }
};

const Live = {
  Ws : null,

  pgStore : null,
  viewStore : null,
  pmsStore : null,

  iconsSet : [
    ['live', 'hsl(0, 0%, 10%)', 'hsl(120, 100%, 50%)', 1, "&#8226;", {textLength:5, x:5.5, y:12, lengthAdjust: 'spacing'}],
    ['landed', 'hsl(0, 0%, 30%)', 'hsl(190, 100%, 50%)', 1, "&#10003;"],
    ['lost', 'hsl(0, 0%, 30%)', 'hsl(0, 100%, 75%)', 1, "?", {textLength:7, x:4, y:11.5}]
  ],

  /**
   * type = 'individual'|'clustered'
  */
  getPosLayerDef (type = 'individual') {
    switch (type) {
      case 'individual':
        return {
          "filter": ["!", ['has', 'point_count']],
          "paint": {
            "text-color": ["case",
              ["has", "cText"], ["get", "cText"],
              ['==', ["get", "status"], 'landed'],'hsl(190, 100%, 75%)',
              ['==', ["get", "status"], 'lost'], 'hsl(0, 100%, 80%)',
              'hsl(100, 100%, 50%)'
            ],
            "text-halo-color": ["case",
              ["has", "cHalo"], ["get", "cHalo"],
              ['==', ["get", "status"], 'landed'],'hsl(0, 0%, 10%)',
              ['==', ["get", "status"], 'lost'], 'hsl(0, 0%, 20%)',
              'hsl(0, 0%, 10%)'
            ],
            "text-halo-width": 1,
            "text-halo-blur": 0,
          },
          "layout": {
            "icon-image": ["concat", "icon-dot-", ["get", "status"],
              ["case",
                ["has", "cIcon"], ["concat", "-", ["get", "cIcon"]],
                ""
              ]
            ],
            "text-field": this.viewStore.get('actTextField')
          }
        };
        break;

      case 'clustered':
        return {
          "paint": {
            "text-color": 'hsl(50, 100%, 50%)',
            "text-halo-color": 'hsl(0, 0%, 10%)',
            "text-halo-width": 1,
            "text-halo-blur": 1.5,
          },
          "layout": {
            "symbol-sort-key": 0,
            "icon-image": ["concat", "icon-dot-cluster-", ["get", "point_count"], "-", ["get", "countLive"], "-", ["get", "countLost"], "-", ["get", "countLanded"]],
            "text-field": '', //["concat", "▲", ["get", "maxAlt"], " m", "\n", "▼", ["get", "minAlt"], " m"],
            "text-line-height": 0.9,
            "text-anchor": "left",
            "text-offset": [0.9, 0]
          },
          "filter": ['has', 'point_count'],
        };
        break;
    };
  },

  /**
   * type = 'followed'|'clustered'
  */
  getPosSourceDef (type = 'followed') {
    switch (type) {
      case 'followed':
        return {
          filter : ["has", "cText"],
        };
        break;

      case 'clustered':
        return {
          cluster : true,
          clusterMaxZoom : 12,
          clusterMinPoints : 2,
          clusterRadius : 25,
          clusterProperties : {
            'minAlt' : ["min", ["get", "a"]],
            'maxAlt' : ["max", ["get", "a"]],
            'countLive' : ["+", ["case", ["==", ["get", "status"], "live"], 1, 0]],
            'countLost' : ["+", ["case", ["==", ["get", "status"], "lost"], 1, 0]],
            'countLanded' : ["+", ["case", ["==", ["get", "status"], "landed"], 1, 0]],
          },
          filter : ["!",["has", "cText"]],
        };
        break;
    };
  },

  /** volat uplne na zacatku **/
  setStores ({pgStore, viewStore, pmsStore}) {
    this.pgStore = pgStore;
    this.viewStore = viewStore;
    this.pmsStore = pmsStore;

    XCUtil.setStores({pgStore});
  },



  /**
   * sestavi spojeni se livetrack serverem a osetri vsechny udalosti
   * posle uvodni zpravy WebFilterArea a WebFilterContest
   * nastavi listenery na prichozi zpravy LiveFlightInfos a LiveStaticInfos
   * osetri visibilitychange stranky
   * @param Play - Play objekt (play.js)
  */
  establish (Play) {
/*
    const stl = Util.getColorCodes().reduce((acc, code, index) => {
  		const rgb = Util.hex2rgb(code);
  		const hsla = Util.createHslaColor(rgb);
  		const rgbInv = Util.invert(rgb, true);
  		const hslaInv = Util.createHslaColor(rgbInv);
  		acc.root += "--c" + index + ": "+hsla+"; --ci"+ index + ": " + hslaInv + ";\n";
      acc.label += ".c" + index + " label.checked:before {color:var(--ci"+index+");background-color:var(--c"+index+")}\n";
      acc.li += "li.pilot-item.c" + index + " {border-color:var(--c"+index+")}\n";
  		return acc;
  	}, {
      root : ":root {\n",
      label : "",
      li : "",
    });
  	console.log("style", stl.root + "}", stl.label, stl.li)
*/

    //na move hodit do history current bouding box
    this.pmsStore.getPMS('MapLoaded').then(map => {
      map.on('moveend', evt => {
        const bounds = map.getBounds().toArray();
        Util.setHistoryState({bounds});
      });
    });

    //layers pro actual positions
    const PMSactPosLayer = Promise.all([
      PGUtil.showPositionsLayer(
        this._posLayerId(),
        this._posSourceId(),
        this.getPosLayerDef('individual'),
        this.getPosSourceDef('clustered'),
        () => this.loadIcons('basic')
      ),
      PGUtil.showPositionsLayer(
        this._posLayerId('clustered'),
        this._posSourceId(),
        this.getPosLayerDef('clustered'),
        this.getPosSourceDef('clustered'),
        () => this.loadIcons('clustered')
      ),
      PGUtil.showPositionsLayer(
        this._posLayerId('followed'),
        this._posSourceId('followed'),
        this.getPosLayerDef('individual'),
        this.getPosSourceDef('followed'),
        null
      ),
    ]).then(([
      [map, PosLayer],
      [, PosLayerClustered],
      [, PosLayerFollowed]
    ]) => [map, PosLayer, PosLayerClustered, PosLayerFollowed]);


/*
    //skryvani/odkryvani actPosLayer pri vstupu/vystupu do/z playMode
    this.viewStore.getStores('playMode').subscribe($playMode => {
      PMSactPosLayer.then(([map]) => {
        const hide = $playMode ? [this._posLayerId()] : [];
        const show = $playMode ? [] : [this._posLayerId()];
        MBUtil.changeVisibility(map, hide, show);
      });
    });
*/
//console.log('APL', PMSactPosLayer)
    this.pmsStore.sPMS('ActPosLayer', PMSactPosLayer);

    this.preparePositionsContextMenu();

    this.Ws = new WS(PATHS.WSS);

    this.Ws.onmsg("challenge", (data) => {
      this.onChallenge(data, () => {
        this.send(this.msgWebFilterArea());
        this.send(this.msgWebFilterContest());

        const uuidsDisplay = this.pgStore.get('uuidsDisplay');
        const currFollowed = this._followedOnly(uuidsDisplay);

        this._messageFollow(currFollowed, currFollowed);
      });
    });
/*
    this.Ws.onopn(() => {
      this.send(this.msgWebFilterArea());
      this.send(this.msgWebFilterContest());

      const uuidsDisplay = this.pgStore.get('uuidsDisplay');
      const currFollowed = this._followedOnly(uuidsDisplay);

      this._messageFollow(currFollowed, currFollowed);

    });
*/

    this.Ws.onmsg("LiveFlightInfos", (data) => {
      this.onLiveFlightInfos(data);
    });

    this.Ws.onmsg("LiveStaticInfos", (data) => {
      this.onLiveStaticInfos(data);
    });

    this.Ws.onmsg("LiveFlightChunk", (data) => {
      this.onLiveFlightChunk(data);
    });

    document.addEventListener("visibilitychange", () => {
      warn('document.visibilitychange - hidden:', document.hidden);
      if (document.hidden) {
        this.Ws.hide();
      } else {
        this.Ws.show();
      };
    });

    if (!document.hidden) this.Ws.show();
/*
    //spusteni play
    Promise.all([
      this.pmsStore.getPMS('GraphPrepared'),
      this.pmsStore.getPMS('StyleLoaded')
    ]).then(() => {
      this.viewStore.set({playOpened: true});
      Play.playPause();
    });
*/
  },

  /**
   * odeslani message pres WebSocket
   * @param data - data zpravy (cokoliv co se da JSON.stringify())
   * @param plain - pokud je true, posilaji se data tak jak prisla (napr. binary); defaultne se ocekava json, ktery se stringify()
  */
  send (data = {}, plain = false) {
    this.Ws.send(plain ? data : Util.stringify(data));
  },

  /**
   * sestavuje zpravu WebFilterContest
   * bere si nastavenou league/volume z api
  */
  msgWebFilterContest () {
    const src = this.pgStore.get('apiSource');
    return {
      tag: "WebFilterContest",
      contents: src.league + (src.volume || '')
    };
  },

  /**
   * sestavuje zpravu WebFilterArea
  */
  msgWebFilterArea () {
    return {
      tag: "WebFilterArea",
      area: null
      /*
      area: [
        {lat: -90, lon: -180},
        {lat:  90, lon:  180}
      ]
      */
    };
  },

  /**
   * sestavuje zpravu WebRequestInfo
   * @param uuids Array - pole uuids od kterych chceme static info
  */
  msgWebRequestInfo (uuids = []) {
    return {
      "tag": "WebRequestInfo",
      "contents": uuids
    };
  },

  /**
   * sestavuje zpravu WebFollow
   * @param follows Array [{uuid: uuid, ?start: null|ISO timestamp, ?force: false|true}, ...]
   * - povinne je jen uuid, ostatni se doplni na defaulty start: null, force: false
   * jak funguje webFollow:
   * - zacne followovat vsechny uuids, ktere se doposud nefollowuji
   *  - jako prvni vrati pozadovany flight chunk (od zacatku nebo od definovaneho timestampu - dle atributu start)
   *  - pak vraci update - nove flightChunks hned jak prijdou
   * - dalsi volani webFollow prepise tu predchozi tak, ze:
   *  - pridane flightUuids se zacnou followovat
   *  - odebrane flightUuids se prestanou followovat
   * - atribut start: null - prvni chunk bude cely tracklog od zacatku, cas (ISO time) naplni prvni chunk daty od dalsi sekundy az po aktualni cas (to funguje NEZAVISLE na atributu force)
   * - atributu force: false (nic se nemeni) | true (chova se to jako kdyby se to volalo poprve)
   * TODO force mozna nebude potreba, protoze v realnem scenari nebudem potrebovat nikdy od tracklog znovu od zacatku....uvidime
  */
  msgWebFollow (follows = []) {
    return {
      "tag": "WebFollow",
      "contents": follows.map(
        follow => Object.assign({}, {
          flightUuid: follow.uuid,
          start: follow.start || null,
          force: follow.force || false
        })
      )
    };
  },

  onChallenge (data, onAfterChallenge = () => {}) {
    d(data, undefined, {msg : true, dgt : true})
    .then(digest => {
//console.log('onChallenge:d.digest', digest);
      this.send(this.msgChallengeResponse(digest), true);
      onAfterChallenge();
    })
    .catch(err  => {
      //BOT DETECTION FAIL FOUND - nepokracujeme a terminujeme WS
      if (err.msg == 'B.D.F') {
        this.Ws.terminate();
      };
    })
  },

  /**
   * @param digest - hmac digest - ArrayBuffer
  */
  msgChallengeResponse (digest) {
    return digest;
  },

  /**
   * vola se pokud prijde zprava LiveFlightInfos (kazdou minutu zpravidla)
   * ulozi vsechny actual data do map liveInfoActual
   * pokud nemame k nejakemu uuid static data, posle WebRequestInfo
   * @param data - data prichozi zpravy LiveFlightInfos
  */
  onLiveFlightInfos (data) {
//console.log('onLiveFlightInfos', data);

    //vytvarime novy Map objekt, aby fungovalo getPrev() a nebyl to stejny objekt
    const liveInfoActual = new Map(this.pgStore.get('liveInfoActual'));
    const liveInfoStatic = this.pgStore.get('liveInfoStatic');



    //update liveInfoActual
    //ziskani uuids ke kterym potrebujeme staticInfo
    const uuids = Object.entries(data.info)
      .reduce((missUuids, [uuid, info]) => {
        liveInfoActual.set(uuid, info);
        if (!liveInfoStatic.has(uuid)) missUuids.push(uuid);
        return missUuids;
      }, []);

    const uuidsActive = Object.keys(data.info);

    //propsani do stores
    this.pgStore.set({uuidsActive, liveInfoActual});

    if (uuids.length > 0) {
      //requestujeme chybejici static info, pokud nejake chybi
      this.send(this.msgWebRequestInfo(uuids));
    } else {
      //oznamime nacteni
      this.pmsStore.sPMS('LiveFlightsLoaded', Promise.resolve());
    };
  },

  /**
   * zpracovava zpravu LiveStaticInfos
   * uklada do map liveInfoStatic
   * @param data - data prichozi zpravy LiveStaticInfos
  */
  onLiveStaticInfos (data) {
//console.log('onLiveStaticInfos', data);

    const liveInfoStatic = this.pgStore.get('liveInfoStatic');

    Object.entries(data.static)
      .forEach(([uuid, info]) => {
        liveInfoStatic.set(uuid, info);
      });

    //propsani do stores
    this.pgStore.set({liveInfoStatic});

    //oznamime nacteni
    this.pmsStore.sPMS('LiveFlightsLoaded', Promise.resolve());
  },

/*88888888888888888888888888888888888888888*/
/*
XCUtil.checkType(o.type, TRACK_DATA_TYPES);

const encKEY = o.type == 'hires' ? 'encHiRes' : 'enc';
const bData = store.getFlightData(o.id);
const startT = new ExtDate().setIsoTime(bData.pointStart.time);
const endT = new ExtDate().setIsoTime(bData.pointEnd.time);
const indexLength = ((endT.getTime() - startT.getTime())/1000)+1;

//priprava GeoJSON track source
return XCUtil.prepareTrackData({
  enc: data[encKEY],
  res: EncPoly.getFreshResultObject(indexLength, data[encKEY].prec, o.id),
  add: o.type=='basic' ? bData.pointEnd : null,
  id: XCUtil.getFlightId(data)
}).then(([res, id]) => {
  //ulozeni resultu do Store
  const keypath = o.type+'Track';
  const track = Object.assign({}, store.get()[keypath], {[id]: res});
//console.log('track', track)
  store.set({[keypath]: track});
  return res;
});
*/
/*9999999999999999999999999999999999999999999999*/
/*
{
  "tag":"LiveFlightChunk",
  "flightUuid":"4bb5b047-029c-4cc2-b346-8f48cf19c21e",
  "trackChunk":{
    "prec":6,
    "startTime":"2019-05-22T16:10:52Z",
    "data":"wtel_Bcsth^euA??sUqkARgECAoCAANyAAAlA?Cp@}CCAy@AA`ByBAAM?AvCy@CAc@AAvDPAA]??xD~ACAM?AlDpCAAA??xCxCCAP?CvC~CAAA?CnC`ECAE?C|BtFAAM?ChAnHAAE?E?jICAC?CaAxHCA?@EaClGAAE?CiD|DAAF@C}DtAAAJ@EcD?CAd@?C{Bw@AAd@@E{AgBAAP?Co@qCAAH?CBsDAAK?C\\sDAAC??xAoCCAIAAjCaAAAS?AzC?AAKAA~Cj@AAE?AbDv@CAEACdDjAAAE?AfDtBAAKAChD~CCAOACtCfEAA@AC|BdFCAB?CbBdFAAPAC|AfFCA@ACnAvFAAA?Cf@lGCA?AC_ArGAAK@?{CfGCAm@??mDdFAA@@?oDjDCAT@AyCdCAA`@@CeDfBAAA@AmDl@CA@BCeDa@AAH@CaCuACA\\?CsAeCAAT?COkDCAJ?Cp@gDCAI?CdBmBAAE?CnCICAUAAzClBAA]ACjCzECA]AC`BjHAAQ??\\fICABAA]`IAAB@AoAdHAAB@A_CtFCA@@A",
    "props":7,
    "endTime":"2019-05-22T16:11:51Z",
    "bounds":{"minX":16.408857,"minY":50.546868,"maxX":16.411772,"maxY":50.54806}
  },
  //"firstTstamp":"2019-05-22T16:10:52Z",
  //"lastTstamp":"2019-05-22T16:11:51Z"
}
*/
  PMSflightChunk : {},

  //pridava promise na zpracovani chunku do fronty
  queueFlightChunk (uuid, PMStrackData) {
    if (!this.PMSflightChunk[uuid]) this.PMSflightChunk[uuid] = Promise.resolve();
    this.PMSflightChunk[uuid] = this.PMSflightChunk[uuid].then(() => PMStrackData);

    return this.PMSflightChunk[uuid];
  },

  //pokud predana promise je ta ulozena v promenne, muzeme ji anulovat, protoze mezitim zjevne zadna nepribyla
  resetQueueFlightChunk (uuid, PMS) {
    if (PMS === this.PMSflightChunk[uuid]) this.PMSflightChunk[uuid] = undefined;
  },

  onLiveFlightChunk (data) {
console.log('onLiveFlightChunk', data);

    const uuid = data.flightUuid;
    const liveFlightChunk = this.pgStore.get('liveFlightChunk');

    liveFlightChunk.set(uuid, data);

    this.loadFlightData(uuid, data);

    const PMS = this.queueFlightChunk(uuid, this.prepareTrackData(uuid, data));

    PMS.then(res => {
      this.resetQueueFlightChunk(PMS);
      const uuidsFollowed = this.pgStore.get('uuidsFollowed');
      if (!uuidsFollowed.includes(uuid)) return;

      showFlight(uuid, this.viewStore.get('showFlightSet'), true, this._posLayerId('followed'));
    });
/*
    this.prepareTrackData(uuid, data).then(res => {
      //pokud uuid letu neni aktualne followed, nevolame showFlight
      const uuidsFollowed = this.pgStore.get('uuidsFollowed');
      if (!uuidsFollowed.includes(uuid)) return;

      showFlight(uuid, this.viewStore.get('showFlightSet'), true, this._posLayerId());
    });
*/
  },

  loadFlightData (id, data) {
    const liveInfo = this.pgStore.get('liveInfo')[id];
    const infoS = liveInfo.static;
    const infoA = liveInfo.actual;

    const boundsArray = [data.trackChunk.bounds];

    const prevFlightData = this.pgStore.getFlightData(id);
    if (prevFlightData) boundsArray.push(prevFlightData.enc.bounds);
    const bounds = Util.reduceBounds(boundsArray);

    const flightData = this._mapFunctions.flight(id, infoS, infoA, bounds, this._mapFunctions, this.pgStore.get('contest'));
//console.log('live.loadFlightData', flightData)

    //vlozeni do store jako basicFlightData
    this.pgStore.setFlightData('basic', id, flightData);

    //aktualizace PMS
    ['hires','basic'].forEach(type => this.pmsStore.sPMS(
      ['FlightDataLoaded.'+type, id],
      Promise.resolve().then(() => this.pgStore.getFlightData(id))
    ));
  },

  _mapFunctions: {
    flight: (id, infoStatic, infoActual, bounds, {point, points, routeType}, contest) => {
      //const route = infoActual.contest.alpha9999.route;
      const lg = infoActual.contest[contest];
      //pokud neni route, vytvorime defaultni - 2 body, takeoff-lastFix
      const route = lg.route ? lg.route : {
        type : {
          tag : "FreeFlight",
          coef : 1,
          turnpoints : 0
        },
        turnpoints : [
          infoStatic.takeoff.point,
          infoActual.lastFix
        ]
      };

      return {
        //live: true, //pridano aby bylo mozno detekovat.....mozna to nebude treba?
        id, //id flight
        type: 1, //type flight
        pilot: {
          id: infoStatic.user.uid, //id pilot
          username: infoStatic.user.username,
        },
        enc: {
          bounds
        },
        pointStart: point(infoStatic.takeoff.point),
        pointEnd: point(infoActual.lastFix),
        league: {
          route: {
            type: routeType(route.type.tag),
            turnpoints: points(
              route.turnpoints,
              point
            )
          }
        },

        countries: [], //TODO - zde maji byt zeme, kterymi let leti, kvuli prostorum

        utcOffsetStart: Util.getUtcOffset()
      };
    },
/*
Array(4)
0: 16.819652
1: 49.999775
2: 666
3: {g: 658, t: "2019-05-24T11:37:52Z", b: 690}

=>
{
"time": "2019-04-15T10:46:36Z",
"altitude": 485,
"longitude": 13.77108,
"latitude": 50.40653
}
*/
    point: (Point = []) => {
      const time = Point[3].t;
      const altitude = Point[2];
      const longitude = Point[0];
      const latitude = Point[1];
      return {time, altitude, longitude, latitude};
    },

    points: (Points = [], fMappingPoint) => Points.map(fMappingPoint),

    routeType: type => {
      return {
        FreeFlight : "FREE_FLIGHT",
        FlatTriangle : "FREE_TRIANGLE",
        FaiTriangle : "FAI_TRIANGLE"
      }[type];
    }
  },

  prepareTrackData (id, data) {
    const keypath = 'hiresTrack';

    return Promise.resolve()
    .then(() => {
      const chunk = data.trackChunk;

      //pokud uz resultObject pro dane id existuje, bere si ho, jinak vytvari novy
      const storedRes = this.pgStore.get(keypath)[id];

      const liveInfoStatic = this.pgStore.get('liveInfoStatic').get(id);
      const trackStartT = new ExtDate().setIsoTime(liveInfoStatic.takeoff.point[3].t);
      const chunkStartT = new ExtDate().setIsoTime(chunk.startTime);

      //TODO - tohle neni idealni - 50 tisic sekund je necelych 14 hodin, pevny index. Asi by se mel nejak dynamicky upravovat - cas od casu index prepocitat?
      const indexLength = 50000;

      const res = storedRes || EncPoly.getFreshResultObject(indexLength, chunk.prec, id);

      //pri pridavani chunku do existujiciho resultObjectu musime nastavit:
      //1) vychozi i - index prvniho bodu - na soucasnou delku geometry, jinak by se prepisovalo
      res.i = storedRes ? storedRes.geojson2D.geometry.coordinates.length : 0;
      //2) vychozi time - dt je pro prvni bod totiz 0
      res.time = ( chunkStartT.getTime() - trackStartT.getTime() ) / 1000;

      return {chunk, res};
    })
    .then(({chunk, res}) => {
      const PMS = XCUtil.prepareTrackData({
        enc : chunk,
        id,
        res,
        add : null
      }).then(([res, id]) => {
        //ulozeni resultu do Store
//console.log("LIVE resultObject", id, res);
        const track = Object.assign({}, this.pgStore.get(keypath), {[id]: res});
        this.pgStore.set({[keypath]: track});
        return res;
      });

      //naplnujem hires i basic PMS stejnou
      ['hires','basic'].forEach(type => this.pmsStore.sPMS(['TrackDataReady.'+type, id], PMS));

      return PMS;
    });
/*
    return new Promise ((resolve, reject) => {
      const keypath = 'hiresTrack';

      const chunk = data.trackChunk;

      //pokud uz resultObject pro dane id existuje, bere si ho, jinak vytvari novy
      const storedRes = this.pgStore.get(keypath)[id];

      const liveInfoStatic = this.pgStore.get('liveInfoStatic').get(id);
      const trackStartT = new ExtDate().setIsoTime(liveInfoStatic.takeoff.point[3].t);
      const chunkStartT = new ExtDate().setIsoTime(chunk.startTime);

      //TODO - tohle neni idealni - 50 tisic sekund je necelych 14 hodin, pevny index. Asi by se mel nejak dynamicky upravovat - cas od casu index prepocitat?
      const indexLength = 50000;

      const res = storedRes || EncPoly.getFreshResultObject(indexLength, chunk.prec, id);

      //pri pridavani chunku do existujiciho resultObjectu musime nastavit:
      //1) vychozi i - index prvniho bodu - na soucasnou delku geometry, jinak by se prepisovalo
      res.i = storedRes ? storedRes.geojson2D.geometry.coordinates.length : 0;
      //2) vychozi time - dt je pro prvni bod totiz 0
      res.time = ( chunkStartT.getTime() - trackStartT.getTime() ) / 1000;

      const PMS = XCUtil.prepareTrackData({
        enc : chunk,
        id,
        res,
        add : null
      }).then(([res, id]) => {
        //ulozeni resultu do Store
  console.log("LIVE resultObject", id, res);
        const track = Object.assign({}, this.pgStore.get(keypath), {[id]: res});
        this.pgStore.set({[keypath]: track});
        return res;
      });

      //naplnujem hires i basic PMS stejnou
      ['hires','basic'].forEach(type => this.pmsStore.sPMS(['TrackDataReady.'+type, id], PMS));


      //resolve
      PMS.then(res => resolve(res));
    });
*/
  },

  /**
   * nacita ikony pro positions layer
   musi vracet promisu
   * type = 'basic'|'clustered'
  */
  loadIcons (type = 'basic') {
    switch (type) {
      case 'basic':
        //nacteni default ikon live, landed, lost
        const PMS1 = PGUtil.loadIconsPromise(this.iconsSet);

        //nacteni barevnych kombinaci live, landed, lost
        const IconsSet = Util.getColorCodes()
          .map((code, index) => [code, Util.getHslaColor(index)])
          .reduce((acc, [ident, fill]) => {
            this.iconsSet.forEach(iconSet => {
              const newIconSet = [...iconSet];
              newIconSet[0] = iconSet[0]+'-'+ident;//icon-dot-{live|landed|lost}-{color code}
              newIconSet[1] = fill;//fill
              newIconSet[2] = "white";//stroke
              acc.push(newIconSet);
            });
            return acc;
          }, []);

        const PMS2 = PGUtil.loadIconsPromise(IconsSet);
        return Promise.all([PMS1, PMS2]);

        break;

      case 'clustered':
        return this.pmsStore.getPMS('MapLoaded').then(map => {
          //dynamicke pridavani ikon s cisly v clusteru
          map.on('styleimagemissing', e => {
            const id = e.id; // id of the missing image

            // Check if this missing icon is
            // one this function can generate.
            const prefix = "icon-dot-cluster-";
            if (id.indexOf(prefix) !== 0) return;

            // Get the number from id
            //const vals = id.replace(prefix, '').split('-');
            const [point_count, countLive, countLost, countLanded] = id.replace(prefix, '').split('-').map(val => parseInt(val));
            //const point_count = parseInt(id.replace(prefix, ''));

            //style props according to the number of cluster leaves
            const strokewidth = 1.5;
            const size = point_count < 10 ? 22 : point_count < 100 ? 23 : 25;
            const viewBox = size;
            const diameter = point_count < 10 ? 15 : point_count < 100 ? 16 : 18;
            const fontSize = 11;

            const textLength = point_count < 10 ? 6 : point_count < 100 ? 10 : 14;
            const x = point_count < 10 ? 4.5 : point_count < 100 ? 3 : 2;
            const y = point_count < 10 ? 11.5 : point_count < 100 ? 12 : 13;
            const fill = 'hsl(50, 100%, 50%)';

            //icon settings
            const iconsSet = [
              [
                'cluster-' + point_count + '-' + countLive + '-' + countLost + '-' + countLanded,
                'hsl(0, 0%, 10%)',
                'hsl(0, 0%, 10%)',
                strokewidth,
                point_count,
                {textLength, x, y, fill},
                {size, viewBox, diameter, fontSize},
                [countLive, countLost, countLanded]
              ]
            ];
            PGUtil.loadIconsPromise(iconsSet);
          });
        });
    }
  },



  preparePositionsContextMenu () {

    const PopupListNode = window.document.createElement('div');
    const PopupList = new LiveList({
      target : PopupListNode,
      props : {
        mode : 'popup',
        Live: this
      }
    });

    this.PopupDetailNode = window.document.createElement('div');
    this.PopupDetail = new LiveList({
      target : this.PopupDetailNode,
      props : {
        mode : 'detail',
        Live: this
      }
    });

//console.log('preparePositionsContextMenu 1', this.PopupDetailNode, this.PopupDetail)

    this.pmsStore.getPMS('MapLoaded').then(map => {

      //cursor nad position layers
      map.on('mousemove', evt => {
        const layers = [
          this._posLayerId(),
          this._posLayerId('clustered'),
          this._posLayerId('followed')
        ];
        const rendered = map.queryRenderedFeatures(evt.point, {layers});
        map.getCanvas().style.cursor = rendered.length == 0 ? '' : 'pointer';
      });


      const showPopup = (evt, detail = false) => {
        const layers = [
          this._posLayerId(),
          this._posLayerId('clustered'),
          this._posLayerId('followed')
        ];
        const rendered = map.queryRenderedFeatures(evt.point, {layers});

        if (rendered.length == 0) return;

        //pokud chceme dostat jednotlive polozky (piloty) z clusteru, tak metoda getClusterLeaves je vraci na callback
        //musime iterativne vytvorit promises
        const leavesPmsArr = rendered.map(feature => new Promise((res, rej) => {
          if (feature.properties.cluster) {
            map.getSource(feature.source).getClusterLeaves(
              feature.properties.cluster_id,
              feature.properties.point_count,
              0,
              (err, features) => {
                res(features);
              }
            );
          } else {
            res([feature]);
          }
        }));

        //z promises pak po jejich resolvovani vytvorime pole features (coz jsou koncovi piloti)
        Promise.all(leavesPmsArr)
        .then(featuresArr => featuresArr.reduce(
          (acc, features) => acc.concat(features),
          []
        ))
        .then(features => {
          const filterUuids = features.map(r => r.properties.uuid);

          //pri prave 1 letu ma byt zobrazen rovnou detail, pokud je priznak detail == true
          if (detail && filterUuids.length == 1) {
            this.openPopupDetail(filterUuids[0]);
            return;
          };

          const lngLat = filterUuids.length == 1 ? this._getLastFixLngLat(filterUuids[0]) : evt.lngLat;



          PopupList.$set({filterUuids});
          this.getPositionsPopup().setLngLat(lngLat).setDOMContent(PopupListNode).addTo(map);
          Util.dialogOpened('popup');
        });
      };


      //click nad konkretni position
      map.on('click', evt => showPopup(evt, false));

      //contextmenu nad konkretni position
      map.on('contextmenu', evt => showPopup(evt, true));
    });

  },

  openPopupDetail (uuid) {
    this.pmsStore.getPMS('MapLoaded').then(map => {
      const filterUuids = [uuid];
      this.PopupDetail.$set({filterUuids});

//console.log('openPopupDetail', this.PopupDetail, this.PopupDetailNode)

      this.getPositionsPopup().setLngLat(this._getLastFixLngLat(uuid)).setDOMContent(this.PopupDetailNode).addTo(map);
      Util.dialogOpened('popup');
    });
  },

  _getLastFixLngLat (uuid) {
    const lastFix = this.pgStore.get('liveInfo')[uuid].lastFix;
    return [lastFix[0], lastFix[1]];
  },

  /**
   * vytvari objekt pro popup positions
   * pokud je vytvoren, vraci ho (je jen jeden jediny, ulozeny ve store.positionsPopup
   * @returns mapbox GL JS Popup objekt
  */
  getPositionsPopup () {
    let p = this.pgStore.get('positionsPopup');
    if (p != null) return p;

    p = new mapboxgl.Popup({closeButton: false, closeOnClick: true, closeOnMove: true,/* anchor: 'bottom',*/ className: 'positions-popup', maxWidth: '320px'});
/*
    p.on('close', (ev) => {
      Util.dialogClosed('popup');
    });
*/
    this.pgStore.set({positionsPopup : p});
    return p;
  },



  /**
   * checkuje ownersForceFollow a pokud nejakeho detekuje v nactenych letech, dela autofollow
   * autofollow ownery vyrazuje z ownersForceFollow, zbyle tam nechava
  */
  checkOwnersFollow (uuidsLastByOwner) {

    let {
      uuidsFollowed,
      ownersForceFollow,
      ownersLivelinkFollow,
    } = this.pgStore.get([
      'uuidsFollowed',
      'ownersForceFollow',
      'ownersLivelinkFollow',
    ]);

    if (ownersForceFollow.length === 0) return;

    const ownersAutoFollow = ownersForceFollow.filter(ownerId => uuidsLastByOwner.get(ownerId) !== undefined);
    const uuidsAutoFollow = ownersAutoFollow.map(ownerId => uuidsLastByOwner.get(ownerId));

    const updateUuids = Util.arrDiff(uuidsAutoFollow, uuidsFollowed).length > 0;

    if (ownersAutoFollow.length > 0) {
      ownersForceFollow = Util.arrDiff(ownersForceFollow, ownersAutoFollow);
      this.pgStore.set({ownersForceFollow});
    };

    if (updateUuids) {
      uuidsFollowed = Util.arrUniq([...uuidsFollowed, ...uuidsAutoFollow]);
//console.log("Live::checkOwnersFollow - update Uuids", {updateUuids, ownersAutoFollow, uuidsAutoFollow, uuidsFollowed, ownersForceFollow})
      this.pgStore.set({uuidsFollowed});
    };
  },

  /**
   * centruje na pilota, ktery byl automaticky follownut v ramci livelinku v URL
  */
  livelinkCenter (liveInfoDisplayByPilot) {

    let { ownersLivelinkFollow } = this.pgStore.get(['ownersLivelinkFollow']);
    if (ownersLivelinkFollow.length == 0) return;

    const flights = liveInfoDisplayByPilot.get(ownersLivelinkFollow[0]);
    if (!flights) return;

    const item = flights.entries().next().value;
    const flightUuid = item[0];
    const lastFix = item[1].lastFix;

    const center = [lastFix[0], lastFix[1]];
    const zoom = 12;

    //musime pockat az se mapa nacte. livedata pilotu, ktere chcem zamerit, mohou byt driv
    this.pmsStore.getPMS('MapLoaded').then(map => {
      map.easeTo({center, zoom});
      //bez pockani na moveend se popup neotevre
      map.once('moveend', () => {
        this.openPopupDetail(flightUuid);
      });
    });
  },

  /**
   * posila msgWebFollow po zmene followovanych
  */
  followChange ({current, previous}) {
    const currFollowed = this._followedOnly(current);
    const prevFollowed = this._followedOnly(previous);
    const addFollowed = Util.arrDiff(currFollowed, prevFollowed);
    const remFollowed = Util.arrDiff(prevFollowed, currFollowed);
//console.log('Live::followChange', {currFollowed, prevFollowed, addFollowed, remFollowed})
    this._messageFollow(currFollowed, addFollowed);

    //notifikuje addFlight
		addFollowed.forEach(uuid => this.pgStore.addLiveFlight(uuid));
    //notifikuje removeFlight
		remFollowed.forEach(uuid => this.pgStore.removeFlight(uuid));

    //pokud je let unfollow a hned follow drive, nez prijde dalsi liveFlightChunk, tak zadna aktualizace liveTrackChunk nedorazi
    //tim padem se ani nespusti showFlight() v onLiveFlightChunk pro dane id
    //proto zde pro nove followovane volame radeji showFlight() rovnou
    //ale jen pro ty uuid, pro ktere existuje promise TrackDataReady - tj. uz v minulosti byly nacteny
    addFollowed.forEach(uuid => {
      const PMStrackDataReady = this.pmsStore.gPMS(["TrackDataReady.hires", uuid]);
      if (!PMStrackDataReady) return;
      PMStrackDataReady.then(() => {
        showFlight(uuid, this.viewStore.get('showFlightSet'), true, this._posLayerId('followed'));
      });
    });
  },

  changePosTextField () {
    [
      this._posLayerId(),
      this._posLayerId('followed')
    ].forEach(layerId => {
      this.pgStore.get('map').setLayoutProperty(layerId, 'text-field', this.viewStore.get('actTextField'));
    });
  },

  changePositionsBaseSize (baseSize) {
    this.pmsStore.getPMS('ActPosLayer').then(([map]) => {
      //text size - vsechny 3 vrstvy
      [
        this._posLayerId(),
        this._posLayerId('clustered'),
        this._posLayerId('followed')
      ]
      .forEach(layerId => {
        map.setLayoutProperty(layerId, 'text-size', baseSize);
      });

      //halo width - jen individual a followed vrsvy
      [
        this._posLayerId(),
        this._posLayerId('followed')
      ]
      .forEach(layerId => {
        map.setPaintProperty(layerId, 'text-halo-width', baseSize/10);
      });
    });
  },

  /**
   * @param positions - positions z live pgStore
  */
	updatePositions (positions) {
//console.log('updatePositions::positions', positions);
    this.pmsStore.getPMS('ActPosLayer').then(([map]) => {
      [
        this._posSourceId(),
        this._posSourceId('followed')
      ]
      .forEach(sourceId => {
        MBUtil.setSourceData(map, sourceId,
          Util.createJsonUrl(JSON.stringify(positions), true),
          true
        );
      });
    });
	},

  /**
   * zajisti automaticky follow
   * auto follow (predchozi followed let pilota skoncil a zacal novy)
   * force follow (vnuceny follow odjinud)
  */
  autoFollow (uuidsLastByOwner) {
    const {uuidsLast, ownersByFlights, ownersFollow} = this.pgStore.get(['uuidsLast', 'ownersByFlights', 'ownersFollow']);
    let uuidsFollowed = this.pgStore.get('uuidsFollowed');

    const uuidsAdd = uuidsFollowed
      .filter(uuid => !uuidsLast.includes(uuid)) //vyfiltruje followed uuids, co nejsou last
      .map(uuid => ownersByFlights[uuid]) //mapuje na owner ids
      .filter(owner => ownersFollow.includes(owner)) //filtruje pouze na ty ownery co se maji followovat
      .map(owner => uuidsLastByOwner.get(owner)); //mapuje na last uuids dle ownera

//console.log("autoFollow", uuidsLastByOwner, uuidsAdd);

      if (uuidsAdd.length) {
        uuidsFollowed = Util.arrUniq([...uuidsFollowed, ...uuidsAdd]);
//console.log("autoFollow.uuidsFollowed");
        this.pgStore.set({uuidsFollowed});
      };
  },

  /** odstranuje lety, pote, co zmizely z uuidsActive, i z uuidsFollowed **/
  orphanUnfollow (uuidsActive) {
    let uuidsFollowed = this.pgStore.get('uuidsFollowed');
    const uuidsUnfollow = Util.arrDiff(uuidsFollowed, uuidsActive);

    if (uuidsUnfollow.length == 0) return;

    uuidsFollowed = Util.arrDiff(uuidsFollowed, uuidsUnfollow);
    this.pgStore.set({uuidsFollowed});
  },

  /**
   * pokud detekuje, ze je followed ne-last let, unfollowuje vsechny lety daneho pilota
  */
  autoUnfollow (uuidsFollowed) {
    const {uuidsLast, uuidsLastByOwner, ownersByFlights, ownersFollow} = this.pgStore.get(['uuidsLast', ' uuidsLastByOwner', 'ownersByFlights', 'ownersFollow']);
    const ownersByFlightsArr = Object.entries(ownersByFlights);

    //owneri k unfollow
    const unfollowOwners = Util.arrUniq(
      uuidsFollowed
      .filter(uuid => !uuidsLast.includes(uuid)) //vyfiltruje followed uuids, co nejsou last
      .map(uuid => ownersByFlights[uuid]) //mapuje na owner ids
      .filter(owner => !uuidsFollowed.includes(uuidsLastByOwner.get(owner)))//vyfiltruje pouze ty ownery, jejich last flight NENI followed
    );

    //najde prislusne lety k unfollowOwners
    const unfollowFlights = Util.arrUniq(
      unfollowOwners
      .reduce((acc, owner) => {
        const uuids = ownersByFlightsArr.filter(([uuid, ownr]) => owner == ownr).map(([uuid, ownr]) => uuid);
        acc = [...acc, ...uuids];
        return acc;
      }, [])
    );



//console.log("autoUnfollow", unfollowFlights)

    if (unfollowFlights.length > 0) {
      uuidsFollowed = Util.arrDiff(uuidsFollowed, unfollowFlights);
      //this.pgStore.set({uuidsFollowed});
    };

    return uuidsFollowed;
  },

  updateRadar (map, radar) {
//console.log('updateRadar', radar)
    const layerId = "background_rain";

    //skryti radaru -invisibluje vrstvu a rusi settimeout
    if (!radar) {
      if (map.getLayer(layerId)) map.setLayoutProperty(layerId, "visibility", "none");
      clearTimeout(this.viewStore.get('radarTimeoutId'));
      return;
    };

    const now = Date.now()/1000;
    const lastRadarTimestamp = this.viewStore.get('radarTimestamp');

    //pokud neni sance na novejsi vrstvu
    if (now - lastRadarTimestamp < 600) {
      //pokud byla jen skryta, ukazuje ji, jinak ji prida
      if (map.getLayer(layerId)) {
        map.setLayoutProperty(layerId, "visibility", "visible");
        this.setRadarUpdateTimeout(map, this.computeRadarTimeout(lastRadarTimestamp) * 1000);
      } else {
        this.updateRadarLayer(map, lastRadarTimestamp);
      };
      return;
    };

    try {
      //zobrazeni nebo update radaru
      fetch("https://api.rainviewer.com/public/maps.json")
      .then(res => res.json())
      .then(timestamps => timestamps.sort().pop())//vyberem posledni
      .then(radarTimestamp => {

        //pokud posledni zjisteny radar timestamp neni novejsi, zkusime to za 30 sec znovu
        if (radarTimestamp <= lastRadarTimestamp) {
          this.setRadarUpdateTimeout(map, 30 * 1000);
          return;
        };

        //pokud timestamp je novejsi, updatujeme radar layer
        this.updateRadarLayer(map, radarTimestamp);
      })
      .catch((err) => {
        console.log('rainviewer json fetch catch', err);
        this.setRadarUpdateTimeout(map, 30 * 1000);
      });
    } catch (err) {
      console.log('rainviewer json fetch error', err);
      this.setRadarUpdateTimeout(map, 30 * 1000);
    };
  },

  updateRadarLayer (map, radarTimestamp) {
    const layerId = "background_rain";

    //pripadne odebrani layeru a source, pokud existuje
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    };
    if (map.getSource(layerId)) {
      map.removeSource(layerId);
    };

    map.addLayer({
     "id": layerId,
     "type": "raster",
     "source": {
        "type": "raster",
        "tiles": [
          `https://tilecache.rainviewer.com/v2/radar/${radarTimestamp}/256/{z}/{x}/{y}/8/1_0.png`
        ],
        "tileSize": 256,
        "minzoom": 0,
        "maxzoom": 12,
        "attribution": "<a href='https://www.xcontest.org/'>&copy; XContest.org</a> | Radar: Rainviewer"
      },
     "paint": {
       "raster-opacity": 1.0
     },
     "layout": {
       "visibility": "visible"
     }
   }, "landuse");

   this.setRadarUpdateTimeout(map, this.computeRadarTimeout(radarTimestamp) * 1000);
   this.viewStore.set({radarTimestamp});
 },

 /** vypocita potrebny timeout v sekundach **/
 computeRadarTimeout (radarTimestamp) {
   const now = Date.now()/1000;
   return (Math.ceil((now - radarTimestamp) / 600) * 600) + 30 + radarTimestamp - now;
 },

 setRadarUpdateTimeout (map, timeout) {
   const radarTimeoutId = setTimeout(() => this.updateRadar(map, true), timeout);
   this.viewStore.set({radarTimeoutId});
 },

 /**
   * sestavuje webFollow message a odesila ji
   * @param currFollowed array - ids letu ktere se maji followovat
   * @param addFollowed array - ids letu ktere byly od posledka pridany
   */
  _messageFollow (currFollowed, addFollowed) {
    const liveFlightChunk = this.pgStore.get('liveFlightChunk');
    const useStart = uuid => addFollowed.includes(uuid) && liveFlightChunk.has(uuid);

    const follows = currFollowed
      .map(uuid => ({
        uuid,
        start: useStart(uuid) ? liveFlightChunk.get(uuid).trackChunk.endTime : null, // od konce posledniho prijateho chunku, pokud je
        force: false,
      }));

    this.send(this.msgWebFollow(follows));
  },

  /**
   * transformuje pole ve formatu stateUuids na jednoduche pole uuids, ktere jsou followed
   * @param stateUuids array [[uuid, followed], ...]
   * @returns array [uuid1, uuid2,...] pole uuid ktere maji followed = true
  */
  _followedOnly (stateUuids) {
    return stateUuids.filter(([uuid, followed]) => followed).map(([uuid]) => uuid);
  },

  _posLayerId (suffix = null) {
    return ID_ROOT.pos+':actual' + (suffix ? ':'+suffix : '');
  },
  _posSourceId (suffix = null) {
    return ID_ROOT.possrc+':actual' + (suffix ? ':'+suffix : '');
  }
};

export {Live};
