import {ExtDate} from './extdate.js';
import {
  ROUTE_TYPES,
  COLORS,
  TS_LABEL_STEPS,
  TS_NOTCH_STEPS
} from './const.js';
import {Util} from "./util.js";
import {MBUtil} from "./mbutil.js";
import {XCUtil} from "./xcutil.js";
import {SvgUtil} from "./svgutil.js";
import { PMS } from './pms-store.js';

const GraphMod = {

  pgStore : null,
  pmsStore : null,

  /** volat uplne na zacatku **/
  setStores ({pgStore,  pmsStore}) {
    this.pgStore = pgStore;
    this.pmsStore = pmsStore;

    XCUtil.setStores({pgStore});
    PMS.store = pmsStore;
  },

  /**
   * znovunastavi width canvasu dle aktualni width graph elementu
   * musi se volat pote, co dojde ke zmene sirky (bud zmenou velikosti okna, nebo zmenou velikosti elementu)
  */
  checkResize (evt) {
    window.setTimeout(() => {
      const sizes = this.pgStore.get('graphNode').getBoundingClientRect();
      const canvas = {w: sizes.width || 100, h: 100};
      this.pgStore.set({canvas});
    }, 10);
  },

  /**
   * pripravi SVG graf
   * @returns Promise, ktera resolvuje s [canvas, svg]
  */
  prepareGraph () {
    return PMS('GraphPrepared', () => GraphMod._prepareGraph());
  },
  _prepareGraph () {
    //napred cekame na DOMContentLoaded, abychom mohli zavolat getBoundingClientRect()
    return this.pmsStore.DOMContentLoaded()
    .then(() => {
      GraphMod.checkResize('DomContentLoaded');

      //const svg = document.querySelector('#graph svg');
      const svg = this.pgStore.get('graphNode').querySelector('svg');

      //pridani/odebrani letu ci pridani bounds
      ['flight-add','flight-rem','bounds-add'].forEach(evt => {
        this.pgStore.on(evt, ({id}) => {
          GraphMod.checkFlightFills(id);
        });
      });

      ['global-bounds-change', 'canvas-change'].forEach(evt => {
        //reakce na zmeny globalnich bounds
        this.pgStore.on(evt, () => {
          this.pgStore.get('allZTbounds').forEach(([id]) => {
            GraphMod.checkFlightFills(id);
          });
        });
      });
      return svg;
    });
  },

  /**
   * @param canvasDim - width nebo height canvasu, dle dimenze, ve ktere pracujem
   * @param scale - transform.scaleX nebo scaleY (dle dimenze)
   * @param translate - transform.translateX nebo translateY (dle dimenze)
   * @param gapBounds - $gaps.bounds, optional
   * @returns function; mapovaci funkce pro pole steps
   * - namapovany step je objekt:
   * - step: hodnota sveho step
   * - items() generator funkce vracejici iterator; iterator vraci objekt {dim, px}
  */
  getLabelIterator (canvasDim, scale, translate, gapBounds = []) {
    //mapovaci funkce
    return (step, i, steps) => {
      //generator funkce iteratoru
      const items = function* () {
        const start = translate % step > 0 ? (translate + step - (translate % step)) : translate;
        const end = (canvasDim*scale + translate);

        for (let d = start; d <= end; d += step) {
          //vynechavame items, ktere vychazeji i na vyssi stepy
          if (steps.filter(_step => _step > step && d % _step == 0).length > 0) continue;

          const px = (d-translate)/scale;
          //pridavame pripadne gaps
          const [g0, g1] = [Util.getPrevGap(d, gapBounds, false), Util.getPrevGap(d, gapBounds, true)];
          const dim = g0 == g1 ? d + g0 : [d + g0, d + g1];
          yield {dim, px};
        };
      };
      return {step, items};
    };
  },

  /**
   * pripravi k letu:
   * - paths pro graf
   * - absolutni bounds
   * @param id idFlight
   * @param res resultObject
   * @returns {paths, bounds} paths = {a, b, g}; bounds {minX, maxX, minY, maxY}
  */
  preparePathsData (id, res) {
    const data = this.pgStore.getFlightData(id);
//console.log('GraphMod.preparePathData', id, res.geojson.geometry.coordinates);
    let {paths, bounds} = SvgUtil.coordsToPaths(res.geojson.geometry.coordinates, res.index);
//console.log('GraphMod.preparePathData 2 ', id, paths);
    bounds = Util.translateBounds(bounds, {x: Date.parse(data.pointStart.time)/1000});
//console.log('GraphMod.preparePathData 3 ', id, bounds);
    return {paths, bounds};
  },

  /**
   * vytvari vsechny cary a vyplne k jednomu letu
   * @param {id, svg, res, force}: idFlight, svgNode, resultObject; force -
   * @param data.force true|false (default false) - je-li true, dojde VZDY k celemu cistemu behu, tj.
   * - probehne i kdyz uz PMS existuje
   * @returns Promise, resolvuje s id
  */
  createFlightGroup (data) {
    const keypath = ['GraphFlightDrawn', data.id];
    if (data.force) this.pmsStore.sPMS(keypath, null);
    return PMS(keypath, (data) => GraphMod._createFlightGroup(data), data);
  },
  _createFlightGroup({id, svg, res}) {
    return new Promise((resolve, reject) => {
      const data = this.pgStore.getFlightData(id);
      const {paths, bounds} = GraphMod.preparePathsData(id, res);

      //pridani ZTbounds do store
      this.pgStore.addBounds(id, bounds);

      //pridani paths
      paths.type = data.type;

      this.pgStore.setProp('paths', id, paths);

      resolve(id);
    });
  },

  drawFlightGroup (id, svg, paths, matrix, dataType) {
    /*

    speed: '#000000',
    vario: '#800000',

     if (create.speed) {
           'lineWidth': 1.5,
           'fillStyle':'#FF9966'
        });
     };
     if (create.vario) {
           'lineWidth': 1.5,
           'fillStyle':'#FFFF00'
        });
     };
    */
    const typeClass = {1: 'flight', 2: 'walk', 3: 'rest'};

    //g pro souhrny transform matrix
    const g = SvgUtil.createNode('g', {
      id: XCUtil.getGraphGroupId(id),
      class: typeClass[dataType],
      transform: 'matrix('+matrix.join(', ')+')'
    });

    //gps alti fill
    g.appendChild(SvgUtil.createNode('path', {
      class: 'path-fill',
      d: SvgUtil.toPathFill(paths.a)
    }));

    //ground
    g.appendChild(SvgUtil.createNode('path', {
      class: 'path-ground',
      d: SvgUtil.toPathFill(paths.g)
    }));

    //gps alti
    g.appendChild(SvgUtil.createNode('path', {
      id: XCUtil.getGraphPathId('gps', id),
      class: 'path-gps',
      d: SvgUtil.toPathLine(paths.a)
    }));

    if (paths.b) {
      //baro alti
      g.appendChild(SvgUtil.createNode('path', {
        class: 'path-baro',
        d: SvgUtil.toPathLine(paths.b)
      }));
    };

    svg.querySelector('.paths').appendChild(g);
    return g;
  },

  /**
   * filter funkce pro lety kterym maji byt zobrazeny turnpointy
   * @param multiple $multiple (bool)
   * @returns function ktera filtruje lety
  */
  getFilterTPs (multiple) {
    return ([id, data, bounds]) => {
      if (multiple) return false; //v multiple modu se TP nevykresluji
      if (data.type != 1) return false; //vykreslujeme pouze pokud data.type = 1, tj. flight
      return true;
    };
  },

  /**
   * mapovaci funkce ktera mapuje pro ucely iterace turnpointu
   * @param routeType $routeType
   * @param globalZTbounds $globalZTbounds
   * @returns function, ktera mapuje kazdy "let" na objekt {id, items, minY, maxY, routeType}
  */
  getTurnpointsIterator (routeType, globalZTbounds, matrixes) {
    //mapovaci funkce
    return ([id, data, ZTbounds]) => {
      const {minX} = ZTbounds;
      const {minY, maxY} = globalZTbounds;
      const matrix = matrixes.find(([i]) => i==id)[1];

      //generator funkce iteratoru
      const items = XCUtil.getTurnpointsData(data, 'turnpoints', routeType)
        .map(point => {
          return new ExtDate().setIsoTime(point.time).getTime()/1000 - minX;
        });
      return {id, items, minY, maxY, matrix, routeType};
    };
  },

  /**
   * pro multiMode == SERIAL vytvori na grafu vypln mezi jednotlivymi tracklogy pilota
   * @param id idFlight - id letu pridaneho, odebraneho...
   * @returns void
  */
  checkFlightFills (id) {
    if (this.pgStore.get('multiMode') != 'SERIAL') return;
    if (this.pgStore.get('graphTimeMode') == 'ABSOLUTE') return;

    const PMSgraphPrepared = GraphMod.prepareGraph();
    const PMSgraphFlightDrawn = this.pmsStore.getPMS(['GraphFlightDrawn', id]);

    Promise.all([PMSgraphPrepared, PMSgraphFlightDrawn])
    .then(([svg, id]) => {
      //ziskani id pilota
      const idPilot = XCUtil.getPilotId(this.pgStore.getFlightData(id));

      GraphMod.deleteFlightFills(idPilot);

      //ziskani vsech bounds letu daneho pilota a serazeni dle casu
      const ZTbounds = this.pgStore.get('translatedActiveZTbounds')
        .filter(([id, bounds]) => this.pgStore.get('flightsByPilots')[idPilot].includes(id))
        .sort((a, b) => a[1].minX - b[1].minX);
/*
      const defaultZTbounds = this.pgStore.get('translatedDefaultZTbounds');

      if (defaultZTbounds !== null) {

        ZTbounds.push(defaultZTbounds);

      };
*/

      //z mezer udela fills
      const fills = ZTbounds
        .map(([id, bounds], i) => {
          const [id1, bounds1] = ZTbounds[i+1] || [];
          if (!bounds1 || bounds.maxX >= bounds1.minX) return null;

          const [coords, coords1] = [
            this.pgStore.get('basicTrack')[id].geojson.geometry.coordinates,
            this.pgStore.get('basicTrack')[id1].geojson.geometry.coordinates
          ];
          const [p0, p1] = [
            Util.fresh(coords[coords.length-1]),
            Util.fresh(coords1[0])
          ];

          //premazani casu bodu
          p0[3].t = 0;
          p1[3].t = bounds1.minX-bounds.maxX;

          return {
            ids: [id, id1],
            coords: [p0, p1],
            minX: bounds.maxX,
          };
        })
        .filter(b => b != null);

      //vytvori paths pro fills a nakresli to
      const flightFills = fills.map(({ids, coords, minX}) => {
        const {bounds, paths} = SvgUtil.coordsToPaths(coords);
        const fBounds = Util.translateBounds(bounds, {x: minX});
        delete paths.b; //smazeme baro paths

        const canvas = this.pgStore.get('canvas');
        const gBounds = this.pgStore.get('globalZTbounds');
        const matrix = Util.getTransformMatrix(canvas, fBounds, gBounds);

        return GraphMod.drawFlightGroup(ids.join('-'), svg, paths, matrix, 3);
      });

      this.pgStore.setProp('flightFills', idPilot, flightFills);
    });
  },

  deleteFlightFills (idPilot) {
    //smazani vsech
    let flightFills = this.pgStore.get('flightFills')[idPilot];
    flightFills = Array.isArray(flightFills) ? flightFills : [];
    flightFills.forEach(g => g.parentNode.removeChild(g));
    this.pgStore.setProp('flightFills', idPilot, []);
  },

  /**
   * vraci gX, tX pro dany event
   * @param evt:  object  Mouse click event | touch event
   * @returns {gX, tX} nebo null, pokud se nepodarilo nic vratit
  */
  getX (evt) {
    const canvas = this.pgStore.get('canvas');
    const gBounds = this.pgStore.get('globalZTbounds');
    if (!canvas.w || Number.isNaN(gBounds.minX) || gBounds.minX == Infinity) return null;

    const {x} = Util.eventXY(evt);
    return this.pgStore.gX2tX(x);
  },

  /**
   * pohyb koleckem mysi po grafu
   * vytvari novou custom udalost
  */
  graphMouseWheel (evt) {
    const map = this.pgStore.get('map');
    if (!map) return;

    const target = map.getCanvas();
    const mapRect = target.getBoundingClientRect();

    const clientX = mapRect.width/2 + mapRect.left;
    const clientY = mapRect.height/2 + mapRect.top;
    const screenX = clientX + window.screenX;
    const screenY = clientY + window.screenY;

    const init = Object.assign({}, {
      deltaX : evt.deltaX,
      deltaY : evt.deltaY,
      deltaZ : evt.deltaZ,
      deltaMode : evt.deltaMode,

      screenX,
      screenY,
      clientX,
      clientY,

      view : window,
      bubbles : true,
      cancellable : true
    });

    target.dispatchEvent(new WheelEvent('wheel', init));
  }
};
export {GraphMod};
