import PgRBush from './pgrbush.js';
import knn from "rbush-knn";
import distance from "@turf/distance";

import {fromState} from './helpers.js';

const Util = {
  /**
   * prvni pismeno male
  */
  lcfirst (str) {
    return str.charAt(0).toLowerCase() + str.substr(1);
  },

  eventXY (e) {
    // e = Mouse click event | touch event
    const target = e.currentTarget || e.target;
    const rect = target.getBoundingClientRect();
    const x = e.clientX - rect.left; //x position within the element.
    const y = e.clientY - rect.top;  //y position within the element.
    return {x, y};
  },

  /**
   * vraci Touch event objekt nejvice vlevo
   * @param e :object DOM event
  */
  getLeftmostTouch (e) {
    return [...e.targetTouches].reduce((t, touch) => {
      if (!t) t = touch;
      if (touch.clientX < t.clientX) t = touch;
      return t;
    }, null);
  },


  fullscreen (node, propName = 'RequestFullscreen', call = false) {
    const pref = ['', 'moz', 'webkit', 'ms'];
    const vars = {
      RequestFullscreen : {moz : 'RequestFullScreen'},
      ExitFullscreen : {moz : 'CancelFullScreen'},
      FullscreenElement : {moz : 'FullScreenElement'},
      FullscreenEnabled : {moz : 'FullScreenEnabled'}
    };
    let pname, prop;
    for (let prefix of pref) {
      pname = vars[propName][prefix] ? vars[propName][prefix] : propName
      prop = Util.lcfirst(prefix + pname);
      if (typeof node[prop] == "undefined") continue;
      if (call) {
        node[prop]();
        break;
      } else {
        return node[prop];
      };
    };
  },



  /**
   * delayer promise
  */
  delay (ms) {
    return data => new Promise((res, rej) => {
      window.setTimeout(() => res(data), ms);
    });
  },

  /**
  * Converts an RGB color value to HSL. Conversion formula
  * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
  * Assumes r, g, and b are contained in the set [0, 255] and
  * returns h, s, and l in the set [0, 1].
  *
  * @param   {number}  r       The red color value
  * @param   {number}  g       The green color value
  * @param   {number}  b       The blue color value
  * @return  {Array}           The HSL representation
  */
  rgb2hsl ([r, g, b]) {
    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max == min) {
        h = s = 0; // achromatic
    }else{
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return [Math.round(h*360), Math.round(s*100), Math.round(l*100)];
  },

  /**
   * @param hex - 6mistny hex kod barvy
  */
  hex2rgb (hex = '') {
    return [parseInt(hex.substr(0,2),16), parseInt(hex.substr(2,2),16), parseInt(hex.substr(4,2),16)];
  },

  invert ([r, g, b], bw = false) {
    if (bw) {
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? [0,0,0] : [255,255,255];
    };

    r = (255 - r);
    g = (255 - g);
    b = (255 - b);

    return [r, g, b];
  },

  /**
   * vraci HSLA barvu dle rgb a alpha
  */
  createHslaColor (rgb, alpha = 1) {
    const [h, s, l] = Util.rgb2hsl(rgb);
    return 'hsla('+h+', '+s+'%, '+l+'%, '+alpha+')';
  },


  palette : [
    'FF9800',

    'F44336','9C27B0','3F51B5',
    '03A9F4','009688','8BC34A',
    'FFEB3B','FF5722','9E9E9E',

    'E91E63','673AB7','2196F3',
    '00BCD4','4CAF50','CDDC39',
    'FFC107','795548','607D8B'
  ],

  baseColor: 'FFFFFF',

  /**
   * vraci hexa kod dle color index - bud z palety, nebo baseColor
  **/
  getColorCode (colorIndex) {
    return colorIndex < this.palette.length ? this.palette[colorIndex] : this.baseColor;
  },

  /** vraci pole vsech dostupnych barev (jen codes) z palette + baseColor **/
  getColorCodes () {
    const n = this.palette.length+1;
    return [...Array(n).keys()]
      .map(index => this.getColorCode(index));
  },

  /**
   * vraci HSLA barvu dle zadaneho color indexu
  */
  getHslaColor (colorIndex, alpha = 1) {
    const rgb = this.hex2rgb(this.getColorCode(colorIndex));
    return this.createHslaColor(rgb, alpha);
  },

  /**
   * @param values :Array
   * @param minmax :Array[min, max] - dosavadni min a max bounds
   * @returns array [min, max] - novy min a max bounds
  */
  extendRange (values = [], [min, max]) {
    return values.reduce((mm, value) => {
      mm[0] = value < mm[0] ? value : mm[0];
      mm[1] = value > mm[1] ? value : mm[1];
      return mm;
    }, [min, max]);
  },

  /**
   * zakrouhluje range na min. krok
   * @param minmax :Array [min, max] aktualni min a max bound
   * @param step krok na ktery se maji bounds zaokrouhlit
   * @returns array [min, max] - novy min a max bounds zaokroughleny na step
  */
  roundRange ([min, max], step = 1) {
    return [
      min - (min % step),
      max + step - (max % step)
    ];
  },

  /**
   * vraci cisty object bounds
   * @param bounds objekt - pokud chceme nektere property hned na zacatku prepsat
   * @returns objekt {minX, maxX, minY, maxY}
  */
  getFreshBounds (bounds = null) {
    return Object.assign({
      minX: Infinity,
      maxX: -Infinity,
      minY: Infinity,
      maxY: -Infinity
    }, bounds);
  },

  getFreshGeoJSON (type = "LineString", assignO = {}, coordinates = []) {
    return this.fresh(Object.assign({
      "type": "Feature",
      "id" : null,
      "properties": {},
      "geometry": {
        type,
        coordinates
      }
    }, assignO));
  },

  getFreshGeoJSONCollection (assignO = {}) {
    return this.fresh(Object.assign({
      "type":"FeatureCollection",
      "features":[]
    }, assignO));
  },

  stringify (o = {}) {
    return JSON.stringify(o);
  },

  /**
   * udela deep kopii objektu nebo pole pres json
   * pouze pro JSONizovatelne objekty (datove)
   * @param  o Object|Array
   * @returns Object|Array
  */
  fresh (o = {}) {
    return JSON.parse(this.stringify(o));
  },

  createJsonUrl (json = '', sync = false) {
    //synchronne
    if (sync) return window.URL.createObjectURL(new Blob([json], {type: 'application/json'}));
    //asynchronne
    return new Promise ((res, rej) => {
      res(window.URL.createObjectURL(new Blob([json], {type: 'application/json'})));
      json = null;
    });
  },

  /**
   * prevede pole ve formatu, ktery vraci Object.entries() zpet na Object
   * @param entries - array [[key, value], [key, value], ...]
   * @returns object
  */
  entriesToObject (entries = []) {
    return entries.reduce((obj, [key, val]) => {
      obj[key] = val;
      return obj;
    }, {});
  },


  /**
   * namapuje objekt s klici id na pole [[id, value], ...] - id prevede na integer
   * @param  o Object kde keys jsou id
   * @returns Array [[id, value], ...]
  */
  idEntries (o = {}) {
    return Object.entries(o).map(([id, ...rest]) => [parseInt(id), ...rest]);
  },

  /**
   * namapuje objekt s klici id na pole [id0, id1, ...] - id prevede na integer
   * @param  o Object kde keys jsou id
   * @returns Array [id0, id1, ...]
  */
  ids (o = {}) {
    return Object.keys(o);//.map(id => parseInt(id));
  },

  /**
   * namapuje objekt s klici a true/false hodnotami [key0: true, key1 : false, ...] na pole klicu, u kterych hodnoty byly true
   * @param  o Object kde values jsou true/false
   * @returns Array [key0, key2, ...]
  */
  trueKeys (o = {}) {
    return Object.entries(o).filter(([key, is]) => is).map(([key, val]) => key);
  },

  /**
   * vrati rozdil values (values v a1 co nejsou v a0)
   * @param a1 array
   * @param a0 array
   * @returns Array [v1, v2,...]
  */
  arrDiff (a1 = [], a0 = []) {
    return a1.filter(id => !a0.includes(id));
  },

  /**
   * vrati rozdil ids (ids v o1 co nejsou v o0)
   * @param o1 Object kde keys jsou id
   * @param o0 Object kde keys jsou id
   * @returns Array [id1, id2,...]
  */
  idsDiff (o1 = {}, o0 = {}) {
    return Util.arrDiff(Util.ids(o1), Util.ids(o0));
  },

  arrUniq (arr = []) {
    return [...new Set(arr)];
  },

  /**
   * @param store - store object
   * @param evt string; nazev udalosti
   * @param c - current state
   * @param p - previous state
   * @param ext - optional object, jeho props se pridavaji
  */
  fireChange (store, evt, current, previous, ext = null) {
    store.fire(evt, Object.assign({
      current,
      previous
    }, ext));
  },

  /**
   * vytvari celkove bounds z nekolika predanych bounds
  */
  reduceBounds (bounds = []) {
    //nalezeni min/max X
    const [minX, maxX] = Util.extendRange(
      [...bounds.map(b => b.minX), ...bounds.map(b => b.maxX)],
      [Infinity, -Infinity]
    );
    //nalezeni min/max Y
    const [minY, maxY] = Util.extendRange(
      [...bounds.map(b => b.minY), ...bounds.map(b => b.maxY)],
      [Infinity, -Infinity]
    );
    return {minX, maxX, minY, maxY};
  },

  /**
   * posunuje bounds o nejakou hodnotu
   * muze byt jen x nebo jen y
   * hodi se k presunu minX/maxX u ZT bounds na absolutni hodnoty casu
   * @returns nova kopie bounds s posunem
  */
  translateBounds (bounds, {x, y}) {
    bounds = Object.assign({}, bounds);
    if (x) {
      bounds.minX += x;
      bounds.maxX += x;
    };
    if (y) {
      bounds.minY += y;
      bounds.maxY += y;
    };
    return bounds;
  },

  /**
   * posouva bounds dle graphTimeMode po ose x

   * @param bounds {minX, maxY, minY, maxY}
   * @param timeMode ABSOLUTE|UTCTIME|LOCALTIME|STARTTIME
   * @param utcOffsetStart utc offset v sekundach (nutny pouze pro LOCALTIME) - defaultne UTC offset z lokalniho casu
   * @returns translatedBounds {minX, maxY, minY, maxY}
  */
  translateBoundsByTimeMode (bounds, timeMode, utcOffsetStart = null) {
    if (utcOffsetStart === null) utcOffsetStart = (new Date()).getTimezoneOffset()*-60;

    const timeF = x => (x % 86400) - x;
    let x;

    switch (timeMode) {
      case 'ABSOLUTE':
        x = 0;
        break;

      case 'UTCTIME':
        x = timeF(bounds.minX);
        break;

      case 'LOCALTIME':
        x = timeF(bounds.minX) + utcOffsetStart;
        break;

      case 'STARTTIME':
        x = -bounds.minX;
        break;
    };
    const tBounds = Util.translateBounds(bounds, {x});
    return tBounds;
  },

  /**
   * vraci UTC offset v sekundach z Date objektu, kompatibilni s tim co je u flightdata.utcOffsetStart
  */
  getUtcOffset (now = null) {
    if (now === null) now = new Date();
    return now.getTimezoneOffset() * -60;
  },

  /**
   * vraci translated time zpatky na absolutni
   * @param timeX - translated time, v sec
   * @param timeMode ABSOLUTE|UTCTIME|LOCALTIME|STARTTIME
   * @param utcOffsetStart utc offset v sekundach (nutny pouze pro LOCALTIME); defaultne offset ziskany z now
   * @param now Date() s casem; defaultne null, pak se bere aktualni cas
   * @returns absolute time (timestamp v sec)
  */
  retranslateTimeByTimeMode (timeX, timeMode, utcOffsetStart = null, now = null) {
    if (now === null) now = new Date();
    if ( utcOffsetStart === null ) utcOffsetStart = this.getUtcOffset(now);

    const tm = now.getTime()/1000;
    const timeF = x => x - (x % 86400);
    let x;

    switch (timeMode) {
      case 'ABSOLUTE':
        x = 0;
        break;

      case 'UTCTIME':
        x = timeF(tm);
        break;

      case 'LOCALTIME':
        x = timeF(tm) - utcOffsetStart;
        break;

      case 'STARTTIME':
        x = tm;
        break;
    };

    return timeX + x;
  },

  /**
   * spocita zakrouhleny casovy usek kolem soucasneho casu
   * @param timespan - delka casoveho useku v sekundach
   * @param margin - delka marginu v sec po obou stranach timespanu
   * @param tm - aktualni timestamp v sec, nebo null (pak si udela timestamp z aktualniho date)
   * @returns {minX, maxX} - lze pouzt v ZTbounds objektu
  */
  getCurrentTimespan (timespan = 600, margin = 300, tm = null) {
    if (tm === null) tm = Date.now() / 1000;
    const minX = Util.round(tm - tm % timespan - margin);
    const maxX = minX + timespan + 2 * margin;
    return {minX, maxX};
  },

  /**
   * vraci transformaci parametry pro dane bounds a dany canvas
   * @param bounds {minX, maxX, minY, maxY}
   * @param canvas {w, h}
   * @param gapSum gaps.sum, pripadne 0 (pan nehraje roli)
   * @returns {scaleX, scaleY, translateX, translateY}
  */
  getTransformParams (bounds, canvas, gapSum = 0) {
    const effectiveW = canvas.w-1;//kvuli tomu, ze realne na canvasu eventy dostavaj max souradnice 0 az width-1
    const [scaleX, scaleY] = [(bounds.maxX-bounds.minX-gapSum)/effectiveW, (bounds.maxY-bounds.minY)/canvas.h];
    const [translateX, translateY] = [bounds.minX, bounds.minY];
    return {scaleX, scaleY, translateX, translateY};
  },

  /**
   * spocita default transformacni matrix
   * @param canvas {w, h} - svg canvas size
   * @param bounds {minX, maxX, minY, maxY}
   * @param G global bounds {minX, maxX, minY, maxY}; pokud nejsou predany, pocita se to jen z bounds
   * @param gaps objekt {sum: celkovy gap, bounds: [gB0, gB1,..]} gapTbounds; pokud neni predano,nehraje roli
   * bounds: x osa jsou sekundy, y osa metry (nadmorska vyska)
   * @returns [a, b, c, d, e, f] - matrix pro svg transform
  */
  getTransformMatrix ({w, h}, {minX, maxX, minY, maxY}, G = null, gaps = {bounds: [], sum : 0}) {
    if (!G) G = {minX, maxX, minY, maxY};
    const prevGap = Util.getPrevGap(minX, gaps.bounds);//delka gapu pred zacatkem letu
    return  [
      w/(G.maxX-G.minX-gaps.sum),
      0,
      0,
      -h/(G.maxY-G.minY),
      ((minX-G.minX-prevGap)/(G.maxX-G.minX-gaps.sum))*w,
      h+((G.minY/(G.maxY-G.minY))*h)
    ];
  },

  /**
   * spocita pole diskretnich bounds, tj. spojitych useku s prekryvajici se bounds
   * resi jen osu X, tj. bounds staci kdyz maji {minX, maxX} - k minY, maxY se neprihlizi
   * @param bounds - bounds ze kterych pocitame
   * @param step - na jaky krok se ma zakrouhlovat range (minX, maxX)
   * @returns Array [discreteBounds1, discreteBounds2, ...] nebo []
  */
  discreteBounds (bounds, step = 1) {
    return bounds.reduce((acc, [id, fB]) => {
      const [minX, maxX] = Util.roundRange([fB.minX, fB.maxX], step);
      fB = {minX, maxX};
      if (acc.some(dB => fB.minX <= dB.maxX && fB.maxX >= dB.minX)) {
        return acc.map(dB => {
          let minX, maxX;
          if (fB.minX <= dB.maxX && fB.maxX >= dB.minX) {
            [minX, maxX] = Util.extendRange([fB.minX, fB.maxX], [dB.minX, dB.maxX]);
          } else {
            [minX, maxX] = [dB.minX, dB.maxX];
          }
          return {minX, maxX};
        });
      } else {
        const [minX, maxX] = [fB.minX, fB.maxX];
        return acc.concat({minX, maxX});
      };
    }, []).sort((a, b) => a.minX - b.minX);
  },

  /**
   * spocita bounds pripadnych mezer mezi jednotlivymi bounds
   * @param bounds - Array [bounds0, bounds1, ...] kde boundsX = {minX, maxX}
   * @returns Array [gapBounds0, gapBounds1, ...] nebo []
  */
  gapBounds (bounds) {
    return bounds.sort((a, b) => a.minX - b.minX)
    .reduce((acc, a, i, Bs) => {
      const b = Bs[i+1];
      if (!b || b.minX - a.maxX <= 0) return acc;
      const [minX, maxX] = [a.maxX, b.minX];
      return acc.concat({minX, maxX});
    }, []);
  },

  /**
   * delka gapu pred zacatkem letu
   * @param tX - tX v sekundach
   * @param gapTbounds gaps.bounds Array
   * @param incl false (default) | true - jestli se ma pocitat mezera, ktera zacina presne v tX
   * @returns celkovy gap PRED (incl = false) nebo V CASE (incl = true) tX
  */
  getPrevGap (tX, gapTbounds, incl = false) {
    return gapTbounds.reduce((acc, B) => acc += ((incl ? tX >= B.minX : tX > B.minX) ? B.maxX - B.minX : 0), 0);
  },

  /**
   * vraci filtrovaci funkci pro viditelne kroky
   * @param scale - scaleX nebo scaleY z transform
   * @param minPixelStep - min. pixel rozestup; int nebo funkce
   * @returns function (step, i, steps) => true ci false
  */
  getFilterStepsVisible (scale, minPixelStep) {
    return (step, i, steps) => {
      minPixelStep = typeof minPixelStep == 'function' ? minPixelStep(step) : minPixelStep;
      const nextStep = steps[i+1] && steps[i+1]%step > 0 ? steps[i+1]%step : step;
      return Math.min(step, nextStep)/scale > minPixelStep; //testujeme min. rozestup a take min. rozestup od nejblize vyssiho
    };
  },

  /**
   * prohledava index pole tracku
   * @param x - sekunda ke ktere hledame index; muze byt i float
   * @param index - index pole (resObject.index)
   * @param coords - geojson coords Array
   * @returns matrix [point0, point1, dist0, dist1] nebo null; kde:
   * point0 - predchozi bod tracku (defacto index bodu v coords)
   * point1 - nasledujici bod tracku (defacto index bodu v coords)
   * dist0 - rozdil mezi x a indexem bodu0 (defacto sekundy)
   * dist1 - rozdil mezi x a indexem bodu1 (defacto sekundy)
  */
  getInterpolateMatrix (x, index, coords) {
    const maxI = index.length-1;
    if (x < 0 || x > maxI) return null;
    const x0 = Math.floor(x);
    let point0 = index[x0==maxI ? x0-1 : x0];
    let point1 = point0 + 1;

    //test jestli body nejsou out of range - pokud jo, posouvame je, aby byly shodne
    if (coords[point0] === undefined) {
console.log('getInterpolateMatrix point0 is undefined, shifting...', x, x0, maxI, point0/*, index, coords*/)
      point0 = point1;//posouvame point 0 aby byl stejny jako point1
    };
    if (coords[point1] === undefined) {
console.log('getInterpolateMatrix point1 is undefined, shifting...', x, point1/*, index, coords*/)
      point1 = point0;//posouvame point 0 aby byl stejny jako point1
    };

    const dist0 = x - coords[point0][3].t;
    const dist1 = coords[point1][3].t - x;
    return [point0, point1, dist0, dist1];
  },

  /**
   * vytvari interpolovane coords
   * @param coords - pole z GeoJSON ...geometry.coordinates
   * @param index - indexove pole, res.index
   * @param matrix [point0, point1, dist0, dist1] - viz Util.searchIndex()
   * @returns coord Array [x,y,z, {g,t,d,v,s[,b]}]
   * pridava v (vario m/s, prednostne z baro vysky)
   * pridava s (speed km/h)
  */
  interpolateCoords (coords, index, [p0, p1, d0, d1]) {
    //pokud je point0 a point1 jsou stejne, vracime point0

    if (p0==p1) {
      const c0 = coords[p0];
      const {v, s} = Util.averagedVarioSpeed(c0, coords, index);
      return [...c0.slice(0,-1), {...c0[3], v, s}];
    };

    const c0 = coords[p0];
    const c1 = coords[p1];
    const step = d0 + d1;
    const r = d0 / step;
    const hasB = c0[3].b && c1[3].b;

    const {v, s} = Util.averagedVarioSpeed(c0, coords, index);

    const cx = [
      this.round(this.interpolateDim(c0[0], c1[0], r),6),
      this.round(this.interpolateDim(c0[1], c1[1], r),6),
      this.interpolateDim(c0[2], c1[2], r),
      {
        g: this.interpolateDim(c0[3].g, c1[3].g, r),//gnd height
        t: this.interpolateDim(c0[3].t, c1[3].t, r),//time
        d: this.interpolateDim(c0[3].d, c1[3].d, r),//distance
        l: this.interpolateDim(c0[3].l, c1[3].l, r),//length
        v, s
        /*
        v: (hasB ? c1[3].b - c0[3].b : c1[2]- c0[2]) / step, //vario m/s - prednostne z baro vysky, jinak z gps vysky
        s: (c1[3].d - c0[3].d) / step * 3.6, //rychlost km/h
        */
      }
    ];
    if (hasB) cx[3].b = this.interpolateDim(c0[3].b, c1[3].b, r);
    return cx;
  },

  interpolateDim (dim0, dim1, ratio) {
    return dim0 + (dim1-dim0)*ratio;
  },

  /**
   * spocita vario a speed pro danou coord
   * prumerovani 10 sekund pri 1 sec bodech - pokud mame delsi intervaly mezi body, nemusi to byt presne 10 sekund (variuje to kolem)
   * @param coord - bod z coords, ke kteremu potrebujem spocitat vario a speed
   * @param coords - pole coords -> res.geojson.geometry.coordinates
   * @param index - indexove pole -> res.coords
   * @returns {v,s} objekt
  */
  averagedVarioSpeed (coord, coords, index) {
    //pro vario a speed potrebujem predchozi bod -10 sec
    //pokud je to pred zacatkem tracklogu, berem 1. bod
    const prevI = index[coord[3].t-10];
    const prevCoord = coords[prevI ? prevI : 0];

    let deltaTime = coord[3].t - prevCoord[3].t;
    deltaTime = deltaTime || 1;

    const v = (coord[3].b && prevCoord[3].b ? coord[3].b - prevCoord[3].b : coord[2] - prevCoord[2]) / deltaTime;
    const s = Math.round((coord[3].d - prevCoord[3].d) / deltaTime * 3.6);

    return {v, s};
  },

  /**
   * zaokrouhluje cislo na urcity pocet desetinnych mist
  */
  round (num, digits = 0) {
    return parseFloat(num.toFixed(digits));
  },

  /**
   * omezuje nejakou hodnotu aby byla mezi nejmene min a nejvice max
  */
  bound (value, min, max) {
    return Math.min(max, Math.max(min, value));
  },

  createRbush () {
    return new PgRBush(9);
  },

  /**
   * vraci vzdalenost v metrech
   * @param from [x,y]
   * @param to [x,y]
   * pouziva @turf/distance;
  */
  distance (from, to)  {
    return distance(from, to)*1000;
  },

  distFromTo (pointA, pointB) {
    return (this.distance([pointA[0], pointA[1]], [pointB[0], pointB[1]])/1000).toFixed(1);
  },

  /**
   * notifikuje otevteni dialogu
   * pridava zaznam do historie, pokud neni (pushState), nebo ho modifikuje (replaceState)
   * ident - list|popup|...atd
  */
  dialogOpened (ident) {
    setTimeout(() => {
      const opened = fromState('dialogOpened', []);
      if (!opened.includes(ident)) opened.push(ident);
  //console.log('dialogOpened', ident, opened, fromState('dialogOpened'));
      this.setHistoryState({dialogOpened : opened}, !Array.isArray(fromState('dialogOpened')));
    }, 20);
  },

  /**
   * notifikuje zavreni dialogu, bud hlavniho flightsListu, nebo popup (popup|detail, oboji ma typ popup)
   * odebira z pole dialogOpened -> pokud v nem aspon neco bylo a ted neni nic, vola history.back() - ten spousti popstate event, ktery se obsluhuje v Live.html
   * ident - list|popup
  */
  dialogClosed (ident) {
    //musi tu byt setTimeout aby se stihla synchronizovat promenna dialogOpened v history.state pri otevreni dialogu, ktere zpusobi zavreni jineho dialogu
    //bez setTimeout se zahy zavre i ten nove otevreny dialog
    setTimeout(() => {
      let opened = fromState('dialogOpened', []);
//console.log('dialogClosed1', ident, opened);
      opened = this.arrDiff(opened, [ident]);
//console.log('dialogClosed2', ident, opened, fromState('dialogOpened', []));
//console.trace();
      if (opened.length == 0 && fromState('dialogOpened', []).length > 0) history.back();
    }, 10);
  },

  setHistoryState (obj, push = false) {
    const state = Object.assign({}, history.state || {}, obj);
//console.log('setHistoryState', obj, push, JSON.stringify(state))
    if (push) {
      history.pushState(state, '');
    } else {
      history.replaceState(state, '');
    };
  }
};

export {Util};
