import {
  ID_ROOT,
  FLIGHT_TYPES,
  ROUTE_GROUPS,
  TRACK_TYPES,
  COLORS
} from './const.js';
import {EncPoly} from "./encpoly.js";
import {MBUtil} from "./mbutil.js";
import {SvgUtil} from "./svgutil.js";
import {Util} from "./util.js";
//import {pgStore} from "./stores.js";

/**
 * Utils pro XContest web
 * utility funkce zavisle na datech XContest API atd
*/
const XCUtil = {
  pgStore : null,

  /** volat uplne na zacatku **/
  setStores ({pgStore}) {
    this.pgStore = pgStore;
  },

  resolveTicketUrl (api_key = '03ECF5952EB046AC-A53195E89B7996E4-D1B128E82C3E2A66') {
    const apiType = this.pgStore.get('apiType');
    //return 'https://www.xcontest.org/'+apiType+'/data/ticket/?key='+api_key;
    return window.location.origin+'/'+apiType+'/data/ticket/?key='+api_key;
  },

  /**
   * vraci URL na API dle flight id
  */
  resolveFlightApiUrl (id, type, api_key = '03ECF5952EB046AC-A53195E89B7996E4-D1B128E82C3E2A66') {
    const srcParts = ['league', 'volume', 'subcontest'];
    const src = Object.entries(this.pgStore.get('apiSource'))
      .filter(([ident, value]) => srcParts.includes(ident))
      .sort(([a], [b]) => srcParts.indexOf(a) - srcParts.indexOf(b))
      .map(([ident, value]) => value)
      .join("/");
    const apiType = this.pgStore.get('apiType');
    const V = apiType=='api.dev' ? '_' : '_';
    const apiLang = this.pgStore.get('apiLang');
    let url = window.location.origin+'/'+apiType+'/data/?flights'+V+'/'+src+':'+id+'&lng='+apiLang+'&key='+api_key;
    if (type == 'hires') url += '&profile=enc-hi-res';
    return url;
  },

  /**
    * airspace api url
    * @param country iso2 napr. CZ
    * @param date iso8601 date, napr. 2018-06-27T10:00:00.000Z nebo nic
    * @returns url
  */
  resolveAirspaceApiUrl (country, date = '') {
    let url = 'https://airspace.xcontest.org/api/geo/files/' + country;
    if (date) url += '/' + date;
    return url;
  },

  /**
    * task api url
    * @param code 4pismenny code tasku
    * @returns url
  */
  resolveTaskApiUrl (code = '') {
    return 'https://tools.xcontest.org/api/xctsk/geojson/' + code;
  },


  /**
   * @returns Promise; resolved s nactenymi json daty
  */
  fetchJSON (URL, init = {}) {
    return fetch(URL, init)
    //detekce jestli mame nacteno
    .then(res => {
      if (!res.ok) throw new Error('Fetch not OK');
      return res.json();
    });
  },

  /**
   * @param optionsdata
   * options: objekt, options pro incializaci mapbox mapy
   * b: objekt bounds {minX, maxX, minY, maxY}
   * @returns Promise; resolved: object Mapbox map
  */
  prepareMap ([options, b, pgStore]) {
    return new Promise(function (resolve, reject) {
      let map = MBUtil.loadMap(options, [b.minX, b.minY, b.maxX, b.maxY]);
      if (map == null) reject('MapboxGL.JS not supported');

      map = MBUtil.prepareMapBehavior(map, pgStore);
      map.on("load", evt => {
//console.log('map.load', evt)
        resolve(map);
      });
    });
  },

  /**
   * pridava source do mapy nebo updatuje data, pokud source (dle id) existuje
   * @param map - mapbox map
   * @param id - id source v mapbox mape
   * @param data - data pro source (url|geojson object)
   * @param assignO - object jehoz property se pridaji do definice source
  */
  setSource (map, id, data, assignO = {}) {
    const src = map.getSource(id);
    //jen update data
    if (src) {
      src.setData(data);
      return;
    };
    //pridani noveho source
    map.addSource(id, Object.assign({
        type: 'geojson',
        data: data
    }, assignO));
  },

  /**
   * vraci id letu
   * kvuli ruznym datovym zdrojum - XContest API, livetracking...kde muze byt id jinde
  */
  getFlightId (data) {
    return String(data.id);
  },

  /**
   * vraci id pilota
   * kvuli ruznym datovym zdrojum - XContest API, livetracking...kde muze byt id jinde
  */
  getPilotId (data) {
//console.log("getPilotId", data)
    return String(data.pilot.id);
  },

  /**
   * vraci id pro allocate color - bud id letu nebo id pilota
   * @param id - id flight
  */
  getOwnerId (id) {
    const data = this.pgStore.getFlightData(id);
    return this.pgStore.get('colorOwner')=='FLIGHT' ? XCUtil.getFlightId(data) : XCUtil.getPilotId(data);
  },

  /**
   * ziskani color indexu pro dany id flight
   * @param id - flightId
  */
  getColorIndex (id) {
    return this.pgStore.allocateColor(this.getOwnerId(id));
  },

  /**
   * ziskani hsla color
   * @param id - flightId
   * @returns hsla color
  */
  getColor (id, alpha = 1) {
    return Util.getHslaColor(this.getColorIndex(id), alpha);
  },

  /**
   * ziskani color code
   * hodi se pro barvu ikonky nebo pro inverted color
  */
  getColorCode (id) {
    return Util.getColorCode(this.getColorIndex(id));
  },

  /**
   * ziskani inverted BW color, ktera bude kontrastni k dane barve
  */
  getInvertedColor (id, alpha = 1) {
    const code = this.getColorCode(id);
    return Util.createHslaColor(Util.invert(Util.hex2rgb(code), true), alpha);
  },

  /**
   * nacita ikonu pro marker pilota
   * @returns promise
  */
  loadIcon (fill = '#000', stroke = 'white', strokewidth = 1.2, text = null, textAttrs = {}, attrs = {size: 15, viewBox: 15, diameter: 15, fontSize: 11}, donut = []) {
    return new Promise((resolve, reject) => {
      const size = attrs && attrs.size ? attrs.size : 15;
      const viewBox = attrs && attrs.viewBox ? attrs.viewBox : 15;
      const diameter = attrs && attrs.diameter ? attrs.diameter : 15;
      const fontSize = attrs && attrs.fontSize ? attrs.fontSize : 11;

      const img = new Image(size,size);
      const tcss = text ? ' .txt {font-size:'+fontSize+'px;font-family:sans-serif} ' : '';
      const css = '<style>' + tcss + '</style>';

      const move = (viewBox - diameter)/2;
      const scale = diameter/15;
      const translateValue = move > 0 ? ' translate('+move+','+move+')' : '';
      const scaleValue = scale !== 1 ?  ' scale(' + scale + ')' : '';
      const transformText = translateValue ? ' transform="' + translateValue + '"' : '';
      const transformCircle = translateValue || scaleValue ? ' transform="' + translateValue + scaleValue + '"' : '';

      const txtAttrs = Object.entries(Object.assign({
        'class': 'txt',
        'x': 2.5,
        'y': 11,
        'textLength': 10,
        'lengthAdjust': 'spacingAndGlyphs',
        'fill': stroke,
      }, textAttrs))
        .reduce((acc, [key, val]) => acc + ' ' + key + '="' + val + '"', '');

      const txt = text ? '<text '+ txtAttrs + transformText +'>'+text+'</text>' : '';
      const icoSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="'+size+'px" height="'+size+'px" viewBox="0 0 '+viewBox+' '+viewBox+'">'
        + css
        +'<path d="M14,7.5c0,3.5899-2.9101,6.5-6.5,6.5S1,11.0899,1,7.5S3.9101,1,7.5,1S14,3.9101,14,7.5z" fill="'+fill+'" stroke="'+stroke+'" stroke-width="'+strokewidth+'" ' + transformCircle + ' />'
        + (donut && donut.length ? this.donutSegments(donut, viewBox, diameter) : '')
        + txt
        +'</svg>';
//console.log('donut', donut, icoSvg)
      const icoUrl = URL.createObjectURL(new Blob([icoSvg], {type: 'image/svg+xml'}));
      img.onload = (evt) => resolve(img);
      img.onerror = (evt) => console.log(evt);
      img.src = icoUrl;
    });
  },

  donutSegments (donut = [], viewBox = 15, diameter = 15) {
    //const [live, lost, landed] = donut;
    const colors = [
      'hsl(120, 100%, 50%)', //live
      'hsl(0, 100%, 75%)', //lost
      'hsl(190, 100%, 50%)', //landed
    ];

    //sum funkce
    const sum = arr => arr.reduce((a, b) => a + b, 0);

    const total = sum(donut);
    const offsets = donut.reduce(
      (acc, cnt) => {
        const offset = sum(acc) + cnt;
        return acc.concat(offset);
      },
      [0]
    );
    offsets.pop();


    let code = '';
    donut.forEach((cnt, i) => {
      code += this.donutSegment(
        offsets[i] / total,
        (offsets[i] + cnt) / total,
        viewBox/2,
        diameter/2,
        colors[i]
      );
    });
//console.log('donut', donut, total, offsets)
    return code;
  },

  donutSegment (start, end, r, r0, color) {
    if (end - start === 1) end -= 0.00001;
    var a0 = 2 * Math.PI * (start - 0.25);
    var a1 = 2 * Math.PI * (end - 0.25);
    var x0 = Math.cos(a0),
    y0 = Math.sin(a0);
    var x1 = Math.cos(a1),
    y1 = Math.sin(a1);
    var largeArc = end - start > 0.5 ? 1 : 0;

    return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    'L',
    r + r * x0,
    r + r * y0,
    'A',
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    'L',
    r + r0 * x1,
    r + r0 * y1,
    'A',
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    '" fill="' + color + '" />'
    ].join(' ');
  },


  /**
   * vrati pole flight layer ids, pripadne profiltrovane
   * @param FilterF = filtrovaci funkce pro type
   * @param id = id flight
  */
  getTrackLayersIds (id, FilterF = () => true) {
    return TRACK_TYPES.filter(FilterF).reduce((acc, type) => {
      return acc.concat(['line', 'outline'].map(subtype => XCUtil.getTrackLayerId(type, subtype, id)));
    }, []);
  },

  /**
   * @param type = :TRACK_TYPES
   * @param subtype = line|outline
   * @param id = id flight
  */
  getTrackLayerId (type, subtype, id) {
    return ID_ROOT.track+type+'-'+subtype+'-'+id;
  },
  /**
   * @param type = :TRACK_TYPES
   * @param id = id flight
  */
  getTrackSourceId (type, id) {
    return ID_ROOT.tracksrc+type+'-'+id;
  },

  /**
   * @param type = :ROUTE_TYPES
   * @param id = id flight
  */
  getRouteLayerId (type, id) {
    return ID_ROOT.route+type+'-'+id;
  },

  /**
   * @param type = :ROUTE_TYPES
   * @param id = id flight
  */
  getRouteSourceId (type, id) {
    return ID_ROOT.routesrc+type+'-'+id;
  },

  /**
   * @param type = :MARKER_TYPES
   * @param id = id flight
  */
  getMarkerLayerId (type, id) {
    return ID_ROOT.marker+type+'-'+id;
  },

  /**
   * @param type = :MARKER_TYPES
   * @param id = id flight
  */
  getMarkerSourceId (type, id) {
    return ID_ROOT.markersrc+type+'-'+id;
  },

  /**
   * id skupiny pro 1 let (g) v svg grafu
   * @param id = id flight
  */
  getGraphGroupId (id) {
    return 'group-'+id;
  },

  getAirspaceId (iso) {
    return 'airspace-'+iso;
  },

  getTaskId (code) {
    return 'task-'+code;
  },

  /**
   * id path v svg grafu
   * @param type = :PATH_TYPES
   * @param id = id flight
  */
  getGraphPathId (type, id) {
    return 'path-'+type+'-'+id;
  },

  /**
   * @param data - basic data letu
   * @param type :MARKER_TYPES
   * @param route_type :ROUTE_TYPES
   * @returns objekt s daty turnpointu z API dat
  */
  getTurnpointsData (data, type, route_type) {
    let points;
    switch (type) {
      case 'startend':
        points = [data.pointStart, data.pointEnd];
        break;

      case 'turnpoints':
        switch (route_type) {
          case 'DEFAULT':
          case 'TRIANGLE':
            points = data.league.route;
            break;

          case 'FREE_FLIGHT':
            points = data.routeFreeFlight;
            break;

          case 'FREE_DISTANCE':
            points = data.routeFreeDistance;
            break;
        };
        points = points.turnpoints;
        break;
    };
    return points;
  },

  /**
   * @return custom DOM node pro marker turpoint
  */
  getTurnpointElement () {
    const el = document.createElement('div');
    //el.className = 'icon rotate';
    el.innerHTML = "<svg class='icon w18 h18'><use xlink:href='#icon-rotate'/></svg>";
    el.style.color = COLORS.turnpoints;
    return el;
  },

  /**
   * @return array; custom DOM nodes pro marker start a end
  */
  getStartendFlyElements () {
    const elS = document.createElement('div');
    elS.className = 'xcicon start';
    elS.style.color = COLORS.startend[0];
    elS.appendChild(SvgUtil.createNode(
      'svg',
      {x:0, y:0, viewBox: '0 0 12 12'},
      null,
      '<style type="text/css">.st4{stroke-width:0} .st5{fill:none;stroke:#FFFFFF;stroke-width:1.575;stroke-linecap:round;stroke-miterlimit:10;}</style><g><circle class="st4" cx="6" cy="6" r="6"/><g><line class="st5" x1="8" y1="6" x2="5.1" y2="9"/><line class="st5" x1="5.1" y1="3" x2="8" y2="6"/></g></g>'
    ));

    const elE = document.createElement('div');
    elE.className = 'xcicon end';
    elE.style.color = COLORS.startend[1];
    elE.appendChild(SvgUtil.createNode(
      'svg',
      {x:0, y:0, viewBox: '0 0 12 12'},
      null,
      '<style type="text/css">.st6{stroke-width:0} .st5{fill:none;stroke:#FFFFFF;stroke-width:1.575;stroke-linecap:round;stroke-miterlimit:10;}</style><g><circle class="st6" cx="6" cy="6" r="6"/><g><line class="st5" x1="8.5" y1="3.6" x2="3.6" y2="8.4"/><line class="st5" x1="3.6" y1="3.6" x2="8.5" y2="8.4"/></g></g>'
    ));

    return [elS, elE];
  },
  /**
   * @return array; custom DOM nodes pro marker start a end
  */
  getStartendWalkElements () {
    const el = document.createElement('div');
    //el.className = 'icon depart';
    el.innerHTML = "<svg class='icon w18 h18'><use xlink:href='#icon-depart'/></svg>";
    el.style.color = COLORS.startend[0];
    return [el, document.createElement('div')];
  },

  /**
   * @return custom DOM node pro marker pilot
  */
  getPilotElement (color) {
    const el = document.createElement('div');
    //el.className = 'icon point';
    el.innerHTML = "<svg class='icon w18 h18'><use xlink:href='#icon-circle'/></svg>";
    Object.assign(el.style, {
      color,
      cursor : 'pointer',
      //'text-shadow' :'-1px -1px 1px white, 1px -1px 1px white, -1px 1px 1px white, 1px 1px 1px white'
    });

    return el;
  },

  /**
   * @param encRes: objekt {
   *  enc: encoded data letu - objekt s klici xy, a, t, d, g, b
   *  res: resultObject - na zacatku fresh
   *  add: objekt - point z JSON API data s klici latitude, longitude, ktery se prida na konec,
   *  id: id ktere se predava dal
   * }
   * @returns Promise; resolvuje s naplnenym resultObject a id [resObj, id]
  */
  prepareTrackData ({enc, res, add, id}) {
    return Promise.resolve(res)
    .then(res => EncPoly.decode(
      enc.data,
      res,
      EncPoly.callbackPoint,
      enc.props
    ))
    .then(res => [res, id]);
  },

  /**
   * kresli track letu/walku do mapy
   * [
      map - mapbox map objekt,
      ids - objekt s klici
       - line,
       - outline (pouzije se pro ID layeru),
       - source (pro ID source),
       - before (optional: ID layeru pod ktery se ma vykreslit)
      colors - objekt s klici line a outline (pouzije se pro color layeru),
    ]
    * @param flight_type :FLIGHT_TYPES 1|2
    * @param layerDef - objekt, pripadne se assignuje do definice layeru
    * @returns Promise; resolvuje s resultObject
  */
  drawTrack ([map, ids, colors], flight_type = 1, layerDef = {}) {
    return new Promise(function (resolve, reject) {
      const type = FLIGHT_TYPES[flight_type];
//console.log('XCUtil.drawTrack', MBUtil.getLayerDef(ids.line, type+'-line', ids.source, colors.line, layerDef))
      map.addLayer(
        MBUtil.getLayerDef(ids.line, type+'-line', ids.source, colors.line, layerDef),
        ids.before
      );
      map.addLayer(
        MBUtil.getLayerDef(ids.outline, type+'-outline', ids.source, colors.outline, layerDef),
        ids.line
      );
      resolve();
    });
  },

  /**
   * ziskani route group
   * vezme prvni route_group ve ktere se dany route type nachazi
   * pokud neni v zadne route group, vytvori group s 1 prvkem
   * @param type: route_type
   * @returns array, bud 1 prvek (svuj route_type), nebo svou route_group
  */
  getRouteGroup (type) {
    return ROUTE_GROUPS.filter(group => group.includes(type)).shift() || [type];
  },
  /**
   * @param data - basic data letu
   * @param type :ROUTE_TYPES
   * @returns objekt s daty turnpointu z API dat
  */
  getRouteData (data, type) {
    let route;
    switch (type) {
      case 'DEFAULT':
      case 'TRIANGLE':
        route = data.league.route;
        break;

      case 'FREE_FLIGHT':
        route = data.routeFreeFlight;
        break;

      case 'FREE_DISTANCE':
        route = data.routeFreeDistance;
        break;
    };
    return route;
  },

  /**
   * prevadi turpoints z route API do geojsonu
   * @param routeRes: object {
   *  turnpoints: array route z API (klic turnpoints)
   *  res: resultObject pro route - na zacatku fresh
   * }
   * @returns Promise; resolvuje s naplnenym resultObject
  */
  prepareRouteData ({route, res}) {
    return route.turnpoints
      .reduce(XCUtil.coordsReducer, res);
  },

  prepareTriangleData ({route, res}) {
    return route.turnpoints
      .filter((tp, i) => ![0,4].includes(i)) //vyhodime bod c.0 a 4
      .concat(route.turnpoints[1]) //pridame bod c.1 na konec aby se to uzavrelo
      .reduce(XCUtil.coordsReducer, res);
  },

  preparePointsData ({points, res}) {
    return points.reduce(XCUtil.coordsReducer, res);
  },

  /**
   * reducer funkce pro pridani lng lat coord
   * @param r - resultObject s parametrem geojson
   * @param tp - turnpoint, object s prop longitude, latitude
  */
  coordsReducer (r, tp) {
    r.geojson.geometry.coordinates.push([tp.longitude, tp.latitude]);
    return r;
  },

  /**
   * reducer funkce pro funkce vyse
   * @param r - resultObject s parametrem geojson
   * @param tp - turnpoint, object s prop longitude, latitude, altitude
   * @param o - optional; objekt ktery se prida na 4. misto coord bodu
  */
  extCoordsReducer (r, tp, o = null) {
    const p = [tp.longitude, tp.latitude, (tp.altitude ? tp.altitude : 0)];
    if (o) p.push(o);
    r.geojson.geometry.coordinates.push(p);
    return r;
  },


  /**
   * kresli route do mapy
   * [
      map - mapbox map objekt,
      id - id (pouzije se pro ID layeru),
      source - geojson data (object) | id source (string)
      color - color (pouzije se pro color layeru),
      before - ID layeru, pred ktery se route dava, nebo undefined
    ]
    * @returns resultObject
  */
  drawRoute ([map, id, source, color, before]) {
    map.addLayer(
      MBUtil.getLayerDef(id, 'route-line', source, color),
      before
    );
    return source;
  },

  /**
   * kresli airspace do mapy
   * @param map - mapbox map objekt,
   * @param id - id (pouzije se pro ID layeru),
   * @param geojson - airspace geojson
   * @param before - ID layeru, pred ktery se route dava, nebo undefined
   * @returns objekt map Mapbox GL JS
  */
  drawAirspace (map, id, geojson, before) {
    return map.addLayer(
      MBUtil.getLayerDef(id, 'airspace', geojson),
      before
    );
  },

  drawTask (map, id, gs, before) {
    map = map.addLayer(
      MBUtil.getLayerDef(id+'-fill', 'task-fill', gs.fill),
      before
    )
    .addLayer(
      MBUtil.getLayerDef(id+'-line', 'task-line', gs.line),
      before
    )
    /*.addLayer(
      MBUtil.getLayerDef(id+'-symbol', 'task-symbol', geojson.symbol),
      before
    )*/;
    if (gs.bounds) map.fitBounds(gs.bounds, {padding: 40});

    return map;
  },

  getLiveUserId (staticInfo) {
    return staticInfo.user.uid;
  },

  getLiveXcDist (actualInfo, contest) {
    const lg = actualInfo.contest[contest];
    return lg.route ? lg.route.distance : (lg.score ? lg.score.distanceKm : 0);
  },

  /** ERRORS & WARNINGS **/
  checkType (type, types) {
    if (!types.includes(type)) throw new Error("incorrect type, must be" + types.toString());
  }
};
export {XCUtil};
