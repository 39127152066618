class ExtDate extends Date {
  /**
   * formatuje podle strftime standardu
   * https://www.freebsd.org/cgi/man.cgi?query=strftime&sektion=3
  */
  strftime (fmt) {
  	let res = '';
  	let n = 0;
  	while(n < fmt.length) {
  		let c = fmt.substring(n, n+1);
  		if (c == '%') {
  			c = fmt.substring(++n, n+1);
  			res += (this.strftime_f[c]) ? this.strftime_f[c](this) : c;
  		} else res += c;
  		++n;
  	}
  	return res;
  }

  /**
   * @param DateObj - Date nebo ExtDate objekt; neni-li predan, bere se now()
   * @param timezone - integer, requested timezone offset in hours; default to UTC (0)
   * @returns new date object adjusted to requested timezone
  */
  static toTimezone (DateObj = undefined, timezone = 0) {
    DateObj = DateObj ? DateObj : new ExtDate(Date.now());
    const UTCTime = DateObj.getTime() + (DateObj.getTimezoneOffset() * 60 * 1000);
  	return new ExtDate(UTCTime + (timezone * 60 * 60 * 1000));
  }

  /**
   * @param DateObj - Date nebo ExtDate objekt; neni-li predan, bere se now()
   * @returns timezone in hours of current machine (browser)
  */
  static clientTimezone (DateObj = undefined) {
    DateObj = DateObj ? DateObj : new ExtDate(Date.now());
    return DateObj.getTimezoneOffset()/-60;
  }

  /**
   * @param timezone timezone offset in hours
   * @returns string; timezone offset in format +-hh:mm
  */
  static timezoneToUTCoffset (timezone) {
    const secs = timezone*3600;
    if (secs==0) return " UTC";
    const sign = secs>=0 ? "+" : "-";
    const Dsecs = new ExtDate(Math.abs(secs*1000));
    return sign + ExtDate.toTimezone(Dsecs).strftime("%H:%M");
  }

  static pad (val, n, p = '0') {
		return  ('' + val).padStart(n, p);
	}

  /**
   * create internal datetime from ISO datetime string, example: 2007-10-26T22:29:27Z
   * could be only date, ex. 2007-10-26
   * @returns date object (self)
   * WARNING! ONLY UTC (Z) expected
  */
  setIsoTime (isoTimeString) {
  	const iS = isoTimeString;
  	const wT = iS.length>=20 && iS.indexOf('T')==10;//time?
    const wM = iS.length>21;//miliseconds?

    this.setTime(ExtDate.UTC(
      parseInt(iS.substr(0,4), 10),
      parseInt(iS.substr(5,2), 10)-1,
      parseInt(iS.substr(8,2), 10),
      wT ? parseInt(iS.substr(11,2), 10) : 0,
      wT ? parseInt(iS.substr(14,2), 10) : 0,
      wT ? parseInt(iS.substr(17,2), 10) : 0,
      wM ? parseInt(iS.substr(20,3), 10) : 0
    ));

  	return this;
  }

  getIsoTime () {
    return ExtDate.toTimezone(this).strftime("%FT%TZ");
  }

  /**
   * funguje pouze pro intervaly do 24 hodin
  */
  setIsoInterval (isoIntervalString) {
  	let iS = isoIntervalString;
    if (iS==null) iS = 'PT00H00M00S';

    this.setTime(ExtDate.UTC(2000, 0, 1,
      parseInt(iS.substr(2,2), 10),
      parseInt(iS.substr(5,2), 10),
      parseInt(iS.substr(8,2), 10),
      0
    ));
  	return this;
  }

  /**
   * funguje pouze pro intervaly do 24 hodin
   * prevadi cas nastaveny v Date do ISO intervalu
  */
  getIsoInterval () {
    return ExtDate.toTimezone(this).strftime("PT%HH%MM%SS");
  }

  /**
   * pocet milisec od pulnoci UTC v danem dni
  */
  getTimeInDay () {
    return (this.getUTCHours()*3600+this.getUTCMinutes()*60+this.getUTCSeconds())*1000;
  }

  /**
   * timestamp pro pulnoc UTC daneho dne
  */
  getMidnightTime () {
    return this.getTime() - this.getTimeInDay();
  }
};

ExtDate.prototype.months = [
	'January', 'February', 'March', 'April', 'May', 'June', 'July',
	'August', 'September', 'October', 'November', 'December'
];

ExtDate.prototype.weekdays = [
	'Sunday', 'Monday', 'Tuesday', 'Wednesday',
	'Thursday', 'Friday', 'Saturday'
];

ExtDate.prototype.dpm = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

ExtDate.prototype.strftime_f = {
	A: d => d.weekdays[d.getDay()],
	a: d => d.weekdays[d.getDay()].substring(0,3),
	B: d => d.months[d.getMonth()],
	b: d => d.months[d.getMonth()].substring(0,3),
	C: d => Math.floor(d.getFullYear()/100),
	c: d => d.toString(),
	D: d => d.strftime_f.m(d) + '/' + d.strftime_f.d(d) + '/' + d.strftime_f.y(d),
	d: d => ExtDate.pad(d.getDate(), 2),
	e: d => ExtDate.pad(d.getDate(), 2, ' '),
	F: d => d.strftime_f.Y(d) + '-' + d.strftime_f.m(d) + '-' + d.strftime_f.d(d),
	H: d => ExtDate.pad(d.getHours(), 2),
	I: d => ExtDate.pad((d.getHours() % 12 || 12), 2),
  i: d => ExtDate.pad(d.getMinutes(), 2, ''),//extenze - minuty bez leading zeros
	j: d => {
	  let t = d.getDate();
		let m = d.getMonth() - 1;
		if (m > 1) {
			const y = d.getYear();
			if (((y % 100) == 0) && ((y % 400) == 0)) ++t;
			else if ((y % 4) == 0) ++t;
		}
		while (m > -1) t += d.dpm[m--];
		return ExtDate.pad(t, 3);
	},
	k: d => ExtDate.pad(d.getHours(), 2, ' '),
	l: d => ExtDate.pad((d.getHours() % 12 || 12), 2, ' '),
	M: d => ExtDate.pad(d.getMinutes(), 2),
	m: d => ExtDate.pad((d.getMonth()+1), 2),
	n: d => "\n",
	p: d => d.getHours() > 11 ? 'PM' : 'AM',
	R: d => d.strftime_f.H(d) + ':' + d.strftime_f.M(d),
	r: d => d.strftime_f.I(d) + ':' + d.strftime_f.M(d) + ':' + d.strftime_f.S(d) + ' ' + d.strftime_f.p(d),
	S: d => ExtDate.pad(d.getSeconds(), 2),
	s: d => Math.floor(d.getTime()/1000),
	T: d => d.strftime_f.H(d) + ':' + d.strftime_f.M(d) + ':' + d.strftime_f.S(d),
	t: d => "\t",
/*		U: function (d) { return false }, */
	u: d => (d.getDay() || 7),
/*		V: function (d) { return false }, */
	v: d => d.strftime_f.e(d) + '-' + d.strftime_f.b(d) + '-' + d.strftime_f.Y(d),
/*		W: function (d) { return false }, */
	w: d => d.getDay(),
	X: d => d.toTimeString(), // wrong?
	x: d => d.toDateString(), // wrong?
	Y: d => d.getFullYear(),
	y: d => ExtDate.pad((d.getYear() % 100), 2),
//		Z: function (d) { return d.toString().match(/\((.+)\)$/)[1]; },
//		z: function (d) { return d.getTimezoneOffset() }, // wrong
//		z: function (d) { return d.toString().match(/\sGMT([+-]\d+)/)[1]; },
	'%': d => '%',
};

ExtDate.prototype.strftime_f['+'] = ExtDate.prototype.strftime_f.c;
ExtDate.prototype.strftime_f.h = ExtDate.prototype.strftime_f.b;

export {ExtDate};
