import RBush from 'rbush';

export default class PgRBush extends RBush {
    toBBox ([x, y]) {
      return {minX: x, minY: y, maxX: x, maxY: y};
    }

    compareMinX (a, b) {
      return a[0] - b[0];
    }

    compareMinY (a, b) {
      return a[1] - b[1];
    }
};
