<script>
export let info = null;
</script>

<style>
  div {
    display: flex;
    align-items: flex-end;
    margin-right: 5px;
  }
  .takeoff {
    font-weight: bold;
    max-width: 80px;
    margin: 0 3px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>

<div><svg class='icon w12 h12'><use xlink:href='#icon-mountain' /></svg><span class="takeoff">{info.static.takeoff.name}</span> <span class="country">[{info.static.country.iso}]</span></div>
