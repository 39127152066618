/**
full Mapbox style
**/
const Style = {
  "metadata": {
    "mapbox:autocomposite": false,
    "mapbox:type": "template",
    "maputnik:renderer": "mbgljs",
    "openmaptiles:version": "3.x",
  },
  "sources": {
    "src:xcontest:topo": {
      "type": "raster",
      "tiles": [
        //"https://maps1.pgweb.cz/elev/{z}/{x}/{y}"
        //"https://d3ulwt7gr64jrn.cloudfront.net/elev/{z}/{x}/{y}"
        "https://topo.xcontest.app/elev/{z}/{x}/{y}.jpg"
      ],
      "tileSize": 256,
      "minzoom": 0,
      "maxzoom": 19,
      "attribution": "<a href='https://www.xcontest.org/'>&copy; XContest.org</a>"
    },
    "src:esri:sat": {
      "type": "raster",
      "tiles": [
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      ],
      "tileSize": 256,
      "minzoom": 0,
      "maxzoom": 17,
      "attribution": "<a href='https://www.xcontest.org/'>&copy; XContest.org</a> | Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
    },
    "openmaptiles": {
      "type": "vector",
      "url": "https://tile.xcontest.app/v1.json",
      //"url": "https://d393ilck4xazzy.cloudfront.net/w/metadata.json"
    }
  },
  "layers": [
    {
      "id": "background_green",
      "type": "background",
      "paint" : {
        "background-color":  "hsla(108, 45%, 43%, 1)"
      }
    },
    {
      "id": "background_topo",
      "type": "raster",
      "source": "src:xcontest:topo"
    },
    {
      "id": "background_sat",
      "type": "raster",
      "source": "src:esri:sat",
      "layout": {
        "visibility": "none"
      }
    },
    {
      "id": "landuse",
      "type": "fill",
      "source": "openmaptiles",
      "source-layer": "landuse",
      "filter": [
        "==",
        ["string", ["get", "class"]],
        "agriculture"
      ],
      "paint": {
        "fill-color": "#eae0d0"
      }
    },
    {
      "id": "landuse_overlay_national_park",
      "type": "fill",
      "source": "openmaptiles",
      "source-layer": "landcover",
      "filter": [
        "==",
        ["string", ["get", "class"]],
        "national_park"
      ],
      "paint": {
        "fill-color": "#E1EBB0",
        "fill-opacity": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          5, 0,
          9, 0.75
        ]
      }
    },
    {
      "id": "park_outline",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "park",
      "layout": {},
      "paint": {
        "line-color": "rgba(159, 183, 118, 0.69)",
        "line-dasharray": [0.5, 1]
      }
    },
    {
      "id": "waterway-tunnel",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "waterway",
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "LineString"],
        ["==", ["string", ["get", "brunnel"]], "tunnel"]
      ]],
      "paint": {
        "line-color": "hsl(205, 56%, 73%)",
        "line-width": [
          "interpolate",
          ["exponential", 1.4],
          ["zoom"],
          8, 1,
          20, 2
        ],
        "line-opacity": 1,
        "line-gap-width":
        [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          12, 0,
          20, 6
        ],
        "line-dasharray": [3, 3]
      }
    },
//included START
    {
      "id": "water",
      "type": "fill",
      "source": "openmaptiles",
      "source-layer": "water",
      "filter": [
        "all",
          ["==", ["geometry-type"], "Polygon"],
          ["!=", ["string", ["get", "class"]], "ocean"]
      ],
      "paint": {
        "fill-color": "#456A85"
      }
    },
    {
      "id": "waterway",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "waterway",
      "minzoom": 8,
      "filter": [
        "all",
        ["==", ["geometry-type"], "LineString"],
        ["!=", ["string", ["get", "class"]], "stream"],
        [
          "match",
          ["string", ["get", "brunnel"], ""],
          ["tunnel", "bridge"],
          false,
          true
        ]
      ],
      "paint": {
        "line-color": "#456A85",
        "line-width": {"base": 1.4, "stops": [[8, 0.8], [20, 8]]},
        "line-opacity": 1
      }
    },
//included END
    {
      "id": "tunnel_railway_transit",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "minzoom": 0,
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "LineString"],
        ["==", ["string", ["get", "brunnel"]], "tunnel"],
        ["==", ["string", ["get", "class"]], "transit"]
      ]],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter"
      },
      "paint": {
        "line-color": "hsl(34, 12%, 66%)",
        "line-opacity": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          11, 0,
          16, 1
        ],
        "line-dasharray": [3, 3]
      }
    },
    {
      "id": "building",
      "type": "fill",
      "source": "openmaptiles",
      "source-layer": "building",
      "paint": {
        "fill-color": "rgba(222, 211, 190, 1)",
        "fill-outline-color": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          15, "rgba(212, 177, 146, 0)",
          16, "rgba(212, 177, 146, 0.5)"
        ],
        "fill-opacity": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          13, 0,
          15, 1
        ],
        "fill-antialias": true
      }
    },
    {
      "id": "road_bridge_area",
      "type": "fill",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "Polygon"],
        ["==", ["string", ["get", "brunnel"]], "bridge"]
      ]],
      "layout": {},
      "paint": {
        "fill-color": "hsl(47, 26%, 88%)",
        "fill-opacity": 0.5
      }
    },
    {
      "id": "road_path",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
        "let", "cl", ["string", ["get", "class"]],
        [
          "all",
          ["==", ["geometry-type"], "LineString"],
          [
            "any",
            ["==", ["var", "cl"], "path"],
            ["==", ["var", "cl"], "track"]
          ]
        ]
      ],
      "layout": {
        "line-cap": "square",
        "line-join": "bevel",
        "visibility": "visible"
      },
      "paint": {
        "line-color": "rgba(247, 247, 247, 0.5)",
        "line-dasharray": [1, 1],
        "line-width": [
          "interpolate",
          ["exponential", 1.55],
          ["zoom"],
          4, 0.25,
          20, 10
        ]
      }
    },
    {
      "id": "road_minor",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
        "let", "cl", ["string", ["get", "class"]],
        [
          "all",
          ["==", ["geometry-type"], "LineString"],
          [
            "any",
            ["==", ["var", "cl"], "minor"],
            ["==", ["var", "cl"], "service"]
          ]
        ]
      ],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-color": "rgba(247, 247, 247, 0.5)",
        "line-width": [
          "interpolate",
          ["exponential", 1.55],
          ["zoom"],
          4, 0.25,
          20, 30
        ]
      }
    },
    {
      "id": "tunnel_minor",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "LineString"],
        ["==",["string", ["get", "brunnel"]], "tunnel"],
        ["==",["string", ["get", "class"]], "minor_road"]
      ]],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter"
      },
      "paint": {
        "line-color": "#efefef",
        "line-width": [
          "interpolate",
          ["exponential", 1.55],
          ["zoom"],
          4, 0.25,
          20, 30
        ],
        "line-dasharray": [0.36, 0.18]
      }
    },
    {
      "id": "tunnel_major",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
         "let", "cl", ["string", ["get", "class"]],
        [
          "all",
          ["==", ["geometry-type"], "LineString"],
          ["==", ["string", ["get", "brunnel"]], "tunnel"],
          [
            "any",
            ["==", ["var", "cl"], "primary"],
            ["==", ["var", "cl"], "secondary"],
            ["==", ["var", "cl"], "tertiary"],
            ["==", ["var", "cl"], "trunk"]
          ]
        ]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter"
      },
      "paint": {
        "line-color": "#fff",
        "line-width": [
          "interpolate",
          ["exponential", 1.4],
          ["zoom"],
          6, 0.5,
          20, 30
        ],
        "line-dasharray": [0.28, 0.14]
      }
    },
    {
      "id": "aeroway-area",
      "type": "fill",
      "metadata": {
        "mapbox:group": "1444849345966.4436"
      },
      "source": "openmaptiles",
      "source-layer": "aeroway",
      "minzoom": 4,
      "filter": [
        "let", "cl", ["string", ["get", "class"]],
        [
          "all",
          ["==", ["geometry-type"], "Polygon"],
          [
            "any",
            ["==", ["var", "cl"], "runway"],
            ["==", ["var", "cl"], "taxiway"]
          ]
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-opacity": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          13, 0,
          14, 0.5
        ],
        "fill-color": "rgba(255, 255, 255, 1)"
      }
    },
    {
      "id": "aeroway-taxiway",
      "type": "line",
      "metadata": {
        "mapbox:group": "1444849345966.4436"
      },
      "source": "openmaptiles",
      "source-layer": "aeroway",
      "minzoom": 12,
      "filter": ["boolean", [
        "all",
        ["==", ["string", ["get", "class"]], "taxiway"],
        ["==", ["geometry-type"], "LineString"]
      ]],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-color": "rgba(255, 255, 255, 1)",
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          12, 1,
          17, 10
        ],
        "line-opacity": 0.6
      }
    },
    {
      "id": "aeroway-runway",
      "type": "line",
      "metadata": {
        "mapbox:group": "1444849345966.4436"
      },
      "source": "openmaptiles",
      "source-layer": "aeroway",
      "minzoom": 4,
      "filter": ["boolean", [
        "all",
        ["==", ["string", ["get", "class"]], "runway"],
        ["==", ["geometry-type"], "LineString"]
      ]],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsla(0, 0%, 70%, 1)",
        "line-width": [
          "interpolate",
          ["exponential", 1.5],
          ["zoom"],
          11, 4,
          17, 50
        ],
        "line-opacity": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          12, 0.6,
          14, 1
        ]
      }
    },
    {
      "id": "road_trunk_primary",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "minzoom": 7,
      "filter": [
        "let", "cl", ["string", ["get", "class"]],
        [
          "all",
          ["==", ["geometry-type"], "LineString"],
          [
            "any",
            ["==", ["var", "cl"], "primary"],
            ["==", ["var", "cl"], "trunk"]
          ]
        ]
      ],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible",
        "line-miter-limit": 2,
        "line-round-limit": 1
      },
      "paint": {
        "line-color": "hsla(40, 20%, 80%, 1)",
        "line-width": [
          "interpolate",
          ["exponential", 1.4],
          ["zoom"],
          6,0.5,
          8,1,
          16,10
        ]
      }
    },
    {
      "id": "road_secondary_tertiary",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
        "let", "cl", ["string", ["get", "class"]],
        [
          "all",
          ["==", ["geometry-type"], "LineString"],
          [
            "any",
            ["==", ["var", "cl"], "secondary"],
            ["==", ["var", "cl"], "tertiary"]
          ]
        ]
      ],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsla(40, 20%, 80%, 0.9)",
        "line-width": [
          "interpolate",
          ["exponential", 1.4],
          ["zoom"],
          7,0.5,
          10,1,
          18,10
        ]
      }
    },
    //included START
    {
      "id": "highway_motorway_casing",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
        "all",
        ["==", ["string", ["geometry-type"]], "LineString"],
        ["==", ["string", ["get", "class"]], "motorway"]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter",
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsla(0, 0%, 67%, 1)",
        "line-width": {"base": 1.4, "stops": [[5.8, 0], [6, 3], [20, 40]]},
        "line-dasharray": [2, 0],
        "line-opacity": 1
      },
      "minzoom": 6,
      "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"}
    },
    {
      "id": "highway_motorway_inner",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
        "all",
        ["==", ["string", ["geometry-type"]], "LineString"],
        ["==", ["string", ["get", "class"]], "motorway"]
      ],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-color": {
          "base": 1,
          "stops": [[5.8, "hsla(0, 0%, 85%, 0.53)"], [6, "hsla(40, 20%, 80%, 1)"]]
        },
        "line-width": {"base": 1.4, "stops": [[4, 2], [6, 1.3], [20, 30]]}
      },
      "minzoom": 6,
      "metadata": {"mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5"}
    },
    //included END

    //REMOVED:
    /*
    {
      "id": "road_major_motorway",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "LineString"],
        ["==", ["string", ["get", "class"]], "motorway"]
      ]],
      "layout": {
        "line-cap": "round",
        "line-join": "round",
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsla(68, 33%, 31%, 1)",
        "line-width": [
          "interpolate",
          ["exponential", 1.4],
          ["zoom"],
          5,1,
          8,2,
          16,10
        ]
      }
    },
    */

    {
      "id": "road_oneway",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "minzoom": 15,
      "filter": ["==", ["number", ["get", "oneway"]], 1],
      "layout": {
        "symbol-placement": "line",
        "icon-image": "oneway",
        "symbol-spacing": 200,
        "icon-padding": 2,
        "icon-rotation-alignment": "map",
        "icon-rotate": 0,
        "icon-size": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          15, 0.5,
          19, 1
        ]
      },
      "paint": {
        "icon-opacity": 0.5
      }
    },
    {
      "id": "road_oneway_opposite",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "minzoom": 15,
       "filter": ["==", ["number", ["get", "oneway"]], -1],
      "layout": {
        "symbol-placement": "line",
        "icon-image": "oneway",
        "symbol-spacing": 200,
        "icon-padding": 2,
        "icon-rotation-alignment": "map",
        "icon-rotate": 180,
        "icon-size": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          15, 0.5,
          19, 1
        ]
      },
      "paint": {
        "icon-opacity": 0.5
      }
    },
    {
      "id": "railway-transit",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": ["boolean", [
        "all",
        ["==", ["string", ["get", "class"]], "transit"],
        ["!=", ["string", ["get", "brunnel"]], "tunnel"]
      ]],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsl(34, 12%, 66%)",
        "line-opacity": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          11, 0,
          16, 1
        ]
      }
    },
    //included START
    {
      "id": "railway",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
        "all",
        ["==", ["string", ["geometry-type"]], "LineString"],
        [
          "all",
          ["==", ["string", ["get", "class"]], "rail"]
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round"
      },
      "paint": {
        "line-color": "#999999",
        "line-width": {"base": 1.3, "stops": [[16, 3], [20, 7]]}
      },
      "minzoom": 11
    },
    {
      "id": "railway_dashline",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
        "all",
        ["==", ["string", ["geometry-type"]], "LineString"],
        [
          "all",
          ["==", ["string", ["get", "class"]], "rail"]
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round"
      },
      "paint": {
        "line-color": "#cccccc",
        "line-width": {"base": 1.3, "stops": [[16, 2], [20, 6]]},
        "line-dasharray": [3, 3]
      },
      "minzoom": 11
    },
    //included END

    //removed:
    /*
    {
      "id": "railway",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter":  ["==", ["string", ["get", "class"]], "rail"],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsl(34, 12%, 66%)",
        "line-opacity": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          11, 0,
          16, 1
        ]
      }
    },
    */
    {
      "id": "waterway-bridge-case",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "waterway",
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "LineString"],
        ["==", ["string", ["get", "brunnel"]], "bridge"]
      ]],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter"
      },
      "paint": {
        "line-color": "#bbbbbb",
        "line-width": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          12, 0.5,
          20, 10
        ],
        "line-gap-width": [
          "interpolate",
          ["exponential", 1.55],
          ["zoom"],
          4, 0.25,
          20, 30
        ]
      }
    },
    {
      "id": "waterway-bridge",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "waterway",
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "LineString"],
        ["==", ["string", ["get", "brunnel"]], "bridge"]
      ]],
      "layout": {
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-color": "hsl(205, 56%, 73%)",
        "line-width": [
          "interpolate",
          ["exponential", 1.55],
          ["zoom"],
          4, 0.25,
          20, 30
        ]
      }
    },
    {
      "id": "bridge_minor_case",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "LineString"],
        ["==", ["string", ["get", "brunnel"]], "bridge"],
        ["==", ["string", ["get", "class"]], "minor_road"]
      ]],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter"
      },
      "paint": {
        "line-color": "#dedede",
        "line-width": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          12, 0.5,
          20, 310
        ],
        "line-gap-width": [
          "interpolate",
          ["exponential", 1.55],
          ["zoom"],
          4, 0.25,
          20, 30
        ]
      }
    },
    {
      "id": "bridge_major_case",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "transportation",
      "filter": [
         "let", "cl", ["string", ["get", "class"]],
        [
          "all",
          ["==", ["geometry-type"], "LineString"],
          ["==", ["string", ["get", "brunnel"]], "bridge"],
          [
            "any",
            ["==", ["var", "cl"], "primary"],
            ["==", ["var", "cl"], "secondary"],
            ["==", ["var", "cl"], "tertiary"],
            ["==", ["var", "cl"], "trunk"]
          ]
        ]
      ],
      "layout": {
        "line-cap": "butt",
        "line-join": "miter"
      },
      "paint": {
        "line-color": "#dedede",
        "line-width": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          12, 0.5,
          20, 10
        ],
        "line-gap-width": [
          "interpolate",
          ["exponential", 1.55],
          ["zoom"],
          4, 0.25,
          20, 30
        ]
      }
    },
    {
      "id": "admin_sub_4",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "boundary",
      "minzoom": 6,
      "filter": [
         "let", "al", ["number", ["get", "admin_level"]],
        ["==", ["var", "al"], 4]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsl(0, 0%, 76%)",
        "line-dasharray": [2, 1]
      }
    },
    {
      "id": "admin_sub_6",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "boundary",
      "minzoom": 11,
      "filter": [
         "let", "al", ["number", ["get", "admin_level"]],
        ["==", ["var", "al"], 6]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsl(0, 0%, 76%)",
        "line-dasharray": [2, 1]
      }
    },
    {
      "id": "admin_sub_8",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "boundary",
      "minzoom": 14,
      "filter": [
         "let", "al", ["number", ["get", "admin_level"]],
        ["==", ["var", "al"], 8]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-color": "hsl(0, 0%, 76%)",
        "line-dasharray": [2, 1]
      }
    },
    {
      "id": "admin_country",
      "type": "line",
      "source": "openmaptiles",
      "source-layer": "boundary",
      "filter": ["boolean", [
        "all",
        ["<=", ["number", ["get", "admin_level"]], 2],
        ["==", ["geometry-type"], "LineString"]
      ]],
      "layout": {
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-color": "rgba(252, 252, 252, 0.7)",
        "line-width": [
          "interpolate",
          ["exponential", 1.15],
          ["zoom"],
          3, 0.5,
          22, 15
        ]
      }
    },
    {
      "id": "mountain_peak",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "mountain_peak",
      "minzoom": 7,
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["number", ["get", "rank"]], 1]
      ]],
      "layout": {
        "text-size": 10,
        "text-font": [
          "Noto Sans Regular"
        ],
        "visibility": "visible",
        "text-offset": [0, 0.5],
        "icon-size": 1,
        "text-anchor": "bottom",
        "text-field": "{name:latin} {name:nonlatin}\n{ele} m\n▲",
        "text-max-width": 8
      },
      "paint": {
        "text-color": "hsla(31, 100%, 15%, 1)",
        "text-halo-width": 1,
        "text-halo-color": "hsla(0, 100%, 100%, 0.8)",
        "text-halo-blur": 1
      }
    },
    {
      "id": "mountain_peak_2",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "mountain_peak",
      "minzoom": 12,
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["number", ["get", "rank"]], 2]
      ]],
      "layout": {
        "text-size": 9,
        "text-font": [
          "Noto Sans Regular"
        ],
        "visibility": "visible",
        "text-offset": [0, 0.5],
        "icon-size": 1,
        "text-anchor": "bottom",
        "text-field": "{name:latin} {name:nonlatin}\n{ele} m\n▲",
        "text-max-width": 8
      },
      "paint": {
        "text-color": "hsla(31, 100%, 15%, 1)",
        "text-halo-width": 0.5,
        "text-halo-color": "hsla(0, 100%, 100%, 0.8)",
        "text-halo-blur": 1
      }
    },
    {
      "id": "poi_label",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "poi",
      "minzoom": 14,
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["number", ["get", "rank"]], 1]
      ]],
      "layout": {
        "text-size": 11,
        "text-font": [
          "Noto Sans Regular"
        ],
        "visibility": "visible",
        "text-offset": [0, 0.5],
        "icon-size": 1,
        "text-anchor": "top",
        "text-field": "{name:latin}\n{name:nonlatin}",
        "text-max-width": 8
      },
      "paint": {
        "text-color": "#666",
        "text-halo-width": 1,
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-blur": 1
      }
    },
    {
      "id": "airport-label",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "aerodrome_label",
      "minzoom": 10,
      "filter": ["has", "iata"],
      "layout": {
        "text-size": 11,
        "text-font": [
          "Noto Sans Regular"
        ],
        "visibility": "visible",
        "text-offset": [0, 0.5],
        "icon-size": 1,
        "text-anchor": "top",
        "text-field": "{name:latin}\n{name:nonlatin}",
        "text-max-width": 8
      },
      "paint": {
        "text-color": "#666",
        "text-halo-width": 1,
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-blur": 1
      }
    },
    {
      "id": "road_major_label",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "transportation_name",
      "filter": ["==", ["geometry-type"], "LineString"],
      "layout": {
        "symbol-placement": "line",
        "text-field": "{name:latin} {name:nonlatin}",
        "text-font": [
          "Noto Sans Regular"
        ],
        "text-transform": "uppercase",
        "text-letter-spacing": 0.1,
        "text-size": [
          "interpolate",
          ["exponential", 1.4],
          ["zoom"],
          10, 8,
          20, 14
        ],
        "text-rotation-alignment": "map",
        "visibility": "visible"
      },
      "paint": {
        "text-color": "#000",
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-width": 2
      }
    },
    {
      "id": "place_label_other",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "place",
      "minzoom": 8,
      "filter": [
         "let", "cl", ["string", ["get", "class"]],
        [
          "all",
          ["==", ["geometry-type"], "Point"],
          [
            "all",
            ["!=", ["var", "cl"], "city"],
            ["!=", ["var", "cl"], "state"],
            ["!=", ["var", "cl"], "country"],
            ["!=", ["var", "cl"], "continent"]
          ]
        ]
      ],
      "layout": {
        "text-field": "{name:latin}\n{name:nonlatin}",
        "text-font": [
          "Noto Sans Regular"
        ],
        "text-max-width": 6,
        "text-size": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          6, 10,
          12, 14
        ],
        "visibility": "visible",
        "text-anchor": "center"
      },
      "paint": {
        "text-color": "rgba(255, 255, 255, 0.8)",
        "text-halo-color": "rgba(0, 0, 0, 0.5)",
        "text-halo-blur": 0,
        "text-halo-width": 1.4
      }
    },
    {
      "id": "place_label_city",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "place",
      "maxzoom": 16,
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["string", ["get", "class"]], "city"]
      ]],
      "layout": {
        "text-field": "{name:latin}\n{name:nonlatin}",
        "text-font": [
          "Noto Sans Regular"
        ],
        "text-max-width": 10,
        "text-size": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          3, 12,
          8, 16
        ],
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(255, 255, 255, 0.8)",
        "text-halo-color": "rgba(0, 0, 0, 0.5)",
        "text-halo-blur": 0,
        "text-halo-width": 1.6
      }
    },
    {
      "id": "country_label-other",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "place",
      "maxzoom": 12,
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["string", ["get", "class"]], "country"],
        ["!", ["has", "iso_a2"]]
      ]],
      "layout": {
        "text-field": "{name:latin}",
        "text-font": [
          "Noto Sans Regular"
        ],
        "text-max-width": 10,
        "text-size": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          3, 12,
          8, 22
        ],
        "visibility": "visible"
      },
      "paint": {
        "text-color": "hsl(0, 0%, 13%)",
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 2,
        "text-halo-blur": 0
      }
    },
    {
      "id": "country_label",
      "type": "symbol",
      "source": "openmaptiles",
      "source-layer": "place",
      "maxzoom": 12,
      "filter": ["boolean", [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["string", ["get", "class"]], "country"],
        ["has", "iso_a2"]
      ]],
      "layout": {
        "text-field": "{name:latin}",
        "text-font": [
          "Noto Sans Bold"
        ],
        "text-max-width": 10,
        "text-size": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          3, 12,
          8, 22
        ],
        "visibility": "visible"
      },
      "paint": {
        "text-color": "rgba(255, 255, 255, 1)",
        "text-halo-color": "rgba(0, 0, 0, 0.75)",
        "text-halo-width": 2,
        "text-halo-blur": 0
      }
    }
  ]
};
export {Style};
