<script>
import { getContext } from 'svelte';
const viewStore = getContext('viewStore');
const online = viewStore.getStores('online');
</script>

<style>
div {
  margin-right: 5px;
}
.online {
  color: green;
}
.offline {
  color: red;
  font-weight: bold;
}
</style>

{#if $online}
<div class="online inline-flex" title="You are ONLINE :-)"><svg class='icon w24 h24'><use xlink:href="#icon-circle" /></svg></div>
{:else}
<div class="offline inline-flex" title="You are OFFLINE :-(">!<svg class='icon w24 h24'><use xlink:href="#icon-circle" /></svg></div>
{/if}
