<svelte:options immutable/>

<script>
import { onMount, onDestroy, getContext } from 'svelte';
import {fade} from 'svelte/transition';

import {fromState} from './utils/helpers.js';

import {Util} from "./utils/util.js";

const viewStore = getContext('viewStore');

let CPopened = false;
let mouseenter = false;

const mapper = {
  'caret' : ['caret-up', 'caret-down'],
  'sprocket' : ['sprocket', 'sprocket']
};

export let uniqid = '';

$: {
  if (CPopened) {
    Util.dialogOpened(uniqid);
  } else {
    Util.dialogClosed(uniqid);
  };
};

const popstateListener = (ev) => {
//console.log('popstate CP '+uniqid, ev.state);
	const opened = fromState('dialogOpened', []);
	if (!opened[uniqid]) {
		CPopened = false;
	};
};

onMount(() => {
	window.addEventListener('popstate', popstateListener);
});

onDestroy(() => {
	window.removeEventListener('popstate', popstateListener);
});

//typ ikonky na rozbaleni
export let controlType = 'caret'; //caret | sprocket

//obsah controls v control panelu
export let controls;

//jestli je to samostatny control panel
export let standalone = true;

//jestli se ma otvirat jen na onclick (ne na prejeti mysi)
export let openOnclickOnly = false;

//pro pripadne umisteni jinam
export let cssStyle = "";

function forwardTouchstart (evt) {
  const touchstart = evt;
  viewStore.set({touchstart});
};

/**
* @param key fullscreen|sat|hires|airspace
* @param value cokoliv
*/
function changeControl(key, value, letOpened = false, localStorage = false) {
  viewStore.set({[key] : value});//nastavi globalni store promennou dle key
  if (localStorage) {
    try {
      window.localStorage.setItem(key, value);
    } catch (err) {
      console.log("localStorage write failed", err);
    };
  };
  controls = Util.arrUniq(controls);
  CPopened = letOpened; //zavre control panel, nebo ne
};


function getStoreValue (key) {
  return viewStore.get(key);
};

</script>

<div
class="CP mapboxgl-ctrl { CPopened ? 'open' : '' } {standalone ? 'standalone' : ''}"
style="{cssStyle}"
on:mouseleave="{() => {CPopened = false; mouseenter = false}}"
on:click|stopPropagation
on:mousemove|stopPropagation
on:touchstart|passive|stopPropagation={forwardTouchstart}>
{#if openOnclickOnly}
  <div
    class="CPiconOOO"
    on:click="{() => CPopened = !CPopened}"
  ><svg class='icon w30 h30 color-gray'><use xlink:href='#icon-{ CPopened ? mapper[controlType][0] :  mapper[controlType][1]  }'/></svg></div>
{:else}
  <div
    class="CPicon"
    on:mouseenter="{() => {CPopened = true; mouseenter = true}}"
    on:click="{() => {CPopened = mouseenter ? true : !CPopened; mouseenter = false}}"
  ><svg class='icon w30 h30 color-gray'><use xlink:href='#icon-{ CPopened ? mapper[controlType][0] :  mapper[controlType][1]  }'/></svg></div>
{/if}

{#if CPopened}
  <div
    class="CParea checkbox-pill {standalone ? '' : 'included'}" out:fade='{{delay: 100, duration: 200}}'>
    {#each controls as control}
      {#if control.checked !== undefined}
      <!--
        <label class="icon check" class:checked={control.checked}>
          <input type="checkbox"
            bind:checked="{control.checked}"
            on:change="{() => changeControl(control.key, control.checked, control.letOpened, control.localStorage)}" />{control.label}
        </label>
      -->
      <label class='checkbox-container flex flex--row'>
        <input type='checkbox' 
          bind:checked="{control.checked}"
          on:change="{() => changeControl(control.key, control.checked, control.letOpened, control.localStorage)}" 
        />
        <div class="checkbox mr12 mt3 color-green-light {control.checked ? 'bg-gray' : 'bg-gray-light'}">
          <svg class='icon'><use xlink:href='#icon-check' /></svg>
        </div>
        {control.label}
      </label>
      {:else if control.range !== undefined}

      <label for={control.key}>{control.label} <output>{getStoreValue(control.key)}</output>
        <input type="range"
          name={control.key}
          id={control.key}
          min={control.range[0]}
          max={control.range[1]}
          value="{getStoreValue(control.key)}"
          on:change={(evt) => changeControl(control.key, parseInt(evt.target.value), control.letOpened, control.localStorage)}
          class="w-5/6">
      </label>
      {/if}
    {/each}
  </div>
{/if}

</div>

<style>
div.CP {
  position: relative;
}
div.CP.standalone {
  position: absolute;
  border-radius: 2px;
  z-index: 3;
  top: 10px;
  right: 10px;
  background-color: hsla(0, 0%, 100%, 0.6);
  font-size: 10px;
  line-height: 12px;
}
div.CP.standalone.open {
  background-color: hsla(0, 0%, 100%, 0.9);
  z-index: 4;
}
div.CPicon, div.CPiconOOO {
  text-align: right;
  width: 30px;
  margin: auto 0 auto auto;
}
div.CPiconOOO {
  cursor: pointer;
}
div.CParea {
  width: 150px;
  padding: 5px 0;
}
div.CParea.included {
  position: absolute;
  right: 0;
  border-radius: 2px;
  background-color: hsla(0, 0%, 100%, 0.9);
}
div.CParea label {
  cursor: pointer;
  display: block;
  padding-left: 5px;
  line-height: 30px;
}
div.CParea label:before {
  margin-top: 5px;
}
div.CParea label.checked:before {
  background-color: hsla(0, 0%, 40%, 1);
  color: lightgreen;
}
div.CParea label:hover {
  background-color: hsla(0, 0%, 90%, 0.9);
}
output {
  font-weight: bold;
}
</style>
